import useRoles from "@/components/hooks/useRoles";
import { getOptions, viewEntity } from "@/features/admin/admin-actions";
import { AppDispatch, RootState } from "@/store";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getCaptcha, sendFeedback } from "@/features/web/web-actions";
import refresh from "@/assets/icon/refresh.png";
import styled from "styled-components";

const StyledForm = styled.div`
max-width: 842px;
margin: 0 auto;

label {
  font-size: 16px;
  margin-bottom: 8px;
}

input,
textarea,
select {
  border: 1px solid var(--blue);
  height: 50px;
}

textarea {
  height: 100px;
}

.submit-btn-container {
  text-align: center;

  button {
    background-color: var(--blue);
    height: 48px;
    width: 185px;

    @media (min-width: 425px) {
      width: 292px;
      height: 55px;
    }
  }
}
.capRefresh{
  cursor: pointer;
  color: var(--blue);
}
.error {
  color: red;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .sm_gap {
    gap: 24px;
  }
}
`;
interface CaptchaData {
  image: string;
  id: number;
}

const countriesLocal = [
  {
    id: 5,
    name: "India",
  },
];

const entityName = "patient";

const Feedback = ({
  style,
  entity = "",
}: any) => {
  let [searchParams] = useSearchParams();

  const [showForm, setShowForm] = useState(true);
  const [countries, setCountries] = useState(countriesLocal);
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };
  const { userInfo = null, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
    );
    const dispatch = useDispatch<AppDispatch>();
    
    const { entityId = null } = userInfo || {};
    
  const [captchaData, setCaptchaData] = useState<CaptchaData | null>(null);
  const [userData, setUserData] = useState<any>(null);

  const name = userData ? userData.first_name + " " + userData.last_name : "";

  const intialValues = {
    email: userData ? userData.email : "",
    name: name,
    country: userData ? userData.country_id : "",
    phone: userData ? userData.contact_no : "",
    feedback: "",
    captcha_code: "",
    captcha_id: captchaData ? captchaData.id : "",
  };

  const isPatient = useRoles("PATIENT");

  useEffect(() => {
    if (isAuthenticated && entityId && isPatient) {
      dispatch(viewEntity({ name: "patient", id: entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setUserData((payload as any).data);
          }
        }
      );
    }
  }, [dispatch, isAuthenticated, entityId, isPatient]);

  useEffect(() => {
    if (options && options.country) {
      setCountries(options.country);
    }
  }, [options]);

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);

  const fetchCaptcha = async () => {
    try {
      const captchaResponse = await getCaptcha();
      setCaptchaData(captchaResponse);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchCaptcha = async () => {
      try {
        const captchaResponse = await getCaptcha(); 
        setCaptchaData(captchaResponse);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCaptcha();
  }, []);

  return (
    <div
      className="card shadow"
      style={{ padding: 24, width: "100%", ...style }}
    >
      {showForm && (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={intialValues}
            validate={(values) => {
              const errors: any = {};

              if (!values.name) {
                errors.name = "Required";
              }

              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (values.phone === "") {
                errors.phone = "Required";
              }
              if (values.feedback === "") {
                errors.feedback = "Required";
              }
              if (values.captcha_code === "") {
                errors.captcha_code = "Required";
              }
              if (values.country === "") {
                errors.country = "Required";
              }
              return errors;
            }}
            onSubmit={(
              { country, ...restValues },
              { setSubmitting, resetForm, setErrors }
            ) => {
              const values = {
                ...restValues,
                country: parseInt(country),
              };
              dispatch(sendFeedback(values)).then(({ payload }) => {
                if (payload && payload.success) {
                  setErrors({});
                  resetForm();
                  setShowForm(false);
                } else {
                  setErrors({
                    captcha_code: "Captcha Invalid",
                  });
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <StyledForm>
                <div className="row" style={{ width: "100%", gap: "24px" }}>
              <div className="row sm_gap">
                <div className="form-group col-lg-6 pe-lg-3">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                 {touched.name && (
                        <div className="error">{(errors as any).name}</div>
                      )}
                </div>

                <div className="form-group col-lg-6">
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                 {touched.email && (
                        <div className="error">{(errors as any).email}</div>
                      )}
                </div>
              </div>

              <div className="row sm_gap">
                <div className="form-group col-lg-6 pe-lg-3">
                  <input
                    className="form-control"
                    type="number"
                    id="phone"
                    name="phone"
                    placeholder="Mobile Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    maxLength={10}
                  />
                  {touched.phone && (
                        <div className="error">{(errors as any).phone}</div>
                      )}
                </div>

                <div className="form-group col-lg-6">
                  <Form.Select
                    placeholder="Country"
                    className="form-control"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  >
                    <option disabled value="">Select Country</option>
                    {countries.map(({ id, name }: any) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                  {touched.country && (
                        <div className="error">{(errors as any).country}</div>
                      )}
                </div>
              </div>

              <div className="form-group ">
                <input
                  className="form-control"
                  id="feedback"
                  name="feedback"
                  placeholder="Feedback"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.feedback}
                />
                {touched.feedback && (
                        <div className="error">{(errors as any).feedback}</div>
                      )}
              </div>

              <div className="row">
                <div className="col-md-6">
                  {captchaData && (
                    <img
                      src={captchaData.image}
                      alt="Captcha"
                    />
                  )}
                </div>

                <div className="form-group col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    id="captcha_code"
                    name="captcha_code"
                    placeholder="Captcha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.captcha_code}
                  />
                 {touched.captcha_code && (
                        <div className="error">{(errors as any).captcha_code}</div>
                      )}
                </div>
                <div className="form-group col-md-6"><img className="capRefresh" src={refresh} 
                onClick={() => {
                  fetchCaptcha();
                }}
                width={15} alt="" /></div>
              </div>
              </div>

                  <div className="submit-btn-container">
                      <button type="submit" className="btn btn-primary">Submit
                    </button>
                  </div>
                </StyledForm>
              </form>
            )}
          </Formik>
        </>
      )}

      {!showForm && (
        <div>
          <p className="sub-heading bold blue">Feedback sent Successfully!</p>
          <div className="submit-btn-container">
            <button
              type="button"
              onClick={() => setShowForm(true)}
              className="btn btn-primary"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
