import styled from "styled-components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocationIcon from "@/components/common/LocationIcon";

const BestHospitalsWrapper = styled.div``;

const BestHospitalsRowStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .first-half,
  second-half {
    display: flex;
  }
  .first-half {
    flex-direction: column;
    gap: 20px;
  }

  .second-half {
    .view-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      button {
        flex: 0 0 40%;
        height: 50px;
        &.view {
          border: 2px solid var(--blue);
          background: #fff;
        }

        &.book {
          background: var(--blue);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 425px) {
    min-height: 124px;
    justify-content: space-between;
    margin: 35px 0;
    flex-direction: row;
    .first-half,
    second-half {
      flex: 0 0 50%;
    }

    .first-half {
      flex-direction: row;
      .name-speciality {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    .second-half {
      flex: 0 0 50%;
      justify-content: space-between;
      display: flex;

      .location-exp {
        display: flex;
        flex-direction: column;
        text-align: left;
        text-transform: capitalize;
      }

      .view-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          width: 234px;
          height: 50px;
          &.view {
            border: 2px solid var(--blue);
            background: #fff;
          }

          &.book {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
`;

interface IBestHospital {
  name: string;
  hospital_type: string;
  city: string;
  established_in: string;
  profile_url: string;
  id: string;
  slug: string;
}

interface BestHospitalsRowProps {
  // data: IBestDoctor;
  data: IBestHospital;
}

export const BestHospitalsRow = ({ data }: BestHospitalsRowProps) => {
  const { name, hospital_type, city, established_in, profile_url, id , slug} = data;
  const navigate = useNavigate();
  return (
    <BestHospitalsRowStyled>
      <div className="first-half">
        <img
          alt=""
          style={{ width: 130, height: 130, borderRadius: 10 }}
          src={profile_url}
        />
        <div className="name-speciality">
          <p className="bold">{name}</p>
          <p>{hospital_type}</p>
        </div>
      </div>
      <div className="second-half">
        <div className="location-exp">
          <LocationIcon className="location_section">{city}</LocationIcon>
          {established_in && <div className="exp">Since {established_in}</div>}
        </div>
        <div className="view-buttons">
          <button
            className="form-control view"
            onClick={() => navigate(`/hospital/${slug}`)}
          >
            View More
          </button>
          <button
            className="form-control book"
            onClick={() => navigate(`/enquiry?hospital_id=${id}`)}
          >
            Enquire Now
          </button>
        </div>
      </div>
    </BestHospitalsRowStyled>
  );
};

const TitleStyled = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 40%;
  }
`;

const TitleViewMore = ({ children, link }: any) => {
  return (
    <TitleStyled>
      <p className="title blue heading bold">{children}</p>
      {link && (
        <a className="d-none d-sm-block" href={link}>
          View More &gt;&gt;
        </a>
      )}
    </TitleStyled>
  );
};

const AssociatedHospitals = ({ title }: any) => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const { Hospitals } = doctor || { Hospitals: [] };

  return (
    <div>
      <TitleViewMore>{title}</TitleViewMore>
      {Hospitals.length == 0 && <div>Not Associated with Hospitals</div>}
      <BestHospitalsWrapper>
        {Hospitals &&
          Hospitals &&
          Hospitals.map((item: any, idx: number) => {
            return (
              <>
                {idx !== 0 && <hr />}
                <BestHospitalsRow key={idx} data={item} />
              </>
            );
          })}
      </BestHospitalsWrapper>
    </div>
  );
};

export default AssociatedHospitals;
