import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { useState } from 'react';
import styled from "styled-components";

const SectionStyled = styled.div`
  .title {
    margin-bottom: 24px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .card {
      width: 150px;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 425px) {
    .wrapper {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .card {
        width: 232px;
        min-height: 80px;
        padding: 5px 5px;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    .card {
      width: 280px !important;
      padding: 1.5em 0em;
      text-overflow: ellipsis;
    }
  }
  }
`;
const LoadMoreButton = styled.div`
 .loadMoreBtn button{
  border: 1px solid var(--blue);
  background: white;
  border-radius: 5px;
  width: 156px;
  height: 50px;
  position: relative;
  top: 3em;
  margin-bottom: 3em;

  @media (min-width: 425px) {
    width: 232px;
  }
 }
`;

const Services = () => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const [visibleItems, setVisibleItems] = useState(3); 
  const itemsPerLoad = 3;

  if (doctor) {
    const { Treatments = [] } = doctor;

    if (Treatments.length === 0) {
      return null;
    }

    const loadMore = () => {
      setVisibleItems((prevVisibleItems) =>
        Math.min(prevVisibleItems + itemsPerLoad, Treatments.length)
      );
    };

    const showLess = () => {
      setVisibleItems(3); 
    };

    return (
      <SectionStyled>
        <div className="title blue bold heading">Services</div>
        <div className="wrapper">
          {Treatments.slice(0, visibleItems).map(({ name, id }) => (
            <div key={id} className="card shadow">
              {name}
            </div>
          ))}
        </div>
        {visibleItems < Treatments.length ? (
          <LoadMoreButton>
            <div className="loadMoreBtn">
              <button onClick={loadMore}>View More</button>
            </div>
          </LoadMoreButton>
        ) : (
          visibleItems > 3 && (
            <LoadMoreButton>
              <div className="loadMoreBtn">
                <button onClick={showLess}>View Less</button>
              </div>
            </LoadMoreButton>
          )
        )}
      </SectionStyled>
    );
  }
  return null;
};



export default Services;
