import { Modal } from "react-bootstrap";

const ImageViewer = ({ handleClose, show, data }: any) => {
  const { [data.urlField]: imageUrl, popupTitle } = data;

  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{popupTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex justify-content-center">
          {imageUrl && <img src={imageUrl} alt="..." />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageViewer;
