import { BlueButton } from "@/components/styled";
import { useDoctorDetail } from "./use-doctor-detail";
import DoctorStyledSection from "./DoctorStyledSection";

const Achievements = () => {
  const data = useDoctorDetail("achievement");
  if (data.length === 0) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Achievements</div>
      <div className="wrapper justify">
        {data.map(({ achievement_name, achievement_year, document_url }, idx) => (
          <div key={idx} className="card shadow">
            <div className="bold">{achievement_name}</div>
            <div className="year">{achievement_year}</div>
          </div>
        ))}
      </div>
    </DoctorStyledSection>
  );
};

export default Achievements;
