import * as journeys from "@/assets/journeys";

import styled from "styled-components";
import Image from "@/components/utils/ResponsiveImage";
import { useTranslation } from "react-i18next";

export const Card = styled.div`
  width: 152px;
  margin-bottom: 20px;

  a.link-text {
    color: ${"var(--blue)"};
  }

  @media (min-width: 425px) {
    width: 396px;
  }
`;

export const ImageWrapper = styled.div`
@media (max-width: 768px) {
  img{
    height: 160px;
  }
}
`;

const texts = [
  "Create your login credentials",
  "Select the treatment from the drop down menu",
  "Select the doctor",
  "Compare the packages from shortlisted doctors",
  "Schedule teleconsultations",
  "Invite final quote and letter of invitation ",
  "Apply for the visa ",
  "Book your tickets",
  "Buy insurance",
  "Book accommodation ",
  "Add other services",
  "Get detailed itinerary",
];

const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;

`;
const Section = styled.div`
flex: 1 0 calc(50% - 10px); /* Adjusted for mobile */
margin: 5px;
@media (min-width: 768px) {
  flex: 1 0 21%; /* Original width for larger screens */
}
`;


const JourneyItems = ({ image, imagexs, text }: any) => {
  return (
    <Section className="">
      <ImageWrapper>
        <Image xs={imagexs} md={image} alt="" />
      </ImageWrapper>
      <p className="link-text">{text}</p>
    </Section>
  );
};

const Journey = () => {
  const { t } = useTranslation();

  return (
    <div className="p-2 card shadow">
      <p className="blue heading bold px-5">
        {t("homePage.journeyOfYourMedicalTour")}
      </p>
      <Sections>
        {texts.map((text, idx) => {
          const image = (journeys as any)["journey" + (idx + 1)];
          return (
            <JourneyItems
              key={idx}
              image={image}
              imagexs={image}
              text={t(`homePage.journey${idx + 1}`)}
            />
          );
        })}
      </Sections>
    </div>
  );
};

export default Journey;
