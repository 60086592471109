import { Formik, FormikValues } from "formik";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { registerHospital } from "@/features/web/web-actions";
import { StyledRegisterForm } from "../common/StyledRegisterForm";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { IFormProps } from "./HospitalFormWizard";
import CKEditorFormik from "../DoctorRegistrationForm/form-components/CKEditorFormik";

const HospitalSchema = Yup.object().shape({
  hospital_rules: Yup.array().min(1, "Required"),
  translator: Yup.array(),
  total_beds: Yup.number().typeError("Invalid").required("Required"),
  total_staff: Yup.number().typeError("Invalid").required("Required"),
  total_consultant: Yup.number().typeError("Invalid").required("Required"),
  number_of_patients_treated: Yup.number()
    .typeError("Invalid")
    .required("Required"),
  tele_consultation: Yup.string(),
  estimate: Yup.string(),
  visa_extension: Yup.string().required("Required"),
  travel_desk: Yup.string(),
  patient_attendant: Yup.string(),
  home_care: Yup.string(),
  team_info: Yup.string(),
  hospital_location_neighbourhood: Yup.string(),
});

const HospitalFormStep2 = ({
  setCurrentStep,
  formValues,
  setFormValues,
}: IFormProps) => {
  const { data } = useSelector((state: RootState) => state.admin);
  const formikRef = useRef<any>();
  const { options = {} } = data["hospital"] || { options: {} };
  const [genericError, setGenericError] = useState(null);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formValues}
      validationSchema={HospitalSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
        setErrors({});
        setGenericError(null);
        registerHospital(values).then((resp: any) => {
          if (!resp.success) {
            if (resp.responseCode === 422) {
              setErrors({
                ...resp.data,
              });
            } else {
              console.error(resp);
              setGenericError(resp.message);
            }
          } else {
            resetForm();
            setCurrentStep(3);
          }
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <StyledRegisterForm>
            {genericError && (
              <div className="genericError">{genericError}!</div>
            )}
            <div className="row gy-4 gx-5">
              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  style={{ width: "100%" }}
                  placeholder="Hospital Rules"
                  id="hospital_rules"
                  onBlur={handleBlur}
                  value={values.hospital_rules}
                  mode="multiple"
                  onChange={(value) => setFieldValue("hospital_rules", value)}
                  options={
                    options.hospital_rules &&
                    options.hospital_rules.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))
                  }
                />
                {touched.hospital_rules && (
                  <div className="error">{errors.hospital_rules}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  placeholder="Translator"
                  id="translator"
                  mode="multiple"
                  value={values.translator}
                  onChange={(value) => setFieldValue("translator", value)}
                  options={
                    options.translator &&
                    options.translator.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))
                  }
                />

                {touched.translator && (
                  <div className="error">{errors.translator}</div>
                )}
              </div>
              <div className="form-group col-md-3 ">
                <input
                  placeholder="Total Beds"
                  className="form-control"
                  name="total_beds"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.total_beds}
                />
                {touched.total_beds && (
                  <div className="error">{errors.total_beds}</div>
                )}
              </div>

              <div className="form-group col-md-3 ">
                <input
                  placeholder="Total Staff"
                  className="form-control"
                  name="total_staff"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.total_staff}
                />
                {touched.total_staff && (
                  <div className="error">{errors.total_staff}</div>
                )}
              </div>
              <div className="form-group col-md-3 ">
                <input
                  placeholder="Total Consultants"
                  className="form-control"
                  name="total_consultant"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.total_consultant}
                />
                {touched.total_consultant && (
                  <div className="error">{errors.total_consultant}</div>
                )}
              </div>
              <div className="form-group col-md-3 ">
                <input
                  placeholder="Total Patients treated"
                  className="form-control"
                  name="number_of_patients_treated"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number_of_patients_treated}
                />
                {touched.number_of_patients_treated && (
                  <div className="error">
                    {errors.number_of_patients_treated}
                  </div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  placeholder="Multi-cusine in-house canteen"
                  id="cuisine"
                  mode="multiple"
                  value={values.cuisine}
                  onChange={(value) => setFieldValue("cuisine", value)}
                  options={
                    options.cuisine &&
                    options.cuisine.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))
                  }
                />

                {touched.cuisine && (
                  <div className="error">{errors.cuisine}</div>
                )}
              </div>

              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  placeholder="Local commute"
                  id="local_commute"
                  mode="multiple"
                  value={values.local_commute}
                  onChange={(value) => setFieldValue("local_commute", value)}
                  options={
                    options.local_commute &&
                    options.local_commute.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))
                  }
                />

                {touched.local_commute && (
                  <div className="error">{errors.local_commute}</div>
                )}
              </div>
              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Visa Extension &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.visa_extension && (
                      <span className="error">{errors.visa_extension}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="visa_extension"
                          value="1"
                          checked={values.visa_extension === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="visa_extension"
                          value="0"
                          checked={values.visa_extension === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Tele-consultation &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.tele_consultation && (
                      <span className="error">{errors.tele_consultation}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="tele_consultation"
                          value="1"
                          checked={values.tele_consultation === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="tele_consultation"
                          value="0"
                          checked={values.tele_consultation === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Estimate &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.estimate && (
                      <span className="error">{errors.estimate}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="estimate"
                          value="1"
                          checked={values.estimate === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="estimate"
                          value="0"
                          checked={values.estimate === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Travel desk &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.travel_desk && (
                      <span className="error">{errors.travel_desk}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="travel_desk"
                          value="1"
                          checked={values.travel_desk === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="travel_desk"
                          value="0"
                          checked={values.travel_desk === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Home Care &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.home_care && (
                      <span className="error">{errors.home_care}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="home_care"
                          value="1"
                          checked={values.home_care === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="home_care"
                          value="0"
                          checked={values.home_care === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="hospitalrdqna">
                  <p>
                    Patient attendant &nbsp;&nbsp;&nbsp;&nbsp;
                    {touched.patient_attendant && (
                      <span className="error">{errors.patient_attendant}</span>
                    )}
                  </p>
                  <div className="inter_radio">
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="patient_attendant"
                          value="1"
                          checked={values.patient_attendant === "1"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="inter_radio">
                      <label>
                        <input
                          type="radio"
                          name="patient_attendant"
                          value="0"
                          checked={values.patient_attendant === "0"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <label htmlFor="">About Hospital </label>
              <div className="form-row col-md-12">
                <CKEditorFormik name="about" />
              </div>
              <label htmlFor="">Hospital Location & Neighbourhood</label>
              <div className="form-row col-md-12">
                <CKEditorFormik name="hospital_location_neighbourhood" />
              </div>
              <label htmlFor="">Teams & Speciality</label>
              <div className="form-row col-md-12">
                <CKEditorFormik name="team_info" />
              </div>

              <div className="submit-btn-container">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setFormValues((existing: FormikValues) => ({
                      ...existing,
                      ...values,
                    }));
                    setCurrentStep(1);
                  }}
                >
                  Previous
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </StyledRegisterForm>
        </form>
      )}
    </Formik>
  );
};
export default HospitalFormStep2;
