import { BlueButton } from "@/components/styled";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import logoAdmin from "@/assets/logo-admin.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { userLogin } from "@/features/auth/authActions";
import { useState } from "react";

const LoginContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .adminContainer {
    height: 100%;
    display: flex;
  }
  
  .container {
    height: 100%;
    display: flex;
  }

  .form-section,
  .branding-section {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .form-section {
    justify-content: center;
  }

  .branding-section {
    background: linear-gradient(
      180deg,
      rgba(43, 172, 226, 0.3) 0%,
      rgba(137, 197, 67, 0.3) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .login-form {
    width: 99%;
    margin-top: 20px;
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .switch {
    font-size: 12px;
    input:checked {
      background-color: var(--green);
      border: 0;
    }
  }

  .signin {
    font-size: 10px;
    text-transform: uppercase;
    height: 45px;
  }
`;

const AdminLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [rememberMe, setRememberMe] = useState(true);
  return (
    <LoginContainer>
      <div className="adminContainer">
        <div className="form-section">
          <div className="form-wrapper">
            <div className="title">
              <div className="sub-heading blue bold">Welcome to PMT Admin Portal</div>
              <div className="bold link-text">
                Enter your email and password to sign in
              </div>
            </div>
            <div className="login-form">
              <Formik
                initialValues={{ password: "", email: "" }}
                validate={(values) => {
                  const errors: any = {};

                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  if (values.password === "") {
                    errors.password = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  dispatch(
                    userLogin({
                      rememberMe,
                      ...values,
                    })
                  );
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, values }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    E-mail
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Your email address"
                    />
                    Password
                    <input
                      className="form-control"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Your password"
                    />
                    <Form.Check
                      type="switch"
                      className="switch"
                      label="Remember me"
                      checked={rememberMe}
                      onChange={(e) => {
                        setRememberMe(e.target.checked);
                      }}
                    />
                    <BlueButton
                      className="form-control bold signin"
                      type="submit"
                    >
                      Sign In
                    </BlueButton>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="branding-section">
          <div className="logo-wrapper">
            <img src={logoAdmin} alt="" />
          </div>
        </div>
      </div>
    </LoginContainer>
  );
};

export default AdminLogin;
