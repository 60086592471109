import SliderTestimonial from "../home/SliderTestimonial";
import ImageGallery from "./ImageGallery";
import Certificate from "./Certificate";

const Gallery = () => {
  return (
    <div>
      <ImageGallery/>
    </div>
  );
};

export default Gallery;
