import { ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import HospitalFormStep1 from "./HospitalFormStep1";
import { AppDispatch } from "@/store";
import { useDispatch } from "react-redux";
import { getOptions } from "@/features/admin/admin-actions";
import HospitalFormStep2 from "./HospitalFormStep2";
import { Navigate } from "react-router-dom";

const intialValues = {
  name: "",
  ownership: "",
  profile_url: "",
  country: "",
  treatments: [],
  form_c: "",
  hospital_infrastructure: [],

  hospital_rules: [],
  local_commute: [],
  city: "",
  established_in: "",
  hospital_service_categories: [],
  hospital_type: "",
  accreditation: [],

  translator: [],
  total_beds: "",
  total_staff: "",
  total_consultant: "",
  cuisine: [],

  number_of_patients_treated: "",
  about: "",
  tele_consultation: "",
  estimate: "",
  visa_extension: "",
  travel_desk: "",
  patient_attendant: "",
  home_care: "",
  hospital_location_neighbourhood: "",
  address: "",
  state: "",
  zipcode: "",
  treatment: [],
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  contact_no: "",
  team_info: "",
};

export interface IFormProps {
  formValues: typeof intialValues;
  setCurrentStep: any;
  setFormValues: any;
}

const Form1 = ({ style, isCompact = false }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formValues, setFormValues] =
    useState<typeof intialValues>(intialValues);
  useEffect(() => {
    dispatch(getOptions("hospital"));
  }, [dispatch]);
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [currentStep]);
  return (
    <>
      {currentStep === 1 && (
        <>
          <div className="text-center">
            <p className="mb-0">Welcome! </p>
            <p className="pb-3">
              Please help us know you better for a better experience!
            </p>
            <ProgressBar className="progressBar" now={33} />
          </div>
          <HospitalFormStep1
            formValues={formValues}
            setFormValues={setFormValues}
            setCurrentStep={setCurrentStep}
          />
        </>
      )}
      {currentStep === 2 && (
        <>
          <div className="text-center">
            <p className="mb-0">Welcome! </p>
            <p className="pb-3">
              Please help us know you better for a better experience!
            </p>
            <ProgressBar className="progressBar" now={66} />
          </div>
          <HospitalFormStep2
            setFormValues={setFormValues}
            formValues={formValues}
            setCurrentStep={setCurrentStep}
          />
        </>
      )}
      {currentStep === 3 && <Navigate to="../partner/thank-you" />}
    </>
  );
};

export default Form1;
