import styled from "styled-components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import LocationIcon from "@/components/common/LocationIcon";

const BestDoctorsWrapper = styled.div``;

const BestDoctorsRowStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .first-half,
  second-half {
    display: flex;
  }
  .first-half {
    flex-direction: column;
    gap: 20px;
  }

  .second-half {
    .view-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      button {
        flex: 0 0 40%;
        height: 50px;
        &.view {
          border: 2px solid var(--blue);
          background: #fff;
        }

        &.book {
          background: var(--blue);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 425px) {
    justify-content: space-between;
    margin: 35px 0;
    flex-direction: row;
    min-height: 130px;
    .first-half,
    second-half {
      flex: 0 0 50%;
    }

    .first-half {
      flex-direction: row;
      .name-speciality {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    .second-half {
      flex: 0 0 50%;
      justify-content: space-between;
      display: flex;

      .location-exp {
        display: flex;
        flex-direction: column;
        text-align: left;
        text-transform: capitalize;
      }

      .view-buttons {
        margin-top: 0px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          width: 234px;
          height: 50px;
          &.view {
            border: 2px solid var(--blue);
            background: #fff;
          }

          &.book {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
`;

interface IBestDoctor {
  id: number;
  first_name: string;
  salutation: string;
  slug: string;
  last_name: string;
  practice_start_date: string;
  profile_url: string;
  rank_in_country: number;
  Specializations: [];
  city: string;
}

// interface IBestDoctorSectionProps {
//   data: {
//     title: string;
//     list: IBestDoctor[];
//   };
// }

interface BestDoctorsRowProps {
  data: IBestDoctor;
}

export const BestDoctorsRow = ({ data }: BestDoctorsRowProps) => {
  const {
    first_name,
    salutation,
    last_name,
    practice_start_date,
    profile_url,
    id,
    slug,
    Specializations,
    city,
  } = data;
  const startDate = new Date(practice_start_date);
  const practiceStartDateYear = startDate.getFullYear();
  const navigate = useNavigate();
  let specialistArr = [];
  if (Specializations) {
    specialistArr = Specializations.map((item: any, idx) => {
      return item.name;
    });
  }

  return (
    <BestDoctorsRowStyled>
      <div className="first-half">
        <img
          alt=""
          style={{
            width: 130,
            height: 130,
            borderRadius: 10,
            alignSelf: "flex-start !important",
          }}
          src={profile_url}
        />
        <div className="name-speciality">
          <p className="bold">{`${salutation} ${first_name} ${last_name}`}</p>
          <p>{specialistArr.join(", ")}</p>
        </div>
      </div>
      <div className="second-half">
        <div className="location-exp">
          <div className="d-none d-sm-block location ">
            <LocationIcon>{city}</LocationIcon>
          </div>
          {practice_start_date && !isNaN(practiceStartDateYear) && (
            <div className="exp">
              Practising Sincexxx {practiceStartDateYear}
            </div>
          )}
        </div>
        <div className="view-buttons">
          <button
            className="form-control view"
            onClick={() => navigate(`/doctor/${slug}`)}
          >
            View More
          </button>
          <button
            className="form-control book"
            onClick={() => navigate(`/doctor/${id}`)}
          >
            Enquire Now
          </button>
        </div>
      </div>
    </BestDoctorsRowStyled>
  );
};

const TitleStyled = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 40%;
  }
`;

const TitleViewMore = ({ children, link }: any) => {
  return (
    <TitleStyled>
      <p className="title blue heading bold">{children}</p>
      {link && (
        <NavLink className="d-none d-sm-block" to={link}>
          View More &gt;&gt;
        </NavLink>
      )}
    </TitleStyled>
  );
};

const AssociatedDoctors = () => {
  const { associatedDrs, data, error } = useSelector(
    (state: RootState) => state.hospital
  );

  if (!data && error) {
    console.error(error);
    return null;
  }
  return (
    <div>
      <TitleViewMore link="../doctors">Doctors</TitleViewMore>
      {associatedDrs.length === 0 && <div>No Doctor Associated</div>}
      <BestDoctorsWrapper>
        {associatedDrs.map((item, idx) => {
          return (
            <div key={idx}>
              {idx !== 0 && <hr />}
              <BestDoctorsRow data={item} />
            </div>
          );
        })}
      </BestDoctorsWrapper>
    </div>
  );
};

export default AssociatedDoctors;
