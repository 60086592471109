import React from 'react'
import styled from "styled-components";
import AmbulanceHelpForm from '../AmbulanceHelpForm';
import img1 from "@/assets/amulance-cervices-img/vecteezy_white-high-speed-fast-trains-stadler-on-high-speed-rail_14463568_293.jpg" 



const StyledSection = styled.div`
.train_amb ul li{
    text-align: left;
    padding: 5px 0px;
    list-style: none;
}
.train_amb ul li span{
    font-weight: bold;
}
.train_amb h6{
    text-align:left;
    padding: 20px 0px;
    line-height: 1.6;
}
@media only screen and (max-width: 768px) {
    .train_amb{
      padding: 1.5rem;
    }
  }
`;

function index() {
  return (
      <StyledSection>
        <div className='train_amb'>
            <h1 className='blue heading bold'>Train Ambulance</h1>
            <div className="row section1 pt-2 pb-4">
                <div className="col-md-6">
                    <img src={img1} width={400} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 justify">
                    <p>A train ambulance is a specialized medical transportation service that uses trains to transport patients who require medical attention or emergency care. Train ambulances are particularly useful in countries or regions with an extensive and well-developed railway network. Here are some key points about train ambulances:</p>
                    <p><b>Purpose : </b>Train ambulances are used to transport patients who are in critical condition, require specialized medical care, or need to cover long distances quickly while receiving medical attention. They can be used for both emergency and non-emergency medical transportation.</p>
                    <p><b>Facilities : </b>Train ambulances are equipped with medical equipment and facilities to provide basic to advanced medical care during transit. This includes beds, monitoring equipment, medical supplies, and trained medical personnel such as doctors and nurses.</p>
                </div>
            </div>

            <ul>
                <li>
                    <p><span>Types of Patients : </span>Train ambulances can transport a variety of patients, including those with serious injuries, medical conditions, or those who are unable to travel by other means. They can also be used for the transportation of organ donors and transplant recipients.</p>
                </li>
                <li>
                    <p><span>Accessibility : </span>In regions where train ambulances are available, they provide an efficient means of reaching remote or underserved areas where road transportation may be challenging or time-consuming.</p>
                </li>
                <li>
                    <p><span>Cost-Effective : </span> In some cases, train ambulance services can be more cost-effective than other modes of transportation, especially for long-distance journeys.</p>
                </li>
                <li>
                    <p><span>Coordination : </span>The coordination of train ambulance services typically involves cooperation between healthcare providers, railway authorities, and emergency response teams. Proper planning and scheduling are essential to ensure timely and safe transportation.</p>
                </li>
                <li>
                    <p><span>Safety Measures : </span> Safety is a top priority for train ambulances. The train staff and medical personnel are trained to handle emergency situations that may arise during transit.</p>
                </li>
                <li>
                    <p><span>Government and Private Services : </span>Train ambulance services may be operated by government health agencies, private medical transport companies, or in partnership between both. The availability and quality of these services can vary depending on the region.</p>
                </li>
                <li>
                    <p><span>International Use : </span>Train ambulances are not limited to a single country and can be found in various parts of the world where railway infrastructure supports their operation.</p>
                </li>
                <li>
                    <p><span>Limitations : </span>While train ambulances are an efficient mode of transportation in certain situations, they may not be suitable for all cases, especially when time is of the essence. Helicopter or air ambulances may be preferred for extremely urgent situations.</p>
                </li>
            </ul>
            <p className='justify'>In summary, train ambulances are an important part of the healthcare transportation system in regions with robust railway networks. They provide a means to transport patients over long distances while ensuring they receive the necessary medical care during transit.</p>
        </div>
        <section id="Ambulance">
        <AmbulanceHelpForm style={{ maxWidth: 842, margin: "0 auto" }}/>
        </section>
    </StyledSection>

  )
}

export default index