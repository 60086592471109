import "./styles.css";
import whatsappLogo from "@/assets/icon/whatsapp.svg";
import mail from "@/assets/icon/mail.svg";
import { FlexChildContainer, IClassName } from "./ContactBar";
import styled from "styled-components";

const ContactsLink = styled.a`
  display: flex;
  gap: 20px;
  span{
    color: var(--blue);
  }
`;

const Contacts = ({ className }: IClassName) => {
  return (
    <div className={className}>
      <FlexChildContainer style={{ gap: "12px" }}>
        <div className="">
          <ContactsLink target="_blank" href="http://wa.me/+917977733207">
            <img src={whatsappLogo} alt="" width={30} height={30} />
            <span>+917977733207</span>
          </ContactsLink>
        </div>

        <div className="">
          <ContactsLink
            target="_blank"
            href="mailto:patientcare@planmedicaltourism.com"
          >
            <img alt="" src={mail} width={30} height={24} />
            <span>patientcare@planmedicaltourism.com</span>
          </ContactsLink>
        </div>
      </FlexChildContainer>
    </div>
  );
};

export default Contacts;
