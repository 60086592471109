import styled from "styled-components";

export const StyledRegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;

  .form-row {
    flex-direction: row;
  }

  .card {
    width: 898px;
    margin: "0 auto";
  }

  .form-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  input,
  textarea,
  select,
  .select,
  .input-btn,
  button {
    border: 1px solid var(--blue);
  }

  .select {
    width: 100%;
  }

  .array-field-row {
    margin-bottom: 20px;
  }

  .array-field-row:last-child {
    margin-bottom: 0px;
  }

  .ant-upload {
    background: white;
    border-radius: 10px;
    border: 0.5px solid var(--blue);
    width: 100%;
  }

  .profile-picture-upload {
    width: 200px;
    margin: 0 auto;
  }

  .profile-picture-upload .avatar {
    width: 200px;
    height: 200px;
  }

  .profile-picture-upload .ant-upload {
    display: inline;
    padding: 0;
    margin: 0;
    border: none !important;
    color: 0.5px solid var(--blue);
  }

  .select {
    border-radius: 10px;

    .ant-select-selector {
      border: none;
      border-radius: 10px;

      .ant-select-selection-placeholder {
      }
    }
  }

  span.ant-upload-wrapper {
    display: block;
  }

  input,
  .ant-select .ant-select-selector,
  .input-btn,
  .select,
  select {
    min-height: 50px;
  }

  .input-btn {
    width: 100%;
    font-size: 16px;
  }

  .ant-select,
  .ant-select .ant-select-selector {
  }

  .submit-btn-container {
    text-align: right;
    display: flex;
    justify-content: space-evenly;

    button {
      background-color: var(--blue);
      height: 48px;
      width: 185px;

      @media (min-width: 425px) {
        width: 292px;
        height: 55px;
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }

  .otp {
    width: 50%;
    margin: 0 auto;

    .spacer {
      width: 1em;
      display: inline-block;
    }
  }

  .hospitalrdqna {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
    border: 1px solid #2bace2;
    border-radius: 5px;
    padding: 0px 10px;
  }

  .inter_radio {
    display: flex;
    align-items: center;
    gap: 20px;

    label {
      align-items: center;
      display: flex;

      input {
        margin-right: 10px;
      }
    }
  }

  .genericError {
    color: red;
    text-align: center;
  }

  .form-group {
    margin-bottom: 25px;
  }

  a.add-new-btn {
    margin: 24px;
  }
  .attachments {
    display: flex;
    .attachment-link {
      text-decoration: underline;
      color: var(--blue);
      margin-right: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 768px) {

  }
`;
