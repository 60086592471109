import { Button, Modal } from "react-bootstrap";
import { useCallback, useState } from "react";
import { StyledForm, Tpl } from "../entities/EntityForm";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import {
  deleteSubEntity,
  getAllSubEntity,
  subEntityCreate,
} from "@/features/admin/admin-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useOptions from "../entities/useOptions";
import { confirm } from "react-bootstrap-confirmation";
import widgets from "../rjsf-widgets/widgets";
import styled from "styled-components";
import { CloseCircleFilled } from "@ant-design/icons";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  .image {
    flex: 0 1 30%;
    border-radius: 10px;
    border: 2px solid var(--blue);
    overflow: hidden;
    position: relative;
  }
  .image .deleteBtn {
    display: none;
    color: red;
    right: 0;
    bottom: 0;
    font-size: 24px;
    position: absolute;
  }
  .image:hover .deleteBtn {
    display: block;
  }
`;

const GalleryPopup = ({ handleClose, show, data }: any) => {
  const { data: storeData } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppDispatch>();
  const [gridOptions, setGridOptions] = useState<any>([]);
  const { entity } = useParams();
  const [schema, setSchema] = useState<RJSFSchema>();
  const [uischema, uiSetSchema] = useState<RJSFSchema>();
  const finalSchema = useOptions(schema, entity);
  const { conf } = data;
  const [showList, setShowList] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [message, setMessage] = useState<null | string>(null);
  const [formData, setFormData] = useState<null | Record<string, unknown>>();
  useEffect(() => {
    (async () => {
      if (conf && conf.key) {
        try {
          const schema = await import(`../schemas/${conf.key}.json`);
          setSchema(schema as RJSFSchema);
        } catch (e) {
          console.error("schema not found");
        }

        try {
          const uischema = await import(`../schemas/${conf.key}-ui.json`);
          uiSetSchema(uischema as RJSFSchema);
        } catch (e) {
          console.warn("uischema not found");
        }

        try {
          const go = await import(`../grid-options/${conf.key}`);
          setGridOptions(go);
        } catch (e) {
          console.error("GridOptions not found");
        }

        dispatch(
          getAllSubEntity({
            name: entity,
            subEntity: conf.key,
            id: data.id,
          })
        );
      }
    })();
  }, [dispatch, entity, data.id, conf, conf.key]);

  useEffect(() => {
    if (storeData && entity) {
      const rowData = storeData[entity][conf.key];
      if (rowData) {
        setRowData(rowData);
      }
    }
  }, [storeData, entity, conf.key]);

  const onAdd = useCallback(() => {
    setFormData(null);
    setShowList(false);
    setMessage(null);
  }, []);

  const back = () => {
    setShowList(true);
  };
  const onSubmit = ({ formData }: any) => {
    setMessage(null);
    dispatch(
      subEntityCreate({
        body: formData,
        name: entity,
        id: data.id,
        subEntity: conf.key,
      })
    ).then((res) => {
      if (res.hasOwnProperty("error")) {
        setMessage(res.payload as string);
      } else {
        setShowList(true);
        setFormData(null);
        setMessage((res.payload as any).message!);
      }
    });
  };

  const deleteHandle = async (o: any) => {
    const doDelete = await confirm("Are you sure you want to delete?", {
      okText: "delete",
    });
    if (doDelete) {
      dispatch(
        deleteSubEntity({
          name: entity,
          subEntity: conf.key,
          id: data.id,
          subId: o.data.id,
        })
      );
    }
  };
  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{conf.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showList && (
          <div className="list">
            <div style={{ textAlign: "center", color: "green" }}>{message}</div>
            <div
              className="d-flex justify-content-end"
              style={{ marginBottom: 10 }}
            >
              <Button onClick={onAdd}>Add</Button>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", maxHeight: 500, overflow: "auto" }}
            >
              {!!rowData.length && (
                <ImageWrapper>
                  {rowData.map((item, idx) => {
                    const { image_url } = item;
                    return (
                      <div className="image">
                        <CloseCircleFilled
                          className="deleteBtn"
                          onClick={() => deleteHandle({ data: item })}
                        />
                        <img key={idx} src={image_url} alt="..." />
                      </div>
                    );
                  })}
                </ImageWrapper>
              )}

              {rowData.length === 0 && (
                <h5 className="text-center"> No Images found! </h5>
              )}
            </div>
          </div>
        )}
        {!showList && (
          <>
            <Button onClick={back}>&lt; Back</Button>
            {message && (
              <div style={{ textAlign: "center", color: "orange" }}>
                {message}
              </div>
            )}
            <StyledForm
              schema={finalSchema as RJSFSchema}
              uiSchema={uischema as UiSchema}
              formData={formData}
              widgets={widgets}
              validator={validator}
              onSubmit={onSubmit}
              onChange={({ formData }) => setFormData(formData as any)}
              templates={{ FieldTemplate: Tpl }}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GalleryPopup;
