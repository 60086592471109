import {
  FieldTemplateProps,
  RJSFSchema,
  RegistryFieldsType,
} from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import styled from "styled-components";
import { createElement, memo, useEffect, useMemo, useState } from "react";
import useOptions from "./useOptions";
import widgets from "../rjsf-widgets/widgets";
import { Select, message } from "antd";
import { calculateBMI } from "@/pages/my-profile/MyProfile";

export const StyledForm = styled(Form)`
  & > .myfield {
    margin-bottom: 20px;
  }
  fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    .myfield {
      display: flex;
      flex: 1 1 48%;
      flex-direction: column;
      margin-bottom: 0px;
      min-height: 60px;
      span.req {
        color: red;
      }
    }
  }

  .field-radio-group {
    font-size: 14px;
    display: flex;
    margin: 0 10px;
    flex-wrap: wrap;
    > .radio {
      flex: 1 1 50%;
      margin-top: 10px;
      input[type="radio"] {
        margin-right: 5px;
      }
    }
  }
`;

export function Tpl(props: FieldTemplateProps) {
  const { id, label, required, children, errors } = props;
  return (
    <div className="myfield">
      <label htmlFor={id}>
        {label}
        {required ? <span className="req">*</span> : null}
      </label>
      {children}
      {errors}
    </div>
  );
}

const ArrayField = (props: any) => {
  const options = useMemo(() => {
    if (props.schema.items.enum) {
      return props.schema.items.enum.map((item: any, idx: number) => ({
        label: props.schema.items.enumNames[idx],
        value: item,
      }));
    }
    return [];
  }, [props.schema.items]);

  const widgetName = props.uiSchema["ui:widget"];

  const [value, setValue] = useState(props.value || props.formData);

  useEffect(() => {
    const val = props.value || props.formData;
    if (val && Array.isArray(val)) {
      setValue(val);
    }
  }, [props.value, props.formData, value]);

  useEffect(() => {
    //undefined check;
    if (value && value.indexOf(undefined) !== -1) {
      props.onChange(value.filter(Boolean));
    }
  }, [value]);

  if (props.registry.widgets[widgetName]) {
    return createElement(props.registry.widgets[widgetName], { ...props });
  }

  const handleChange = (value: string[]) => {
    props.onChange(value);
  };

  return (
    <Select
      placeholder="Select"
      style={{ maxHeight: 200 }}
      value={value}
      mode="multiple"
      onChange={handleChange}
      filterOption={(inputValue, option) =>
        !!option && typeof option.label === 'string' && option.label.toLowerCase().includes(inputValue.toLowerCase())
    }
    
      options={options}
    />
  );
};

const customFields: RegistryFieldsType = { ArrayField };

const SchemaForm = ({
  schema,
  uiSchema,
  onSubmit,
  entity,
  formData,
  onChange,
  errors,
}: any) => {
  const [fd, setfd] = useState(formData);
  const finalSchema = useOptions(schema, entity, fd);

  const onFormChange = (data: any, id?: string) => {
  };

  const handleFormSubmit = async (formData: any) => {
    await onSubmit(formData);
    message.success(`Success! ${formData.formData.name}  record has been added.`);
  };

  useEffect(() => {
  }, [formData]);

  return (
    <>
      {finalSchema && (
        <StyledForm
          showErrorList={false}
          schema={finalSchema as RJSFSchema}
          uiSchema={uiSchema}
          validator={validator}
          onSubmit={handleFormSubmit} 
          widgets={widgets}
          templates={{ FieldTemplate: Tpl }}
          formData={fd}
          fields={customFields}
          onChange={onFormChange}
          extraErrors={errors}
          noHtml5Validate
        />
      )}
    </>
  );
};

export default memo(SchemaForm);