import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { message } from "antd";
import StyledLoginForm from "./StyledLoginForm";
import { sendPasswordResetLink } from "@/features/web/web-actions";
import { useState } from "react";

const ForgotPassword = ({ style }: any) => {
  const { usertype = "" } = useParams<{ usertype: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [screen, setScreen] = useState("SEND_OTP");
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    setFieldValue,
    errors,
  } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await dispatch(sendPasswordResetLink(values));
        message.success("Password reset link sent successfully");
        navigate(`/login/${usertype}`);
      } catch (error) {
        message.error("Failed to send password reset link");
      }
    },
  });


  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <form onSubmit={handleSubmit}>
        <StyledLoginForm>
          <h3> Forgot Password</h3>
            <>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email Id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && <div className="error">{errors.email}</div>}
                </div>
              </div>

              <div className="submit-btn-container">
                <button
                  disabled={errors && errors.hasOwnProperty("email")}
                  type="submit"
                  className="btn btn-primary"
                >
                  Send
                </button>
              </div>
            </>
        </StyledLoginForm>
      </form>
    </div>
  );
};

export default ForgotPassword;
