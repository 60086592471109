import DoctorStyledSection from "./DoctorStyledSection";
import YoutubeEmbed from "./YoutubeVideo";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

const DoctorIntroVideo = () => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const { video_url = null } = doctor || { video_url: "" };

  if (!video_url) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Intro Video </div>
      <YoutubeEmbed url={video_url} />
    </DoctorStyledSection>
  );
};

export default DoctorIntroVideo;
