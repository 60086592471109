import styled from "styled-components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocationIcon from "@/components/common/LocationIcon";
import { Pagination } from "react-bootstrap";
import React from "react";

const BestDoctorsWrapper = styled.div``;

const BestDoctorsRowStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .first-half,
  second-half {
    display: flex;
  }
  .first-half {
    flex-direction: column;
    gap: 20px;
  }

  .second-half {
    .view-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      button {
        flex: 0 0 40%;
        height: 50px;
        &.view {
          border: 2px solid var(--blue);
          background: #fff;
        }

        &.book {
          background: var(--blue);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 425px) {
    justify-content: space-between;
    margin: 35px 0;
    flex-direction: row;
    .first-half,
    second-half {
      flex: 0 0 50%;
    }

    .first-half {
      flex-direction: row;
      .name-speciality {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    .second-half {
      flex: 0 0 50%;
      justify-content: space-between;
      display: flex;

      .location-exp {
        display: flex;
        flex-direction: column;
        text-align: left;
        text-transform: capitalize;
      }

      .view-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          width: 234px;
          height: 50px;
          &.view {
            border: 2px solid var(--blue);
            background: #fff;
          }

          &.book {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
`;

interface IBestDoctor {
  id: number;
  first_name: string;
  slug: string;
  last_name: string;
  salutation: string;
  Specializations: string;
  practice_start_date: string;
  profile_url: string;
  rank_in_country: number;
  city: string;
}

interface BestDoctorsRowProps {
  data: IBestDoctor;
}

const BestDoctorsRow = ({ data }: BestDoctorsRowProps) => {
  const { first_name,
    last_name,
    practice_start_date,
    profile_url,
    id,
    salutation,
    slug,
    city,
    Specializations } =
    data;
  const SpecializationsArr =
    Specializations && Specializations.length
      ? Array.from(Specializations).map(({ name }: any) => name)
      : [];
  const startDate = new Date(practice_start_date);
  const practiceStartDateYear = startDate.getFullYear();
  const navigate = useNavigate();
  return (
    <BestDoctorsRowStyled>
      <div className="first-half">
        <img
          alt=""
          style={{ width: 130, height: 130, borderRadius: 10 }}
          src={profile_url}
        />
        <div className="name-speciality">
          <p className="bold">{`${salutation} ${first_name} ${last_name}`}</p>
          <p>{SpecializationsArr.join(",")}</p>
        </div>
      </div>
      <div className="second-half">
        <div className="location-exp">
          <div className="d-none d-sm-block location ">
            <LocationIcon>{city}</LocationIcon>
          </div>
          <div className="exp">Practising Since {practiceStartDateYear}</div>
        </div>
        <div className="view-buttons">
          <button
            className="form-control view"
            onClick={() => navigate(`/doctor/${slug}`)}
          >
            View More
          </button>
          <button
            className="form-control book"
            onClick={() => navigate(`/doctor/${slug}`)}
          >
            Enquire Now
          </button>
        </div>
      </div>
    </BestDoctorsRowStyled>
  );
};

const TitleStyled = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 40%;
  }
`;

const TitleViewMore = ({ children, link }: any) => {
  return (
    <TitleStyled>
      <p className="title blue heading bold">{children}</p>
      {link && (
        <a className="d-none d-sm-block" href={link}>
          View More &gt;&gt;
        </a>
      )}
    </TitleStyled>
  );
};

const TopDoctors = ({ className = "" }: any) => {
  const { data = { name: "treatment" }, topDoctors } = useSelector(
    (state: RootState) => state.treatment
  );

  const [activePage, setActivePage] = React.useState(1);
  const itemsPerPage = 3;

  const totalPages = Math.ceil(topDoctors.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  return (
    <div className={className}>
      <TitleViewMore>Best Doctors In India for {data?.name}</TitleViewMore>
      <BestDoctorsWrapper>
        {topDoctors
          .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
          .map((item, idx) => {
            return (
              <>
                {idx !== 0 && <hr />}
                <BestDoctorsRow key={idx} data={item} />
              </>
            );
          })}
      </BestDoctorsWrapper>

      <Pagination className="mt-4 d-flex align-items-center justify-content-center">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === activePage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default TopDoctors;
