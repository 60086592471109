import React from "react";
import styled from "styled-components";
import whatsapp from "@/assets/icon/whatsapp.png";

const StyledSection = styled.div`
  .whatsapp-button {
    position: fixed;
    bottom: 199px;
    right: -15px;
    padding: 10px;
    border-radius: 50%;
    z-index: 1000;
    cursor: pointer;
  }

  .whatsapp-button img {
    width: 40px;
    height: 40px;
    display: block;
  }
`;
function WhatsAppButton() {
  const whatsappLink = "http://wa.me/+917977733207";
  return (
    <StyledSection>
      <div>
        <a
          href={whatsappLink}
          target='_blank'
          rel='noopener noreferrer'
          className='whatsapp-button'
        >
          <img src={whatsapp} className='w-100 ' alt='...' />
        </a>
      </div>
    </StyledSection>
  );
}

export default WhatsAppButton;
