import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useEffect } from "react";
import { getBestof } from "@/features/web/web-actions";
import { useSelector } from "react-redux";
import { WebDoctorState } from "@/features/web/web-slice";
import { useNavigate } from "react-router-dom";
import LocationIcon from "@/components/common/LocationIcon";
import NABH from "@/assets/accreditation/nabh.png";
import NABL from "@/assets/accreditation/nabl.png";
import JCI from "@/assets/accreditation/jci.png";

const BestHospitalsWrapper = styled.div``;

const BestHospitalsRowStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .hospitalHeading,
  .location_section {
    font-size: 20px;
    text-wrap: balance;
  }
  .first-half,
  second-half {
    display: flex;
  }
  .first-half {
    flex-direction: column;
    gap: 20px;
  }

  .second-half {
    .view-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      button {
        flex: 0 0 40%;
        height: 50px;
        &.view {
          border: 2px solid var(--blue);
          background: #fff;
        }

        &.book {
          background: var(--blue);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 425px) {
    min-height: 124px;
    justify-content: space-between;
    margin: 35px 0;
    flex-direction: row;
    .first-half,
    second-half {
      flex: 0 0 50%;
    }

    .first-half {
      flex-direction: row;
      .name-speciality {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    .second-half {
      flex: 0 0 50%;
      justify-content: space-between;
      display: flex;

      .location-exp {
        display: flex;
        flex-direction: column;
        text-align: left;
        text-transform: capitalize;
      }

      .view-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          width: 234px;
          height: 50px;
          &.view {
            border: 2px solid var(--blue);
            background: #fff;
          }

          &.book {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
`;
const AccreditationSection = styled.div`
  .accreditationImg {
    display: flex;
    align-items: center;
  }
  .accreditationImg img {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 768px) {
    .accreditationImg {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      gap: 10px;
    }
  }
`;
/*interface IBestDoctor {
  doctorName: string;
  speciality: string;
  location: string;
  experience: string;
  profile_url: string;
}*/

interface IBestHospital {
  name: string;
  hospital_type: string;
  city: string;
  established_in: string;
  profile_url: string;
  profile_alt_tag: string;
  id: string;
  slug: string;
  HospitalType: any;
  accreditation: { id: number; name: string }[];
}

/*interface IBestHospitalsectionProps {
  data: {
    title: string;
    list: IBestDoctor[];
  };
}*/

interface BestHospitalsRowProps {
  // data: IBestDoctor;
  data: IBestHospital;
}

const apidata = {
  title: "Best Doctors in India",
  list: [],
};

interface Accreditation {
  id: number;
  name: string;
}

interface Hospital {
  accreditation: Accreditation[];
}

const AccreditationImages: React.FC<{ accreditation: Accreditation[] }> = ({
  accreditation,
}) => {
  const accreditationImages: Record<string, string> = {
    NABH: NABH,
    NABL: NABL,
    JCI: JCI,
  };
  return (
    <AccreditationSection>
      <div className="accreditationImg">
        {accreditation.map((accreditation) => {
          const { id, name } = accreditation;
          const imageUrl = accreditationImages[name];
          return (
            <div key={id}>
              {imageUrl ? <img src={imageUrl} alt={name} /> : <p>{name}</p>}
            </div>
          );
        })}
      </div>
    </AccreditationSection>
  );
};
export const BestHospitalsRow = ({ data }: BestHospitalsRowProps) => {
  const {
    name,
    hospital_type,
    city,
    established_in,
    profile_url,
    profile_alt_tag,
    id,
    slug,
    HospitalType,
    accreditation
  } = data;
  
  const navigate = useNavigate();
  return (
    <div>
      <BestHospitalsRowStyled>
        <div className="first-half">
          <img
            alt={profile_alt_tag}
            style={{ width: 160, height: 160, borderRadius: 10 }}
            src={profile_url}
          />
          <div className="name-speciality">
            <p className="hospitalHeading bold">{name}</p>
            <p>
              {hospital_type
                ? hospital_type
                : HospitalType && HospitalType.name}
            </p>
            <AccreditationImages accreditation={accreditation} />
          </div>
        </div>
        <div className="second-half">
          <div className="location-exp">
            <LocationIcon className="location_section">{city}</LocationIcon>
            <div className="exp">Since {established_in}</div>
          </div>
          <div className="view-buttons">
            <button
              className="form-control view"
              onClick={() => navigate(`/hospital/${slug}`)}
            >
              View More
            </button>
            <button
              className="form-control book"
              onClick={() =>
                navigate(`/hospital/${slug}`)
              }
            >
              Enquire Now
            </button>
          </div>
        </div>
      </BestHospitalsRowStyled>
      <hr />
    </div>
  );
};

const TitleStyled = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 40%;
  }
`;

const TitleViewMore = ({ children, link }: any) => {
  
  return (
    <TitleStyled>
      <p className="title blue heading bold">{children}</p>
      {link && (
        <a className="d-none d-sm-block" href={link}>
          View More &gt;&gt;
        </a>
      )}
    </TitleStyled>
  );
};

const BestHospitals = ({ title, category }: any) => {
  // const dispatch = useDispatch<AppDispatch>();
  // const datax = useSelector((state:RootState) => state.web);
  // const category = 'country';
  const data = apidata;
  const { [`bestin_${category}` as keyof WebDoctorState]: datax } = useSelector(
    (state: RootState) => state.web.data.hospital
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      getBestof({
        entity: "hospital",
        category,
      })
    );
  }, []);

  return (
    <div>
      <TitleViewMore link="./search/hospital">
        {title || data.title}
      </TitleViewMore>
      <BestHospitalsWrapper>
        {datax &&
          datax.list &&
          datax.list.map((item, idx) => {
            return (
              <>
                {idx !== 0}
                <BestHospitalsRow key={idx} data={item} />
              </>
            );
          })}
      </BestHospitalsWrapper>
    </div>
  );
};

export default BestHospitals;
