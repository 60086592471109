import { removeEmpty } from "@/admin/entities/EntityPopup";
import { getAllCities, getTreatmentsByCat } from "@/features/web/web-actions";
import { Select } from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SearchContainer = styled.div`
  padding: 24px;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;

  .select .ant-select-selector,
  select,
  input {
    width: 152px;
    height: 48px;
    border: 2px solid var(--blue);
    font-size: 16px;
    overflow: auto;
    @media (max-width: 425px) {
      width: 152px;
      height: 48px;
      font-size: 11px;
    }
    @media (min-width: 425px) {
      width: 268px;
      height: 64px;
    }
  }

  button {
    height: 48px;
    width: 210px;
    color: #fff;
    background: var(--blue);
    @media (max-width: 468px) {
      height: 48px;
      width: 152px;
    }
    @media (min-width: 425px) {
      width: 234px;
      height: 64px;
    }
  }
  .sbType {
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    justify-content: center;
    font-size: 11px;
  }
`;

export const hospitalTypes = [
  { id: 1, name: "Allopathy" },
  { id: 2, name: "Naturopathy" },
  { id: 3, name: "Ayurveda" },
  { id: 4, name: "Others" },
  { id: 5, name: "Deaddiction" },
  { id: 6, name: "Wellness" },
];

interface SearchValTypes {
  treatmentSlug: null | string;
  city: null | string;
  hospitalType: null | number[];
}

const SearchBar = ({ entity }: any) => {
  const [params] = useSearchParams();
  const [values, setValues] = useState<SearchValTypes>({
    treatmentSlug: params.get("treatmentSlug") || null,
    city: params.get("city") || null,
    hospitalType: params.get("hospitalType")
      ? decodeURI(params.get("hospitalType") as string)
          .split(",")
          .map(Number)
      : [],
  });

  const [options, setOptions] = useState({
    treatments: [],
    cities: [],
  });

  const navigate = useNavigate();

  const onSearch = () => {
    let queryObj = removeEmpty(values);
    const query = new URLSearchParams(queryObj).toString();
    navigate(`/search/${entity}?${query}`);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((vals) => ({
      ...vals,
      [name]: value || null,
    }));
  };

  useEffect(() => {
    getAllCities().then((cities) => {
      setOptions((opts) => ({
        ...opts,
        cities,
      }));
    });
  }, []);

  const hospitalTypeChange = useCallback((value: any[]) => {
    setValues((vals) => ({
      ...vals,
      hospitalType: value,
    }));
  }, []);

  useEffect(() => {
    if (values.hospitalType?.length) {
      const categoryQuery = values.hospitalType
        .map((id: number) => `category_id=${id}`)
        .join("&");
      getTreatmentsByCat(categoryQuery).then((treatments) => {
        setOptions((opts) => ({
          ...opts,
          treatments,
        }));
      });
    } else {
      setOptions((opts) => ({
        ...opts,
        treatments: [],
      }));
    }
  }, [values.hospitalType]);

  return (
    <SearchContainer className="card shadow">
      <Select
        className="select sbType"
        placeholder={entity + " Type"}
        id="specialization"
        mode="multiple"
        value={values.hospitalType}
        onChange={hospitalTypeChange}
        options={hospitalTypes.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
      />

      <Form.Select
        placeholder="Treatment"
        className="form-control"
        name="treatmentSlug"
        onChange={handleChange}
        value={values.treatmentSlug!}
      >
        <option selected value="">
          Select Treatment
        </option>
        {options.treatments &&
          options.treatments.map(({ id, slug, name }: any) => (
            <option value={slug}> {name}</option>
          ))}
      </Form.Select>
      <Form.Select
        placeholder="City"
        className="form-control"
        name="city"
        value={values.city!}
        onChange={handleChange}
      >
        <option value="">City </option>
        {options.cities &&
          options.cities.map(({ id, name }: any) => (
            <option value={id}> {name}</option>
          ))}
      </Form.Select>
      <div>
        <button className="primary form-control" onClick={onSearch}>
          Search
        </button>
      </div>
    </SearchContainer>
  );
};

export default SearchBar;
