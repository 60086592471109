import styled from "styled-components";
import { FlexChildContainer, IClassName } from "./ContactBar";
import "./search.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef } from "react";

const Input = styled.input<{ $inputColor?: string }>`
  border: none;
  width: 90%;
  padding: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  font-size: 14px;
  :focus {
    outline-width: 0;
    outline: none;
  }
  background: transparent;
  height: 100%;
`;

const Icon = styled.a`
  right: 10px;
  top: 4px;
  cursor: pointer;
`;

const Search = ({ className }: IClassName) => {
  const [searchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement | null>(null);
  const keyword = searchParams.get("q") || "";
  const navigate = useNavigate();
  const handleSubmit = () => {
    const q = new FormData(formRef.current as HTMLFormElement).get("q");
    navigate(`./search-all?q=${q}`);
    return false;
  };

  return (
    <div className={className}>
      <FlexChildContainer>
        <form onSubmit={handleSubmit} method="get" ref={formRef}>
          <div className="search-wrapper">
            <Input name="q" type="search" defaultValue={keyword} />
            <Icon className="icon" onClick={handleSubmit}>
              <i className="bi-search"></i>
            </Icon>
          </div>
        </form>
      </FlexChildContainer>
    </div>
  );
};

export default Search;
