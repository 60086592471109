/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import HospitalDetail from "./HospitalDetail";
import BelowDetail from "./BelowDetail";
import AboutSection from "./AboutSection";
import TreatmentSection from "../doctors/Treatments";
import FacilitiesSection from "./FacilitiesSection";
// import SurroundingsSection from "./SurroundingsSection";
import PhotosSection from "./Photos";
import HelpForm from "../doctors/HelpForm";
import HospitalNav from "./HospitalNav";
import DetailPageLayout from "@/conatiners/layouts/DetailPageLayout";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fetchAssociateDoctors,
  fetchHospitalById,
  fetchHospitalGallery,
} from "@/features/web/hospital-actions";
import AssociatedDoctors from "./AssociatedDoctors";
import { useSelector } from "react-redux";
import TreatmentsHospital from "../doctors/TreatmentsHospital";
import { Helmet } from "react-helmet";

interface HospitalData {
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  canonical: string;
}

const HospitalDetailPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, error } = useSelector((state: RootState) => state.hospital);

  const { slug } = useParams();
  const [hospitalId, setHospitalId] = useState('');
  const [hospitalData, setHospitalData] = useState<HospitalData | null>(null);
  
  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        const response = await dispatch(fetchHospitalById(slug as string));
        const id = response.payload.data.id; 
        if (id) {
          setHospitalData(response.payload.data);
          setHospitalId(id);
          dispatch(fetchHospitalGallery(parseInt(id)));
          dispatch(fetchAssociateDoctors(parseInt(id)));
        }
      } catch (error) {
        
        console.error('Error fetching hospital data:', error);
      }
    };
  
    fetchHospitalData();
  }, [dispatch, slug]);
  

  

  if (!data && error) {
    console.error(error);
    return <div>Hospital Not found!</div>;
  }

  return (
    <DetailPageLayout
      sideBar={
        <>
          <HospitalNav />
          <HelpForm additionalVals={{ hospital_id: hospitalId }} />
        </>
      }
      content={
        <>
         <Helmet>
        <title>{hospitalData?.meta_title}</title>
        <meta name="keywords" content={hospitalData?.meta_keyword} />
        <meta name="description" content={hospitalData?.meta_description} />
        <link rel="canonical" href={hospitalData?.canonical} />
      </Helmet>
          <HospitalDetail />
          <BelowDetail />
          <div>
            <a id="About"></a>
            <AboutSection />
          </div>
          <div>
            <a id="Doctors"></a>
            <AssociatedDoctors />
          </div>
          <div>
            <a id="Treatment"></a>
            <TreatmentsHospital title="Treatments" />
          </div>
          <div>
            <a id="Facilities"></a>
            <FacilitiesSection />
          </div>
          {/*<a id="Surroundings"></a>
          <SurroundingsSection />*/}
          <div>
            <a id="Photos"></a>
            <PhotosSection />
          </div>
          <div className="d-block d-sm-none">
            <HelpForm additionalVals={{ hospital_id: hospitalId }} />
          </div>
        </>
      }
    />
  );
};

export default HospitalDetailPage;
