import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk action creator
export const fetchDoctorById = createAsyncThunk(
  "doctor/fetchById",
  async (slug: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/slug/${slug}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk action creator
export const fetchDoctorsByHospitalId = createAsyncThunk(
  "doctors/fetchByHospitalId",
  async (hosId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/associate/hospital/${hosId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQualification = createAsyncThunk(
  "doctor/qualication/get",
  async (docId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/qualification/get/all/${docId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getExperience = createAsyncThunk(
  "doctor/experience/get",
  async (docId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/experience/get/all/${docId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addDoctor = createAsyncThunk(
  "create/doctor",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/web/create`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addHospital = createAsyncThunk(
  "create/hospital",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/hospital/create`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRank = createAsyncThunk(
  "update/ranking",
  async ({ putData, entity }: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity}/updaterank`,
        {
          method: "PUT",
          body: JSON.stringify(putData),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDoctorMasterData = createAsyncThunk(
  "treatments/fetch",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/get/master/data`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      }
      return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addProfile = createAsyncThunk(
  "create/hospital",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDoctorDetairFor = async (section: string, slug: string) => {
  try {
    const { data, success, message } = await fetch(
      `${API_BASE_URL}/api/tourism/doctor/${section}/get/all/slug/${slug}`
    ).then((data) => data.json());
    if (success) {
      return data;
    }

    throw new Error(message);
  } catch (error) {
    return error;
  }
};
