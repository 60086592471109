import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { useState } from 'react';
import styled from "styled-components";

const SectionStyled = styled.div`
.title{
  margin-bottom:24px;
}
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .card{
        width: 100%;
        height 68px;
    }
  }
  @media (max-width: 768px) {
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    align-items: center;
      gap: 24px;
      .card{
          width: 280px;
          height 68px;
      }
    }
  }
`;
const LoadMoreButton = styled.div`
 .loadMoreBtn button{
  border: 1px solid var(--blue);
  background: white;
  border-radius: 5px;
  width: 156px;
  height: 50px;
  position: relative;
  top: 3em;
  margin-bottom: 3em;

  @media (min-width: 425px) {
    width: 234px;
  }
 }
`;

const Specializations = () => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const defaultItemsToShow = 3;
  const [visibleCount, setVisibleCount] = useState(defaultItemsToShow);

  if (doctor) {
    const { Specializations = [] } = doctor;

    const visibleSpecializations = Specializations.slice(0, visibleCount);
    const hasMoreItems = Specializations.length > visibleCount;

    const showMoreItems = () => {
      setVisibleCount(prevCount => prevCount + defaultItemsToShow);
    };

    const showLessItems = () => {
      setVisibleCount(defaultItemsToShow);
    };

    return (
      <SectionStyled>
        <div className="title blue bold heading">Specializations</div>
        <div className="wrapper">
          {visibleSpecializations.map(({ name, id }) => (
            <div key={id} className="card shadow">
              {name}
            </div>
          ))}
        </div>
        {hasMoreItems && (
          <LoadMoreButton>
            <div className="loadMoreBtn">
              <button onClick={showMoreItems}>View More</button>
            </div>
          </LoadMoreButton>
        )}
        {visibleCount > defaultItemsToShow && (
          <LoadMoreButton>
            <div className="loadMoreBtn">
              <button onClick={showLessItems}>View Less</button>
            </div>
          </LoadMoreButton>
        )}
      </SectionStyled>
    );
  }

  return null;
};


export default Specializations;
