import CostRange from "./CostRange";
import FileWidget from "./FileWidget";
import RTEditor from "./RTEditor";

const widgets = {
  fileWidget: FileWidget,
  RTEditor: RTEditor,
  costRange: CostRange,
};

export default widgets;
