import styled from "styled-components";

const NavWrapper = styled.div`
  ul {
    margin: 0;
    padding: 0;
    li {
      text-align: left;
      list-style-type: none;
      padding: 19px;
      height: 68px;
      border-top: 1px solid rgb(202, 202, 202);
      font-size: 20px;
      &:first-child {
        border-top: none;
      }
    }
  }
`;

const TreatmentNav = () => {
  return (
    <NavWrapper className="card shadow">
      <ul>
        <li>
          <a href="#About">About the treatment</a>
        </li>
        <li>
          <a href="#Cost">Cost of treatment</a>
        </li>
        <li>
          <a href="#Inclusivity">Inclusivity in cost</a>
        </li>
        <li>
          <a href="#Factors">Factors affecting cost</a>
        </li>
        {/* <li>
          <a href="#Cities">Popular cities in India</a>
        </li> */}
        <li>
          <a href="#Hospitals">Top Hospitals in India</a>
        </li>
        <li>
          <a href="#Doctors">Best Doctors in India</a>
        </li>
      </ul>
    </NavWrapper>
  );
};

export default TreatmentNav;
