import styled from "styled-components";
import { BlueButton } from "@/components/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { getBlogById, publishBlog } from "@/features/admin/admin-actions";
import { confirm, alert } from "react-bootstrap-confirmation";
import interceptor from "@/utils/intercept";
import { Helmet } from "react-helmet";

const StyledSection = styled.div`
  padding: 20px;
  text-align: left;
`;

const BlogDetail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<any>({});
  const { slug = "", preview } = useParams();
  const [id, setId] = useState('');
  
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        if (slug) {
          interceptor();
          const response = await dispatch(getBlogById(slug));
          const blogId = response.payload.id;
          
          if (blogId) {
            setId(blogId.toString());
            setData(response.payload);
          }
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };
    fetchBlogData();
  }, [dispatch, slug]);
  

  const navigate = useNavigate();
  const handlePublish = async (o: any) => {
    const publish = await confirm("Are you sure you want to Publish?", {
      okText: "Publish",
    });
    if (publish) {
      dispatch(publishBlog(id)).then((res) => {
        const message = res.hasOwnProperty("error")
          ? res.payload
          : "Blog updated successfully";
        alert(message).then((a) => {
          navigate(`/blog/${slug}`);
        });
      });
    }
  };

  const { title, document_url, banner_alt_tag, content ,meta_title,meta_keyword,meta_description ,canonical} = data;

  return (
    <StyledSection>
      {preview && (
        <div style={{ textAlign: "right" }}>
          <BlueButton style={{ padding: 10 }} onClick={handlePublish}>
            Publish
          </BlueButton>
        </div>
      )}
       <Helmet>
        <title>{meta_title}</title>
        <meta name="keywords" content={meta_keyword} />
        <meta name="description" content={meta_description} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <div className="article-title green sub-heading">{title}</div>
      <div style={{ textAlign: "center" }}>
        <img src={document_url} alt={banner_alt_tag} />
      </div>
      <br />
      <br />
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
    </StyledSection>
  );
};

export default BlogDetail;
