import { RootState } from "@/store";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .photos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (min-width: 425px) {
    .title {
      font-size: 36px;
      text-align: left;
    }
    .photos {
      img {
        flex: 0 1 45%;
        max-width: 45% !important;
      }
    }
  }
  @media (max-width: 768px) {
.photos {
  padding: 0px 2em;
}
  }
`;

const PhotosSection = () => {
  const { gallery, data, error } = useSelector(
    (state: RootState) => state.hospital
  );

  if (!data && error) {
    console.error(error);
    return null;
  }
  return (
    <StyledSection>
      <div className="title blue bold heading">Gallery</div>
      {gallery.length === 0 && <div>No Images found</div>}
      <div className="photos">
        {gallery.map(({ image_url }, idx) => (
          // <Image xs={image_url} md={image_url} />
          <img alt="..." key={idx} src={image_url} />
        ))}
      </div>
    </StyledSection>
  );
};

export default PhotosSection;
