import { useEffect, useState } from "react";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import dermatology from "@/assets/doctors/treatments/dermatology.png";
import eyeDrop from "@/assets/doctors/treatments/eye-drop.png";
import gynecology from "@/assets/doctors/treatments/gynecology.png";
import heartRate from "@/assets/doctors/treatments/heart-rate.png";
import neurology from "@/assets/doctors/treatments/neurology.png";
import oncology from "@/assets/doctors/treatments/oncology.png";
import orthopedics from "@/assets/doctors/treatments/orthopedics.png";
import soreThroat from "@/assets/doctors/treatments/sore-throat.png";
import surgeryRoom from "@/assets/doctors/treatments/surgery-room.png";
import urology from "@/assets/doctors/treatments/urology.png";
import { NavLink } from "react-router-dom";

import styled from "styled-components";

const CardOuter = styled.div`
  width: 93px;
  @media (min-width: 425px) {
    width: 180px;
  }
`;

const CardInner = styled.div`
  width: 93px;
  height: 91px;
  img {
    width: 95%;
  }
  @media (min-width: 425px) {
    width: 180px;
    height: 180px;
  }
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0;
  .item {
    width: 234px;
    height: 75px;
    line-height: 1;
    padding: 0px 5px;
    box-shadow: 0px 4px 20px 0 rgba(148, 148, 148, 0.24) !important;
    color: #61dafb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 425px) {
    font-size: 20px;
  }
`;
const LoadMoreButton = styled.div`
  .loadMoreBtn button {
    border: 1px solid var(--blue);
    background: white;
    border-radius: 5px;
    width: 156px;
    height: 50px;
    position: relative;
    top: 3em;

    @media (min-width: 425px) {
      width: 234px;
    }
  }
`;
const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  @media (min-width: 425px) {
    gap: 85px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const list = [
  {
    image: heartRate,
    title: "Cardiology",
  },
  {
    image: neurology,
    title: "Cardiac Surgery",
  },
  {
    image: dermatology,
    title: "Joint Replacement ",
  },
  {
    image: soreThroat,
    title: "Gynecology & Infertility Specialist",
  },
  {
    image: orthopedics,
    title: "Oncology",
  },
  {
    image: urology,
    title: "Dentistry",
  },
  {
    image: gynecology,
    title: "Neurology & Neuro surgery",
  },

  {
    image: eyeDrop,
    title: "Urology & Nephrology",
  },

  {
    image: oncology,
    title: "Rheumatology",
  },

  {
    image: surgeryRoom,
    title: "Others",
  },
];

const TreatmentCard = ({ name, id, slug, profile_url }: any) => (
  <CardOuter>
    <NavLink to={`../treatment/${slug}`}>
      <CardInner className='card shadow'>
        <img alt='' src={profile_url} />
      </CardInner>
      <div className='item'>{name}</div>
    </NavLink>
  </CardOuter>
);

const TreatmentsHospital = ({ title }: any) => {
  const [data, setData] = useState([]);
  const defaultDisplayCount = 3;
  const [displayCount, setDisplayCount] = useState(defaultDisplayCount);
  const { data: hospital } = useSelector((state: RootState) => state.hospital);

  const { treatments } = hospital || { treatments: [] };

  if (!hospital) {
    return null;
  }

  const handleLoadMore = () => {
    setDisplayCount(displayCount + defaultDisplayCount);
  };

  const handleShowLess = () => {
    setDisplayCount(defaultDisplayCount);
  };

  return (
    <div>
      {title && <p className='title blue heading bold'>{title}</p>}
      <TreatmentsWrapper>
        {treatments &&
          treatments
            .slice(0, displayCount)
            .map((item: any, idx) => <TreatmentCard key={idx} {...item} />)}
      </TreatmentsWrapper>
      {treatments && treatments.length > defaultDisplayCount && (
        <div className='loadMoreBtn'>
          {displayCount < treatments.length ? (
            <LoadMoreButton>
              <div className='loadMoreBtn'>
                <button onClick={handleLoadMore}>View More</button>
              </div>
            </LoadMoreButton>
          ) : (
            displayCount > defaultDisplayCount && (
              <LoadMoreButton>
                <div className='loadMoreBtn'>
                  <button onClick={handleShowLess}>View Less</button>
                </div>
              </LoadMoreButton>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default TreatmentsHospital;
