import { RootState } from "@/store";
import { useSelector } from "react-redux";
import styled from "styled-components";
import userImg from "@/assets/doctors/doctor-detail.png";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  .title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 20px;
  }
  .img {
    width: 120px;
    height: 120px;
  }

  .treatment-img-name {
    display: flex;
    gap: 24px;
  }
  @media (max-width: 768px) {
    text-align: center;
    .link-text{
    text-align: justify;
    }
    .treatment-img-name {
        flex-direction: column-reverse;
  }
  }
`;

const TreatmentDetail = () => {
  const { name, description, profile_url,profile_alt_tag } = useSelector(
    (state: RootState) => state.treatment.data!
  );
  return (
    <StyledSection>
      <div className="treatment-img-name">
        <img src={profile_url} className="img" alt={profile_alt_tag} />
        <div> <h1 className="title blue bold heading"> {name}</h1> </div>
      </div>

      <div><h2 className="sub-title blue bold">What is "{name}"?</h2></div>
      <div className="link-text">
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </StyledSection>
  );
};

export default TreatmentDetail;
