import { useDoctorDetail } from "./use-doctor-detail";
import DoctorStyledSection from "./DoctorStyledSection";

const Experiences = () => {
  const data = useDoctorDetail("experience");

  if (data.length === 0) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Experiences</div>
      <div className="wrapper justify">
        {data.map(({ designation, hospital_name, from_date, to_date }, idx) => (
          <div key={idx} className="card shadow">
            <div className="blue body-text bold">{designation}</div>
            <div className="bold">{hospital_name}</div>
            <div className="year">
              {`${from_date && to_date ? "From" : ""} ${from_date || ""} ${
                from_date && to_date ? "to" : ""
              } ${to_date || ""}`}
            </div>
          </div>
        ))}
      </div>
    </DoctorStyledSection>
  );
};

export default Experiences;