import brand from "@/assets/icon/logo.png";

import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Dropdown, Layout, Menu, theme } from "antd";
import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setCurrentPage } from "@/features/admin/admin-slice";
import menuItems from "./SideMenu";
import { logout } from "@/features/auth/authSlice";
import useRoles from "@/components/hooks/useRoles";
import { UserOutlined } from "@ant-design/icons";

const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  span {
    font-size: 12px;
    color: white;
  }
`;

const HeaderInfo = styled.div`
  text-align: left;
`;

const HeaderButtons = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-right: 36px;
  gap: 24px;
`;

const { Header, Content, Footer, Sider } = Layout;

const StyledSider = styled(Sider)`
  border: 1px solid;

  .ant-menu-submenu,
  .ant-menu-item {
    text-align:left;
    color: #2BACE2;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-menu-item.ant-menu-item-selected {
    color: var(--green);
  }
  .ant-menu-title-content {
  }
`;

const Admin: FC = () => {
  const { entities } = useSelector((state: RootState) => state.admin) as Record<
    string,
    any
  >;

  const {
    userInfo: { first_name },
    entities: allowedEntities,
  } = useSelector((state: RootState) => state.auth);
  const isAdmin = useRoles("ADMIN");

  const filteredMenuItems = useMemo(() => {
    if (isAdmin) {
      return menuItems;
    }
    if (allowedEntities.length) {
      return menuItems?.filter(
        ({ key }: any) => allowedEntities.indexOf(key) !== -1
      );
    }
    return [];
  }, [allowedEntities, isAdmin]);
  const { entity } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clickedMenu = (item: any) => {
    dispatch(setCurrentPage(entities[item]));
    navigate(item.key);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink to="../">Go to Website</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        {isAdmin ? (
          <NavLink onClick={logoutHandler} to="../admin">
            Logout
          </NavLink>
        ) : (
          <NavLink onClick={logoutHandler} to="../login">
            Logout
          </NavLink>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout hasSider>
      <StyledSider
        theme="light"
        width={258}
        style={{
          overflowX: "hidden",
          height: "100vh",
          left: 0,
          top: 0,
          bottom: 0,
          border: "none",
        }}
      >
        <div className="demo-logo-vertical">
          <BrandWrapper>
            <img alt="" src={brand} />
          </BrandWrapper>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[entity as string]}
          items={filteredMenuItems}
          onClick={clickedMenu}
          className="sideNav"
        />
      </StyledSider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <HeaderInfo>
            <h2 className="blue semi-bold">Hello {first_name} !</h2>
          </HeaderInfo>
          <HeaderButtons>

          <Dropdown overlay={menu} trigger={['hover']}>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Avatar icon={<UserOutlined />} />
          </div>
        </Dropdown>
          </HeaderButtons>
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <div
            style={{
              borderRadius: '8px',
              textAlign: "center",
              background: colorBgContainer,
              height: "100%",
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};

export default Admin;
