//
import MedicalTourismIndia from "./MedicalTourismIndia";
import PopularDestinationsIndia from "./PopularDestinationsIndia";
import "./home.scss";
import PolularHospitalsIndia from "./PolularHospitalsIndia";
import PopularTreatmentsIndia from "./PopularTreatmentsIndia";
import WhyChooseUs from "./WhyChooseUs";
import Blogs from "./Blogs";
import Journey from "./Journey";
// import PatientTestimonials from "./PatientTestimonials";
import Awards from "./Awards";
import Faq from "./Faq";
import FlyingBanner from "@/components/FlyingBanner";
import PackageHelpForm from "./PackageHelpForm";
import styled from "styled-components";
import SliderTestimonial from "./SliderTestimonial";
import GroupAwardCarousel from "../awards/GroupAwardCarousel";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 0px;
    .custom_padding{
      padding-bottom:1em;
    }
  }
`;

const HomeContent = () => {
  return (
    <BodyWrapper>
       <Helmet>
        <title>Plan Medical Tourism | Plan Your medical trip in India</title>
        <meta name="description" content="Plan Medical Tourism is one of the renowned companies that specialises in Indian medical tourism. Get affordable treatment from top doctors, luxury hospitals and explore stunning destinations. Plan your medical trip today." />
        <link rel="canonical" href="https://planmedicaltourism.com/" />
        <meta name="keywords" content="" />
      </Helmet>
      <FlyingBanner id={1} />
      <MedicalTourismIndia />
      <PopularDestinationsIndia />
      <PolularHospitalsIndia />
      <div className="custom_padding"></div>
      <PopularTreatmentsIndia />
      <WhyChooseUs />
      <Blogs />
      <div className="custom_padding"></div>
      <Journey />
      {/* <PatientTestimonials /> */}
      <div className="custom_padding"></div>
      <SliderTestimonial />
      <GroupAwardCarousel/>
      <Faq />
      <PackageHelpForm />
    </BodyWrapper>
  );
};

export default HomeContent;
