import { RootState } from "@/store";
import { useMemo } from "react";
import { useSelector } from "react-redux";

type TypeRoles = "DOCTOR" | "ADMIN" | "PATIENT" | "FAREAGENT" | "HOSPITAL";

const useRoles = (ROLE: TypeRoles) => {
  const { userInfo, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const result = useMemo(
    () =>
      isAuthenticated &&
      userInfo &&
      userInfo.roles.some(({ code }: any) => code === ROLE.toUpperCase()),
    [isAuthenticated, userInfo, ROLE]
  );

  return result;
};

export default useRoles;
