import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useField } from "formik";

const CKEditorFormik = ({ name }: any) => {
  const [, { value }, { setValue }] = useField(name);
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value || ""}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        setValue(data);
      }}
    />
  );
};
export default CKEditorFormik;
