import { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getAll } from "@/features/admin/admin-actions";
import styled from "styled-components";
import ReOrderList from "./ReOrderList";

const ListWrappers = styled.div`
  display: flex;
  gap: 10px;
  > * {
    flex: 0 0 33%;
  }
`;

interface DoctorRankingItem {
  id: string;
  title: string;
  rank_in_country: number;
  rank_in_metro_city: number;
  rank_in_famous_destination: number;
}

const DoctorRanking = () => {
  const [entity] = useState("doctor");
  const [rankingLists, setRankingLists] = useState<{
    rank_in_country: DoctorRankingItem[];
    rank_in_metro_city: DoctorRankingItem[];
    rank_in_famous_destination: DoctorRankingItem[];
  }>({
    rank_in_country: [],
    rank_in_metro_city: [],
    rank_in_famous_destination: [],
  });

  const { data: storeData, entities } = useSelector(
    (state: RootState) => state.admin
  ) as Record<string, any>;
  const { [entity as string]: data } = storeData;
  const { title = "" } = entity ? entities[entity] : {};

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (entity) {
      dispatch(getAll({ entity }));
    }
  }, [entity]);

  useEffect(() => {
    if (data && data.rows && data.rows.length) {
      const list = data.rows.map((item: any) => ({
        id: item.id.toString(),
        title: `${item.salutation} ${item.first_name} ${item.last_name}`,
        rank_in_country: item.rank_in_country,
        rank_in_metro_city: item.rank_in_metro_city,
        rank_in_famous_destination: item.rank_in_famous_destination,
      }));

      const sortAndFilterList = (list: DoctorRankingItem[], rankColumn: keyof DoctorRankingItem) => {
        const ranked1To10 = list.filter(item => item[rankColumn] > 0 && item[rankColumn] <= 10);
        const rankedZero = list.filter(item => item[rankColumn] === 0);
        const rankedOthers = list.filter(item => item[rankColumn] > 10);
        return [
          ...ranked1To10.sort((a, b) => (a[rankColumn] as number) - (b[rankColumn] as number)),
          ...rankedZero,
          ...rankedOthers.sort((a, b) => (a[rankColumn] as number) - (b[rankColumn] as number))
        ];
      };

      const sortedLists = {
        rank_in_country: sortAndFilterList(list, "rank_in_country"),
        rank_in_metro_city: sortAndFilterList(list, "rank_in_metro_city"),
        rank_in_famous_destination: sortAndFilterList(list, "rank_in_famous_destination"),
      };

      setRankingLists(sortedLists);
    }
  }, [data]);

  console.log('log 1', rankingLists.rank_in_country);
  console.log('log 2', rankingLists.rank_in_metro_city);
  console.log('log 3', rankingLists.rank_in_famous_destination);

  return (
    <div className="d-flex flex-column">
      <h3 style={{ textAlign: "left" }}>{title + " Ranking"}</h3>
      <ListWrappers>
        <ReOrderList
          list={rankingLists.rank_in_country}
          name="Top 5 Doctors in Country"
          entity={entity}
          rankColumn="rank_in_country"
        />
        <ReOrderList
          list={rankingLists.rank_in_metro_city}
          name="Top 5 Doctors in Metro City"
          entity={entity}
          rankColumn="rank_in_metro_city"
        />
        <ReOrderList
          list={rankingLists.rank_in_famous_destination}
          name="Top 5 Doctors in Top Travel Destinations"
          entity={entity}
          rankColumn="rank_in_famous_destination"
        />
      </ListWrappers>
    </div>
  );
};

export default DoctorRanking;
