import Contacts from "./Contacts";
import Language from "./LanguageSelect";
import Search from "./Search";
import SocialLinks from "./SocialLinks";
import brand from "@/assets/mobile/brand.png";

import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 57px;
  @media (min-width: 425px) {
    height: 90px;
  }
  @media (max-width: 768px) {
    justify-content: space-around !important;
  }
`;

export const FlexChildContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  justity-content: center;
  align-items: center;
  gap: 10px;
  min-height: 100%;
`;

export interface IClassName {
  className?: string;
}

const ContactBar = () => {
  return (
    <FlexContainer className="d-flex justify-content-between">
      <img className="d-sm-block d-md-none align-self-center" src={brand} />
      <Contacts className="d-none d-md-block" />
      <Search className="d-none d-md-block order-md-2" />
      <Language className="order-1 order-md-1" />

      <SocialLinks className="order-0 order-md-3 float-sm-end " />
    </FlexContainer>
  );
};

export default ContactBar;
