import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import StyledLoginForm from "./StyledLoginForm";
import { changePassword } from "@/features/web/web-actions";

const ChangePassword = ({ style }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
    setErrors,
  } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      email: "",
      new_password: "",
      current_password: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
    onSubmit: (values) => {},
  });

  const submitNewPassword = async () => {
    const response = await dispatch(
      changePassword({
        email: values.email,
        new_password: values.new_password,
        current_password: values.current_password,
      })
    );

    if (response.payload.success) {
      navigate(`/`);
    } else {
      const errors: any = {};
      if (response.payload.responseCode === 422) {
        errors.current_password = response.payload.message;
      }
      setErrors(errors);
    }
  };

  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <form onSubmit={handleSubmit}>
        <StyledLoginForm>
          <h3> Change Password</h3>

          <div className="form-row">
            <div className="flex-grow-1">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Id"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && <div className="error">{errors.email}</div>}
            </div>
          </div>

          <div className="form-row">
            <div className="flex-grow-1">
              <input
                type="password"
                name="current_password"
                className="form-control"
                placeholder="Current Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.current_password}
              />
              {touched.current_password && (
                <div className="error">{errors.current_password}</div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="flex-grow-1">
              <input
                type="password"
                name="new_password"
                className="form-control"
                placeholder="New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new_password}
              />
            </div>
          </div>

          <div className="submit-btn-container">
            <button
              type="button"
              onClick={submitNewPassword}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </StyledLoginForm>
      </form>
    </div>
  );
};

export default ChangePassword;
