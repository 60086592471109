import { getTreatments } from "@/features/web/web-actions";
import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const MainWrapper = styled.div`
  padding: 0 15px;

  .treatment {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 24px;

    .sub-heading {
      text-align: center;
      @media (min-width: 425px) {
        text-align: left;
      }
    }

    .treatment-wrapper-outer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .buttons {
      display: flex;
      gap: 24px;
      justify-content: center;

      button {
        width: 142px;
        height: 48px;
        @media (min-width: 425px) {
          width: 234px;
          height: 50px;
        }
      }
    }
  }
`;

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  padding: 20px 0;
  @media (min-width: 425px) {
    justify-content: space-between;
  }

  .treatment-item {
    width: 156px;
    height: 50px;
    @media (min-width: 425px) {
      width: 234px;
      flex: 0 0 20%;
    }
  }
`;

const BlueButton = styled.button`
  background: var(--blue);
  color: white;
  width: 234px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 5px;
  border: 0px;
`;

const WhiteButton = styled.button`
  border: 1px solid var(--blue);
  background: white;
  border-radius: 5px;
  width: 156px;
  height: 50px;

  @media (min-width: 425px) {
    width: 234px;
  }
`;

const medicalSpecialities = [
    { id: 1, name: "Spine Surgery" },
    { id: 2, name: "Plastic Surgery" },
    { id: 3, name: "Pulmonology" },
    { id: 4, name: "Internal Medicine" },
    { id: 5, name: "Critical Care" },
    { id: 6, name: "Pediatrics & Pediatric Speciality" },
    { id: 7, name: "Pysiotherapy" },
    { id: 8, name: "Infectious Disease & HIV" },
    { id: 9, name: "Pathology" },
    { id: 10, name: "Ear, Nose & Throat" },
    { id: 11, name: "Dermatologist" },
    { id: 12, name: "Pediatrics" },
    { id: 13, name: "Orthopedics" },
    { id: 14, name: "Sports Medicine" },
    { id: 15, name: "Psychiatry" },
    { id: 16, name: "Endocrinology" },
    { id: 17, name: "Gastroenterology" },
    { id: 18, name: "Rhematology" },
    { id: 19, name: "Haematology & Bone Marrow transplant" },
    { id: 20, name: "Allergy & Immunology" },
    { id: 21, name: "Opthalmology" },
    { id: 22, name: "Physical Medicine and Rehabilitation" }
  ];
  


  
   
  


const SpecialityTreatment = ({ heading }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    dispatch(getTreatments()).then((res) => {
      if ((res.payload as any).data) {
        setTreatments((res.payload as any).data);
      }
    });
  }, [dispatch]);

  return (
    <MainWrapper>

        <h1 className="blue heading fw-bold">Other speciality</h1>
<div className="treatment-wrapper-outer">
      {/* <div className="green sub-heading medium text-start">{name}</div> */}
      <TreatmentsWrapper>
        {medicalSpecialities.map(({ name, id }: any, idx: number) => (
          <div className="card shadow treatment-item treatment blue" key={idx}>
              {name}
          </div>
        ))}
      </TreatmentsWrapper>
    </div>
    </MainWrapper>
  );
};

export default SpecialityTreatment;
