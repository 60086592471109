import { fileUpload } from "@/features/admin/admin-actions";
import { useEffect, useState } from "react";
import styled from "styled-components";

const FileInputWrapper = styled.div`
  position: relative;
  > * {
    position: absolute;
  }
  .image {
    left: 104px;
  }
`;

const processFile = (file: any, uploadParams: any) =>
  fileUpload({
    ...uploadParams,
    file,
  }).then((resp) => resp.imageUrl);

const processFiles = (files: any, uploadParams: any) =>
  Promise.all(
    Array.from(files).map(
      (file: any): Promise<string> => processFile(file, uploadParams)
    )
  );

const FileWidget = (props: any) => {
  const multiple = !!props.uiSchema["ui:isMultiple"];
  const uploadParams = props.uiSchema["ui:uploadParams"];
  const accept = props.uiSchema["ui:accept"];
  const [value, setValue] = useState(props.value || props.formData);

  useEffect(() => {
    const val = props.value || props.formData;
    if (val && Array.isArray(val)) {
      setValue(val);
    }
  }, [props.value, props.formData]);

  if (!uploadParams) {
    return <div className="alert">'uploadParams' missing.</div>;
  }

  return (
    <FileInputWrapper>
      <input
        type="file"
        id="files"
        required={props.required}
        multiple={multiple}
        accept={accept}
        onChange={(event) =>
          processFiles(event.target.files, uploadParams).then(props.onChange)
        }
      />
      <div className="image">
        {value &&
          value.map((item: string, idx: number) => (
            <a
              href={item}
              key={idx}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              <img src={item} height={30} width={30} alt="..." />
            </a>
          ))}
      </div>
    </FileInputWrapper>
  );
};

export default FileWidget;
