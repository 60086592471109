import { useField } from "formik";

interface TextInputProps {
  name: string;
  placeholder: string;
  type?: string;
  maxLength?: number;
}
const TextInput = (props: TextInputProps) => {
  const { name, placeholder, type = "input", maxLength } = props;
  const [{ onChange, onBlur }, { value, error, touched }] = useField(name);
  const handleKeyPress = (event : any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
    }
  };
  return (
    <>
      <input
        type={type}
        className="form-control"
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onKeyDown ={handleKeyPress}
        maxLength={maxLength}
      />
      {touched && <div className="error">{error}</div>}
    </>
  );
};

export default TextInput;
