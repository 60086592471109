import { CloseOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { StyledActionButton } from "../entities";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Styled = styled.div`
  display: flex;
  gap: 24px;
  margin: 16px 0;

  .search {
    position: relative;
    min-width: 250px;
    min-height: 58px;
    &-input {
      height: 48px;
      border: 1px solid var(--blue);
      font-size: 16px;
      overflow: auto;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      left: 0;
    }
    &-clear-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      line-height: initial;
    }
  }
`;

const SearchDoctor = ({ onChange, onCancel }: any) => {
  const [values, setValues] = useState<any>({});
  const [showClearBtn, setShowClearBtn] = useState(false);

  const { search = "" } = values;

  const onSearchInputChange = (e: any) => {
    const { value } = e.target;
    setValues({
      search: value,
    });
    onChange(value);
  };

  useEffect(() => {
    setShowClearBtn(search.length > 0);
  }, [search]);

  const clearSearch = () => {
    const obj = {
      ...values,
      search: "",
    };
    setValues(obj);
    onCancel();
  };

  return (
    <Styled>
      <div className="search">
        <Input
          placeholder="Search"
          className="search-input"
          type="text"
          value={search}
          onChange={onSearchInputChange}
        />
        {showClearBtn && (
          <Button
            onClick={clearSearch}
            className="search-clear-icon"
            shape="circle"
            icon={<CloseOutlined />}
          />
        )}
      </div>
    </Styled>
  );
};

export default SearchDoctor;
