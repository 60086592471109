import { useDoctorDetail } from "./use-doctor-detail";
import DoctorStyledSection from "./DoctorStyledSection";
import { BlueButton } from "@/components/styled";

const Memberships = () => {
  const data = useDoctorDetail("membership");
  if (data.length === 0) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Membership</div>
      <div className="wrapper justify">
        {data.map(({ membership_name, membership_year, document_url }, idx) => (
          <div key={idx} className="card shadow">
            <div className="bold">{membership_name}</div>
            <div className="year">{membership_year}</div>
          </div>
        ))}
      </div>
    </DoctorStyledSection>
  );
};

export default Memberships;
