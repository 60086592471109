import "./App.scss";
import HomeLayout from "./conatiners/layouts/HomeLayout";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeContent from "./pages/home/HomeContent";
import ComingSoon from "@/pages/coming-soon";
import Hospitals from "@/pages/hospitals";
import Doctors from "@/pages/doctors";
import Enquiry from "@/pages/enquiry";
import TravelServices from "@/pages/travel-services";
import Partner from "@/pages/partner";
import Login from "@/pages/login";
import PrivacyPolicy from "@/pages/privacy";
import Disclaimer from "@/pages/disclaimer";
import TermsCondition from "@/pages/terms-conditions";
import FAQ from "@/pages/home/faqDetails";
import AdminApp from "@/admin";
import Entities from "@/admin/entities";
import { ToastContainer } from "react-toastify";
import TreatmentsPage from "@/pages/treatments/TreatmentsPage";
import HospitalDetailPage from "./pages/hospitals/HospitalDetailPage";
import DoctorDetailPage from "./pages/doctors/DoctorDetailPage";
import DoctorRanking from "./admin/entities/DoctorRanking";
import GalleryRanking from "./admin/entities/GalleryRanking";
import PopularCityRanking from "./admin/entities/PopularCity"
import TreatmentDetailPage from "./pages/treatments/TreatmentDetailPage";
import AboutUS from "./pages/about-us";
import ContactUS from "./pages/contact-us";
import GroupAwards from "./pages/awards";
import Gallery from "./pages/gallery";
import WhyChooseUsDetails from "./pages/why-choose-us";
import Blog from "./pages/blogs";
import SearchListing from "./pages/search-listing";
import ScrollTop from "./utils/ScrollTop";
import SpecialityTreatment from "./pages/doctors/SpecialityTreatment";
import HospitalRanking from "./admin/entities/HospitalRanking";
import PackageDetails from "./pages/packages/PackageDetails";
import KnowledgeMain from "@/pages/news";
import RoadAmbulance from "./pages/AmbulanceService/RoadAmbulance";
import AirAmbulance from "./pages/AmbulanceService/AirAmbulance";
import TrainAmbulance from "./pages/AmbulanceService/TrainAmbulance";
import AdvanceLifeSupport from "./pages/AmbulanceService/AdvanceLifeSupport";
import HearseAmbulance from "./pages/AmbulanceService/HearseAmbulance";
import BasicLifeSupport from "./pages/AmbulanceService/BasicLifeSupport";
import InterpreterServices from "./pages/InterpreterServices";
import { MyProfile } from "./pages/my-profile";
import PackageListing from "./pages/packages/PackageListing";
import PackageListingDetails from "./pages/packages/PackageListingDetails";
import PackageBooking from "./pages/packages/PackageBooking";
import MyEnquiry from "./pages/my-profile/PatientEnquiry";
import ForgotPassword from "./pages/login/ForgotPassword";
import ChangePassword from "./pages/login/ChangePassword";
import PatientTranslatorEnquiry from "./pages/my-profile/PatientTranslatorEnquiry";
import GlobalSearchListing from "./pages/search-listing/GlobalSearchListing";
import ResetPassword from "./pages/login/ResetPassword";
import Dashboard from "./admin/components/dashbroad";
import PopularDestinationDetail from "./pages/PopularDestination/PopularDestinationDetail";
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <div className="App">
      <ScrollTop />
      <Routes>
        <Route path="" element={<HomeLayout />}>
          <Route index element={<HomeContent />} />
          <Route path="coming-soon" element={<ComingSoon />} />
          <Route path="hospitals" element={<Hospitals />} />
          <Route path="hospital/:slug" element={<HospitalDetailPage />} />
          <Route path="doctors" element={<Doctors />} />
          <Route path="doctor/:slug" element={<DoctorDetailPage />} />
          <Route path="treatments" element={<TreatmentsPage />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route
            path="treatment/:slug"
            element={<TreatmentDetailPage />}
          />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="popular-destination/:slug" element={<PopularDestinationDetail />} />
          <Route path="travel-services" element={<TravelServices />} />
          <Route path="knowledge/:catId" element={<KnowledgeMain />} />
          <Route
            path="knowledge"
            element={<Navigate to="../knowledge/blog" replace />}
          />

          <Route path="blog/:slug/:preview?" element={<Blog />} />
          <Route path="partner" element={<Partner />} />
          <Route path="partner/:usertype" element={<Partner />} />
          <Route path="login" element={<Login />} />
          <Route path="login/change-password" element={<ChangePassword />} />
          <Route path="login/:usertype" element={<Login />} />
          <Route
            path="login/:usertype/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="login/reset-password"
            element={<ResetPassword />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="terms-conditions" element={<TermsCondition />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="about-us" element={<AboutUS />} />
          <Route path="contact-us" element={<ContactUS />} />
          <Route path="why-choose-us" element={<WhyChooseUsDetails />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="packagesdetails" element={<PackageDetails />} />
          <Route path="group-awards" element={<GroupAwards />} />
          <Route path="search-all" element={<GlobalSearchListing />} />
          <Route path="search/:entity" element={<SearchListing />} />
          <Route path="air-ambulance" element={<AirAmbulance />} />
          <Route path="road-ambulance" element={<RoadAmbulance />} />
          <Route path="train-ambulance" element={<TrainAmbulance />} />
          <Route path="basic-life-support" element={<BasicLifeSupport />} />
          <Route path="hearse-ambulance" element={<HearseAmbulance />} />
          <Route path="advance-life-support" element={<AdvanceLifeSupport />} />
          <Route
            path="interpreter-services"
            element={<InterpreterServices />}
          />
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="my-enquiry" element={<MyEnquiry />} />
          <Route
            path="interpreter-enquiry"
            element={<PatientTranslatorEnquiry />}
          />
          <Route path="packages" element={<PackageListing />} />
          <Route
            path="packages/:packageId"
            element={<PackageListingDetails />}
          />
          <Route path="packages/:packageId/book" element={<PackageBooking />} />
          {/* <Route
            path="partner/doctor-resgistration-form2"
            element={<DoctorRegistrationForm2 />}
          /> */}
          <Route
            path="speciality-treatment"
            element={<SpecialityTreatment />}
          />
        </Route>
        <Route path="admin" element={<AdminApp />}>
          <Route index element={<Navigate to="dashbroad" replace />} />
          <Route path=":entity" element={<Entities />} />
          <Route path="doctor/ranking" element={<DoctorRanking />} />
          <Route path="gallery/ranking" element={<GalleryRanking />} />
          <Route path="popularcity/ranking" element={<PopularCityRanking />} />
          <Route path="hospital/ranking" element={<HospitalRanking />} />
          <Route path="dashbroad" element={<Dashboard />} />
        </Route>
        <Route path="agent" element={<AdminApp />}>
          <Route index element={<Navigate to="agent/agent" replace />} />
          <Route path=":entity" element={<Entities />} />
          <Route path="doctor/ranking" element={<DoctorRanking />} />
          <Route path="hospital/ranking" element={<HospitalRanking />} />
          <Route path="dashbroad" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default App;
