import { store } from "@/store";

const interceptor = async () => {
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    const { token } = store.getState().auth;
    let [resource, config] = args;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    };

    if (config?.body && config?.body instanceof FormData) {
      delete (headers as any)["Content-Type"];
    }
    const interceptedConfig = {
      ...config,
      headers,
    };
    const response = await originalFetch(resource, interceptedConfig);
    if (response.status === 401) {
      localStorage.clear();
      // window.location.href = "/uat/admin/login";
      // window.location.reload();
      return Promise.reject(response);
    }
    if (!response.ok && response.status === 404) {
      return Promise.reject(response);
    }
    return response;
  };
};

export default interceptor;
