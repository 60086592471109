import styled from "styled-components";

import logow from "@/assets/icon/whatsapp.svg";
import logom from "@/assets/icon/mail.svg";
const StyledGetIntouch = styled.div`
  .card {
    text-align: left;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 16px;
    .link-text {
      a {
        color: #2f2f2f;
      }
    }
  }
`;

const GetIntouch = ({ ...props }) => (
  <StyledGetIntouch {...props}>
    <div className="card shadow">
      <div className="body-text">Need Help? Get in touch!</div>
      <div className="link-text">
        <a target="_blank" href="//wa.me/917977733207" rel="noreferrer">
          <img src={logow} width={29} alt="" />
          &nbsp; +91 79777 33207
        </a>
      </div>
      <div className="link-text">
        <a
          target="_blank"
          href="mailto:patientcare@planmedicaltourism.com"
          rel="noreferrer"
          style={{fontSize:12}}
        >
          <img src={logom} width={29} alt="" />
          &nbsp; patientcare@planmedicaltourism.com
        </a>
      </div>
    </div>
  </StyledGetIntouch>
);

export default GetIntouch;
