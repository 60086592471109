import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
 // createEntity,
 // deleteEntity,
 // getAll,
 // getAllSubEntity,
  //getOptions,
  //saveEntity,
  viewEntity,
} from "./admin-actions";
import { AdminState } from "@/models";
import entities from "./entities.json";

const initialState: AdminState = {
  loading: false,
  viewedEntity: null,
  data: {},
  currentPage: null,
  apiState: "",
  saveApiState: "",
  saveApiErrorMessage: "",
  entities,
};

export const adminSlice = createSlice({
  name: "Agent",
  initialState,
  reducers: {
    clearViewEntity(state) {
      state.viewedEntity = null;
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
   /* builder.addCase(getAll.pending, (state, action) => {
      state.saveApiErrorMessage = "";
      state.saveApiState = "";
      state.loading = true;
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAll.fulfilled, (state, action) => {
      if (action.payload) {
        const { data, entity } = action.payload as any;
        state.saveApiErrorMessage = "";
        state.saveApiState = "";
        state.data[entity] = {
          ...state.data[entity],
          ...data,
        };
        state.loading = false;
      }
    });
    builder.addCase(getAllSubEntity.fulfilled, (state, action) => {
      const { data, entity } = action.payload as any;
      // state.saveApiErrorMessage = "";
      // state.saveApiState = "";
      state.data[entity.name] = {
        ...state.data[entity.name],
        [entity.subEntity]: data,
      };
      state.loading = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      const { arg } = action.meta;
      if (arg) {
        state.data[arg.entity] = {
          ...(state.data[arg.entity] || {}),
          error: action.payload,
        };
      }
      state.loading = false;
    });

    builder.addCase(deleteEntity.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteEntity.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteEntity.rejected, (state, action) => {
      state.loading = false;
    }); */

    builder.addCase(viewEntity.fulfilled, (state, action) => {
      state.viewedEntity = action.payload;
      state.saveApiErrorMessage = "";
      state.saveApiState = "";
      state.loading = false;
    });

   /* builder.addCase(saveEntity.pending, (state, action) => {
      state.loading = true;
      state.saveApiErrorMessage = "";
      state.saveApiState = "";
    });

    builder.addCase(saveEntity.fulfilled, (state, action) => {
      state.loading = false;
      state.saveApiErrorMessage = "";
      state.saveApiState = "loaded";
    });

    builder.addCase(saveEntity.rejected, (state, action) => {
      state.loading = false;
      state.saveApiErrorMessage = action.error.message!;
      state.saveApiState = "failed";
    });

    builder.addCase(createEntity.pending, (state, action) => {
      state.loading = true;
      state.saveApiState = "";
    });

    builder.addCase(createEntity.fulfilled, (state, action) => {
      state.saveApiErrorMessage = "";
      state.loading = false;
      state.saveApiState = "loaded";
    });

    builder.addCase(createEntity.rejected, (state, action) => {
      state.saveApiErrorMessage = action.error.message!;
      state.saveApiState = "failed";
      state.loading = false;
    });

    builder.addCase(getOptions.pending, (state, action) => {
      const { arg } = action.meta;
      state.loading = true;
      state.data[arg] = {
        optionsApiStatus: "pending",
      };
    });

    builder.addCase(getOptions.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const { data } = action.payload as any;
      state.loading = false;
      state.data[arg] = {
        ...(state.data[arg] || {}),
        options: {
          ...data,
          college_city: data.city,
        },
        optionsApiStatus: "loaded",
      };
    });

    builder.addCase(getOptions.rejected, (state, action) => {
      const { arg } = action.meta;

      state.saveApiErrorMessage = action.error.message!;
      state.loading = false;
      state.data[arg] = {
        ...(state.data[arg] || {}),
        optionsApiStatus: "failed",
      };
    }); */
  }, 
});

// Action creators are generated for each case reducer function
export const { clearViewEntity, setCurrentPage } = adminSlice.actions;

export default adminSlice.reducer;
