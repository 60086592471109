import styled from "styled-components";
import { useEffect } from "react";
import { getBestof } from "@/features/web/web-actions";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useSelector } from "react-redux";
import { WebDoctorState } from "@/features/web/web-slice";
import { NavLink, useNavigate } from "react-router-dom";
import LocationIcon from "@/components/common/LocationIcon";

const BestDoctorsWrapper = styled.div``;

const BestDoctorsRowStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .first-half,
  second-half {
    display: flex;
  }
  .first-half {
    flex-direction: column;
    gap: 20px;
  }
.docHeading,
.location{
  font-size:20px;
  text-wrap: balance;
}
  .second-half {
    .view-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      button {
        flex: 0 0 40%;
        height: 50px;
        &.view {
          border: 2px solid var(--blue);
          background: #fff;
        }

        &.book {
          background: var(--blue);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 425px) {
    justify-content: space-between;
    margin: 35px 0;
    flex-direction: row;
    min-height: 130px;
    .first-half,
    second-half {
      flex: 0 0 50%;
    }

    .first-half {
      flex-direction: row;
      .name-speciality {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    .second-half {
      flex: 0 0 50%;
      justify-content: space-between;
      display: flex;

      .location-exp {
        display: flex;
        flex-direction: column;
        text-align: left;
        text-transform: capitalize;
      }

      .view-buttons {
        margin-top: 0px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          width: 234px;
          height: 50px;
          &.view {
            border: 2px solid var(--blue);
            background: #fff;
          }

          &.book {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
`;

interface IBestDoctor {
  id: number;
  first_name: string;
  salutation: string;
  last_name: string;
  city: string;
  slug: string;
  Specializations: string;
  practice_start_date: string;
  profile_url: string;
  profile_alt_tag: string;
  rank_in_country: number;
}

// interface IBestDoctorSectionProps {
//   data: {
//     title: string;
//     list: IBestDoctor[];
//   };
// }
interface BestDoctorsRowProps {
  data: IBestDoctor;
}

const apidata = {
  loading: false,
  success: true,
  title: "Best Doctors in India",
  list: [],
};

export const BestDoctorsRow = ({ data }: BestDoctorsRowProps) => {
  const {
    first_name,
    last_name,
    practice_start_date,
    profile_url,
    profile_alt_tag,
    id,
    city,
    Specializations,
    salutation,
    slug,
  } = data;

  const SpecializationsArr =
    Specializations && Specializations.length
      ? Array.from(Specializations).map(({ name }: any) => name)
      : [];
  // calculate practice since years
  const startDate = new Date(practice_start_date);
  const practiceStartDateYear = startDate.getFullYear();
  const navigate = useNavigate();
  return (
    <div>
    <BestDoctorsRowStyled>
      <div className="first-half">
        <img
          alt={profile_alt_tag}
          style={{
            width: 130,
            height: 130,
            borderRadius: 10,
            alignSelf: "flex-start !important",
          }}
          src={profile_url}
        />
        <div className="name-speciality">
          <p className="docHeading bold">{`${salutation} ${first_name} ${last_name}`}</p>
          <p>{SpecializationsArr.join(",")}</p>
        </div>
      </div>
      <div className="second-half">
        <div className="location-exp">
          <div className="d-none d-sm-block location ">
            <LocationIcon>{city}</LocationIcon>
          </div>
          <div className="exp">Practising Since {practiceStartDateYear}</div>
        </div>
        <div className="view-buttons">
          <button
            className="form-control view"
            onClick={() => navigate(`/doctor/${slug}`)}
          >
            View More
          </button>
          <button
            className="form-control book"
            onClick={() => navigate(`/doctor/${slug}`)}
          >
            Enquire Now
          </button>
        </div>
      </div>
    </BestDoctorsRowStyled>
     <hr />
     </div>
  );
};

const TitleStyled = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 40%;
  }
`;

const TitleViewMore = ({ children, link }: any) => {
  return (
    <TitleStyled>
      <p className="title blue heading bold">{children}</p>
      {link && (
        <NavLink className="d-none d-sm-block" to={link}>
          View More
        </NavLink>
      )}
    </TitleStyled>
  );
};

const BestDoctors = ({ title, category, className = "" }: any) => {
  // const category = "country";
  const data = apidata;
  const { [`bestin_${category}` as keyof WebDoctorState]: datax } = useSelector(
    (state: RootState) => state.web.data.doctor
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      getBestof({
        entity: "doctor",
        category,
      })
    );
  }, [category, dispatch]);
  return (
    <div className={className}>
      <TitleViewMore link="../search/doctor">
        {title || data.title}
      </TitleViewMore>
      <BestDoctorsWrapper>
        {datax &&
          datax.list &&
          datax.list.map((item, idx) => {
            return (
              <>
                {idx !== 0}
                <BestDoctorsRow key={idx} data={item} />
              </>
            );
          })}
      </BestDoctorsWrapper>
    </div>
  );
};

export default BestDoctors;
