import { Modal } from "react-bootstrap";
import UnAssciatedDrList from "./UnAssciatedDrList";
import AssociatedDrList from "./AssociatedDrList";
import AssociatedDrListAdmin from "./AssociatedDrListAdmin";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { fetchDoctorsByHospitalId } from "@/features/web/doctor-actions";
import { useCallback, useEffect, useState } from "react";
import { Button, ConfigProvider } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import useRoles from "@/components/hooks/useRoles";
const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>No Doctors</p>
  </div>
);

const DrAssociation = ({ handleClose, show, data }: any) => {
  const [associatedList, setAssociatedList] = useState([]);

  const dispatch = useDispatch<AppDispatch>();
  const isHopital = useRoles("HOSPITAL");
  const loadAssociatedDoctors = useCallback(
    (id: number) => {
      dispatch(fetchDoctorsByHospitalId(id as number)).then((res) => {
        setAssociatedList(res.payload);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (data && data.id) {
      loadAssociatedDoctors(data.id);
    }
  }, [data, loadAssociatedDoctors]);
 if(isHopital){
  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Doctor Association for {data.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <div style={{ display: "flex", gap: 20 }}>
          {/*  <UnAssciatedDrList
              associatedDrList={associatedList}
              hospital_id={data.id}
              onAddSuccess={() => {
                loadAssociatedDoctors(data.id);
              }}
            />  */}
            <AssociatedDrList
              hospital_id={data.id}
              onRemoveSuccess={() => {
                loadAssociatedDoctors(data.id);
              }}
              list={associatedList}
              hospitalName={data.name}
            />
          </div>
        </ConfigProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} type="primary">
          Finish
        </Button>
      </Modal.Footer>
    </Modal>
  ); }
  else{
    return (
      <Modal onHide={handleClose} show={show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Doctor Association for {data.name}</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div style={{ display: "flex", gap: 20 }}>
              <UnAssciatedDrList
                associatedDrList={associatedList}
                hospital_id={data.id}
                onAddSuccess={() => {
                  loadAssociatedDoctors(data.id);
                }}
              />  
              <AssociatedDrListAdmin
                hospital_id={data.id}
                onRemoveSuccess={() => {
                  loadAssociatedDoctors(data.id);
                }}
                list={associatedList}
                hospitalName={data.name}
              />
            </div>
          </ConfigProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} type="primary">
            Finish
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default DrAssociation;
