import { getTreatments } from "@/features/web/web-actions";
import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const MainWrapper = styled.div`
  padding: 0 15px;

  .treatment {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 24px;

    .sub-heading {
      text-align: center;
      @media (min-width: 425px) {
        text-align: left;
      }
    }

    .treatment-wrapper-outer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 425px) {
        margin-top: 20px;
      }
    }

    .buttons {
      display: flex;
      gap: 24px;
      justify-content: center;

      button {
        width: 142px;
        height: 48px;
        @media (min-width: 425px) {
          width: 234px;
          height: 50px;
        }
      }
    }
  }
`;

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  padding: 20px 0;
  @media (min-width: 425px) {
    // justify-content: space-between;
    justify-content: flex-start;
    gap: 73px;
  }

  .treatment-item {
    width: 156px;
    height: 75px;
    line-height: 1;
    padding: 0px 5px;
    @media (min-width: 425px) {
      width: 234px;
      flex: 0 0 20%;
      margin-bottom: -40px;

      .App-link {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
        margin: 0 10px;
      } &:hover {
        background: #eee;
      }
    }
  }
`;

const BlueButton = styled.button`
  background: var(--blue);
  color: white;
  width: 234px;
  height: 50px;
  background-color: var(--blue);
  border-radius: 5px;
  border: 0px;
`;

const WhiteButton = styled.button`
  border: 1px solid var(--blue);
  background: white;
  border-radius: 5px;
  width: 156px;
  height: 50px;

  @media (min-width: 425px) {
    width: 234px;
  }
`;
const LoadMoreButton = styled.div`
 .loadMoreBtn button{
  border: 1px solid var(--blue);
  background: white;
  border-radius: 5px;
  width: 156px;
  height: 50px;
  position: relative;
  top: 3em;

  @media (min-width: 425px) {
    width: 234px;
  }
  &:hover{
    background: #8bc451;
    color: #fff;
  }
 }
    @media (max-width: 768px) {
       .loadMoreBtn button{
          top: 1em;
    }

`;

const TreatmentSpeciality = ({ data }: any) => {
  const { name, treatments } = data;
  type Treatment = { id: number; name: string };
  const sortedTreatments: Treatment[] = treatments.sort(
    (a: Treatment, b: Treatment) => a.name.localeCompare(b.name)
  );

  const [visibleItems, setVisibleItems] = useState(8);

  const loadMore = () => {
    setVisibleItems(visibleItems + 8);
  };

  return (
    <div className="treatment-wrapper-outer">
      <div className="green sub-heading medium text-start">{name}</div>
      <TreatmentsWrapper>
        {sortedTreatments.slice(0, visibleItems).map(({ name, id, slug }: any, idx: number) => (
          <div className="card shadow treatment-item" key={idx}>
            <NavLink className="App-link" to={`../treatment/${slug}`}>
              {name}
            </NavLink>
          </div>
        ))}
      </TreatmentsWrapper>
      {visibleItems < sortedTreatments.length && (
      <LoadMoreButton>
        <div className="loadMoreBtn">
        <button onClick={loadMore}>View More</button>
        </div>
      </LoadMoreButton>
      )}
    </div>
  );
};

const BestMedicalTreatments = ({ heading }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const [treatments, setTreatments] = useState([]);

  useEffect(() => {
    dispatch(getTreatments()).then((res) => {
      if ((res.payload as any).data) {
        setTreatments((res.payload as any).data);
      }
    });
  }, [dispatch]);

  return (
    <MainWrapper>
      {heading && <div className="blue bold heading">{heading}</div>}

      <div className="treatment">
        {treatments.map((item, idx) => (
          <TreatmentSpeciality key={idx} data={item} />
        ))}

        <div className="buttons">
          {/* <WhiteButton>View More</WhiteButton>
          <BlueButton>Enquire Now</BlueButton> */}
        </div>
      </div>
    </MainWrapper>
  );
};

export default BestMedicalTreatments;
