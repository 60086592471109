/* eslint-disable no-extend-native */
declare global {
  interface Number {
    pad: () => string;
  }
}

Number.prototype.pad = function () {
  const n = this as number;
  return n < 10 ? "0" + n : "" + n;
};

const dtToday = new Date();
let month = dtToday.getMonth() + 1;
let day = dtToday.getDate();
const year = dtToday.getFullYear();
console.log(month, day, year);
const today = year + "-" + month.pad() + "-" + day.pad();
export default today;
