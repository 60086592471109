/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { getTestimonials } from "@/features/admin/admin-actions";
import styled from "styled-components";

export const StyledSection = styled.div`
.testimonial_main_div .slick-slider .slick-track,
.slick-slider .slick-list {
  padding-top: 3rem !important;
}
[data-title] {
  position: relative;
  cursor: help;
}
  
[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 0px;
  padding: 10px;
  background: rgb(241 241 241);
  color: rgb(0, 0, 0);
  font-size: 14px;
  height: 180px;
  overflow-x: auto;
}
[data-title]:hover::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 8px;
  border: 8px solid transparent;  
  border-bottom: 8px solid #000;
}
`;
const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: (
    <div>
      <div className="next-slick-arrow">
        <i className="bi bi-arrow-right-circle bi_arrow"></i>
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className="prev-slick-arrow">
        <i className="bi bi-arrow-left-circle bi_arrow"></i>
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Partner = () => {
  // return null;
  type TestimonialData = {
    profile_url: string;
    description: string;
    patient_name: string;
  };
  type TestimonialSliderProps = {
    data: TestimonialData[];
  };
  const truncateDescription = (description: string, maxLength: number): string => {
    const words = description.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return description;
  };
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getTestimonials()).then((res: any) => {
      setData(res.payload.rows);
    });
  }, []);
  return (
    <StyledSection>
      <h1 className="blue heading bold pt-5">Patient Testimonials</h1>
      <div className="slider_content testimonial_main_div">
        <div className="slider_container">
          {data && !!data.length && (
            <Slider {...settings}>
              {data.map(
                ({ profile_url, description, patient_name }: any, idx) => (
                  <div className="slider_testimonial">
                    <img src={profile_url} className="img" alt="" />
                    <div data-title={description}>

                    <p className="description">{truncateDescription(description, 38)}</p>
                    </div>
                    <h2 className="title">{patient_name}</h2>
                  </div>
                )
              )}
            </Slider>
          )}
        </div>
      </div>
    </StyledSection>
  );
};

export default Partner;
