import React from "react";
import "./admin.styles.scss";

import { RootState } from "../store";
import { useSelector } from "react-redux";
import Admin from "./Admin";
import Agent from "./Agent";
import { LoadingOutlined } from "@ant-design/icons";
import useRoles from "@/components/hooks/useRoles";
import { Spin } from "antd";
import AdminLogin from "./AdminLogin";
const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const AdminApp = () => {
  const { loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const {
    entities: allowedEntities,
  } = useSelector((state: RootState) => state.auth);
  const isPatient = useRoles("PATIENT");
  const isDoctor = useRoles("DOCTOR");
  const isAgent = useRoles("FAREAGENT");
  const isHospital = useRoles("HOSPITAL");
  const isAdmin = useRoles("ADMIN");

  if (loading) {
    return <Spin style={{ marginTop: "20%" }} indicator={antIcon} />;
  }

  if (isAuthenticated && !isPatient && isAdmin) {
    return <Admin />;
  }
  if (isAuthenticated && !isPatient && isHospital) {
    return <Admin />;
  }
  if (isAuthenticated && !isPatient && isDoctor) {
    return <Admin />;
  }
  if (isAuthenticated && isAgent ) {
    return <Agent />;
  }
  return <AdminLogin />;
};

export default AdminApp;
