import styled from "styled-components";

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  line-height: 24px;
  margin-top: 24px;
`;

const StyledPackageCard = styled.div`
  width: 152px;
  text-align: left;
  margin-bottom: 24px;
  padding: 16px;
  display: flex;
  gap: 36px;
  flex: 0 0 42%;
  .pricing {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  table p{
    margin: 0px;

  }
table span{
  font-size: 14px;
}
table td{
  height: 60px
}
  
  .compare_ul {
    list-style: none;
    padding: 0px;
  }
  .compare_ul i {
    padding-right: 10px;
  }
  .compare_ul li {
    font-size: 14px;
  }
  .bi-check-circle-fill{
    color: #89c543;
  }
  .bi-x-circle-fill{
    color: #fb100a;
  }
`;

const PackagesCard = ({ data }: any) => {
  const { title, subtitle, amount, moreLink, includes, excludes } = data;
  return (
    <StyledPackageCard className='card shadow'>
      <div className='body-text green semibold'>{title}</div>
      <div className='pricing'>
        <div className='link-text'>{subtitle}</div>
        <div className='body-text blue semibold'>{amount}</div>
      </div>
      {/* <a href={moreLink}>View More &gt;</a> */}
      <div className='row'>
      <div className="col-md-6">
  <table className='table table-bordered'>
    <thead>
      <tr>
        <th scope='col'><p className='green semibold'>Includes</p></th>
      </tr>
    </thead>
    <tbody>
          {includes.split(", ").map((item: string, index: number) => (
      <tr  key={index} >
        <td>
            <div className="list-item bordered-item">
              <i className='bi bi-check-circle-fill'></i>
             <span> {item}</span>
            </div>
        </td>
      </tr>
          ))}
    </tbody>
  </table>
</div>
<div className="col-md-6">
<table className='table table-bordered'>
    <thead>
      <tr>
      <th scope='col'><p className='green semibold'>Excludes</p></th>
      </tr>
    </thead>
    <tbody>
          {excludes.split(", ").map((item: string, index: number) => (
      <tr key={index}>
      <td>
            <div  className="list-item bordered-item">
              <i className='bi bi-x-circle-fill'></i>
              <span> {item}</span>
            </div>
        </td>
      </tr>
          ))}
    </tbody>
  </table>
</div>



        {/* <div className='col-md-6'>
         
          <ul className='compare_ul'>
            {includes.split(", ").map((item: string, index: number) => (
              <li key={index}>
                <i className='bi bi-check-circle-fill'></i>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className='col-md-6'>
          
          <ul className='compare_ul'>
            {excludes.split(", ").map((item: string, index: number) => (
              <li key={index}>
                <i className='bi bi-x-circle-fill'></i>
                {item}
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </StyledPackageCard>
  );
};

const packageList: any[] = [
  {
    title: "IVF/ICSI – Self cycle",
    subtitle: "Starting From",
    amount: "INR 150000/-",
    includes:
      "Consultation, Sonography, IVF-ICSI drugs, Ovum Pick-up, procedure charges, Anaesthesia charges, IVF-ICSI lab charges, embryo culture charges, First Embryo transfer charges, semen analysis and semen freezing charges, OT charges, Embryo transfer charges",
    excludes:
      "All blood reports, embryo freezing charges, frozen embryo transfer charges, any other treatment charges other than IVF, any drugs other than IVF-ICSI",
  },
  {
    title: "IVF/ICSI – Ovum donation cycle",
    subtitle: "Starting From",
    amount: " INR 180000/-",
    includes:
      "Consultation, Sonography, IVF-ICSI drugs, procedure charges, Anaesthesia charges, IVF-ICSI lab charges, embryo culture charges, Embryo transfer charges, semen analysis and semen freezing charges, OT charges, Embryo transfer charges",
    excludes:
      "All blood reports, embryo freezing charges, frozen embryo transfer charges, any other treatment charges other than IVF, any drugs other than IVF-ICSI",
  },
  {
    title: "IVF/ICSI – Embryo donation cycle",
    subtitle: "Starting From",
    amount: "INR 150000/-",
    includes:
      "Consultation, Sonography, IVF-ICSI drugs, procedure charges, Anaesthesia charges, IVF-ICSI lab charges, embryo culture charges, Embryo transfer charges, semen analysis and semen freezing charges, OT charges, Embryo transfer charges",
    excludes:
      "All blood reports, embryo freezing charges, frozen embryo transfer charges, any other treatment charges other than IVF, any drugs other than IVF-ICSI",
  },
  {
    title: "Frozen Embryo Transfer",
    subtitle: "Starting From",
    amount: " INR 35000/-",
    includes:
      "Medicines required for frozen embryo transferred, embryo thawing charges, consultation and sonography charges, embryo transfer charges.",
    excludes:
      "All blood reports, medicines other than used in thaw embryo transfer, treatment or procedure other than frozen embryo transfer. ",
  },
  {
    title: "Video Consultation",
    subtitle: "Starting From",
    amount: " INR 2500/-",
    includes: "All video consultations",
    excludes: "",
  },
];

const PackageDetails = () => {
  return (
    <div className='packages'>
      <div className='heading blue bold'>Packages</div>
      <CardsWrapper>
        {packageList.map((item, idx) => {
          return <PackagesCard key={idx} data={item} />;
        })}
      </CardsWrapper>
    </div>
  );
};

export default PackageDetails;
