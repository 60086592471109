import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBestof = createAsyncThunk(
  "home/bestof/fetch",
  async ({ entity, category }: any) => {
    try {
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity}/get/best/${category}`
      ).then((data) => data.json());
      return {
        entity,
        category,
        data,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTreatments = createAsyncThunk(
  "treatments/fetch",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/treatment/display`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      }

      return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTreatmentOptions = createAsyncThunk(
  "treatment/options/fetch",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/treatment/display`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      }

      return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCityOptions = createAsyncThunk(
  "city/options/fetch",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/city/display`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      }

      return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEnquiry = createAsyncThunk(
  "enquiry/send",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/enquiry/create`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendPasswordResetLink = createAsyncThunk(
  "send/passwordResetLink/get",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/email/reset`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword/get",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/reset/password`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getOtp = createAsyncThunk(
  "login/otp/get",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/send/forgot/otp`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      debugger;
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "login/otp/verify",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/validate/otp`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setNewPassword = createAsyncThunk(
  "login/password/setnew",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/reset/creds`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'login/password/setnew',
  async (postdata: any, { rejectWithValue,fulfillWithValue }) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/tourism/auth/change/creds`,
        {
          method: 'POST',
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());

      if (response.success) {
        return fulfillWithValue(response);
      } else {
        return rejectWithValue(response);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const search = createAsyncThunk(
  "search/send",
  async (
    { entity, query, page = 1, size = 10 }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity}/display${query}&page=${page}&size=${size}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const globalSearch = createAsyncThunk(
  "global/search",
  async (
    { query, page = 1, size = 10 }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/searchx?search=${query}&page=${page}&size=${size}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerDoctor = async (postdata: any) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/tourism/doctor/web/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
    }).then((data) => data.json());

    // if (resp.success) {
    //   // store.dispatch(setToken(resp.data.token));
    // }
    return resp;
  } catch (error) {
    return error;
  }
};

export const registerHospital = async (postdata: any) => {
  try {
    const resp = await fetch(
      `${API_BASE_URL}/api/tourism/hospital/web/create`,
      {
        method: "POST",
        body: JSON.stringify(postdata),
      }
    ).then((data) => data.json());

    // if (resp.success) {
    //   // store.dispatch(setToken(resp.data.token));
    // }
    return resp;
  } catch (error) {
    return error;
  }
};

export const saveEntity = async ({ body, name, id }: any) => {
  try {
    const config = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const res = await fetch(
      `${API_BASE_URL}/api/tourism/${name}/edit/${id}`,
      config
    ).then((data) => data.json());
    return res;
  } catch (error) {
    return error;
  }
};

export const saveEntityForDr = async ({ body, id, entity }: any) => {
  try {
    const config = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const res = await fetch(
      `${API_BASE_URL}/api/tourism/doctor/${entity}/create/${id}`,
      config
    ).then((data) => data.json());
    return res;
  } catch (error) {
    return error;
  }
};
export const getTreatmentsByCat = async (query: string) => {
  try {
    const { data, success, message } = await fetch(
      `${API_BASE_URL}/api/tourism/treatment/display/category?${query}`
    ).then((data) => data.json());
    if (success) {
      return data;
    }

    throw new Error(message);
  } catch (error) {
    return error;
  }
};

export const getAllCities = async () => {
  try {
    const { data, success, message } = await fetch(
      `${API_BASE_URL}/api/tourism/city/display`
    ).then((data) => data.json());
    if (success) {
      return data;
    }

    throw new Error(message);
  } catch (error) {
    return error;
  }
};

export const getPackagesHome = async () => {
  try {
    const { data, success, message } = await fetch(
      `${API_BASE_URL}/api/tourism/package/display?page=1&size=50`
    ).then((data) => data.json());
    if (success) {
      return data;
    }

    throw new Error(message);
  } catch (error) {
    return error;
  }
};

export const getSpecialities = async () => {
  const response = await fetch(
    `${API_BASE_URL}/api/tourism/speciality/display`
  );

  if (!response.ok) {
    throw new Error("An error occurred");
  }

  return response.json();
};

export const getKnowledgeCat = createAsyncThunk(
  "knowledge/category/get",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/blogcategory/display`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPatientById = createAsyncThunk(
  "patient/fetchbyid",
  async (id: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/patient/view/${id}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGallery = createAsyncThunk(
  "gallery/fetch",
  async (
    { categoryId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/gallery/display?gallery_categories_id=${categoryId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getStaticPages = createAsyncThunk(
  "staticPages/fetch",
  async (
    { categoryId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/staticPages/display?static_pages_categories_id=${categoryId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getDestination = createAsyncThunk(
  "destination/fetch",
  async (
    { categoryId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/gallery/display?gallery_categories_id=${categoryId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getGroupAwards = createAsyncThunk(
  "groupAwards/fetch",
  async (
    { categoryId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/gallery/display?gallery_categories_id=${categoryId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getPopularTreatments = createAsyncThunk(
  "popularTreatments/fetch",
  async (
    { categoryId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/gallery/display?gallery_categories_id=${categoryId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPatientEnquiryData = createAsyncThunk(
  "patientEnquiry/fetchData",
  async (
    { patientId, page = 1, size = 10 }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/tourism/enquiry/get/patient?patient_id=${patientId}&page=${page}&size=${size}`
      );
      const { data, success } = await response.json();

      if (success) {
        return fulfillWithValue(data);
      }

      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getPatientTranslatorEnquiryData = createAsyncThunk(
  "patientTranslatorEnquiry/fetchData",
  async (
    { patientId, page = 1, size = 10 }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/tourism/booking/TranslatorInterpretors/patient/get?patient_id=${patientId}&page=${page}&size=${size}`
      );
      const { data, success } = await response.json();

      if (success) {
        return fulfillWithValue(data);
      }

      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendOrder = createAsyncThunk(
  "order/send",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/order/create`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCaptcha = async () => {
  try {
    const { data, success, message } = await fetch(
      `${API_BASE_URL}/api/tourism/query/feedback/captcha/160/100`
    ).then((data) => data.json());
    if (success) {
      return data;
    }

    throw new Error(message);
  } catch (error) {
    return error;
  }
};

export const sendFeedback = createAsyncThunk(
  "order/send",
  async (postdata: any, { rejectWithValue,fulfillWithValue }) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/tourism/query/feedback/create`,
        {
          method: 'POST',
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());

      if (response.success) {
        return fulfillWithValue(response);
      } else {
        return rejectWithValue(response);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerFacilitator = async (postdata: any) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/tourism/agent/web/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};
export const getPopularCities = createAsyncThunk(
  "popularCities/fetch",
  async (a, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/popularcity/get`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      }

      return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPopularCitiesById = createAsyncThunk(
  "popularCitiesById/fetchById",
  async (slug: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/popularcity/slug/${slug}`
      ).then((response) => response.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);