import React, { useEffect, useState } from "react";
import "./ImageGallery.css";
import { getGallery } from "@/features/web/web-actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";

const ImageGallery = () => {
  const { images, loading, error } = useSelector((state: RootState) => state.gallery);
  const dispatch = useDispatch();
  const [hoveredImage, setHoveredImage] = useState(null);

  useEffect(() => {
    dispatch(getGallery({ categoryId : 1 }));
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!images || images.count === 0) {
    return <div>No data available.</div>;
  }

  return (
    <div id='gallery' className='image-gallery about_us_div'>
      <h1 className="blue heading bold pb-3">Gallery</h1>
      <div className='row gx-4'>
        {images.rows &&
          images.rows.map((image) => (
            <div className='col-md-4' key={image.id}>
              <div
                className={`image-wrapper ${
                  hoveredImage === image.id ? "hovered" : ""
                }`}
                onMouseEnter={() => setHoveredImage(image.id)}
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img src={image.image_url} alt={image.alt_tag} className='img-responsive' />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageGallery;
