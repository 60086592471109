/* eslint-disable jsx-a11y/anchor-is-valid */
import { FieldArray, Formik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getOptions } from "@/features/admin/admin-actions";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useDispatch } from "react-redux";
import TextInput from "../common/fields/TextInput";
import SingleSelect from "../common/fields/SingleSelect";

import { StyledRegisterForm } from "../common/StyledRegisterForm";
import FileInput from "../common/fields/FileInput";
import { registerPatient } from "@/features/patient/patient-actions";
import { calculateBMI } from "@/pages/my-profile/MyProfile";
import DateInput from "../common/fields/DateInput";

export const patientInitialValues = {
  first_name: "",
  password: "",
  last_name: "",
  email: "",
  contact_no: "",
  gender: "",
  dob: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
  height: "",
  weight: "",
  bmi: "",
  chief_complaint: "",
  address: "",
  any_info_doctor: "",
  diseases: [
    {
      refno: 1,
      disease_name: "",
      diagnosis_date: "",
      duration: "",
      treatment: "",
      notes: "",
      document_urls: [],
      current_status: "",
    },
  ],
  emergencyContacts: [
    {
      refno: 1,
      first_name: "",
      last_name: "",
      contact_no: "",
      relationship_id: 1,
    },
  ],
  drugs: [
    {
      refno: 1,
      drug_name: "",
      notes: "",
    },
  ],
  surgeries: [
    {
      refno: 1,
      surgery_name: "",
      diagnosis_date: "",
      duration: "",
      notes: "",
      document_urls: [],
      current_status: "",
    },
  ],
  hospitalizations: [
    {
      refno: 1,
      hospital_name: "",
      disease_name: "",
      diagnosis_date: "",
      treatment: "",
      duration: "",
      notes: "",
      document_urls: [],
    },
  ],
  habits: [
    {
      refno: 1,
      healthy_habit_name: "",
      unhealthy_habit_name: "",
      medicines: "",
    },
  ],
  answers: [
    {
      question_id: 1,
      answer1: "",
      refno: 1,
    },
    {
      question_id: 2,
      answer1: "",
      refno: 2,
    },
    {
      question_id: 3,
      answer1: "",
      refno: 3,
    },
  ],
};

const entityName = "patient";

const PatientForm = ({ style }: any) => {
  const [questions, setQuestions] = useState<any>(null);
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };
  
  const dispatch = useDispatch<AppDispatch>();
  const [currentDivIndex, setCurrentDivIndex] = useState(0);
  const [genericError, setGenericError] = useState(null);

  const handleNextClick = () => {
    setCurrentDivIndex((prevIndex) => (prevIndex < 2 ? prevIndex + 1 : 0));
  };

  const handlePrevClick = () => {
    setCurrentDivIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };
  const handleKeyPress = (event : any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
    }
  };
  // useEffect(() => {
  //   if (options && options.country) {
  //     setCountries(options.country);
  //   }
  // }, [options]);

  useEffect(() => {
    if (options && options.questions && questions == null) {
      setQuestions(
        options.questions.reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        )
      );
    }
  }, [options, questions]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);
  const totalSteps = 3; // Set the total number of steps here
  return (
    <>
      <div className='text-center'>
        <p className='mb-0'>Welcome! </p>
        <p className='pb-3'>
          Please help us know you better for a better experience!
        </p>

        <ProgressBar
          animated={true}
          className='progressBar'
          now={(currentDivIndex / totalSteps) * 100}
        />
      </div>
      <div className='card shadow' style={{ padding: 36, ...style }}>
        <Formik
          initialValues={patientInitialValues}
          onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            setErrors({});
            setGenericError(null);

            registerPatient({
              ...values,
              bmi: calculateBMI(values.height, values.weight) || "",
            }).then((resp: any) => {
              if (!resp.success) {
                if (resp.responseCode === 422) {
                  setErrors({
                    ...resp.data,
                  });
                } else {
                  setGenericError(resp.message);
                }
                window.scrollTo(0, 0);
              } else {
                resetForm();
                navigate("../partner/thank-you");
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <StyledRegisterForm>
                {genericError && (
                  <div className='genericError'>{genericError}!</div>
                )}
                <div
                  style={{
                    display: currentDivIndex === 0 ? "block" : "none",
                  }}
                >
                  <div className='row gy-4 gx-5'>
                    <div className='form-row col-md-6'>
                      <TextInput name='first_name' placeholder='First name' />
                    </div>
                    <div className='form-row col-md-6'>
                      <TextInput name='last_name' placeholder='Last name' />
                    </div>
                    <div className='form-row col-md-12'>
                      <TextInput name='email' placeholder='Email' />
                    </div>
                    <label htmlFor=''>
                      Password
                      <div className='form-row col-md-12'>
                        <TextInput
                          type='password'
                          name='password'
                          placeholder='Password'
                        />
                      </div>
                    </label>

                    <div className='form-row col-md-6 '>
                      <DateInput
                        placeholder='Date of birth (DD/MM/YYYY)'
                        name='dob'
                      />
                    </div>
                    <div className='form-row col-md-6 '>
                      <SingleSelect
                        name='gender'
                        placeholder='Gender'
                        options={[
                          { id: "F", name: "Female" },
                          { id: "M", name: "Male" },
                        ]}
                      />
                    </div>

                    <div className='col-md-12 '>
                      <TextInput
                        placeholder='Mobile Number'
                        name='contact_no'
                        maxLength={12}
                      />
                    </div>
                    <div className='col-md-12'>
                      <TextInput placeholder='Address' name='address' />
                    </div>
                    <div className='form-row col-md-3 '>
                      <SingleSelect
                        placeholder='City'
                        name='city'
                        options={options?.city}
                        backendsavefield='name'
                      />
                    </div>
                    <div className='form-row col-md-3 '>
                      <SingleSelect
                        placeholder='State'
                        name='state'
                        backendsavefield='id'
                        options={options?.state}
                      />
                    </div>
                    <div className='form-row col-md-3 '>
                      <SingleSelect
                        placeholder='Country'
                        name='country'
                        backendsavefield='id'
                        options={options?.country}
                      />
                    </div>
                    <div className='form-row col-md-3 '>
                      <TextInput
                        type='number'
                        placeholder='Zip Code'
                        name='zipcode'
                      />
                    </div>
                    <div className='col-md-4'>
                      <TextInput
                        type='number'
                        placeholder='Height (in inches)'
                        name='height'
                      />
                    </div>
                    <div className='col-md-4'>
                      <TextInput
                        type='number'
                        placeholder='Weight (in kgs)'
                        name='weight'
                      />
                    </div>
                    <div className='col-md-4'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Total BMI %'
                        onChange={handleChange}
                        onKeyDown ={handleKeyPress}
                        onBlur={handleBlur}
                        readOnly
                        value={calculateBMI(values.height, values.weight)}
                      />
                    </div>
                    <div className='submit-btn-container'>
                      <button
                        onClick={handleNextClick}
                        type='button'
                        className='btn btn-primary'
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                {/* this is second section start */}

                <div
                  style={{
                    display: currentDivIndex === 1 ? "block" : "none",
                  }}
                >
                  <div className='form-row col-md-12'>
                    <p>Chief Complaint</p>
                    <TextInput
                      name='chief_complaint'
                      placeholder='Chief Complaint'
                    />
                  </div>

                  <FieldArray
                    name='diseases'
                    render={(arrayHelpers) => (
                      <div>
                        {values.diseases.map(
                          (
                            {
                              current_status,
                              diagnosis_date,
                              document_urls,
                            }: any,
                            index: number
                          ) => (
                            <div
                              className='row gy-4 gx-5'
                              style={{ paddingTop: 36 }}
                              key={index}
                            >
                              {index > 0 && <hr />}
                              <label>
                                History of any medical disease {index + 1}
                              </label>
                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`diseases.${index}.disease_name`}
                                  placeholder='Disease Name'
                                />
                              </div>

                              <div className='form-row col-md-6'>
                                <DateInput
                                  placeholder='Date of diagnosis (DD/MM/YYYY)'
                                  name={`diseases.${index}.diagnosis_date`}
                                />
                              </div>
                              <div className='form-row col-md-6'>
                                <TextInput
                                  placeholder='Duration'
                                  name={`diseases.${index}.duration`}
                                />
                              </div>
                              <div className='form-row col-md-6 '>
                                <SingleSelect
                                  placeholder='treatment'
                                  options={options?.treatments}
                                  backendsavefield='name'
                                  name={`diseases.${index}.treatment`}
                                />
                              </div>

                              <div className='form-row col-md-12'>
                                <TextInput
                                  placeholder='Notes'
                                  name={`diseases.${index}.notes`}
                                />
                              </div>
                              <div className='form-row'>
                                <FileInput
                                  name={`diseases.${index}.document_urls`}
                                  data={{
                                    maincategory: "patient",
                                    subcategory: "documents",
                                  }}
                                />
                              </div>
                              <div className='form-row col-md-12'>
                                <div className='hospitalrdqna'>
                                  <p>Current status</p>
                                  <div className='inter_radio'>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`diseases.${index}.current_status`}
                                          value='active'
                                          checked={current_status === "active"}
                                          onChange={handleChange}
                                        />
                                        Active
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`diseases.${index}.current_status`}
                                          value='in_remission'
                                          checked={
                                            current_status === "in_remission"
                                          }
                                          onChange={handleChange}
                                        />
                                        In-Remission
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`diseases.${index}.current_status`}
                                          value='resolved'
                                          checked={
                                            current_status === "resolved"
                                          }
                                          onChange={handleChange}
                                        />
                                        Resolved
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <a
                          role='button'
                          className='d-block blue text-center add-new-btn'
                          onClick={() => {
                            setFieldValue("diseases", [
                              ...values.diseases,
                              {
                                refno: values.diseases.length,
                                disease_name: "",
                                diagnosis_date: "",
                                duration: "",
                                treatment: "",
                                notes: "",
                                document_urls: [],
                                current_status: "",
                              },
                            ]);
                          }}
                        >
                          + Add new
                        </a>
                      </div>
                    )}
                  />
                  <div className='submit-btn-container'>
                    <button
                      onClick={handlePrevClick}
                      type='button'
                      className='btn btn-primary'
                    >
                      Previous
                    </button>

                    <button
                      onClick={handleNextClick}
                      type='button'
                      className='btn btn-primary'
                    >
                      Next
                    </button>
                  </div>
                </div>

                {/* this is third section start */}

                <div
                  style={{
                    display: currentDivIndex === 2 ? "block" : "none",
                  }}
                >
                 
                  <div className="row gy-4 gx-5">
                    <label className="bold" htmlFor="">Emergency Contact</label>
                    <div className="form-row col-md-6">
                      <TextInput name="emergencyContacts[0].first_name" placeholder="First name" />
                    </div>
                    <div className="form-row col-md-6">
                      <TextInput name="emergencyContacts[0].last_name" placeholder="Last name" />
                    </div>
                    <div className="form-row col-md-6">
                      <SingleSelect
                        placeholder="Relationship"
                        options={options?.ecy_p_relationship_id}
                        backendsavefield="id"
                        name="emergencyContacts[0].relationship_id"
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <TextInput name="emergencyContacts[0].contact_no" placeholder="Mobile Number" />
                    </div>
                  </div>


                  <FieldArray
                    name='drugs'
                    render={(arrayHelpers) => (
                      <div>
                        {values.drugs.map(
                          ({ refno, drug_name, notes }: any, index: number) => (
                            <div
                              className='row gy-4 gx-5'
                              style={{ paddingTop: 36 }}
                              key={index}
                            >
                              {index > 0 && <hr />}
                              <label>
                                History of any drug allergies {index + 1}
                              </label>
                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`drugs.${index}.drug_name`}
                                  placeholder='drug Name'
                                />
                              </div>

                              <div className='form-row col-md-6'>
                                <TextInput
                                  placeholder='Notes'
                                  name={`drugs.${index}.notes`}
                                />
                              </div>
                            </div>
                          )
                        )}
                        <a
                          role='button'
                          className='d-block blue text-center add-new-btn add-new-btn'
                          onClick={() => {
                            setFieldValue("drugs", [
                              ...values.drugs,
                              {
                                refno: values.drugs.length,
                                drug_name: "",
                                notes: "",
                              },
                            ]);
                          }}
                        >
                          + Add new
                        </a>
                      </div>
                    )}
                  />
                  <label>History of any previous surgeries</label>
                  <FieldArray
                    name='surgeries'
                    render={(arrayHelpers) => (
                      <div>
                        {values.surgeries.map(
                          (
                            {
                              current_status,
                              diagnosis_date,
                              document_urls,
                            }: any,
                            index: number
                          ) => (
                            <div
                              className='row gy-4 gx-5'
                              style={{ paddingTop: 36 }}
                              key={index}
                            >
                              {index > 0 && <hr />}

                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`surgeries.${index}.surgery_name`}
                                  placeholder='Disease Name'
                                />
                              </div>

                              <div className='form-row col-md-6'>
                                <DateInput
                                  placeholder='Date of diagnosis (DD/MM/YYYY)'
                                  name={`surgeries.${index}.diagnosis_date`}
                                />
                              </div>
                              <div className='form-row col-md-6'>
                                <TextInput
                                  placeholder='Duration'
                                  name={`surgeries.${index}.duration`}
                                />
                              </div>
                              <div className='form-row col-md-6 '>
                                <SingleSelect
                                  placeholder='treatment'
                                  options={options?.treatments}
                                  backendsavefield='name'
                                  name={`surgeries.${index}.treatment`}
                                />
                              </div>

                              <div className='form-row col-md-12'>
                                <TextInput
                                  placeholder='Notes'
                                  name={`surgeries.${index}.notes`}
                                />
                              </div>
                              <div className='form-row'>
                                <FileInput
                                  name={`surgeries.${index}.document_urls`}
                                  data={{
                                    maincategory: "patient",
                                    subcategory: "documents",
                                  }}
                                />
                              </div>
                              <div className='form-row col-md-12'>
                                <div className='hospitalrdqna'>
                                  <p>Current status</p>
                                  <div className='inter_radio'>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`surgeries.${index}.current_status`}
                                          value='active'
                                          checked={current_status === "active"}
                                          onChange={handleChange}
                                        />
                                        Active
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`surgeries.${index}.current_status`}
                                          value='in_remission'
                                          checked={
                                            current_status === "in_remission"
                                          }
                                          onChange={handleChange}
                                        />
                                        In-Remission
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`surgeries.${index}.current_status`}
                                          value='resolved'
                                          checked={
                                            current_status === "resolved"
                                          }
                                          onChange={handleChange}
                                        />
                                        Resolved
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <a
                          role='button'
                          className='d-block blue text-center add-new-btn'
                          onClick={() => {
                            setFieldValue("surgeries", [
                              ...values.surgeries,
                              {
                                refno: values.surgeries.length,
                                disease_name: "",
                                diagnosis_date: "",
                                duration: "",
                                treatment: "",
                                notes: "",
                                document_urls: [],
                                current_status: "",
                              },
                            ]);
                          }}
                        >
                          + Add new
                        </a>
                      </div>
                    )}
                  />

                  <label>Please list history of any hospitalization</label>
                  <FieldArray
                    name='hospitalizations'
                    render={(arrayHelpers) => (
                      <div>
                        {values.hospitalizations.map(
                          (
                            {
                              current_status,
                              diagnosis_date,
                              document_urls,
                            }: any,
                            index: number
                          ) => (
                            <div
                              className='row gy-4 gx-5'
                              style={{ paddingTop: 36 }}
                              key={index}
                            >
                              {index > 0 && <hr />}

                              <div className='form-row col-md-6 '>
                                <SingleSelect
                                  placeholder='Hospital Name'
                                  options={options?.hospital}
                                  backendsavefield='name'
                                  name={`hospitalizations.${index}.hospital_name`}
                                />
                              </div>
                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`hospitalizations.${index}.disease_name`}
                                  placeholder='Disease Name'
                                />
                              </div>

                              <div className='form-row col-md-6'>
                                <DateInput
                                  placeholder='Date of diagnosis (DD/MM/YYYY)'
                                  name={`hospitalizations.${index}.diagnosis_date`}
                                />
                              </div>
                              <div className='form-row col-md-6'>
                                <TextInput
                                  placeholder='Duration'
                                  name={`hospitalizations.${index}.duration`}
                                />
                              </div>
                              <div className='form-row col-md-6 '>
                                <SingleSelect
                                  placeholder='Treatment'
                                  options={options?.treatments}
                                  backendsavefield='name'
                                  name={`hospitalizations.${index}.treatment`}
                                />
                              </div>

                              <div className='form-row col-md-12'>
                                <TextInput
                                  placeholder='Notes'
                                  name={`hospitalizations.${index}.notes`}
                                />
                              </div>
                              <div className='form-row'>
                                <FileInput
                                  name={`hospitalizations.${index}.document_urls`}
                                  data={{
                                    maincategory: "patient",
                                    subcategory: "documents",
                                  }}
                                />
                              </div>
                              <div className='form-row col-md-12'>
                                <div className='hospitalrdqna'>
                                  <p>Current status</p>
                                  <div className='inter_radio'>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`hospitalizations.${index}.current_status`}
                                          value='active'
                                          checked={current_status === "active"}
                                          onChange={handleChange}
                                        />
                                        Active
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`hospitalizations.${index}.current_status`}
                                          value='in_remission'
                                          checked={
                                            current_status === "in_remission"
                                          }
                                          onChange={handleChange}
                                        />
                                        In-Remission
                                      </label>
                                    </div>
                                    <div className='inter_radio'>
                                      <label>
                                        <input
                                          type='radio'
                                          name={`hospitalizations.${index}.current_status`}
                                          value='resolved'
                                          checked={
                                            current_status === "resolved"
                                          }
                                          onChange={handleChange}
                                        />
                                        Resolved
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <a
                          role='button'
                          className='d-block blue text-center add-new-btn'
                          onClick={() => {
                            setFieldValue("hospitalizations", [
                              ...values.hospitalizations,
                              {
                                refno: values.hospitalizations.length,
                                hospital_name: "",
                                disease_name: "",
                                diagnosis_date: "",
                                duration: "",
                                treatment: "",
                                notes: "",
                                document_urls: [],
                                current_status: "",
                              },
                            ]);
                          }}
                        >
                          + Add new
                        </a>
                      </div>
                    )}
                  />

                  <label>Habbits</label>
                  <FieldArray
                    name='habits'
                    render={(arrayHelpers) => (
                      <div>
                        {values.habits.map(
                          (
                            {
                              healthy_habit_name,
                              unhealthy_habit_name,
                              medicines,
                            }: any,
                            index: number
                          ) => (
                            <div
                              className='row gy-4 gx-5'
                              style={{ paddingTop: 36 }}
                              key={index}
                            >
                              {index > 0 && <hr />}

                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`habits.${index}.healthy_habit_name`}
                                  placeholder='Healthy Habit Name'
                                />
                              </div>
                              <div className='form-row col-md-6 '>
                                <TextInput
                                  name={`habits.${index}.unhealthy_habit_name`}
                                  placeholder='Unhealthy Habit Name'
                                />
                              </div>

                              <div className='form-row col-md-12'>
                                <TextInput
                                  placeholder='Medicines'
                                  name={`habits.${index}.medicines`}
                                />
                              </div>
                            </div>
                          )
                        )}
                        <a
                          role='button'
                          className='d-block blue text-center add-new-btn'
                          onClick={() => {
                            setFieldValue("habits", [
                              ...values.habits,
                              {
                                refno: values.habits.length,
                                healthy_habit_name: "",
                                unhealthy_habit_name: "",
                                medicines: "",
                              },
                            ]);
                          }}
                        >
                          + Add new
                        </a>
                      </div>
                    )}
                  />
                  {questions && (
                    <>
                      <label>Family history of</label>
                      <FieldArray
                        name='answers'
                        render={(arrayHelpers) => (
                          <div style={{ marginBottom: 24 }}>
                            {values.answers.map(
                              (
                                { question_id, answer1, refno }: any,
                                index: number
                              ) => (
                                <div
                                  className='row gy-4 gx-5'
                                  style={{ paddingTop: 16 }}
                                  key={index}
                                >
                                  <div className='form-row col-md-12'>
                                    <div className='hospitalrdqna'>
                                      <p>{questions[question_id].name}</p>
                                      <div className='inter_radio'>
                                        <div className='inter_radio'>
                                          <label>
                                            <input
                                              type='radio'
                                              name={`answers.${index}.answer1`}
                                              value='yes'
                                              checked={answer1 === "yes"}
                                              onChange={handleChange}
                                            />
                                            Yes
                                          </label>
                                        </div>
                                        <div className='inter_radio'>
                                          <label>
                                            <input
                                              type='radio'
                                              name={`answers.${index}.answer1`}
                                              value='no'
                                              checked={answer1 === "no"}
                                              onChange={handleChange}
                                            />
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                             <div className="form-row col-md-12 pt-3">
                              <TextInput name="any_info_doctor" placeholder="Any other information that would like to share with your doctor?" />
                            </div>
                          </div>
                        )}
                      />
                    </>
                  )}

                  <div className='submit-btn-container'>
                    <button
                      onClick={handlePrevClick}
                      type='button'
                      className='btn btn-primary'
                    >
                      Previous
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                </div>

                {currentDivIndex === 3 && (
                  <Navigate to='../partner/thank-you' />
                )}
              </StyledRegisterForm>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PatientForm;
