import { useContext, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionButton,
} from "react-bootstrap";
import styled from "styled-components";
import HelpForm from "../doctors/HelpForm";

const HeaderContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 10px;
  .row-1 {
    display: flex;
    justify-content: space-between;

    span.icon {
      display: inline-block;
      width: 20px;
    }
  }
  .row-2 {
    text-align: left;
  }
`;

const CardHeader = styled(Card.Header)`
  background: transparent;
`;

interface FAQItem {
  question: string;
  answer: string;
}

const Faq = () => {
  const [activeKey, setActiveKey] = useState<string | null>('0');

  const faqData: FAQItem[] = [
    {
      question: 'How to select medical tourism destination?',
      answer: 'Not all medical tourism destinations are famous for all kinds of treatment. Few destinations might be good in cancer treatement while other few might be popular for heart surgery. Inorder to find out which destinations are better for the kind of treatment you are looking for, read our blog on : famous destinations for various treatments. ',
    },
    {
      question: 'How to plan medical tourism travel?',
      answer: 'Planning a medical travel outside your country is a very difficult process. It can give you nightmare even thinking about it. The first thing that you need to do is find a medical tourism value planner. At plan medical tourism we will guide you step by step to plan your medical tourism travel the way you want to. We give attention to every minute needs that you would want in your medical tour. ',
    },
    {
      question: 'For which treatments India is more preferred?',
      answer: 'India is preferred for joint replacement surgeries, bone marrow transfers, cancer surgeries, organ transplants, heart surgeries, skin treatment, dental treatments, nephrology procedures and infertility treatments. To know more about famous treatments in India read our blog on “popular treatments in India”',
    },
    {
      question: 'Which cities have direct connecting flights from various nations?',
      answer: 'Mumbai, Delhi and Bangalore are one of the well connected cities in India and have direct international flights from most of the countries. ',
    },
    {
      question: 'Who travels to India for medical tourism?',
      answer: 'Medical tourists across the globe travel to India for medical treatments. Non-Residents Indians across the globe, people from the neighbouring countries like the Most of the tourists are from Asian or African countries such as Sri Lanka, Bangladesh, Nepal, Maldives, Indonesia and Kenya, among others.',
    },
    {
      question: 'How do I select a doctor in India as there are many treating doctors in the same city?',
      answer: 'The best way to choose a doctor is to check his credentials based on his degree, experience, number of patients treated, treatment outcome, medical association memberships, awards and recognitions and review his work on the google review, websites and other sources on the net. ',
    },
    {
      question: 'I am currently in the USA but hold Indian passport, do I need a medical visa for medical treatment in India? ',
      answer: 'Any individual irrespective of its current geographical location can travel to India for medical treatment without applying for medical visa if he or she holds a valid India passport. ',
    },
    {
      question: 'I am a foreign national and planning to come to India for medical treatment. Which visa do I apply for?',
      answer: 'If you are a foreign national it is recommended to come to India on a medical visa. For more information on medical visa application you can visit : https://www.india-visa-online.org/types/india-medical-visa/ ',
    },
    {
      question: 'I am a foreign national do I need to buy insurance for medical treatment in India? ',
      answer: 'While it is not compulsory to buy a health insurance while travelling to India as medical tourists. But you can buy one if you intend to. ',
    },
    {
      question: 'I have a OCI card, do I need to travel to India on Medical Visa when planning for a treatment in India?',
      answer: 'No, OCI card holders need not come to India on a Medical Visa for medical treatment. They can travel on their OCI card to India as they have been doing. ',
    },
    {
      question: 'Can foreigners travel to India for medical treatment without a medical visa?  ',
      answer: 'When travelling from your country to India if the purpose of travel is medical treatment it is recommended to travel to India on a medical visa. Many a times foreigners travel to India for leisure and later during an OPD consultation with a doctor, they decide to get medical treatment. During that time, the travel visa can be converted in to medical visa. ',
    },
    {
      question: 'Is India safe for medical travel? ',
      answer: 'India boasts a robust healthcare infrastructure that rivals some of the best in the world. The country is home to numerous internationally accredited hospitals and clinics that adhere to strict quality standards.',
    },
    {
      question: 'What precautions should I take while travelling to India for medical treatment? ',
      answer: 'Carry all of your medical documents and records along, like the reports, medical history, prescriptions, health records, X-Rays, MRIs, medicines and photographs. Passport & Visa',
    },
    // Add more FAQ data objects as needed
  ];

  const handleAccordionToggle = (eventKey: string | null) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  const renderToggleIcon = (eventKey: string) => {
    return  <div className="bold">
    <span className="icon">{ activeKey === eventKey ? "-" : "+"}</span> Question
  </div>
    //  activeKey === eventKey ? '-' : '+';
  };

  return (
    <>
    <div className="mt-5 terms">
      <p className="blue heading bold m-5 text-center">FAQ’s About Medical Tourism In India</p>
      <Accordion activeKey={activeKey} defaultActiveKey="0">
        {faqData.map((faq, index) => (
          <Card key={index}>
            <Accordion style={{ background: "#fff" }}
              as={Card.Header}
              eventKey={index.toString()}
              onClick={() => handleAccordionToggle(index.toString())}
            >
              <span className="icon">{renderToggleIcon(index.toString())}</span>
              {faq.question}
            </Accordion>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>{faq.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
      <HelpForm style={{ maxWidth: 842, margin: "5em auto 0em auto" }} />
    </>
  );
};

export default Faq;
