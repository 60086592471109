import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPopularTreatments } from "@/features/web/web-actions";
import { RootState, AppDispatch } from '../../store';

export const HospitalCard = styled.div`
  width: 328px;
  height: 314px;
  margin: 1em 1.5em;
  @media (max-width: 768px) {
    margin: 1em 2.5em;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardLabel = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 50px;
  display: block;
`;

const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: (
    <div>
      <div className='next-slick-arrow'>
        <i className='bi bi-arrow-right-circle bi_arrow'></i>
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className='prev-slick-arrow'>
        <i className='bi bi-arrow-left-circle bi_arrow'></i>
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

// Define the type for the props
interface CardPopularTreatmentsProps {
  image: string;
  title: string;
  alt_tag: string;
}

const CardPopularTreatments: React.FC<CardPopularTreatmentsProps> = ({ image, title , alt_tag}) => {
  return (
    <HospitalCard className="card shadow position-relative">
      <ImageWrapper>
        <img src={image} className='card-img-top destination-img crCityImg' alt={alt_tag} />
      </ImageWrapper>
      <CardLabel className="sub-heading blue semibold">{title}</CardLabel>
    </HospitalCard>
  );
};

const PopularTreatmentsIndia = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { images, loading, error } = useSelector((state: RootState) => state.popularTreatments);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getPopularTreatments({ categoryId: 5 })).then((res: any) => {
      setData(res.payload.rows);
    });
  }, [dispatch]);

  return (
    <div className="pt-3">
      <p className="blue heading bold px-5">{t("homePage.popularTreatmentsInIndia")}</p>
      {data ? (
        <Slider {...settings}>
          {data.map(({ image_url, title, url, alt_tag }, idx) => (
            <div key={idx} className='d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-5'>
              <a href={url}>
              <CardPopularTreatments image={image_url} title={title} alt_tag={alt_tag} />
              </a>
            </div>
          ))}
        </Slider>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default PopularTreatmentsIndia;
