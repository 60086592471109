/* eslint-disable jsx-a11y/anchor-is-valid */
import { FieldArray, useFormikContext } from "formik";

const DrFormExperience = ({ values, handleChange, handleBlur }: any) => {
  const formikKey = "experiences";
  const { setFieldValue } = useFormikContext();
  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers) => (
        <div>
          <p className="body-text">Experiences</p>

          {values[formikKey].map((_o: any, index: number) => (
            <div className="form-row row gx-5 array-field-row" key={index}>
              <div className="form-group col-md-6">
                <input
                  type="input"
                  className="form-control"
                  placeholder="Designation"
                  name={`${formikKey}.${index}.designation`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group col-md-6 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Institution Name"
                  name={`${formikKey}.${index}.hospital_name`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name={`${formikKey}.${index}.city`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group col-md-6 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Year"
                  name={`${formikKey}.${index}.from_date`}
                  onChange={handleChange}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    e.target.type = "text";
                    handleBlur(e);
                  }}
                />
              </div>
            </div>
          ))}
          <a
            role="button"
            className="d-block blue text-center"
            onClick={() => {
              setFieldValue(formikKey, [
                ...values[formikKey],
                {
                  designation: "",
                  hospital_name: "",
                  city: "",
                  from_date: "",
                },
              ]);
            }}
          >
            + Add new Experiences
          </a>
        </div>
      )}
    />
  );
};

export default DrFormExperience;
