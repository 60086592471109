import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { getDoctorMasterData } from "@/features/web/doctor-actions";
import { getOptions } from "@/features/admin/admin-actions";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useDispatch } from "react-redux";
import { Select, Upload, message } from "antd";
import * as Yup from "yup";
import { PlusCircleOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "@/utils/Env";
import { StyledRegisterForm } from "../common/StyledRegisterForm";
import CKEditorFormik from "./form-components/CKEditorFormik";
const { Dragger } = Upload;

const entityName = "doctor";

const StringRule = Yup.string()
  .min(1, "Too Short!")
  .max(50, "Too Long!")
  .required("Required");

const DoctorSchema = Yup.object().shape({
  first_name: StringRule,
  last_name: StringRule,
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  specialization: Yup.array().min(1, "Required"),
  treatment: Yup.array().min(1, "Required"),
  practice_start_date: Yup.date().required("Required"),
  license_number: Yup.string(),
  country: Yup.number().required("Required"),
  contact_no: Yup.string()
  .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits')
  .required('Required'),
});

const uploadProps = (setFieldValue: any, field: string) => ({
  data: {
    maincategory: "doctor",
    subcategory: "profile",
  },
  name: "file",
  multiple: true,
  action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
  onChange(info: any) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setFieldValue(field, info.file.response.imageUrl);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
});

const salutations = [
  { id: "Dr.", name: "Dr." },
  { id: "VD.", name: "VD." },
  { id: "Therapist", name: "Therapist" },
];

const DoctorForm1 = ({
  style,
  isCompact = false,
  nextStep,
  setDoctorFormData,
  doctorFormData,
  genericError,
  errors,
}: any) => {
  const { data } = useSelector((state: RootState) => state.admin);
  const { options = {} } = data[entityName] || {};
  const [treatmentList, setTreatmentList] = useState([]);
  const formikRef = useRef<any>();
  const [specilizationVal, setSpecilizationVal] = useState<any>([]);

  const specializationChange = (value: any[]) => {
    formikRef?.current?.setFieldValue("specialization", value);
    setSpecilizationVal(value);
  };

  useEffect(() => {
    if (options.treatment) {
      setTreatmentList(
        options.treatment.filter(
          (item: any) => specilizationVal.indexOf(item.specialization_id) !== -1
        )
      );
    }
  }, [options.treatment, specilizationVal]);

  useEffect(() => {
    if (doctorFormData.specialization) {
      setSpecilizationVal(doctorFormData.specialization);
    }
  }, [doctorFormData]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!options) {
      dispatch(getDoctorMasterData());
    }
  }, [options, dispatch]);

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);

  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <Formik
        innerRef={formikRef}
        initialValues={doctorFormData}
        validationSchema={DoctorSchema}
        enableReinitialize={false}
        initialErrors={errors}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          setErrors({});
          // setGenericError(null);
          setDoctorFormData(values);
          nextStep();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledRegisterForm>
              {genericError && (
                <div className="genericError">{genericError}!</div>
              )}
              <div className="row gx-5 gy-4">
                <div className="form-row col-md-2">
                  <Form.Select
                    className="form-control"
                    name="salutation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.salutation}
                  >
                    <option value="">Salutation</option>
                    {salutations.map(({ id, name }: any) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Select>
                  {touched.salutation && (
                    <div className="error">{errors.salutation as string}</div>
                  )}
                </div>
                <div className="form-row col-md-5">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Doctor’s first name"
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  {touched.first_name && (
                    <div className="error">{errors.first_name as string}</div>
                  )}
                </div>
                <div className="form-row col-md-5">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Doctor’s last name"
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  {touched.last_name && (
                    <div className="error">{errors.last_name as string}</div>
                  )}
                </div>
                <div className="form-row col-md-6">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && (
                    <div className="error">{errors.email as string}</div>
                  )}
                </div>
                <div className="form-row col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {touched.password && (
                    <div className="error">{errors.password as string}</div>
                  )}
                </div>
                <div className="form-row col-md-12">
                  <label className="pb-3" htmlFor="">
                    About the doctor
                  </label>
                  <CKEditorFormik name="about" />
                </div>
                <div className="form-row col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Practice start date"
                    name="practice_start_date"
                    onChange={handleChange}
                    onBlur={(e) => {
                      e.target.type = "text";
                      handleBlur(e);
                    }}
                    value={values.practice_start_date}
                    onFocus={(e) => (e.target.type = "date")}
                  />
                  {touched.practice_start_date && (
                    <div className="error">
                      {errors.practice_start_date as string}
                    </div>
                  )}
                </div>
                <div className="form-row col-md-6">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Licence Number"
                    name="license_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.license_number}
                  />
                  {touched.license_number && (
                    <div className="error">
                      {errors.license_number as string}
                    </div>
                  )}
                </div>

                <div className="form-row col-md-6">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Success rate"
                    name="success_rate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.success_rate}
                  />
                </div>
                <div className="form-row col-md-6">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Number of procedures performed"
                    name="number_of_procedures_performed"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.number_of_procedures_performed}
                  />
                </div>
                <div className="form-group col-md-6 ">
                  <Select
                    className="select"
                    style={{ width: "100%" }}
                    placeholder="Popular specialization"
                    id="specialization"
                    mode="multiple"
                    onChange={specializationChange}
                    onBlur={handleBlur}
                    value={values.specialization}
                    filterOption={(inputValue, option) =>
                      !!option && typeof option.label === 'string' && option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  
                    options={
                      options.specialization &&
                      options.specialization.map(({ id, name }: any) => ({
                        label: name,
                        value: id,
                      }))
                    }
                  />
                  {touched.specialization && (
                    <div className="error">
                      {errors.specialization as string}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6 ">
                  <Select
                    className="select"
                    style={{ width: "100%" }}
                    placeholder="Popular treatments"
                    id="treatment"
                    onBlur={handleBlur}
                    value={values.treatment}
                    mode="multiple"
                    filterOption={(inputValue, option) =>
                      !!option && typeof option.label === 'string' && option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  
                    onChange={(value) => setFieldValue("treatment", value)}
                    options={treatmentList.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                  {touched.treatment && (
                    <div className="error">{errors.treatment as string}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <input
                  type="input"
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                />
              </div>
              <div className="row">
                <div className="col-2">
                  <input
                    type="string"
                    maxLength={4}
                    className="form-control"
                    placeholder="+91"
                    name="isd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isd}
                  />
                </div>
                <div className="col-9 offset-1 ">
                  <input
                    type="string"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="contact_no"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_no}
                    maxLength={10}
                  />
                  {touched.contact_no && (
                    <div className="error">{errors.contact_no as string}</div>
                  )}
                </div>
              </div>
              {!isCompact && (
                <div className="d-none d-sm-block">
                  <div className="form-row row gx-5">
                    <div className="form-group col-md-4">
                      <Form.Select
                        className="form-control"
                        placeholder="City"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      >
                        <option value="">Select City</option>
                        {options.city &&
                          options.city.map(({ id, name }: any) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                    <div className="form-group col-md-4 ">
                      <Form.Select
                        className="form-control"
                        placeholder="State"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                      >
                        <option value="">Select State</option>
                        {options &&
                          options.state &&
                          options.state.map(({ id, name }: any) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                    <div className="form-group col-md-4 ">
                      <Form.Select
                        placeholder="Country"
                        className="form-control"
                        name="country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                      >
                        <option value="">Select Country</option>
                        {options.country &&
                          options.country.map(({ id, name }: any) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </Form.Select>
                      {touched.country && (
                        <div className="error">{errors.country as string}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {isCompact && (
                <div className="form-row">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  />
                  {touched.country && (
                    <div className="error">{errors.country as string}</div>
                  )}
                </div>
              )}

              <label htmlFor="profile">Add a profile Picture</label>
              <div className="form-row">
                <Dragger {...uploadProps(setFieldValue, "profile_url")}>
                  <PlusCircleOutlined />
                  <p className="ant-upload-text">Upload a picture here</p>
                </Dragger>
              </div>
              <div className="submit-btn-container">
                <button type="submit" className="btn btn-primary">
                  Next
                </button>
              </div>
            </StyledRegisterForm>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DoctorForm1;
