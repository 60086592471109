import WhyChooseUs from "../home/WhyChooseUs";

const WhyChooseUsDetails = () => {
  return (
    <div>
      <WhyChooseUs />
    </div>
  );
};

export default WhyChooseUsDetails;
