import logo from "@/assets/icon/logo.png";
import "./navigation.scss";
import { useEffect, useState } from "react";
import Search from "./Search";
import styled from "styled-components";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getKnowledgeCat } from "@/features/web/web-actions";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { logout } from "@/features/auth/authSlice";
import useRoles from "@/components/hooks/useRoles";
const NavBar = styled.nav`
  min-height: 82px;
  justify-content: center;
  align-items: center;
  .nav-item {
    margin: 0 0px;
}
a.navlink {
  min-height: 30px;
  color: var(--blue);
  font-weight:600;
}
`;

const NavSearch = styled(Search)`
  height: 34px;
`;

const NavigationBar = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const {
    entities: allowedEntities,
  } = useSelector((state: RootState) => state.auth);
  const isPatient = useRoles("PATIENT");
  const isDoctor = useRoles("DOCTOR");
  const isAdmin = useRoles("ADMIN");
  const isAgent = useRoles("FAREAGENT");
  const isHospital = useRoles("HOSPITAL");
  const { t } = useTranslation();
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [knowledgeSubMenu, setKnowledgeSubMenu] = useState([
    { name: "Blog", id: "blog" },
    { name: "News", id: "news" },
  ]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setIsNavCollapsed(true);
  }, [location]);

  useEffect(() => {
    dispatch(getKnowledgeCat()).then((response) => {
      // Assuming the API response returns data like [{ id: 1, name: "Blog" }, { id: 2, name: "News" }]
      // Mapping the numerical IDs to strings "blog" and "news"
      const mappedData = response.payload.map((item: { name: any; id: number; }) => ({
        name: item.name,
        id: item.id === 1 ? "blog" : item.id === 2 ? "news" : item.id
      }));
      setKnowledgeSubMenu(mappedData);
    });
  }, [dispatch]);

  const className = ({ isActive, isPending }: any) => {
    return "navlink " + (isPending ? "pending" : isActive ? "active" : "");
  };

  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login/patient");
  };

  return (
    <NavBar className="d-flex navbar navbar-expand-md">
      <NavLink className="d-none d-xl-block navbar-brand" to="/">
        <img alt="" src={logo} />
      </NavLink>

      <button
        className="custom-toggler navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExample09"
        aria-controls="navbarsExample09"
        aria-expanded={!isNavCollapsed ? true : false}
        aria-label="Toggle navigation"
        onClick={handleNavCollapse}
      >
        <span className="navbar-toggler-icon "></span>
      </button>
      <NavSearch className="flex-grow-1 d-sm-block d-md-none" />
      <div
        className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
        id="navbarNav"
      >
        <ul className="d-flex justify-content-around container-fluid navbar-nav mob_nav">
          <li className="nav-item">
            <NavLink to="/" className={className}>
              {t("navigation.home")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/hospitals" className={className}>
              {t("navigation.hospital")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/doctors" className={className}>
              {t("navigation.doctors")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/treatments" className={className}>
              {t("navigation.treatments")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/enquiry" className={className}>
              {t("navigation.enquiry")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/travel-services" className={className}>
              {t("navigation.travelServices")}
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <p className="className nav_link">
              {t("navigation.knowledge")}
            </p>
            <div
              className="dropdown-menu"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {knowledgeSubMenu &&
                Array.isArray(knowledgeSubMenu) &&
                knowledgeSubMenu.map(({ name, id }) => (
                  <NavLink className="dropdown-item" to={`/knowledge/${id}`}>
                    {name}
                  </NavLink>
                ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <p
              className="className nav_link"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("navigation.ambulanceService")}
            </p>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink className="dropdown-item" to="/air-ambulance">
                {t("navigation.airAmbulance")}
              </NavLink>
              <div className="menu-dropdown">
                <ul>
                  <li>
                    <span className="dropdown-item">
                      {t("navigation.roadAmbulance")}&nbsp; &nbsp; &gt;
                    </span>
                    <ul>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/basic-life-support"
                        >
                          {t("navigation.basicLifeSupport")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/hearse-ambulance"
                        >
                          {t("navigation.hearseAmbulance")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/advance-life-support"
                        >
                          {t("navigation.advanceLifeSupport")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <NavLink className="dropdown-item" to="/train-ambulance">
                {t("navigation.trainAmbulance")}
              </NavLink>
            </div>
          </li>
          {!isAuthenticated && (
            <li className="nav-item dropdown">
              <NavLink
                to="/partner"
                className={className}
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.partner")}
              </NavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <NavLink className="dropdown-item" to="/partner/doctor">
                  {t("navigation.partnerDoctor")}
                </NavLink>
                <NavLink className="dropdown-item" to="/partner/hospital">
                  {t("navigation.partnerHospital")}
                </NavLink>
                <NavLink className="dropdown-item" to="/partner/patient">
                  {t("navigation.partnerPatient")}
                </NavLink>
                <NavLink className="dropdown-item" to="/partner/facilitator">
                  {t("navigation.partnerFareAgent")}
                </NavLink>
              </div>
            </li>
          )}

          {!isAuthenticated && (
            <li className="nav-item dropdown">
              <NavLink to="/login" className={className}>
                {t("navigation.login")}
              </NavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <NavLink className="dropdown-item" to="/login/doctor">
                  {t("navigation.loginDoctor")}
                </NavLink>
                <NavLink className="dropdown-item" to="/login/hospital">
                  {t("navigation.loginHospital")}
                </NavLink>
                <NavLink className="dropdown-item" to="/login/patient">
                  {t("navigation.loginPatient")}
                </NavLink>
                <NavLink className="dropdown-item" to="/login/facilitator">
                  {t("navigation.partnerFareAgent")}
                </NavLink>
              </div>
            </li>
          )}

          {isAuthenticated && (
            <li className="nav-item dropdown">
              <NavLink to="#" className={className}>
                My Profile
              </NavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {isPatient && (
                  <>
                    <NavLink className="dropdown-item" to="/my-profile">
                      My Profile
                    </NavLink>
                    <NavLink className="dropdown-item" to="/my-enquiry">
                      My Enquiry
                    </NavLink>
                    <NavLink className="dropdown-item" to="/interpreter-enquiry">
                      Interpreter Enquiry
                    </NavLink>
                  </>
                )}
                <NavLink className="dropdown-item" to="/login/change-password">
                  Change Password
                </NavLink>
                {!isPatient && !isDoctor && !isHospital && !isAgent && (
                  <NavLink className="dropdown-item" to="/admin">
                    Dashboard
                  </NavLink>
                )}
                 {isDoctor && (
                  <NavLink className="dropdown-item" to="/admin/doctor">
                    Dashboard
                  </NavLink>
                )}
                 {isHospital && (
                  <NavLink className="dropdown-item" to="/admin/hospital">
                    Dashboard
                  </NavLink>
                )}
                {isAgent && (
                  <NavLink className="dropdown-item" to="/agent/agent">
                    Dashboard
                  </NavLink>
                )}
                <NavLink
                  className="dropdown-item"
                  to="/logout"
                  onClick={logoutHandler}
                >
                  Logout
                </NavLink>
              </div>
            </li>
          )}
        </ul>
      </div>
    </NavBar>
  );
};

export default NavigationBar;
