import { getPackageById } from "@/features/admin/admin-actions";
import { AppDispatch , RootState } from "@/store";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BlueButton } from "@/components/styled";
import GetIntouch from "./GetInTouch";
import { sendEnquiry } from "@/features/web/web-actions";
import { useSelector } from "react-redux";
import useRoles from "@/components/hooks/useRoles";
import { viewEntity } from "@/features/admin/admin-actions";
const StyledPackageBooking = styled.div`
  text-align: left;
  .payment-confirmation {
    display: flex;
    gap: 24px;
    .card-details {
      text-align: center;
      flex: 1 1 70%;
    }

    .payment-summary {
      flex: 1 1 30%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .card {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .summary {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .title {
          }
          .details {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }
`;

const PackageBooking = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { packageId = "" } = useParams<{ packageId: string }>();

  const [data, setData] = useState<any>();
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const { userInfo = null, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const orderResponse = useSelector((state: RootState) => state.web.orderResponse);
    
  useEffect(() => {
    dispatch(getPackageById(packageId)).then((res: any) => {
      setData(res.payload);
    });
  }, [dispatch, packageId]);

  const isPatient = useRoles("PATIENT");

  useEffect(() => {
    if (isAuthenticated && userInfo?.entityId && isPatient) {
      dispatch(viewEntity({ name: "patient", id: userInfo.entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setUserData((payload as any).data);
          }
        }
      );
    }
  }, [dispatch, isAuthenticated, userInfo, isPatient]);
  if (!data || !userData) {
    return null;
  }

  return (
    <StyledPackageBooking className="">
      <div className="payment-confirmation">
        <div className="card shadow card-details">
        <span className="bold">Payment Summary</span>
        <div className="content">
          <img src={data.profile_url} width={450} height={200} alt="" />
        </div>
        <div className="">
          <h3>Package Name : {data.title}</h3>
          <h3>Package Price : &#8377;{data.actual_price}</h3>
          <div className="book-now">
            <a href={orderResponse.payLink}>

          <BlueButton
            style={{
              width: 234,
              height: 58,
            }}
            >
            Porceed for payment
          </BlueButton>
            </a>
        </div>
        </div>
        </div>
        <div className="payment-summary">
          <div className="card shadow">
            <div className="summary">
              <div className="title blue sub-heading bold">Package Summary</div>
              <div className="details body-text medium">
                <div>{data.title}</div>
                <div>From Apollo Hospitals</div>
              </div>
            </div>
            <div className="summary">
              <div className="title blue sub-heading bold">Patient Details</div>
              <div className="details body-text medium">
                <div>{userData.first_name} {userData.last_name}</div>
                <div>{userData.contact_no}</div>
                <div>{userData.email}</div>
              </div>
            </div>
          </div>
          <GetIntouch />
        </div>
      </div>
    </StyledPackageBooking>
  );
};

export default PackageBooking;
