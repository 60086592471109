import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { useRef, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getAll, updateEnquiry } from "@/features/admin/admin-actions";
import { useSelector } from "react-redux";

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;

const EnquiryPopup = ({ show, handleClose, entity, status }: any) => {
  const commentRef = useRef<HTMLTextAreaElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { data: storeData, entities } = useSelector(
    (state: RootState) => state.admin
  ) as Record<string, any>;

  const options = useMemo(
    () =>
      storeData.enquiry.options
        ? storeData.enquiry.options.enquiryStatus
        : null,
    [storeData.enquiry]
  );

  const update = () => {
    dispatch(
      updateEnquiry({
        status: status.status,
        id: status.id,
        name: "enquiry",
        remarks: commentRef.current?.value,
      })
    ).then((res) => {
      handleClose();
    });
  };

  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Status</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Change status to :
              <strong>{options && options[status.status]}</strong>
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              ref={commentRef}
              placeholder="(optional)"
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <Buttons>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={update}>
              Update
            </Button>
          </Buttons>
        </Form>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default EnquiryPopup;
