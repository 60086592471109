/* eslint-disable jsx-a11y/alt-text */
import { createElement } from "react";
import * as icons from "@ant-design/icons";
import type { MenuProps } from "antd";
import Dashboard from "@/assets/admin/sidenav/Dashbroad-01.png";
import Doctor from "@/assets/admin/sidenav/Doctor-01.png";
import DoctorRank from "@/assets/admin/sidenav/Doctor-ranking-01-01.png";
import Hospital from "@/assets/admin/sidenav/Hospitals-01.png";
import HospitalRank from "@/assets/admin/sidenav/Hospital-ranking-01.png";
import Treatment from "@/assets/admin/sidenav/Treatment-01.png";
import Enquiry from "@/assets/admin/sidenav/Enquiry-01.png";
import Booking from "@/assets/admin/sidenav/Booking-01.png";
import Blog from "@/assets/admin/sidenav/Blog-01.png";
import Banner from "@/assets/admin/sidenav/Banner-01.png";
import Users from "@/assets/admin/sidenav/Users.png";
import Patient from "@/assets/admin/sidenav/patient.png";
import Testimonial from "@/assets/admin/sidenav/Testimonial.png";
import Category from "@/assets/admin/sidenav/Category.png";
import Specialist from "@/assets/admin/sidenav/Specialist.png";
import Speciality from "@/assets/admin/sidenav/Specialisty.png";
import Package from "@/assets/admin/sidenav/packages.png";
import Payment from "@/assets/admin/sidenav/Payment.png";
import Gallery from "@/assets/admin/sidenav/Gallery.png";
import Facilitor from "@/assets/admin/sidenav/facilitator.png";


const menuItems: MenuProps["items"] = [
  {
    icon: createElement("img", { src: Dashboard , style: { width: "24px" }}),
    key: "dashbroad",
    label: "Dashboard",
  },
  {
    icon: createElement("img", { src: Doctor , style: { width: "24px" }}),
    key: "doctor",
    label: "Doctors",
  },
  {
    icon: createElement("img", { src: DoctorRank , style: { width: "24px" }}),
    key: "doctor/ranking",
    label: "Doctor Ranking",
  },
  {
    icon: createElement("img", { src: Hospital , style: { width: "24px" }}),
    key: "hospital",
    label: "Hospitals",
  },
  {
    icon: createElement("img", { src: HospitalRank , style: { width: "24px" }}),
    key: "hospital/ranking",
    label: "Hospital Ranking",
  },
  {
    icon: createElement("img", { src: DoctorRank , style: { width: "24px" }}),
    key: "popularcity",
    label: "Popular City",
  },
  {
    icon: createElement("img", { src: DoctorRank , style: { width: "24px" }}),
    key: "popularcity/ranking",
    label: "Popular City Ranking",
    style: { width: "300px", fontSize: "16px" } // Setting font size correctly
  },
  {
    key: "treatment",
    icon: createElement("img", { src: Treatment , style: { width: "24px" }}),
    label: "Treatment",
  },
  {
    key: "enquiry",
    icon: createElement("img", { src: Enquiry , style: { width: "24px" }}),
    label: "Enquiry"
  },
  {
    key: "booking",
    icon: createElement("img", { src: Booking , style: { width: "24px" }}),
    label: "Booking",
  },
  {
    key: "blog",
    icon: createElement("img", { src: Blog , style: { width: "24px" }}),
    label: "Blog",
  },
  {
    key: "banner",
    icon: createElement("img", { src: Banner , style: { width: "24px" }}),
    label: "Banner",
  },

  {
    key: "user",
    icon: createElement("img", { src: Users , style: { width: "24px" }}),
    label: "Users",
  },

  {
    key: "patient",
    icon: createElement("img", { src: Patient , style: { width: "24px" }}),
    label: "Patient",
  },
  {
    key: "testimonial",
    icon: createElement("img", { src: Testimonial , style: { width: "24px" }}),
    label: "Testimonial",
  },
  {
    key: "specialitycategory",
    icon: createElement("img", { src: Category , style: { width: "24px" }}),
    label: "Category",
  },
  {
    key: "specialization",
    icon: createElement("img", { src: Specialist , style: { width: "24px" }}),
    label: "Specialist",
  },
  {
    key: "speciality",
    icon: createElement("img", { src: Speciality , style: { width: "24px" }}),
    label: "Speciality",
  },
  {
    key: "package",
    icon: createElement("img", { src: Package , style: { width: "24px" }}),
    label: "Packages",
  },
  {
    key: "order",
    icon: createElement("img", { src: Payment , style: { width: "24px" }}),
    label: "Payment",
  },
  {
    key: "gallery",
    icon: createElement("img", { src: Gallery , style: { width: "24px" }}),
    label: "Gallery",
  },
  {
    icon: createElement("img", { src: DoctorRank , style: { width: "24px" }}),
    key: "gallery/ranking",
    label: "Gallery Ranking",
  },
  {
    key: "agent",
    icon:createElement("img", { src: Facilitor , style: { width: "24px" }}),
    label: "Facilitator",
  },
  {
    key: "staticPages",
    icon:createElement("img", { src: Facilitor , style: { width: "24px" }}),
    label: "Static Pages",
  }
];

export default menuItems;
