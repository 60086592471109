import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { Select, Space, message } from "antd";
import { getTreatmentsByCat } from "@/features/web/web-actions";
import { StyledRegisterForm } from "../common/StyledRegisterForm";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { PlusCircleOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "@/utils/Env";
import { IFormProps } from "./HospitalFormWizard";

const uploadProfileProps = (setFieldValue: any, field: string) => ({
  data: {
    maincategory: "hospital",
    subcategory: "profile",
  },
  name: "file",
  multiple: true,
  action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
  onChange(info: any) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setFieldValue(field, info.file.response.imageUrl);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
});

const uploadDocsProps = (setFieldValue: any, field: string) => ({
  data: {
    maincategory: "hospital",
    subcategory: "documents",
  },
  name: "file",
  multiple: true,
  action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
  onChange(info: any) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setFieldValue(field, info.file.response.imageUrl);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
});

const StringRule = Yup.string()
  .min(2, "Too Short!")
  .max(50, "Too Long!")
  .required("Required");

const HospitalSchema = Yup.object().shape({
  name: StringRule,
  ownership: Yup.number().required("Required"),
  established_in: Yup.number().typeError("Invalid"),
  hospital_type: Yup.number(),
  hospital_service_categories: Yup.array().min(1, "Required"),
  treatments: Yup.array().min(1, "Required"),
  first_name: StringRule,
  last_name: StringRule,
  email: Yup.string().email("Invalid email").required("Required"),
  password: StringRule,
  country: Yup.number().required("Required"),
  city: Yup.string().required("Required"),
  contact_no: Yup.string()
    .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits')
    .required('Required'),
  zipcode: Yup.number(),
  profile_url: Yup.string(),
  form_c: Yup.string(),
  hospital_infrastructure: Yup.array().min(1, "Required"),
  accreditation: Yup.array(),
});

const HospitalFormStep1 = ({
  setCurrentStep,
  formValues,
  setFormValues,
}: IFormProps) => {
  const { data } = useSelector((state: RootState) => state.admin);
  const formikRef = useRef<any>();
  const { options = {} } = data["hospital"] || { options: {} };

  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [hospital_type, setHospital_type] = useState([]);
  const [hospitalSvcCat, setHospitalSvcCat] = useState<any[]>([]);

  const hospitalSvcCatChange = (value: any[]) => {
    formikRef?.current?.setFieldValue("hospital_service_categories", value);
    setHospitalSvcCat(value);
  };

  useEffect(() => {
    if (hospitalSvcCat && hospitalSvcCat.length) {
      const categoryQuery = hospitalSvcCat
        .map((id: string) => `category_id=${id}`)
        .join("&");
      getTreatmentsByCat(categoryQuery).then((resp) => {
        setTreatmentOptions(resp);
      });
    }
    if (options.hospital_type) {
      setHospital_type(
        options.hospital_type.filter(
          (item: any) =>
            hospitalSvcCat.indexOf(item.speciality_category_id) !== -1
        )
      );
    }
  }, [hospitalSvcCat, options.hospital_type]);

  useEffect(() => {
    if (formValues.hospital_service_categories) {
      setHospitalSvcCat(formValues.hospital_service_categories);
    }
  }, [formValues.hospital_service_categories]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formValues}
      validationSchema={HospitalSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
        setFormValues(values);
        setCurrentStep(2);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <StyledRegisterForm>
            <div className="row gy-4 gx-5 hospital_form_row">
              <div className="form-row col-md-12">
                <input
                  type="input"
                  className="form-control"
                  placeholder="Hospital’s name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && <div className="error">{errors.name}</div>}
              </div>
              <div className="form-group col-md-6">
                <Form.Select
                  placeholder="Ownership"
                  className="form-control"
                  name="ownership"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ownership}
                >
                  <option value="">Ownership</option>
                  {options.ownership &&
                    options.ownership.map(({ id, name }: any) => (
                      <option value={id}> {name}</option>
                    ))}
                </Form.Select>
                {touched.ownership && (
                  <div className="error">{errors.ownership}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <input
                  placeholder="Established In"
                  className="form-control"
                  name="established_in"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.established_in}
                />
                {touched.established_in && (
                  <div className="error">{errors.established_in}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  placeholder="Hospital Type"
                  id="hospital_service_categories"
                  mode="multiple"
                  onChange={hospitalSvcCatChange}
                  value={values.hospital_service_categories}
                  options={
                    options.hospital_service_categories &&
                    options.hospital_service_categories.map((value: any) => ({
                      label: value.name,
                      value: value.id,
                    }))
                  }
                />

                {touched.hospital_service_categories && (
                  <div className="error">
                    {errors.hospital_service_categories}
                  </div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Form.Select
                  placeholder="Hospital Sub Type"
                  className="form-control"
                  name="hospital_type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.hospital_type}
                >
                  <option value="">Hospital Sub Type</option>
                  {hospital_type.map(({ id, name }: any) => (
                    <option value={id}> {name}</option>
                  ))}
                </Form.Select>

                {touched.hospital_type && (
                  <div className="error">{errors.hospital_type}</div>
                )}
              </div>
              <div className="form-group col-md-12 ">
                <Space style={{ width: "100%" }} direction="vertical">
                  <Select
                    className="select"
                    style={{ width: "100%" }}
                    placeholder="Popular treatments"
                    id="treatment"
                    onBlur={handleBlur}
                    value={values.treatments}
                    mode="multiple"
                    filterOption={(inputValue, option) =>
                      option && option.label.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    onChange={(value) => setFieldValue("treatments", value)}
                    options={treatmentOptions.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </Space>

                {touched.treatments && (
                  <div className="error">{errors.treatments}</div>
                )}
              </div>
              <label className="pt-0" htmlFor="">
                Point of contact from the hospital
              </label>
              <div className="form-group col-md-6 ">
                <input
                  placeholder="First name"
                  className="form-control"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
                {touched.first_name && (
                  <div className="error">{errors.first_name}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <input
                  className="form-control"
                  placeholder="Last name"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                {touched.last_name && (
                  <div className="error">{errors.last_name}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <input
                  placeholder="Email"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="form-group col-md-6 ">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {touched.password && (
                  <div className="error">{errors.password}</div>
                )}
              </div>
              <div className="form-group col-md-12 ">
                <input
                  type="input"
                  className="form-control"
                  placeholder="Contact Number"
                  name="contact_no"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_no}
                />
                {touched.contact_no && (
                  <div className="error">{errors.contact_no}</div>
                )}
              </div>

              {/* <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder="Speciality"
                  id="hospital_specialities"
                  onBlur={handleBlur}
                  value={values.hospital_specialities}
                  onChange={(value) =>
                    setFieldValue("hospital_specialities", value)
                  }
                  options={
                    options.speciality &&
                    options.speciality.map(({ id, name }: any) => ({
                      label: name,
                      value: id,
                    }))
                  }
                />

                {touched.hospital_specialities && (
                  <div className="error">{errors.hospital_specialities}</div>
                )}
              </div> */}

              <div className="form-row">
                <label htmlFor="">Profile</label>

                <Dragger {...uploadProfileProps(setFieldValue, "profile_url")}>
                  <PlusCircleOutlined />
                  <p className="ant-upload-text">Upload a picture here</p>
                </Dragger>
              </div>

              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  style={{ width: "100%" }}
                  placeholder="Hospital Infrastructure"
                  id="hospital_infrastructure"
                  onBlur={handleBlur}
                  value={values.hospital_infrastructure}
                  mode="multiple"
                  onChange={(value) =>
                    setFieldValue("hospital_infrastructure", value)
                  }
                  options={
                    options.hospital_infrastructure &&
                    options.hospital_infrastructure.map(
                      ({ id, name }: any) => ({
                        label: name,
                        value: id,
                      })
                    )
                  }
                />

                {touched.hospital_infrastructure && (
                  <div className="error">{errors.hospital_infrastructure}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Select
                  className="select"
                  placeholder="Accreditations"
                  id="accreditation"
                  mode="multiple"
                  onChange={(value) => setFieldValue("accreditation", value)}
                  onBlur={handleBlur}
                  value={values.accreditation}
                  options={
                    options.accreditation &&
                    options.accreditation.map((value: any) => ({
                      label: value.name,
                      value: value.id,
                    }))
                  }
                />
                {touched.accreditation && (
                  <div className="error">{errors.accreditation}</div>
                )}
              </div>

              <div className="form-row">
                <label htmlFor="">Form C</label>

                <Dragger {...uploadDocsProps(setFieldValue, "form_c")}>
                  <PlusCircleOutlined />
                  <p className="ant-upload-text">Upload a Form C here</p>
                </Dragger>

                {errors.form_c && <div className="error">{errors.form_c}</div>}
              </div>

              <div className="form-row col-md-12">
                <textarea
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                ></textarea>
                {touched.address && (
                  <div className="error">{errors.address}</div>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <Form.Select
                  placeholder="City"
                  className="form-control"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                >
                  <option value="">City</option>
                  {options.city &&
                    options.city.map(({ id, name }: any) => (
                      <option value={id}> {name}</option>
                    ))}
                </Form.Select>
                {touched.city && <div className="error">{errors.city}</div>}
              </div>

              <div className="form-group col-md-6 ">
                <Form.Select
                  placeholder="State"
                  className="form-control"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                >
                  <option value="">State</option>
                  {options.state &&
                    options.state.map(({ id, name }: any) => (
                      <option value={id}> {name}</option>
                    ))}
                </Form.Select>
                {touched.state && <div className="error">{errors.state}</div>}
              </div>

              <div className="form-group col-md-6 ">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Zipcode"
                  name="zipcode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipcode}
                />
                {touched.zipcode && (
                  <div className="error">{errors.zipcode}</div>
                )}
              </div>

              <div className="form-group col-md-6 ">
                <Form.Select
                  placeholder="Country"
                  className="form-control"
                  name="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                >
                  <option value="">Country</option>
                  {options.country &&
                    options.country.map(({ id, name }: any) => (
                      <option value={id}> {name}</option>
                    ))}
                </Form.Select>

                {touched.country && (
                  <div className="error">{errors.country}</div>
                )}
              </div>

              <div className="submit-btn-container">
                <button type="submit" className="btn btn-primary justify-start">
                  Next
                </button>
              </div>
            </div>
          </StyledRegisterForm>
        </form>
      )}
    </Formik>
  );
};
export default HospitalFormStep1;
