/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import TitlePara from "@/components/page-components/TitleParagraph";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    font-size: 20px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
  }
  @media (max-width: 768px) {
    .wrapper{
      text-align: center;
    }
  }
`;

const TreatmentCost = () => {
  const {
    min_domestic_cost,
    max_domestic_cost,
    min_international_cost,
    max_international_cost,
    included_items,
    cost_factors,
    name,
  } = useSelector((state: RootState) => state.treatment.data!);

  return (
    <>
      <StyledSection>
        <div className="title blue bold">Cost of "{name}" in India</div>
        <div className="wrapper">
          <div className="link-text semibold">
            ₹{min_domestic_cost} - ₹{max_domestic_cost}
          </div>
        </div>
        <a id="Inclusivity"></a>
        {/* <TitlePara
          title={'What does the cost of " ' + name + ' " include?'}
          text={included_items}
        />
        <a id="Factors"></a>
        <TitlePara
          title="Factors affecting the cost of treatment"
          text={cost_factors}
        /> */}
      </StyledSection>
    </>
  );
};

export default TreatmentCost;
