import "./styles.scss";
import { useEffect } from "react";
import CarouselContent from "./Carousel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getBannerData } from "@/features/home/banner-actions";

export interface ICarouselListItem {
  banner_url: string;
  banner_alt_tag: string;
  title?: string;
  subtitle?: string;
  cta?: string;
  isExternal?: boolean;
}

const bannerData: ICarouselListItem[] = [
  {
    banner_url: "/src/assets/web-banner.png",
    banner_alt_tag: "Banner",
    title: "",
    subtitle: "",
    cta: "http://www.google.com",
    isExternal: true,
  },
  {
    banner_url: "web-banner.png",
    banner_alt_tag: "Banner",
    title: "",
    subtitle: "",
    cta: "http://www.yahoo.com",
    isExternal: true,
  },
  {
    banner_url: "web-banner.png",
    banner_alt_tag: "Banner",
    title: "",
    subtitle: "",
    cta: "/pages/doctors",
    isExternal: false,
  },
];

const FlyingBanner = ({ id }: { id: number }) => {
  const { data } = useSelector(({ banner }: RootState) => banner);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getBannerData({ id }));
  }, [id, dispatch]);

  if (data.length) {
    return (
      <div className="banner">
        <CarouselContent data={data as any} />
      </div>
    );
  }
  return null;
};

export default FlyingBanner;
