/* eslint-disable jsx-a11y/anchor-is-valid */
import { ButtonContainer, StyledActionButton } from "@/admin/entities";
import { patientInitialValues } from "@/components/PatientRegistrationForm/PatientForm";
import { StyledRegisterForm } from "@/components/common/StyledRegisterForm";
// import FileInput from "@/components/common/fields/FileInput";
import ProfilePictureInput from "@/components/common/fields/ProfilePictureInput";
import SingleSelect from "@/components/common/fields/SingleSelect";
import TextInput from "@/components/common/fields/TextInput";
import { message } from "antd";
import { getOptions, viewEntity } from "@/features/admin/admin-actions";
import { logout } from "@/features/auth/authSlice";
import { updatePatient } from "@/features/patient/patient-actions";
import { AppDispatch, RootState } from "@/store";
import { Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoutIcon from "@/assets/noun-logout.png";
import { FileOutlined } from "@ant-design/icons";
import FileInput from "@/components/common/fields/FileInput";
import useRoles from "@/components/hooks/useRoles";
import maxDate from "@/utils";
import styled from "styled-components";
import * as Yup from "yup";
import today from "@/utils";
import pdfIcon from '@/assets/PDF_icon.png'

const entityName = "patient";
const StyleSection = styled.div`
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.expanded-image {
  width: 100%; 
  max-height: 80vh; 
  object-fit: contain;
}

.download-button {
  display: block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  width: 160px;
  margin: 0 auto;
}

.download-button:hover {
  background-color: #0056b3;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}
.image-container {
  margin: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.thumbnail {
  width: 100px; 
  height: 100px; 
  object-fit: cover; 
  border-radius: 8px;
}

.thumbnail:hover {
  transform: scale(1.1); 
}
`

export const calculateBMI = (h: number | string, w: number | string) => {
  const hieghtcms = parseFloat(h as string) / 0.3937;
  return (parseFloat(w as string) / ((hieghtcms * hieghtcms) / 10000)).toFixed(
    2
  );
};

const Documents = ({ document_urls, isEdit, name }: any) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (url: string) => {
    setSelectedImage(url);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  if (!document_urls || (document_urls.length === 0 && !isEdit)) {
    return null;
  }
  const renderDocument = (url: string, idx: number) => {
    // Check if the document is an image or not (you can customize this condition)
    const isImage = url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg');

    if (isImage) {
      return (
        <div key={idx} className="image-container">
          <img
            src={url}
            alt={`Attachment ${idx + 1}`}
            className="thumbnail"
            onClick={() => openModal(url)}
          />
          <p>Document {idx + 1} </p>
        </div>
      );
    } else {
      // If it's not an image, render a link
      return (
        <div key={idx} className="document-link">
          <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={pdfIcon} width={80} className="pt-3" alt={`Document ${idx + 1}`} />
          <p>Document {idx + 1} </p>
          </a>
        </div>
      );
    }
  };


  return (
    <StyleSection>
     <div>
      {isEdit && (
        <>
          <FileInput
            name={name}
            data={{
              maincategory: 'patient',
              subcategory: 'documents',
            }}
          />
          <br />
        </>
      )}
      <div className="attachments">
        {document_urls.map((url: string, idx: number) => renderDocument(url, idx))}
      </div>

      {selectedImage && (
        <div className="modal">
          <div className="modal-content">
            <img src={selectedImage} alt="Expanded Attachment" className="expanded-image" />
            <a
              href={selectedImage}
              download="Attachment.png"
              className="btn btn-sm download-button mt-4"
              target="_blank"
            >
              Download
            </a>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>

    </StyleSection>
  );
};

const MyProfile = () => {
  const [questions, setQuestions] = useState<any>(null);
  const { data } = useSelector((state: RootState) => state.admin);
  const { userInfo, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const [isEditing, setIsEditing] = useState(false);
  const { options } = data[entityName] || { options: {} };
  const dispatch = useDispatch<AppDispatch>();
  const [genericError, setGenericError] = useState(null);
  const [fd, setfd] = useState<any>(patientInitialValues);

  useEffect(() => {
    if (options && options.questions && questions == null) {
      setQuestions(
        options.questions.reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        )
      );
    }
  }, [options, questions]);

  const navigate = useNavigate();

  const isAdmin = useRoles("ADMIN");

  useEffect(() => {
    if (isAuthenticated && userInfo.entityId) {
      dispatch(getOptions(entityName));
      dispatch(viewEntity({ name: "patient", id: userInfo.entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setfd((payload as any).data);
          } else {
            navigate("/");
          }
        }
      );
    } else {
      if (isAdmin) {
        navigate("/admin/");
      } else {
        navigate("/");
      }
    }
  }, [dispatch, isAuthenticated, navigate, userInfo, isAdmin]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login/patient");
  };

  const editProfile = () => {
    // navigate("/admin/patient");
    setIsEditing(true);
  };

  return (
    <div className="" style={{ padding: 36 }}>
      <Formik
        initialValues={fd}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values, { setErrors, validateForm }) => {
          validateForm(values);
          setErrors({});
          setGenericError(null);
          updatePatient(userInfo.entityId, values).then((resp: any) => {
            if (!resp.success) {
              if (resp.responseCode === 422) {
                setErrors({
                  ...resp.data,
                });
              } else {
                setGenericError(resp.message);
              }
            } else {
              // resetForm();
               message.success(resp.message);
            //  window.alert("Patient's details has been updated");
              setIsEditing(false);
            }
          });
        }}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledRegisterForm
              style={{
                pointerEvents: isEditing ? "all" : "none",
                opacity: isEditing ? 1 : 0.8,
              }}
            >
              {genericError && (
                <div className="genericError">{genericError}!</div>
              )}
              <ButtonContainer
                className="d-flex justify-content-end"
                style={{ pointerEvents: "all" }}
              >
                {!isEditing && (
                  <StyledActionButton variant="primary" onClick={editProfile}>
                    Edit Profile
                  </StyledActionButton>
                )}
                {isEditing && (
                  <>
                    <StyledActionButton variant="primary" type="submit">
                      Update
                    </StyledActionButton>
                    <StyledActionButton
                      variant="primary"
                      type="button"
                      onClick={() => {
                        // navigate("/admin/patient");
                        setIsEditing(false);
                        resetForm(fd);
                      }}
                    >
                      Cancel
                    </StyledActionButton>
                  </>
                )}
                <StyledActionButton variant="primary" onClick={logoutHandler}>
                  <img src={logoutIcon} alt="logout" />
                  Logout
                </StyledActionButton>
              </ButtonContainer>

              <ProfilePictureInput name="profile_url" />
              <div className="text-center">
                <p><i className="blue"> Click the edit profile button to edit the content or view the documents.</i></p>
              </div>
              <div className="row gy-4 gx-5">
                <div className="form-row col-md-6">
                  <TextInput name="first_name" placeholder="First name" />
                </div>
                <div className="form-row col-md-6">
                  <TextInput name="last_name" placeholder="Last name" />
                </div>
                <div className="form-row col-md-12">
                  <TextInput name="email" placeholder="Email" />
                </div>

                <div className="form-row col-md-6 ">
                  <Field
                    className="form-control"
                    type="date"
                    placeholder="Date of birth (DD/MM/YYYY)"
                    name="dob"
                    max={today}
                  />
                </div>
                <div className="form-row col-md-6 ">
                  <SingleSelect
                    name="gender"
                    placeholder="Gender"
                    options={[
                      { id: "F", name: "Female" },
                      { id: "M", name: "Male" },
                    ]}
                  />
                </div>

                <div className="col-md-12 ">
                  <TextInput
                    type="number"
                    placeholder="Mobile Number"
                    name="contact_no"
                    maxLength={12}
                  />
                </div>
                <div className="col-md-12">
                  <TextInput placeholder="Address" name="address" />
                </div>
                <div className="form-row col-md-3 ">
                  <SingleSelect
                    placeholder="City"
                    name="city"
                    options={options?.city}
                    backendsavefield="name"
                  />
                </div>
                <div className="form-row col-md-3 ">
                  <SingleSelect
                    placeholder="State"
                    name="state"
                    backendsavefield="name"
                    options={options?.state}
                  />
                </div>
                <div className="form-row col-md-3 ">
                  <SingleSelect
                    placeholder="Country"
                    name="country"
                    backendsavefield="id"
                    options={options?.country}
                  />
                </div>
                <div className="form-row col-md-3 ">
                  <TextInput
                    type="number"
                    placeholder="Zip Code"
                    name="zipcode"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Height</label>
                  <TextInput
                    type="number"
                    placeholder="Height (in inches)"
                    name="height"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Weight</label>
                  <TextInput 
                    type="number"
                    placeholder="Weight (in kgs)"
                    name="weight"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Total BMI %</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total BMI %"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly
                    value={calculateBMI(values.height, values.weight)}
                  />
                </div>
              </div>

              <div className="form-row col-md-12">
                <p>Chief Complaint</p>
                <TextInput
                  name="chief_complaint"
                  placeholder="Chief Complaint"
                />
              </div>

              <FieldArray
                name="diseases"
                render={(arrayHelpers) => (
                  <>
                    {values.diseases &&
                      values.diseases.map(
                        (
                          {
                            current_status,
                            diagnosis_date,
                            document_urls,
                          }: any,
                          index: number
                        ) => (
                          <div
                            className="row gy-4 gx-5"
                            style={{ marginTop: "auto" }}
                            key={index}
                          >
                            {index > 0 && <hr />}
                            <label>
                              History of any medical disease {index + 1}
                            </label>
                            <div className="form-row col-md-6 ">
                              <TextInput
                                name={`diseases.${index}.disease_name`}
                                placeholder="Disease Name"
                              />
                            </div>

                            <div className="form-row col-md-6">
                              <Field
                                type="date"
                                className="form-control"
                                placeholder="Date of diagnosis (DD/MM/YYYY)"
                                name={`diseases.${index}.diagnosis_date`}
                                max={today}
                              />
                            </div>
                            <div className="form-row col-md-6">
                              <TextInput
                                placeholder="Duration"
                                name={`diseases.${index}.duration`}
                              />
                            </div>
                            <div className="form-row col-md-6 ">
                              <SingleSelect
                                placeholder="treatment"
                                options={options?.treatments}
                                backendsavefield="name"
                                name={`diseases.${index}.treatment`}
                              />
                            </div>

                            <div className="form-row col-md-12">
                              <TextInput
                                placeholder="Notes"
                                name={`diseases.${index}.notes`}
                              />
                            </div>
                            <div className="form-row">
                              <Documents
                                document_urls={document_urls}
                                name={`diseases.${index}.document_urls`}
                                isEdit={isEditing}
                              />
                            </div>
                            <div className="form-row col-md-12">
                              <div className="hospitalrdqna">
                                <p>Current status</p>
                                <div className="inter_radio">
                                  <div className="inter_radio">
                                    <label>
                                      <input
                                        type="radio"
                                        name={`diseases.${index}.current_status`}
                                        value="active"
                                        checked={current_status === "active"}
                                        onChange={handleChange}
                                      />
                                      Active
                                    </label>
                                  </div>
                                  <div className="inter_radio">
                                    <label>
                                      <input
                                        type="radio"
                                        name={`diseases.${index}.current_status`}
                                        value="in_remission"
                                        checked={
                                          current_status === "in_remission"
                                        }
                                        onChange={handleChange}
                                      />
                                      In-Remission
                                    </label>
                                  </div>
                                  <div className="inter_radio">
                                    <label>
                                      <input
                                        type="radio"
                                        name={`diseases.${index}.current_status`}
                                        value="resolved"
                                        checked={current_status === "resolved"}
                                        onChange={handleChange}
                                      />
                                      Resolved
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    {isEditing && (
                      <a
                        role="button"
                        className="blue text-center add-new-btn"
                        onClick={() => {
                          setFieldValue("diseases", [
                            ...values.diseases,
                            {
                              refno: values.diseases.length,
                              disease_name: "",
                              diagnosis_date: "",
                              duration: "",
                              treatment: "",
                              notes: "",
                              document_urls: [],
                              current_status: "",
                            },
                          ]);
                        }}
                      >
                        + Add new
                      </a>
                    )}
                  </>
                )}
              />

                   <div className="row gy-4 gx-5">
                      <label className="bold" htmlFor="">Emergency Contact</label>
                      <div className="form-row col-md-6">
                        <TextInput name="emergencyContact[0].first_name" placeholder="First name" />
                      </div>
                      <div className="form-row col-md-6">
                        <TextInput name="emergencyContact[0].last_name" placeholder="Last name" />
                      </div>
                      <div className="form-row col-md-6">
                        <SingleSelect
                          placeholder="Relationship"
                          options={options?.ecy_p_relationship_id}
                          backendsavefield="id"
                          name="emergencyContact[0].relationship_id"
                        />
                      </div>
                      <div className="form-row col-md-6">
                        <TextInput name="emergencyContact[0].contact_no" placeholder="Mobile Number" />
                      </div>
                    </div>
              <FieldArray
                name="drugs"
                render={(arrayHelpers) => (
                  <>
                    {values.drugs &&
                      values.drugs.map(
                        ({ refno, drug_name, notes }: any, index: number) => (
                          <div
                            className="row gy-4 gx-5"
                            style={{ marginTop: "auto" }}
                            key={index}
                          >
                            {index > 0 && <hr />}
                            <label>
                              History of any drug allergies {index + 1}
                            </label>
                            <div className="form-row col-md-6 ">
                              <TextInput
                                name={`drugs.${index}.drug_name`}
                                placeholder="Drug Name"
                              />
                            </div>

                            <div className="form-row col-md-6">
                              <TextInput
                                placeholder="Notes"
                                name={`drugs.${index}.notes`}
                              />
                            </div>
                          </div>
                        )
                      )}
                    {isEditing && (
                      <a
                        role="button"
                        className="blue text-center add-new-btn add-new-btn"
                        onClick={() => {
                          setFieldValue("diseases", [
                            ...values.drugs,
                            {
                              refno: values.drugs.length,
                              drug_name: "",
                              notes: "",
                            },
                          ]);
                        }}
                      >
                        + Add new
                      </a>
                    )}
                  </>
                )}
              />
              <label>History of any previous surgeries</label>
              <FieldArray
                name="surgeries"
                render={(arrayHelpers) => (
                  <>
                    {values.surgeries.map(
                      (
                        { current_status, diagnosis_date, document_urls }: any,
                        index: number
                      ) => (
                        <div
                          className="row gy-4 gx-5"
                          style={{ marginTop: "auto" }}
                          key={index}
                        >
                          {index > 0 && <hr />}

                          <div className="form-row col-md-6 ">
                            <TextInput
                              name={`surgeries.${index}.surgery_name`}
                              placeholder="Disease Name"
                            />
                          </div>

                          <div className="form-row col-md-6">
                            <Field
                              type="date"
                              className="form-control"
                              placeholder="Date of diagnosis (DD/MM/YYYY)"
                              name={`surgeries.${index}.diagnosis_date`}
                              max={today}
                            />
                          </div>
                          <div className="form-row col-md-6">
                            <TextInput
                              placeholder="Duration"
                              name={`surgeries.${index}.duration`}
                            />
                          </div>
                          <div className="form-row col-md-6 ">
                            <SingleSelect
                              placeholder="treatment"
                              options={options?.treatments}
                              backendsavefield="name"
                              name={`surgeries.${index}.treatment`}
                            />
                          </div>

                          <div className="form-row col-md-12">
                            <TextInput
                              placeholder="Notes"
                              name={`surgeries.${index}.notes`}
                            />
                          </div>
                          <div className="form-row">
                            <Documents
                              document_urls={document_urls}
                              name={`surgeries.${index}.document_urls`}
                              isEdit={isEditing}
                            />
                          </div>
                          <div className="form-row col-md-12">
                            <div className="hospitalrdqna">
                              <p>Current status</p>
                              <div className="inter_radio">
                                <div className="inter_radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name={`surgeries.${index}.current_status`}
                                      value="active"
                                      checked={current_status === "active"}
                                      onChange={handleChange}
                                    />
                                    Active
                                  </label>
                                </div>
                                <div className="inter_radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name={`surgeries.${index}.current_status`}
                                      value="in_remission"
                                      checked={
                                        current_status === "in_remission"
                                      }
                                      onChange={handleChange}
                                    />
                                    In-Remission
                                  </label>
                                </div>
                                <div className="inter_radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name={`surgeries.${index}.current_status`}
                                      value="resolved"
                                      checked={current_status === "resolved"}
                                      onChange={handleChange}
                                    />
                                    Resolved
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {isEditing && (
                      <a
                        role="button"
                        className="blue text-center add-new-btn"
                        onClick={() => {
                          setFieldValue("surgeries", [
                            ...values.surgeries,
                            {
                              refno: values.surgeries.length,
                              disease_name: "",
                              diagnosis_date: "",
                              duration: "",
                              treatment: "",
                              notes: "",
                              document_urls: [],
                              current_status: "",
                            },
                          ]);
                        }}
                      >
                        + Add new
                      </a>
                    )}
                  </>
                )}
              />

              <label>Please list history of any hospitalization</label>
              <FieldArray
                name="hospitalizations"
                render={(arrayHelpers) => (
                  <>
                    {values.hospitalizations.map(
                      (
                        { current_status, diagnosis_date, document_urls }: any,
                        index: number
                      ) => (
                        <div className="row gy-4 gx-5" key={index}>
                          {index > 0 && <hr />}

                          <div className="form-row col-md-6 ">
                            <TextInput
                              name={`hospitalizations.${index}.hospital_name`}
                              placeholder="Disease Name"
                            />
                          </div>
                          <div className="form-row col-md-6 ">
                            <TextInput
                              name={`hospitalizations.${index}.disease_name`}
                              placeholder="Disease Name"
                            />
                          </div>

                          <div className="form-row col-md-6">
                            <Field
                              type="date"
                              className="form-control"
                              placeholder="Date of diagnosis (DD/MM/YYYY)"
                              name={`hospitalizations.${index}.diagnosis_date`}
                              max={today}
                            />
                          </div>
                          <div className="form-row col-md-6">
                            <TextInput
                              placeholder="Duration"
                              name={`hospitalizations.${index}.duration`}
                            />
                          </div>
                          <div className="form-row col-md-6 ">
                            <SingleSelect
                              placeholder="treatment"
                              options={options?.treatments}
                              backendsavefield="name"
                              name={`hospitalizations.${index}.treatment`}
                            />
                          </div>

                          <div className="form-row col-md-12">
                            <TextInput
                              placeholder="Notes"
                              name={`hospitalizations.${index}.notes`}
                            />
                          </div>
                          <div className="form-row">
                            <Documents
                              document_urls={document_urls}
                              name={`hospitalizations.${index}.document_urls`}
                              isEdit={isEditing}
                            />
                          </div>
                        </div>
                      )
                    )}
                    {isEditing && (
                      <a
                        role="button"
                        className=" blue text-center add-new-btn"
                        onClick={() => {
                          setFieldValue("hospitalizations", [
                            ...values.hospitalizations,
                            {
                              refno: values.hospitalizations.length,
                              hospital_name: "",
                              disease_name: "",
                              diagnosis_date: "",
                              duration: "",
                              treatment: "",
                              notes: "",
                              document_urls: [],
                              current_status: "",
                            },
                          ]);
                        }}
                      >
                        + Add new
                      </a>
                    )}
                  </>
                )}
              />

              <label>Habbits</label>
              <FieldArray
                name="habits"
                render={(arrayHelpers) => (
                  <>
                    {values.habits.map(
                      (
                        {
                          healthy_habit_name,
                          unhealthy_habit_name,
                          medicines,
                        }: any,
                        index: number
                      ) => (
                        <div className="row gy-4 gx-5" key={index}>
                          {index > 0 && <hr />}

                          <div className="form-row col-md-6 ">
                            <TextInput
                              name={`habits.${index}.healthy_habit_name`}
                              placeholder="Healthy Habit Name"
                            />
                          </div>
                          <div className="form-row col-md-6 ">
                            <TextInput
                              name={`habits.${index}.unhealthy_habit_name`}
                              placeholder="Unhealthy Habit Name"
                            />
                          </div>

                          <div className="form-row col-md-12">
                            <TextInput
                              placeholder="Medicines"
                              name={`habits.${index}.medicines`}
                            />
                          </div>
                        </div>
                      )
                    )}
                    {isEditing && (
                      <a
                        role="button"
                        className="blue text-center add-new-btn"
                        onClick={() => {
                          setFieldValue("habits", [
                            ...values.habits,
                            {
                              refno: values.habits.length,
                              healthy_habit_name: "",
                              unhealthy_habit_name: "",
                              medicines: "",
                            },
                          ]);
                        }}
                      >
                        + Add new
                      </a>
                    )}
                  </>
                )}
              />
              {questions && (
                <>
                  <label>Family history of</label>
                  <FieldArray
                    name="answers"
                    render={(arrayHelpers) => (
                      <div style={{ marginBottom: 24 }}>
                        {values.answers.map(
                          (
                            { question_id, id, answer1, refno }: any,
                            index: number
                          ) => (
                            <div
                              className="row gy-4 gx-5"
                              style={{ paddingTop: 16 }}
                              key={index}
                            >
                              <div className="form-row col-md-12">
                                <div className="hospitalrdqna">
                                <p>{questions[question_id || id] && questions[question_id || id].name}</p>
                                  <div className="inter_radio">
                                    <div className="inter_radio">
                                      <label>
                                        <input
                                          type="radio"
                                          name={`answers.${index}.answer1`}
                                          value="yes"
                                          checked={answer1 === "yes"}
                                          onChange={handleChange}
                                        />
                                        Yes
                                      </label>
                                    </div>
                                    <div className="inter_radio">
                                      <label>
                                        <input
                                          type="radio"
                                          name={`answers.${index}.answer1`}
                                          value="no"
                                          checked={answer1 === "no"}
                                          onChange={handleChange}
                                        />
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </>
              )}
               <div className="form-row col-md-12 pt-3">
                                  <TextInput name="any_info_doctor" placeholder="Any other information that would like to share with your doctor?" />
                                </div>

              {isEditing && (
                <div className="submit-btn-container">
                  <button type="submit" className="btn btn-primary">
                    update
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      // navigate("/admin/patient");
                      setIsEditing(false);
                      resetForm(fd);
                    }}
                  >
                    cancel
                  </button>
                </div>
              )}
            </StyledRegisterForm>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MyProfile;
