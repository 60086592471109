import { RootState } from "@/store";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 768px) {
    padding-top: 4em;
  }

  .facilities_section ul {
    text-align: initial;
  }

  @media (min-width: 425px) {
    .title {
      font-size: 36px;
      text-align: left;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

interface HospitalData {
  tele_consultation: number;
  estimate: number;
  visa_extension: number;
  travel_desk: number;
  home_care: number;
  patient_attendant: number;
  hospital_location_neighbourhood: string;
}

const FacilitiesSection = () => {
  const { data: hospital } = useSelector((state: RootState) => state.hospital);

  const navigate = useNavigate();

  if (!hospital) {
    return null;
  }

  const hospitalData: HospitalData = hospital || {
    tele_consultation: 0,
    estimate: 0,
    visa_extension: 0,
    travel_desk: 0,
    home_care: 0,
    patient_attendant: 0,
    hospital_location_neighbourhood: "",
  };

  return (
    <StyledSection>
      <div className="title blue bold sub-heading">Facilities</div>
      <Wrapper>
        <div className="facilities_section">
          <ul>
            <li>
              <p><b>Is Tele Consultation available?</b> - {hospitalData.tele_consultation === 1 ? 'Yes' : 'No' }</p>
            </li>
            <li>
              <p><b>Is Estimate available?</b> - {hospitalData.estimate === 1 ? 'Yes' : 'No' }</p>
            </li>
            <li>
              <p><b>Is Visa Extention available?</b> - {hospitalData.visa_extension === 1 ? 'Yes' : 'No' }</p>
            </li>
            <li>
              <p><b>Is Travel desk available?</b> - {hospitalData.travel_desk === 1 ? 'Yes' : 'No' }</p>
            </li>
            <li>
              <p><b>Is Home Care available?</b> - {hospitalData.home_care === 1 ? 'Yes' : 'No' }</p>
            </li>
            <li>
              <p><b>Patient attendant (24x7)</b> - {hospitalData.patient_attendant === 1 ? 'Yes' : 'No' }</p>
            </li>
          </ul>
          <br />
          <p className="justify bold blue heading"> Hospital Location & Neighbourhood</p>
          <p className="justify" dangerouslySetInnerHTML={{ __html: hospitalData.hospital_location_neighbourhood }}></p>
        </div>
      </Wrapper>
    </StyledSection>
  );
};

export default FacilitiesSection;
