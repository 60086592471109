/* eslint-disable jsx-a11y/anchor-is-valid */
// import doctor from "@/assets/doctors/doctor-dummy.png";

import { BestHospitalsRow } from "../hospitals/BestHospitals";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { search } from "@/features/web/web-actions";
import { removeEmpty } from "@/admin/entities/EntityPopup";
import { BestDoctorsRow } from "../doctors/BestDoctors";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { fetchTreatmentById, fetchTreatmentBySlug } from "@/features/web/treatment-actions";

const StyledListing = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
  }
`;
const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  padding: 20px 0;
  @media (min-width: 425px) {
    // justify-content: space-between;
    justify-content: flex-start;
    gap: 73px;
  }

  .treatment-item {
    width: 156px;
    height: 75px;
    line-height: 1;
    padding: 0px 5px;
    @media (min-width: 425px) {
      width: 234px;
      flex: 0 0 20%;
      margin-bottom: -40px;

      .App-link {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
        margin: 0 10px;
      } &:hover {
        background: #eee;
      }
    }
  }
`;
interface IPaginate {
  result: {
    currentPage: number;
    totalPages: number;
  };
  handlePageClick: (event: any) => void;
}

export const Paginate = ({ result, handlePageClick }: IPaginate) => {
  const { currentPage, totalPages } = result;
  return (
    <ReactPaginate
      forcePage={currentPage - 1}
      nextLabel="next >"
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={totalPages}
      previousLabel="< previous"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};

const TreatmentRow = ({ data }: any) => {
  const { name } = data;
  return <div className="card shadow treatment-item">{name}</div>;
};

interface IPagination {
  rows: any[];
  totalPages: number;
  currentPage: number;
  count: number;
}

const itemsPerPage = 10;

const Listing = () => {
  const { entity } = useParams();
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<IPagination>({
    rows: [],
    totalPages: 0,
    currentPage: 0,
    count: 0,
  });
  const [treatmentLists, setTreatmentLists] = useState<{
    hospital: IPagination;
    doctor: IPagination;
  }>({
    hospital: {
      rows: [],
      totalPages: 0,
      currentPage: 0,
      count: 0,
    },
    doctor: {
      rows: [],
      totalPages: 0,
      currentPage: 0,
      count: 0,
    },
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [treatmentName, setTreatmentName] = useState('');
  const [treatmentSlug, setTreatmentSlug] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const dosearch = ({ page, size }: { page: number; size: number }) => {
    const city = searchParams.get("city") || null;
    const treatmentSlug = searchParams.get("treatmentSlug") || null;
    const hospitalType = searchParams.get("hospitalType") || null;
    const speciality_id = searchParams.get("speciality_id") || null;
    const queryObj = removeEmpty({
      treatmentSlug,
      city,
      hospitalType,
      speciality_id,
    });
    const query = `?${new URLSearchParams(queryObj).toString()}`;

    if (entity === "treatment") {
      Promise.all([
        dispatch(
          search({
            entity: "doctor",
            query,
            page,
            size,
          })
        ),
        dispatch(
          search({
            entity: "hospital",
            query,
            page,
            size,
          })
        ),
      ]).then((responses) => {
        const [docResp, hosRes] = responses;
        setTreatmentLists({
          hospital: hosRes.payload,
          doctor: docResp.payload,
        });
        setLoaded(true);
      });
    } else {
      dispatch(
        search({
          entity,
          query,
          page,
          size,
        })
      ).then((res) => {
        setResult(res.payload);
        setLoaded(true);
      });
    }
  };

  const doTreatmentDrSearch = ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    const city = searchParams.get("city") || null;
    const treatmentSlug = searchParams.get("treatmentSlug") || null;
    const hospitalType = searchParams.get("hospitalType") || null;
    const queryObj = removeEmpty({
      treatmentSlug,
      city,
      hospitalType,
    });
    const query = `?${new URLSearchParams(queryObj).toString()}`;

    dispatch(
      search({
        entity: "doctor",
        query,
        page,
        size,
      })
    ).then((docResp) => {
      setTreatmentLists((vls) => ({
        ...vls,
        doctor: docResp.payload,
      }));
      setLoaded(true);
    });
  };

  const doTreatmentHosSearch = ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    const city = searchParams.get("city") || null;
    const treatmentSlug = searchParams.get("treatmentSlug") || null;
    const hospitalType = searchParams.get("hospitalType") || null;
    const queryObj = removeEmpty({
      treatmentSlug,
      city,
      hospitalType,
    });
    const query = `?${new URLSearchParams(queryObj).toString()}`;

    dispatch(
      search({
        entity: "hospital",
        query,
        page,
        size,
      })
    ).then((res) => {
      setTreatmentLists((vls) => ({
        ...vls,
        hospital: res.payload,
      }));
      setLoaded(true);
    });
  };

  useEffect(() => {
    dosearch({ page: 1, size: itemsPerPage });
  }, [searchParams, entity]);

  const handlePageClick = async (event: any) => {
    dosearch({ page: event.selected + 1, size: itemsPerPage });
  };

  const handlePageClickDr = async (event: any) => {
    doTreatmentDrSearch({ page: event.selected + 1, size: itemsPerPage });
  };

  const handlePageClickHos = async (event: any) => {
    doTreatmentHosSearch({ page: event.selected + 1, size: itemsPerPage });
  };

  const rowComponent = (data: any) => {
    switch (entity) {
      case "doctor":
        return <BestDoctorsRow data={data} />;
      case "hospital":
        return <BestHospitalsRow data={data} />;
      case "treatment":
        return null; //<TreatmentRow data={data} />;
      default:
        return null;
    }
  };
  const { data } = useSelector((state: RootState) => state.treatment);
  const slug = searchParams.get("treatmentSlug") || null;
  
  
  useEffect(() => {
    if (slug) {
    dispatch(fetchTreatmentBySlug(slug))
      .then(response => {
      })
      .catch(error => {
      });
  }
}, [slug]);
  
  useEffect(() => {
    if (data != null) {
      setTreatmentName(data['name']);
      setTreatmentSlug(data['slug']);
    }
  }, [data]);
  
  return (
    <StyledListing>
      <div
        style={{
          textAlign: "left",
        }}
      >
        <a
          href="#"
          onClick={() => window.history.back()}
          style={{
            textDecoration: "underline",
          }}
          className="blue link-text"
        >
          &lt;&lt;&nbsp;Back
        </a>
      </div>
      {entity !== "treatment" &&
        loaded &&
        (result === undefined || result.rows.length === 0) && (
          <div>No results</div>
        )}

      {entity === "treatment" && (
        <div>
          {
            <div>
               <div className="blue bold sub-heading">Treatment</div>
               <TreatmentsWrapper>
                  <div className="card shadow treatment-item">
                    <NavLink className="App-link" to={`../treatment/${slug}`}>
                      {slug}
                    </NavLink>
                  </div>
              </TreatmentsWrapper>        
                  <hr className="mt-5"/>
            </div>
          }
          {treatmentLists.doctor && (
            <div>
              <div className="blue bold sub-heading">Doctors</div>
              {loaded && treatmentLists.doctor.rows.length === 0 && (
                <div>No Doctors found </div>
              )}

              {treatmentLists.doctor.rows.map((data: any, idx: number) => (
                <BestDoctorsRow key={idx} data={data} />
              ))}
              <Paginate
                result={treatmentLists.doctor}
                handlePageClick={handlePageClickDr}
              />
            </div>
          )}

          {treatmentLists.hospital && (
            <div
              style={{
                marginTop: 50,
              }}
            >
              <div className="blue bold sub-heading">Hospitals</div>
              {loaded && treatmentLists.hospital.rows.length === 0 && (
                <div>No Hospitals found </div>
              )}
              {treatmentLists.hospital.rows.map((data: any, idx: number) => (
                <BestHospitalsRow key={idx} data={data} />
              ))}
              <Paginate
                result={treatmentLists.hospital}
                handlePageClick={handlePageClickHos}
              />
            </div>
          )}
        </div>
      )}
      {result && entity !== "treatment" && (
        <div>
          {result?.rows.map((data) => rowComponent(data))}
          <Paginate result={result} handlePageClick={handlePageClick} />
        </div>
      )}
    </StyledListing>
  );
};

export default Listing;
