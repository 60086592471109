import React from "react";
import YouTube from "react-youtube";
const opts = {
  height: "390",
  width: "100%",
  playerVars: {
    autoplay: 0,
  },
};

function youtube_parser(url: string) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : undefined;
}
const YoutubeEmbed = ({ url }: any) => {
  const onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div className="video-responsive">
      <YouTube videoId={youtube_parser(url)} opts={opts} onReady={onReady} />;
    </div>
  );
};

export default YoutubeEmbed;
