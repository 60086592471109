import HospitalCarousel from "./HospitalCarousel";
import OtherPartnerCarousel from "./OtherPartnerCarousel";
import TravelCarousel from "./TravelCarousel";
import WellnessCarousel from "./WellnessCarousel";
import styled from "styled-components";

const StyledSection = styled.div`
  @media only screen and (max-width: 768px) {
    .partner_row{
      padding: 1.5rem;
    }
  }
`;

function PartnerCarousel() {

  return (
    <StyledSection>
   <div className="row partner_row  gx-5">
    <p className="heading fw-bold" >Partner</p>
    <div className="col-md-3 col-sm-12">
    <TravelCarousel/>
    </div>
    <div className="col-md-3 col-sm-12">
    <HospitalCarousel/>
    </div>
    <div className="col-md-3 col-sm-12">
    <WellnessCarousel/>
    </div>
    <div className="col-md-3 col-sm-12">
    <OtherPartnerCarousel/>
    </div>
   </div>
   </StyledSection>
  );
}

export default PartnerCarousel;
