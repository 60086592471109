import styled from "styled-components";

const DoctorStyledSection = styled.div`
  .title {
    margin-bottom: 20px;
  }
  .wrapper {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .card {
      display: flex;
      gap: 20px;
      padding: 20px;
    }
  }
  @media (max-width: 768px) {
    .wrapper {
      display: flex;
      gap: 20px;
      flex-direction: column;
      .card {
        display: flex;
        gap: 20px;
        padding: 20px;
        justify-content: center;
    align-items: center;
    margin: 0em 2em;
      }
    }
  }
`;

export default DoctorStyledSection;
