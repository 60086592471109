import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import heart from "@/assets/heart.png";
import stetho from "@/assets/stethoscope.png";
import hospital from "@/assets/hospital.png";
import heartMobile from "@/assets/heart-mobile.png";
import stethoMobile from "@/assets/stethoscope-mobile.png";
import hospitalMobile from "@/assets/hospital-mobile.png";
import Image from "@/components/utils/ResponsiveImage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface AnimatedCountProps {
  count: number;
}

const AnimatedCount: React.FC<AnimatedCountProps> = ({ count }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const props = useSpring({
    from: { value: 0 },
    to: { value: inView ? count : 0 },
    config: { duration: 2500 },
  });

  return <animated.div ref={ref}>{props.value.to((val) => Math.floor(val))}</animated.div>;
};

const CardTourism = ({ title, image, imagexs, count }: any) => {
  return (
    <div className="card shadow tourism-card">
      <div className="d-flex justify-content-around align-items-center flex-column" style={{color:'#2bace2'}}>
        <Image xs={imagexs} md={image}  alt=""/>
        <h3 className="bold d-flex pt-2">
          <AnimatedCount count={count} />
          <span>+</span>
        </h3>
        <p className="card-title bold">{title}</p>
      </div>
    </div>
  );
};

const MedicalTourismIndia = () => {
  const [counts, setCounts] = useState({ doctor_count: 0, hospital_count: 0, enquiry_count: 0 });
  const entityName = "enquiry";
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };

  useEffect(() => {
    if (options && options.count_details) {
      setCounts(options.count_details);
    }
  }, [options]);

  const { t } = useTranslation();

  return (
    <div className="p-4">
      <p className="blue heading bold">{t("homePage.medicalTourismInIndia")}</p>
      <p className="link-text justify ">
        {t("homePage.medicalTourismInIndiaDescription")}
      </p>
      <br />
      <div className="d-flex justify-content-around align-items-center cards-wrapper">
  <CardTourism title="Top Hospitals" image={hospital} imagexs={hospitalMobile} count={(Array.isArray(counts) ? counts[0]?.hospital_count : 0) as number} />
  <CardTourism title="Medical Professionals" image={stetho} imagexs={stethoMobile} count={(Array.isArray(counts) ? counts[0]?.doctor_count : 0) as number} />
  {/* <CardTourism title="Happy Patients" image={heart} imagexs={heartMobile} count={(Array.isArray(counts) ? counts[0]?.enquiry_count : 0) as number} /> */}
  <CardTourism title="Treatments" image={heart} imagexs={heartMobile} count={(Array.isArray(counts) ? counts[0]?.treatment_count : 0) as number} />
</div>
    </div>
  );
};

export default MedicalTourismIndia;
