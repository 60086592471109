import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { getInterpretors } from "./interpreter-actions";

interface Interpreter {
  translatorCode: number;
    yearsofExperience: string | null;
    experinceSummary: string | null;
  }

interface InterpretorState {
  data: Interpreter[];
  loading: boolean;
  error: string | null;
}

const interpreterSlice = createSlice({
    name: "interpretors",
    initialState: { data: [], loading: false, error: null } as InterpretorState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getInterpretors.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getInterpretors.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.map((interpreter: Interpreter) => {
          return {
            translatorCode: interpreter.translatorCode,
            yearsofExperience: interpreter.yearsofExperience || "N/A",
            experinceSummary: interpreter.experinceSummary || "No summary available",
          };
        });
      });
      builder.addCase(getInterpretors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
    },
  });
  
export const selectInterpreter = (state: RootState) => state.interpreters.data;

export default interpreterSlice.reducer;
