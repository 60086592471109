import { createSlice } from "@reduxjs/toolkit";
import { fetchDoctorById } from "./doctor-actions";

const doctorSlice = createSlice({
  name: "doctor",
  initialState: {
    doctor: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDoctorById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.doctor = action.payload;
        state.error = null;
      })
      .addCase(fetchDoctorById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as any;
      });
  },
});

export default doctorSlice.reducer;
