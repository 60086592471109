import { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getAll } from "@/features/admin/admin-actions";
import styled from "styled-components";
import ReOrderList from "./ReOrderList";

const ListWrappers = styled.div`
  display: flex;
  gap: 10px;
  > * {
    flex: 0 0 33%;
  }
`;

const DoctorRanking = () => {
  const [entity] = useState("gallery");
  const [rankingList, setRankingList] = useState([]);
  const [rankingListHospital, setRankingListHospital] = useState([]);
  const [rankingListDestinations, setRankingListDestinations] = useState([]);
  const { data: storeData, entities } = useSelector(
    (state: RootState) => state.admin
  ) as Record<string, any>;
  const { [entity as string]: data } = storeData;
  const { title = "" } = entity ? entities[entity] : {};

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (entity) {
      dispatch(getAll({ entity }));
    }
  }, [entity]);

  useEffect(() => {
    if (data && data.rows && data.rows.length) {
      const list = data.rows.map((item: any) => {
        return {
          id: item.id.toString(),
          title: item.title ,
        };
      });
      const listHospitals =  data.rows.filter((ele :any)  => ele.gallery_categories_id == 3).map((filteredEle:any) => {
        return {
          id: filteredEle.id.toString(),
          title: filteredEle.title ,
        };
      })
      const listDestinations =  data.rows.filter((ele :any)  => ele.gallery_categories_id == 2).map((filteredEle:any) => {
        return {
          id: filteredEle.id.toString(),
          title: filteredEle.title ,
        };
      })
      setRankingList(list);
      setRankingListHospital(listHospitals)
      setRankingListDestinations(listDestinations)
    }
  }, [data]);

  return (
    <div className="d-flex flex-column">
      <h3 style={{ textAlign: "left" }}>{title + " Ranking"}</h3>
      <ListWrappers>
       {/* <ReOrderList
          list={rankingList}
          name="Top 5 Gallery "
          entity={entity}
          rankColumn="rank_in_gallery"
  /> */}
        <ReOrderList
          list={rankingListDestinations}
          name="Top 5  Popular Destinations"
          entity={entity}
          rankColumn="rank_in_popular_destinations"
        />
        <ReOrderList
          list={rankingListHospital}
          name="Top 5 Popular Hospitals"
          rankColumn="rank_in_popular_hospitals"
          entity={entity}
        />
      </ListWrappers>
    </div>
  );
};

export default DoctorRanking;
