import { createSlice } from '@reduxjs/toolkit';
import { getGallery } from './web-actions';

interface GalleryState {
  [key: string]: {
    images: any[];
    loading: boolean;
    error: string | null;
  };
}

const initialState: GalleryState = {};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGallery.pending, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        if (!state[categoryId]) {
          state[categoryId] = { images: [], loading: false, error: null };
        }
        state[categoryId].loading = true;
        state[categoryId].error = null;
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state[categoryId].loading = false;
        state[categoryId].images = action.payload;
      })
      .addCase(getGallery.rejected, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state[categoryId].loading = false;
        state[categoryId].error = action.error.message || 'Failed to fetch images';
      });
  },
});

export default gallerySlice.reducer;
