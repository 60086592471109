import DoctorStyledSection from "./DoctorStyledSection";
import { useDoctorDetail } from "./use-doctor-detail";

const Awards = () => {
  const data = useDoctorDetail("award");
  if (data.length === 0) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Awards </div>
      <div className="wrapper justify">
        {data.map(({ award_name, award_year, document_url }, idx) => (
          <div key={idx} className="card shadow">
            <div className="bold">{award_name}</div>
            <div className="year">{award_year}</div>
          </div>
        ))}
      </div>
    </DoctorStyledSection>
  );
};

export default Awards;