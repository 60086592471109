import styled from "styled-components";

const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;

  .form-row {
    display: flex;
    flex-direction: row;
  }

  .form-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  input,
  textarea,
  select,
  button {
    border: 1px solid var(--blue);
  }

  input,
  select {
    height: 50px;
  }

  .submit-btn-container {
    text-align: center;

    button {
      background-color: var(--blue);
      height: 48px;
      width: 185px;

      @media (min-width: 425px) {
        width: 292px;
        height: 55px;
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }

  .otp {
    width: 50%;
    margin: 0 auto;
    .spacer {
      width: 1em;
      display: inline-block;
    }
  }
`;

export default StyledLoginForm;
