import styled from "styled-components";
import StaticBanner from "@/components/StaticBanner";
import BestHospitals from "../hospitals/BestHospitals";
import WhyChooseUs from "../home/WhyChooseUs";
import Blogs from "../home/Blogs";
import HelpForm from "../doctors/HelpForm";
import TreatmentSection from "../doctors/Treatments";
import SearchBar from "./SearchBar";
import SliderTestimonial from "../home/SliderTestimonial";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const Hospitals = () => {
  return (
    <BodyWrapper>
       <Helmet>
        <title>Top Rated Hospitals in India | Plan Medical Tourism</title>
        <meta name="description" content="Find world-class medical care at a low cost by visiting the top hospitals in India, which are selected with The Plan Your Medical Tourism." />
        <link rel="canonical" href="https://planmedicaltourism.com/hospitals" />
        <meta name="keywords" content="" />
      </Helmet>
      <SearchBar entity="hospital" />
      <StaticBanner id={3} />
      <TreatmentSection title="Speciality" />
      <BestHospitals title="Top Rated Hospitals In India" category="country" />
      <BestHospitals title="Popular Hospitals In Metros" category="metro" />
      <BestHospitals
        title="Hospitals In Top Travel Destinations"
        category="destination"
      />
      <SliderTestimonial />
      <WhyChooseUs />
      <Blogs />
      <HelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default Hospitals;
