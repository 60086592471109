import location from "@/assets/hospitals/belowdetail/location.png";
import star from "@/assets/hospitals/belowdetail/star.png";
import stethoscope from "@/assets/hospitals/belowdetail/stethoscope.png";
import ambulance from "@/assets/hospitals/belowdetail/ambulance.png";
import hospitalBed from "@/assets/hospitals/belowdetail/hospital-bed.png";

import styled from "styled-components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";

const StyledBelowDetail = styled.div``;

const CardOuter = styled.div`
  width: 93px;
  @media (min-width: 425px) {
    width: 120px;
  }
`;

const CardInner = styled.div`
  width: 93px;
  height: 91px;
  img {
    width: 50%;
  }
  @media (min-width: 425px) {
    width: 120px;
    height: 120px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0;

  @media (min-width: 425px) {
    font-size: 16px;
  }
`;

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;

  @media (min-width: 425px) {
    gap: 50px;
    flex-wrap: nowrap;
  }
`;

const list = [
  {
    image: location,
    title: "", //"Mumbai, Maharashtra",
  },
  {
    image: stethoscope,
    title: "", //"165 Doctors",
  },
  {
    image: hospitalBed,
    title: "", //"560 Beds",
  },
  {
    image: ambulance,
    title: "",
  },
  {
    image: star,
    title: "", //"Multi-Speciality Hospital",
  },
];

const TreatmentCard = ({ title, image }: any) => {
  return (
    <CardOuter>
      <CardInner className="card shadow">
        <img alt="" src={image} width={"100%"} />
      </CardInner>
      <Title style={{ textTransform: "capitalize" }}>{title}</Title>
    </CardOuter>
  );
};
interface CommuteType {
  name: string;
}

const BelowDetail = () => {
  const { data: hospital } = useSelector((state: RootState) => state.hospital);
  if (!hospital) return null;
  const {
    city,
    state,
    HospitalType,
    total_beds,
    total_consultant,
    commutes = [],
  } = hospital;
  const location = `${city}, ${state}`;

  let commuteNames =
    commutes && (commutes as CommuteType[]).map((commute) => commute.name);

  list[0].title = location;
  list[1].title = `${total_consultant} Doctors`;
  list[2].title = `${total_beds} Beds`;
  list[3].title = commuteNames.join(", ");
  list[4].title = HospitalType && (HospitalType as any).name;

  return (
    <StyledBelowDetail>
      <TreatmentsWrapper>
        {list.map((item, idx) => {
          return <TreatmentCard key={idx} {...item} />;
        })}
      </TreatmentsWrapper>
    </StyledBelowDetail>
  );
};

export default BelowDetail;
