import { createSlice } from '@reduxjs/toolkit';
import { getPopularCities } from './web-actions';

const initialState = {
  data: null,          
  loading: false,      
  error: null,      
};

const popularCitiesSlice = createSlice({
  name: 'popularCities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPopularCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPopularCities.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.rows; 
        state.error = null;
      })
      .addCase(getPopularCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as any; 
      })
  }
});

export default popularCitiesSlice.reducer;
