import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

const tokenFromStorage = localStorage.getItem("token");
const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenFromStorage}`,
  },
};

const fetchBookingToken = () => {
  return fetch(`${API_BASE_URL}/api/tourism/booking/TranslatorInterpretors/token`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data.data; 
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getLanguages = createAsyncThunk("languages/fetchLanguages", async () => {
  try {
    const bookingToken = await fetchBookingToken(); 
    localStorage.setItem("bookingToken", bookingToken);
    const swagerRequestOptions = {
      method: "GET",
      headers: {
        "accept": "text/plain",
        Authorization: `Bearer ${bookingToken}`,
      },
    };

    const response = await fetch(`https://api.translatorsdesk.com/api/Translator/Getlanguages`, swagerRequestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});
export const send = createAsyncThunk("languages/fetchLanguages", async () => {
  try {
    const bookingToken = await fetchBookingToken(); 
    const swagerRequestOptions = {
      method: "GET",
      headers: {
        "accept": "text/plain",
        Authorization: `Bearer ${bookingToken}`,
      },
    };

    const response = await fetch(`https://api.translatorsdesk.com/api/Translator/Getlanguages`, swagerRequestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const getInterpretors = createAsyncThunk(
  'getInterpretors/send',
  async (postdata: any) => {
    try {
      const bookingToken = await fetchBookingToken();
      const response = await fetch(`https://api.translatorsdesk.com/api/Translator/GetInterpretors`, {
        method: 'POST',
        body: JSON.stringify(postdata),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bookingToken}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }
    } catch (error) {
      console.error('Error fetching interpreters:', error);
      throw error;
    }
  }
);

export const getTranslators = createAsyncThunk(
  'getTranslators/send',
  async (postdata: any) => {
    try {
      const bookingToken = await fetchBookingToken();
      const response = await fetch(`https://api.translatorsdesk.com/api/Translator/GetTranslators`, {
        method: 'POST',
        body: JSON.stringify(postdata),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bookingToken}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }
    } catch (error) {
      console.error('Error fetching translators:', error);
      throw error;
    }
  }
);
export const CreateUpdateAppointment = createAsyncThunk(
  'CreateUpdateAppointment/send',
  async (postdata: any) => {
    try {
      const bookingToken = await fetchBookingToken();
      const response = await fetch(`https://api.translatorsdesk.com/api/Translator/CreateUpdateAppointment`, {
        method: 'POST',
        body: JSON.stringify(postdata),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bookingToken}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }
    } catch (error) {
      console.error('Error fetching translators:', error);
      throw error;
    }
  }
);
export const guestCreateAppointment = createAsyncThunk(
  'guestCreateAppointment/send',
  async (postdata: any) => {
    try {
      const bookingToken = await fetchBookingToken();
      const response = await fetch(`${API_BASE_URL}/api/tourism/booking/TranslatorInterpretors/web/create`, {
        method: 'POST',
        body: JSON.stringify(postdata),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bookingToken}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
      }
    } catch (error) {
      console.error('Error fetching translators:', error);
      throw error;
    }
  }
);

