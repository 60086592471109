import styled from "styled-components";
import LoginForm from "./LoginForm";
import { Helmet } from "react-helmet";
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const Login = () => {
  return (
    <BodyWrapper>
       <Helmet>
        <title>Sign in to Connect with Plan Medical Tourism</title>
        <meta name="description" content="Sign in to Plan Medical Tourism to explore affordable treatment from top doctors, luxury hospitals and explore stunning destinations." />
        <link rel="canonical" href="" />
        <meta name="keywords" content="" />
      </Helmet>
      <LoginForm style={{ width: '100%', margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default Login;
