import "./styles.scss";
import { useEffect } from "react";
import CarouselContent from "./Carousel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getBannerData } from "@/features/home/banner-actions";

const StaticBanner = ({ id }: { id: number }) => {
  const { data } = useSelector(({ banner }: RootState) => banner);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getBannerData({ id }));
  }, [dispatch, id]);

  if (data.length) {
    return (
      <div className="banner">
        <CarouselContent data={data as any} />
      </div>
    );
  }
  return null;
};

export default StaticBanner;
