import React from 'react';
import styled from "styled-components";
import ambulanceIcon from "@/assets/icon/preloader.gif";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

const StyledSection = styled.div`
  .ambulance-button {
    position: fixed;
    bottom: 265px;
    right: -14px;
    padding: 10px;
    border-radius: 50%;
    z-index: 1000;
    cursor: pointer;
  }

  .ambulance-button img {
    width: 40px;
    height: 40px;
    display: block;
  }

  
  .ambulance-button .tooltiptext {
    visibility: hidden;
    text-wrap: nowrap;
    background-color: #28ace2;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    font-weight: 600;
    font-size: 13px;
    top: 15px;
    right: 110%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .ambulance-button .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #28ace2;
  }
  .ambulance-button:hover .tooltiptext {
    visibility: visible;
  }
`;

function AmbulanceIconButton() {
  const location = useLocation();
  const allowedRoutes = [
    "/pages/air-ambulance",
    "/pages/train-ambulance",
    "/pages/basic-life-support",
    "/pages/hearse-ambulance",
    "/pages/advance-life-support",
  ];

  const shouldRenderContent = allowedRoutes.some(
    (route) => location.pathname === route
  );

  let contentToRender;
  if (shouldRenderContent) {
    contentToRender = (
      <a
        href="#Ambulance"
        rel='noopener noreferrer'
        className='ambulance-button'
      >
        <img src={ambulanceIcon} className='w-100 ' alt='...' />
        <span className="tooltiptext">Request for Ambulance</span>
      </a>
    );
  } else {
    contentToRender = (
      <Link to="./air-ambulance?form=true" className='ambulance-button'>
        <img src={ambulanceIcon} className='w-100' alt='...' />
        <span className="tooltiptext">Request for Ambulance</span>
      </Link>
    );
  }

  return (
    <StyledSection>
      <div>
        {contentToRender}
      </div>
    </StyledSection>
  );
}

export default AmbulanceIconButton;
