/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import TreatmentDetail from "./TreatmentDetail";
import HelpForm from "../doctors/HelpForm";
import TreatmentNav from "./TreatmentNav";
import TreatmentCost from "./TreatmentCost";
import {Helmet} from "react-helmet";
// import PopularCities from "./PopularCities";
import DetailPageLayout from "@/conatiners/layouts/DetailPageLayout";
import TopHospitalsTreatment from "./TopHospitalsTreatment";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useParams } from "react-router-dom";
import { useEffect , useState} from "react";
import {
  fetchCitiesTopTreatment,
  fetchDoctorTopTreatment,
  fetchHospitalTopTreatment,
  fetchTreatmentById,
} from "@/features/web/treatment-actions";
import TopDoctors from "./TopDoctors";

const StyledTreatmentDetailPage = styled(DetailPageLayout)`
  .title {
    font-size: 24px;
  }
`;

interface Treatment {
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  canonical: string;
}

const TreatmentDetailPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { slug } = useParams();
  const [treatmentId, setTreatmentId] = useState('');
  const [treatment, setTreatment] = useState<Treatment | null>(null);
  
  useEffect(() => {
    const fetchTreatmentData = async () => {
      try {
        const response = await  dispatch(fetchTreatmentById(slug as string));
        dispatch(fetchCitiesTopTreatment());
        const id = response.payload.id;
        const data = response.payload;
        if (id) {
          dispatch(fetchHospitalTopTreatment(parseInt(id as string)));
          setTreatmentId(id.toString());
          setTreatment(data);
          dispatch(fetchDoctorTopTreatment(parseInt(id)));
        }
      } catch (error) {
        console.error('Error fetching treatment data:', error);
      }
    };
    
    fetchTreatmentData();
  }, [dispatch, slug, treatmentId]);


  const { data } = useSelector((state: RootState) => state.treatment);

  return (
    <StyledTreatmentDetailPage
      sideBar={
        <>
          <TreatmentNav />
          <HelpForm additionalVals={{ treatment_id: treatmentId }} />
        </>
      }
      content={
        data && treatment &&(
          <>
           <Helmet>
        <title>{treatment.meta_title}</title>
        <meta name="keywords" content={treatment.meta_keyword} />
        <meta name="description" content={treatment.meta_description} />
        <link rel="canonical" href={treatment.canonical} />
      </Helmet>
            <div>
              <a id="About"></a>
              <TreatmentDetail />
            </div>
            <div>
              <a id="Cost"></a>
              <TreatmentCost />
            </div>
            {/* <div>
              <a id="Cities"></a>
              <PopularCities />
            </div> */}
            <div>
              <a id="Hospitals"></a>
              <TopHospitalsTreatment />
            </div>
            <div>
              <a id="Doctors"></a>
              <TopDoctors className="treatment-detail" />
            </div>
            <div className="d-block d-sm-none">
              <HelpForm additionalVals={{ treatment_id: treatmentId }} />
            </div>
          </>
        )
      }
    />
  );
};

export default TreatmentDetailPage;
