import TitlePara from "@/components/page-components/TitleParagraph";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledAboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .title {
  }
  @media (max-width: 768px) {
    padding: 0px 2em;
  }
`;

const AboutSection = () => {
  const { data: hospital } = useSelector((state: RootState) => state.hospital);
  if (!hospital) return null;
  const { name, about, team_info } = hospital;

  return (
    <StyledAboutSection>
      <TitlePara title="About" />
      <p className="justify" dangerouslySetInnerHTML={{ __html: about }}></p>
      <a id="Teams"></a>
      <TitlePara
        title="Teams & Specialties"
      />
       <p className="justify" dangerouslySetInnerHTML={{ __html: team_info }}></p>
    </StyledAboutSection>
  );
};

export default AboutSection;
