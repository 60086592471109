/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPopularCitiesById } from "@/features/web/web-actions";

const DesticationCard = styled.div`
p{
  font-size: 18px;
}
  width: 328px;
  height: auto;
  margin: 1em 0em;
  .crCityImg{
    width: 296px;
    height:203px;
  }
`;
const CardDestination = ({ image, title, alt, subtitle = "" }) => {
  return (
    <DesticationCard className='card shadow'>
      <div className='card-body'>
        <img src={image} className='card-img-top destination-img crCityImg' alt={alt} />
        <p className='card-text m-0 sub-heading blue semibold'>{title}</p>
      </div>
    </DesticationCard>
  );
};
const settings = {
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,  
  autoplaySpeed: 3000,
  nextArrow: (
    <div>
      <div className='next-slick-arrow'>
        <i className='bi bi-arrow-right-circle bi_arrow'></i>
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className='prev-slick-arrow'>
        <i className='bi bi-arrow-left-circle bi_arrow'></i>
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const HospitalSlider = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { data, error } = useSelector((state ) => state.popularCitiesById);
  const [getCityDetail, setCityDetail] = useState();

  useEffect(() => {
    const fetchCitiesData = async () => {
      try {
        const response = await dispatch(fetchPopularCitiesById(slug));
        setCityDetail(response.payload.hospital);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCitiesData();
  }, [dispatch, slug]);

  let slidesToShow = 3;
  if (getCityDetail && getCityDetail.length <= 2) {
    slidesToShow = getCityDetail.length;
  }
  return (
    <div className=''>
      <p className='blue heading bold'>
        {/* {t("homePage.popularDestinationsInIndia")} */}
      </p>
      {getCityDetail && getCityDetail.length > 0 && (
        <Slider {...settings} slidesToShow={slidesToShow}>
          {getCityDetail.map(({ profile_url, name, profile_alt_tag, id , slug }, idx) => (
            <a href={`/hospital/${slug}`} key={id}>
              <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-4'>
                <CardDestination
                  image={profile_url}
                  title={name}
                  alt={profile_alt_tag}
                />
              </div>
            </a>
          ))}
        </Slider>
      )}
    </div>
  );
};


export default HospitalSlider;
