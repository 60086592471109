import { useEffect, useState } from "react";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import dermatology from "@/assets/doctors/treatments/dermatology.png";
import eyeDrop from "@/assets/doctors/treatments/eye-drop.png";
import gynecology from "@/assets/doctors/treatments/gynecology.png";
import heartRate from "@/assets/doctors/treatments/heart-rate.png";
import neurology from "@/assets/doctors/treatments/neurology.png";
import oncology from "@/assets/doctors/treatments/oncology.png";
import orthopedics from "@/assets/doctors/treatments/orthopedics.png";
import soreThroat from "@/assets/doctors/treatments/sore-throat.png";
import surgeryRoom from "@/assets/doctors/treatments/surgery-room.png";
import urology from "@/assets/doctors/treatments/urology.png";

import styled from "styled-components";
import { getSpecialities } from "@/features/web/web-actions";
import { useNavigate } from "react-router-dom";

const CardOuter = styled.div`
  width: 93px;
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: 14px;
  @media (min-width: 425px) {
    width: 160px;
  }
`;

const CardInner = styled.div`
  width: 93px;
  height: 91px;
  cursor:pointer;
  img {
    width: 100%;
    border-radius:10px;
  }
  @media (min-width: 425px) {
    width: 150px;
    height: 150px;
    border: 2px solid #2bace2;
    border-radius: 10px;
  }
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0;
  .item {
    width: 234px;
    height: 75px;
    line-height: 1;
    padding: 0px 5px;
    box-shadow: 0px 4px 20px 0 rgba(148, 148, 148, 0.24) !important;
    color: #61dafb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 425px) {
    // font-size: 20px;
  }
`;
const LoadMoreButton = styled.div`
  .loadMoreBtn button {
    border: 1px solid var(--blue);
    background: white;
    border-radius: 5px;
    width: 156px;
    height: 50px;
    position: relative;
    top: 3em;
    margin-bottom: 3em;

    @media (min-width: 425px) {
      width: 234px;
    }
    &:hover{
      background: #8bc451;
      color: #fff;
    }
  }
`;
const TreatmentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 425px) {
    gap: 40px;
    justify-content: center;
  }
  @media (min-width: 425px) {
    gap: 80px;
  }
`;

const list = [
  {
    image: heartRate,
    title: "Cardiology",
  },
  {
    image: neurology,
    title: "Cardiac Surgery",
  },
  {
    image: dermatology,
    title: "Joint Replacement ",
  },
  {
    image: soreThroat,
    title: "Gynecology & Infertility Specialist",
  },
  {
    image: orthopedics,
    title: "Oncology",
  },
  {
    image: urology,
    title: "Dentistry",
  },
  {
    image: gynecology,
    title: "Neurology & Neuro surgery",
  },

  {
    image: eyeDrop,
    title: "Urology & Nephrology",
  },

  {
    image: oncology,
    title: "Rheumatology",
  },

  {
    image: surgeryRoom,
    title: "Others",
  },
];

const TreatmentCard = ({ name, profile_url, profile_alt_tag, onClickFromChild }: any) => {
  const handleChildClick = () => {
    // Call the function passed from the parent when a click event occurs in the child
    onClickFromChild();
  };
  return (
    <CardOuter>
      <CardInner className="card shadow">
        <img width={150} height={150} alt={profile_alt_tag} src={profile_url} onClick={handleChildClick} />
      </CardInner>
      <div className="item">{name}</div>
    </CardOuter>
  );
};

const TreatmentSection = ({ title }: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [displayCount, setDisplayCount] = useState(5);
  const defaultDisplayCount = 5;

  useEffect(() => {
    (async () => {
      const res = await getSpecialities();
      if (res.success) {
        setData(res.data);
      }
    })();
  }, []);

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 5);
  };

  const handleShowLess = () => {
    setDisplayCount(defaultDisplayCount);
  };
  const handleSpecialityClick = (speciality: any) => {
    navigate(`/search/hospital?speciality_id=${speciality.id}`);
  };

  return (
    <div>
      {title && <p className="title blue heading bold">{title}</p>}
      <TreatmentsWrapper>
        {data.slice(0, displayCount).map((item: any, idx) => (
          <TreatmentCard
            key={idx}
            {...item}
            onClickFromChild={() => {
              handleSpecialityClick(item);
            }}
          />
        ))}
      </TreatmentsWrapper>
      {data.length > defaultDisplayCount && (
        <div className="loadMoreBtn">
          {displayCount < data.length ? (
            <LoadMoreButton>
              <div className="loadMoreBtn">
                <button onClick={handleLoadMore}>View More</button>
              </div>
            </LoadMoreButton>
          ) : (
            displayCount > defaultDisplayCount && ( // Only render "View Less" when there are more than defaultDisplayCount items visible
              <LoadMoreButton>
                <div className="loadMoreBtn">
                  <button onClick={handleShowLess}>View Less</button>
                </div>
              </LoadMoreButton>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default TreatmentSection;
