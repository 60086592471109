import styled from "styled-components";
import SearchBar from "../hospitals/SearchBar";
import HelpForm from "../doctors/HelpForm";
import FlyingBanner from "@/components/FlyingBanner";
import { BlueButton } from "@/components/styled";
import { getPackageById } from "@/features/admin/admin-actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import GetIntouch from "./GetInTouch";
import { Formik } from "formik";
import { sendOrder } from "@/features/web/web-actions";
import { useSelector } from "react-redux";
import useRoles from "@/components/hooks/useRoles";
import { viewEntity } from "@/features/admin/admin-actions";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;

  .package-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`;

const StyledPackageOver = styled.div`
  text-align: left;
  display: flex;
  gap: 36px;
  flex-direction: column;

  .details {
    display: flex;
    justify-content: space-between;

    .key-highlights {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
      .card {
        text-align: center;
        width: 200px;
        margin-right: 24px;
        height: 50px;
        padding: 10px;
      }
    }
  }
  .overview {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .inc-exc {
    display: flex;
    justify-content: space-between;
    .card {
      display: flex;
      padding: 16px;
      width: 48%;
      gap: 16px;
    }
  }
`;

const PackageDetailOverview = () => {
  const { userInfo = null, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const isPatient = useRoles("PATIENT");
  const [data, setData] = useState<any>();
  const [boxes, setBoxes] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { packageId = "" } = useParams<{ packageId: string }>();

  useEffect(() => {
    dispatch(getPackageById(packageId)).then((res: any) => {
      setData(res.payload);
      setBoxes([
        ...(res.payload.packageHospitals || []),
        ...(res.payload.packageTreatments || []),
      ]);
    });
  }, [dispatch, packageId]);

  const { entityId = null } = userInfo || {};
  const [userData, setUserData] = useState<any>(null);
  const initialValues = {
    packages_name: data ? data.title : "",
    packages_id: data ? data.id : "",
    quantity:  1,
    packages_code: data ? data.code : "",
    amount: data ? data.actual_price : "",
    billing_name: userData ? userData.first_name : "",
    billing_address: userData ? userData.address : "",
    billing_city: userData ? userData.city : "",
    billing_state: userData ? userData.state : "",
    billing_zip: userData ? userData.zip : "",
    billing_country: userData ? userData.country : "",
    billing_tel: userData ? userData.contact_no : "",
    billing_email: userData ? userData.email : "",
  };

  useEffect(() => {
    if (isAuthenticated && entityId && isPatient) {
      dispatch(viewEntity({ name: "patient", id: entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setUserData((payload as any).data);
          }
        }
      );
    }
  }, [dispatch, isAuthenticated, entityId, isPatient]);

  if (!data) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) => {
        const errors: any = {};
        return errors;
      }}
      onSubmit={(
        {  ...restValues },
        { setSubmitting, resetForm, setErrors }
      ) => {
        const values = {
          ...restValues,
        };
        dispatch(sendOrder(values)).then(() => {
          setErrors({});
          resetForm();
          navigate(`/packages/${data.slug}/book`);

        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <StyledPackageOver>
          <Helmet>
        <title>{data?.meta_title}</title>
        <meta name="keywords" content={data?.meta_keyword} />
        <meta name="description" content={data?.meta_description} />
        <link rel="canonical" href={data?.canonical} />
      </Helmet>
            <div className="details">
              <div className="key-highlights">
                <div className="title blue sub-heading semibold">
                  {data.title}
                </div>
                <div className="cards">
                  <>
                    {boxes.map(({ name }: any) => (
                      <div className="card shadow semibold" key={name}>
                        {name}
                      </div>
                    ))}
                  </>
                  <div className="card shadow semibold ">
                    ₹{data.display_price}
                  </div>
                </div>
              </div>
              <div className="book-now">
                <BlueButton
                  style={{
                    width: 234,
                    height: 58,
                  }}
                  type="submit"
                >
                  Book Now
                </BlueButton>
              </div>
            </div>

            <div className="overview">
              <div className="title blue sub-heading semibold">
                Package Overview
              </div>
              <div
                className="body-text"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </div>

            <div className="inc-exc">
              <div className="card shadow">
                <div className="title blue sub-heading semibold">
                  Package Inclusions
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: data.included_content }}
                ></div>
              </div>
              <div className="card shadow">
                <div className="title blue sub-heading semibold">
                  Package Exclusions
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: data.excluded_content }}
                ></div>
              </div>
            </div>
            <div className="overview">
              <div className="title blue sub-heading semibold">
                How to avail Package
              </div>
              <div
                className="body-text"
                dangerouslySetInnerHTML={{
                  __html: data.how_to_avail_content,
                }}
              ></div>
            </div>
          </StyledPackageOver>
        </form>
      )}
    </Formik>
  );
};

const PackageListingDetails = () => {
  const { packageId = "" } = useParams<{ packageId: string }>();
  const package_id = parseInt(packageId)
  return (
    <BodyWrapper>
      <SearchBar entity="package" />
      <FlyingBanner id={1} />
      <PackageDetailOverview />
      <HelpForm
      additionalPackageVals={{ packageId: package_id }}
        entity="package"
        title={"Get Quotations"}
        subtitle={"Request a Quote"}
        message={"Your request has been successfully submitted, and you will receive an email and follow-up from the customer care executive shortly!"}
        style={{ maxWidth: 842, margin: "0 auto" }}
      />
      <GetIntouch style={{ maxWidth: 373, margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default PackageListingDetails;
