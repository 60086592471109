import { ICarouselListItem } from "@/components/FlyingBanner";
import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBannerData = createAsyncThunk(
  "home/banner/fetch",
  async ({ id }: { id: number }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await fetch(
        // `${API_BASE_URL}/api/tourism/public/banner/get`
        `${API_BASE_URL}/api/tourism/banner/display/${id}`
      ).then((data) => data.json());

      return fulfillWithValue({
        data,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
