import brand from "@/assets/footer-brand.png";
import nidhi from "@/assets/nidhi.jpeg";
import brandm from "@/assets/footer-brand-m.png";
import Image from "@/components/utils/ResponsiveImage";
import SocialLinks from "@/components/header/SocialLinks";
import styled from "styled-components";

import whatsappLogo from "@/assets/icon/whatsapp.svg";
import mail from "@/assets/icon/mail.svg";
import TopButton from "@/components/common/TopButton";
import WhatsAppButton from "@/components/common/WhatsAppButton";
import AmbulanceIconButton from "@/components/common/AmbulanceIconButton";
import { NavLink } from "react-router-dom";
import FeedbackButton from "@/pages/feedback/FeedbackButton";

const StyledFooter = styled.div`
@media (max-width: 768px) {
  margin-top: 20px;
  .credits {
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-align: left;
    padding-left: 4em;
    > a {
      font-size: 12px;
      flex: 0 0 calc(50% - 10px);
    }
  }
    .right-wrapper {
     width: auto !important;
  }
}
  margin-top: 100px;
  .credits {
    display: flex;
    text-align: left;
    padding: 16px;
    justify-content: space-between;
    .left-wrapper {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-right: 20px;
      .branding {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .logo-text {
          font-size: 12px;
        }
      }
      @media (min-width: 425px) {
        flex: 0 0 30%;
      }
    }
    .right-wrapper {
      width: 160px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (min-width: 425px) {
        width: 500px;
        gap: 20px;
      }
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    > a {
      font-size: 12px;
      flex: 0 0 calc(50% - 10px);
    }
  }

  .copyright {
    padding: 20px 0;
    .recognization {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    .copy-text {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;
const ContactsLink = styled.a`
  display: flex;
  gap: 10px;
  font-size: 12px;
  span {
    word-break: break-all;
  }
`;

const Footer = () => {
  const baseUrl = window.location.origin;
  const currentYear = new Date().getFullYear();
  const handleDownload = () => {
    const pdfFilePath = "/pledge-nidhi.pdf";
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.target = "_blank";
    link.download = "pledge-nidhi.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <StyledFooter>
      <FeedbackButton/>
      <AmbulanceIconButton />
      <WhatsAppButton />
      <TopButton />
      <div className="credits">
        <div className="left-wrapper">
          <div className="branding">
            <Image xs={brandm} md={brand} alt="" />
            <div className="logo-text small-text">
              Your Path to Wellness Starts Here
            </div>
          </div>
          <div>
            <SocialLinks />
          </div>
        </div>
        <div className="footer_links">
          <div className="links">
            <NavLink to="./about-us">About us</NavLink>
            <NavLink to="./terms-conditions">Terms & Conditions</NavLink>
            <NavLink to="./contact-us">Contact Us</NavLink>
            <NavLink to="./privacy-policy">Privacy Policy</NavLink>
            <NavLink to="./group-awards">Group Awards</NavLink>
            <NavLink to="./disclaimer">Disclaimer</NavLink>
            <NavLink to="./why-choose-us">Why Choose Us</NavLink>
            <NavLink to="./faq">Faq</NavLink>
            <NavLink to="./partner">Our partners</NavLink>
            <NavLink to="./packages">Packages</NavLink>
            <NavLink to="./gallery">Gallery</NavLink>
          </div>
        </div>
        <div className="right-wrapper">
          <div className="link-text medium">Get in touch!</div>
          <div className="">
            <ContactsLink target="_blank" href="http://wa.me/+917977733207">
              <img src={whatsappLogo} alt="" width={21} height={21} />
              <span>+91 79777 33207</span>
            </ContactsLink>
          </div>

          <div className="">
            <ContactsLink
              target="_blank"
              href="mailto:patientcare@planmedicaltourism.com"
            >
              <img src={mail} alt="" width={21} height={16} />
              <span>patientcare@planmedicaltourism.com</span>
            </ContactsLink>
          </div>
        </div>
      </div>
      <div className="copyright">
        <a href="##" onClick={handleDownload}>
          <Image
            style={{ width: "100px", mixBlendMode: "darken" }}
            xs={nidhi}
            md={nidhi}
            alt=""
          />
        </a>
        <div className="recognization">
          RECOGNIZED BY MINISTRY OF TOURISM, GOVERNMENT OF INDIA
        </div>
        <div className="copy-text">
          Copyright &copy; {currentYear} All Rights Reserved
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
