/* eslint-disable jsx-a11y/anchor-is-valid */
import { FieldArray, Formik } from "formik";
import { API_BASE_URL } from "@/utils/Env";
import { StyledRegisterForm } from "../common/StyledRegisterForm";
import { Button, Upload, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import DrFormQualification from "./form-components/Qualification";
import DrFormExperience from "./form-components/Experience";
import CKEditorFormik from "./form-components/CKEditorFormik";

const uploadProps = (setValue: any, fieldName: string) => ({
  name: "file",
  data: {
    maincategory: "doctor",
    subcategory: "documents",
  },
  multiple: false,
  action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
  onChange(info: any) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      const tempArr = [];
      tempArr.push(info.file.response.imageUrl);
      setValue(fieldName, tempArr);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
});

const DoctorForm2 = ({
  style,
  prevStep,
  finalSubmit,
  genericError,
  errors,
}: any) => {
  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <Formik
        initialErrors={errors}
        initialValues={{
          experiences: [
            {
              designation: "",
              hospital_name: "",
              city: "",
              from_date: "",
            },
          ],
          qualifications: [
            {
              qualification_name: "",
              college_name: "",
              passout_year: "",
              college_city: "",
            },
          ],
          awards: [
            {
              award_name: "",
              award_year: "",
              document_url: "",
            },
          ],
          memberships: [
            {
              membership_name: "",
              membership_year: "",
              document_url: "",
            },
          ],
          publications: [
            {
              publication_name: "",
              publication_date: "",
              publication_type: "",
              about: "",
              document_url: "",
            },
          ],
          achievements: [
            {
              achievement_name: "",
              achievement_year: "",
              document_url: "",
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
          finalSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledRegisterForm>
              {genericError && (
                <div className="genericError">{genericError}!</div>
              )}
              <DrFormQualification
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              <DrFormExperience
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              <FieldArray
                name="awards"
                render={(arrayHelpers) => (
                  <div>
                    <p className="body-text">Awards & Recognitions</p>

                    {values.awards.map(({ award_name }: any, index: number) => (
                      <div
                        className="form-row row gx-5 array-field-row"
                        key={index}
                      >
                        <div className="form-group col-md-4">
                          <input
                            type="input"
                            className="form-control"
                            placeholder="Award Name"
                            name={`awards.${index}.award_name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Award Year"
                            name={`awards.${index}.award_year`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <Upload
                            {...uploadProps(
                              setFieldValue,
                              `awards.${index}.document_url`
                            )}
                          >
                            <Button className="input-btn">
                              <PlusCircleOutlined size={16} /> Upload
                              certificate here
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    ))}
                    <a
                      role="button"
                      className="d-block blue text-center"
                      onClick={() => {
                        setFieldValue("awards", [
                          ...values.awards,
                          {
                            award_name: "",
                            award_year: "",
                            document_url: "",
                          },
                        ]);
                      }}
                    >
                      + Add new Award & Recognition
                    </a>
                  </div>
                )}
              />

              <FieldArray
                name="memberships"
                render={(arrayHelpers) => (
                  <div>
                    <p className="body-text">Memberships</p>

                    {values.memberships.map((award: any, index: number) => (
                      <div
                        className="form-row row gx-5 array-field-row"
                        key={index}
                      >
                        <div className="form-group col-md-4">
                          <input
                            type="input"
                            className="form-control"
                            placeholder="Membership Name"
                            name={`memberships.${index}.membership_name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Membership Year"
                            name={`memberships.${index}.membership_year`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <Upload
                            {...uploadProps(
                              setFieldValue,
                              `memberships.${index}.document_url`
                            )}
                          >
                            <Button className="input-btn">
                              <PlusCircleOutlined size={16} /> Upload
                              certificate here
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    ))}
                    <a
                      role="button"
                      className="d-block blue text-center"
                      onClick={() => {
                        setFieldValue("memberships", [
                          ...values.memberships,
                          {
                            membership_name: "",
                            membership_year: "",
                            document_url: "",
                          },
                        ]);
                      }}
                    >
                      + Add new Memberships
                    </a>
                  </div>
                )}
              />

              <FieldArray
                name="publications"
                render={(arrayHelpers) => (
                  <div>
                    <p className="body-text">Publications</p>

                    {values.publications.map((award: any, index: number) => (
                      <div
                        className="form-row row gx-5 array-field-row"
                        key={index}
                      >
                        <div className="form-group col-md-6">
                          <input
                            type="input"
                            className="form-control"
                            placeholder="Publication Name"
                            name={`publications.${index}.publication_name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="input"
                            className="form-control"
                            placeholder="Publication Type"
                            name={`publications.${index}.publication_type`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-6 ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Publication Date"
                            name={`publications.${index}.publication_date`}
                            onChange={handleChange}
                            onBlur={(e) => {
                              e.target.type = "text";
                              handleBlur(e);
                            }}
                            onFocus={(e) => (e.target.type = "date")}
                          />
                        </div>
                        <div className="form-group col-md-6 ">
                          <Upload
                            {...uploadProps(
                              setFieldValue,
                              `publications.${index}.document_url`
                            )}
                          >
                            <Button className="input-btn">
                              <PlusCircleOutlined size={16} /> Upload
                              certificate here
                            </Button>
                          </Upload>
                        </div>
                        <div className="form-group col-md-12">
                          <CKEditorFormik
                            name={`publications.${index}.about`}
                          />
                        </div>
                      </div>
                    ))}
                    <a
                      role="button"
                      className="d-block blue text-center"
                      onClick={() => {
                        setFieldValue("publications", [
                          ...values.publications,
                          {
                            publication_name: "",
                            publication_date: "",
                            document_url: "",
                          },
                        ]);
                      }}
                    >
                      + Add new Publications
                    </a>
                  </div>
                )}
              />

              <FieldArray
                name="achievements"
                render={(arrayHelpers) => (
                  <div>
                    <p className="body-text">Notable Achievements</p>

                    {values.achievements.map((award: any, index: number) => (
                      <div
                        className="form-row row gx-5 array-field-row"
                        key={index}
                      >
                        <div className="form-group col-md-4">
                          <input
                            type="input"
                            className="form-control"
                            placeholder="Achievements Name"
                            name={`achievements.${index}.achievement_name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Achievements Year"
                            name={`achievements.${index}.achievement_year`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <Upload
                            {...uploadProps(
                              setFieldValue,
                              `achievements.${index}.document_url`
                            )}
                          >
                            <Button className="input-btn">
                              <PlusCircleOutlined size={16} /> Upload
                              certificate here
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    ))}
                    <a
                      role="button"
                      className="d-block blue text-center"
                      onClick={() => {
                        setFieldValue("achievements", [
                          ...values.achievements,
                          {
                            achievement_name: "",
                            achievement_year: "",
                            document_url: "",
                          },
                        ]);
                      }}
                    >
                      + Add new Notable Achievement
                    </a>
                  </div>
                )}
              />

              <div className="submit-btn-container">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => prevStep()}
                >
                  Previous
                </button>
                <button type="submit" className="btn btn-primary">
                  Finish
                </button>
              </div>
            </StyledRegisterForm>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DoctorForm2;
