import Carousel from "react-bootstrap/Carousel";
import Image from "../utils/ResponsiveImage";
import { Button } from "react-bootstrap";
import { ICarouselListItem } from ".";
import { useNavigate } from "react-router-dom";

interface ICarouselContentProps {
  data: ICarouselListItem[];
}

function CarouselContent({ data }: ICarouselContentProps) {
  const navigate = useNavigate();

  return (
    <Carousel>
      {data.map(({ cta, isExternal, title, subtitle, banner_url, banner_alt_tag }, idx) => (
        <Carousel.Item key={idx}>
          <Image xs={banner_url} md={banner_url} alt={banner_alt_tag} style={{ width: "100%" }} />
          <Carousel.Caption>
            <p className="line-1 ">{title}</p>
            <p className="line-2"> {subtitle}</p>
            {cta && (
              <Button
              className="banner_btn"
                onClick={() => {
                  if (isExternal) {
                    window.open(cta);
                  } else {
                    navigate(cta);
                  }
                }}
              >
                Know More &gt;
              </Button>
            )}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselContent;
