import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { getAll } from "@/features/admin/admin-actions";
import { useSelector } from "react-redux";
import { associateDoctorToHospital } from "@/features/web/hospital-actions";
import _ from "lodash";
import SearchDoctor from "./SearchDoctor";
import SearchDoctorAdmin from "./SearchDoctorAdmin";

interface DataType {
  name: string;
  profile_url: string;
  id: string;
  loading?: boolean;
  Specializations: [];
}

interface UnAssciatedDrListProps {
  hospital_id: number;
  associatedDrList: any[];
  onAddSuccess: () => void;
}
const UnAssciatedDrList = ({
  associatedDrList,
  hospital_id,
  onAddSuccess,
}: UnAssciatedDrListProps) => {
  const [entity] = useState("doctor");
  const [list, setList] = useState<DataType[]>([]);
  const [unfiltered, setUnfiltered] = useState<DataType[]>([]);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const { data: storeData } = useSelector(
    (state: RootState) => state.admin
  ) as Record<string, any>;
  const { [entity as string]: data } = storeData;

  useEffect(() => {
    if (entity) {
      dispatch(getAll({ entity }));
    }
  }, [entity]);

  useEffect(() => {
    if (data && data.rows && data.rows.length) {
      const filteredArray = _.differenceBy(data.rows, associatedDrList, "id");

      const list = filteredArray.map((item: any) => {
        return {
          id: item.id.toString(),
          profile_url: item.profile_url,
          name: "Dr. " + item.first_name + " " + item.last_name,
          Specializations: item.Specializations,
        };
      });
      setList(list);
      setUnfiltered(list);
    }
  }, [data, associatedDrList]);

  const addClick = (item: DataType) => {
    setMessage(null);
    dispatch(
      associateDoctorToHospital({
        associate: true,
        hospital_id,
        doctor_id: item.id as unknown as number,
      })
    ).then((res) => {
      if (res.hasOwnProperty("error")) {
        setMessage(res.payload);
      } else {
        onAddSuccess();
      }
    });
  };

  const searchChange = (text: string) => {
    if (text.length) {
      setList((l) =>
        l.filter((item) => item.name.toUpperCase().includes(text.toUpperCase()))
      );
    } else {
      setList(unfiltered);
    }
  };

  const onCancel = () => {
    setList(unfiltered);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ minHeight: 50 }}>All Doctors</div>
      {message && (
        <div
          style={{
            color: "red",
            fontSize: 12,
          }}
        >
          {message}
        </div>
      )}
      <SearchDoctorAdmin onChange={searchChange} onCancel={onCancel} />
      <List
        style={{
          width: "100%",
          height: 500,
          overflow: "auto",
          border: "1px solid",
          padding: "10px",
        }}
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="primary" onClick={() => addClick(item)} block>
                Add
              </Button>,
            ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.profile_url} />}
                title={item.name}
                description={item.Specializations.map(({ name }) => name).join(
                  ","
                )}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default UnAssciatedDrList;
