import styled from "styled-components";
import more from "@/assets/more-arrow.png";
import { NavLink } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useEffect, useState } from "react";
import { getBlogs } from "@/features/admin/admin-actions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
const StyledSection = styled.div`
  padding: 20px;
`;
export const BlogListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  .list-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .image {
      flex: 1 1 40%;
    }
    .details {
      flex: 1 1 60%;

      display: flex;
      flex-direction: column;
      text-align: left;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }

      .date-more {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media (min-width: 425px) {
    flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
    .list-item {
      flex-direction: column;
      justify-content: space-between;
      width: 358px;
      .image {
        overflow: hidden;
        border-radius: 10px;
        min-height: 340px;
        align-items: center;
        display: flex;
        justify-content: center;
        img {
          width: initial;
          max-width: 100%;
        }
      }
      .details {
        justify-content: space-between;
        .date-more {
          margin-top: 10px;
          flex-direction: row;
          justify-content: space-between;
          .more {
            margin-top: -10px;
          }
        }
      }
    }
  }
`;

export const ReadMore = styled(NavLink)`
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    background: url(${more}) no-repeat;
    top: 10px;
    right: -5px;
  }
`;

export const BlogThumbnail = ({ data }: any) => {
  const { title, id, profile_url, publish_date, thumbnail_alt_tag , slug } = data;
  return (
    <div className="list-item">
      <div className="image">
        <img src={profile_url} alt={thumbnail_alt_tag} />
      </div>
      <div className="details">
        <div className="name body-text bold">{title}</div>
        <div className="date-more">
          <div className="date green">
            {new Date(publish_date).toLocaleDateString("en-IN")}
          </div>
          <ReadMore className="more" href={`../blog/${slug}`}>
            Read More
          </ReadMore>
        </div>
      </div>
    </div>
  );
};

const Knowledge = () => {
  const { catId = "" } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [news, setNews] = useState([]);
  const { knowledge } = useSelector((state: RootState) => state.web);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (knowledge.menu && Object.entries(knowledge.menu).length && catId) {
      let categoryId;
      if (catId === "blog") {
        categoryId = 1;
      } else if (catId === "news") {
        categoryId = 2;
      } else {
        categoryId = catId; // Keep the original value if it's neither "blog" nor "news"
      }
  
      setTitle(knowledge.menu[categoryId].name);
      dispatch(
        getBlogs({
          catId: categoryId,
          page: 1,
          size: 50,
        })
      ).then((res: any) => {
        setNews(res.payload.rows);
      });
    }
  }, [knowledge, catId]);
  
  return (
    <StyledSection>
       <Helmet>
        <title>Medical Tourism Blog | Medical Trip Blog Your Guide to the Top Medical Facilities and Treatments | Plan Medical Tourism</title>
        <meta name="description" content="Discover the insight about medical tourism. Learn about the top treatments and hospitals for various diseases with Plan Medical Tourism." />
        <link rel="canonical" href="https://planmedicaltourism.com/knowledge/blog" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="title blue bold sub-heading">{title}</div>
      <BlogListWrapper>
        {news.map((item, idx) => (
          <BlogThumbnail data={item} key={idx} />
        ))}
      </BlogListWrapper>
    </StyledSection>
  );
};

export default Knowledge;
