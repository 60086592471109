/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getGroupAwards } from "@/features/web/web-actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState, AppDispatch } from '../../store';
const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,  
  autoplaySpeed: 3000,
  nextArrow: (
    <div>
      <div className="next-slick-arrow">
        <i className="bi bi-arrow-right-circle bi_arrow"></i>
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className="prev-slick-arrow">
        <i className="bi bi-arrow-left-circle bi_arrow"></i>
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const GroupAwardCarousel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { images, loading, error } = useSelector((state: RootState) => state.groupAward);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getGroupAwards({categoryId: 4})).then((res: any) => {
      setData(res.payload.rows);
    });
  }, [dispatch]);

  
  return (
    <div>
      <p className="blue heading bold">Group Awards</p>
      <div className="slider_content">
        <div className="slider_award_container">
          {data ? (
            <Slider {...settings}>
              {data.map(
                ({ image_url , alt_tag}: any, idx) => (
                  <div className="slider_award">
                    <img src={image_url} className="img" alt={alt_tag} />
                  </div>
                )
              )}
            </Slider>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupAwardCarousel;
