/* eslint-disable jsx-a11y/anchor-is-valid */
import { FieldArray, useFormikContext } from "formik";

const DrFormQualification = ({ values, handleChange, handleBlur }: any) => {
  const formikKey = "qualifications";
  const { setFieldValue } = useFormikContext();
  return (
    <FieldArray
      name={formikKey}
      render={(arrayHelpers) => (
        <div>
          <p className="body-text">Qualifications</p>

          {values.qualifications.map(
            ({ qualification_name }: any, index: number) => (
              <div className="form-row row gx-5 array-field-row" key={index}>
                <div className="form-group col-md-6">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Qualification"
                    name={`${formikKey}.${index}.qualification_name`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group col-md-6 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="College Name"
                    name={`${formikKey}.${index}.college_name`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="College City"
                    name={`${formikKey}.${index}.college_city`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group col-md-6 ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Passout Year"
                    name={`${formikKey}.${index}.passout_year`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            )
          )}
          <a
            role="button"
            className="d-block blue text-center"
            onClick={() => {
              setFieldValue(formikKey, [
                ...values[formikKey],
                {
                  qualification_name: "",
                  college_name: "",
                  college_city: "",
                  passout_year: "",
                },
              ]);
            }}
          >
            + Add new Qualifications
          </a>
        </div>
      )}
    />
  );
};

export default DrFormQualification;
