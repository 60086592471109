import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import Feedback from "./Feedback";

const StyledForm = styled.div`
  .feedback-button-container {
    position: fixed;
    bottom: 11.5em;
    right: 0px;
    transform: rotate(270deg);
    transform-origin: right bottom;
    cursor: pointer;
    transition: transform 0.3s ease-in-out 0s;
  }

  .feedback-button {
    background-color: #2bace2;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  .custom-modal {
    width: 800px;
    height: 400px;
  }
`;

const FeedbackButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const toggleFeedback = () => {
    setShowModal(!showModal);
  };

  return (
    <StyledForm>
      <div
        className={`feedback-button-container ${showModal ? "open" : ""}`}
        onClick={toggleFeedback}
      >
        <div className="feedback-button">Feedback</div>
      </div>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="sub-heading bold blue p-0 m-0">Feedback</p>
          </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <Feedback />
        </Modal.Body>
      </Modal>
    </StyledForm>
  );
};

export default FeedbackButton;
