import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

export const getAll = createAsyncThunk(
  "entity/getall",
  async (
    {
      entity,
      page = 1,
      size = 50,
      qs = null,
    }: { entity: string; page?: number; size?: number; qs?: string | null },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const x = await fetch(
        `${API_BASE_URL}/api/tourism/${entity}/get?page=${page}&size=${size}${
          qs ? "&" + qs : ""
        }`
      ).then((data) => data.json());
      const { data, success, message } = x;
      if(!data.enquiryStatus){
        data.enquiryStatus  =  {0: 'PENDING', 1: 'INPROGRESS', 2: 'COMPLETED', 3: 'REJECTED'}
      }
      if (success) { 
        return fulfillWithValue({
          data,
          entity,
        });
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "blog/getall",
  async ({ page, size, catId }: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/blog/display?blog_category_id=${catId}&page=${page}&size=${size}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPackage = createAsyncThunk(
  "package/getall",
  async ({ page, size }: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/package/display?page=${page}&size=${size}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "testimonials/getall",
  async (arg, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/testimonial/display`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "blog/getbyid",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/blog/slug/${id}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);
export const getPackageById = createAsyncThunk(
  "package/getbyid",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/package/get/${id}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const publishBlog = createAsyncThunk(
  "blog/publish",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/blog/publish/${id}`,
        {
          method: "PUT",
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllSubEntity = createAsyncThunk(
  "subentity/getall",
  async (entity: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const x = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/${entity.subEntity}/get/all/${entity.id}`
      ).then((data) => data.json());
      const { data, success, message } = x;
      if (success) {
        return fulfillWithValue({
          data,
          entity,
        });
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const createEntity = createAsyncThunk(
  "entity/create",
  async (entity: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    const config = {
      method: "POST",
      body: JSON.stringify(entity.body),
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/create`,
        config
      ).then((data) => data.json());

      const { data, message, success } = response;
      if (success) {
        await dispatch(getAll({ entity: entity.name }));
        return fulfillWithValue({
          data,
          message,
          entity: entity.name,
        });
      } else {
        return rejectWithValue({
          errors: data,
          message,
        });
      }
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

export const viewEntity = createAsyncThunk(
  "entity/view",
  async (entity: any) => {
    try {
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/view/${entity.id}`
      ).then((data) => data.json());

      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getOptions = createAsyncThunk(
  "entity/options/get",
  async (entity: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { success, data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity}/get/master/data`
      ).then((data) => data.json());
      if (success) {
        if(!entity.enquiryStatus){
          data.enquiryStatus  =  {0: 'PENDING', 1: 'INPROGRESS', 2: 'COMPLETED', 3: 'REJECTED'}
        }
        return fulfillWithValue({
          data,
          entity: entity.name,
        });
      } else {
        return rejectWithValue("Error");
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const fileUpload = ({ maincategory, subcategory, file }: any) => {
  var data = new FormData();
  data.append("maincategory", maincategory);
  data.append("subcategory", subcategory);
  data.append("file", file);

  return fetch(`${API_BASE_URL}/api/tourism/fileupload/public-upload`, {
    method: "POST",
    body: data,
  }).then((data) => data.json());
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const saveEntity = createAsyncThunk(
  "entity/save",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "PUT",
        body: JSON.stringify(entity.body),
      };
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/edit/${entity.id}`,
        config
      ).then((data) => data.json());

      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);

export const subEntityCreate = createAsyncThunk(
  "subentity/create",
  async (entity: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const config = {
        method: "POST",
        body: JSON.stringify(entity.body),
      };
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/${entity.subEntity}/create/${entity.id}`,
        config
      ).then((data) => data.json());
      if (success === false) {
        return rejectWithValue(message);
      }

      return fulfillWithValue({
        data,
        entity: entity.name,
        message,
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAllSubEntity(entity));
    }
  }
);

export const subEntityEdit = createAsyncThunk(
  "subentity/edit",
  async (entity: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const config = {
        method: "PUT",
        body: JSON.stringify(entity.body),
      };
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/${entity.subEntity}/edit/${entity.id}`,
        config
      ).then((data) => data.json());
      if (success === false) {
        return rejectWithValue(message);
      }

      return fulfillWithValue({
        data,
        entity: entity.name,
        message,
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAllSubEntity(entity));
    }
  }
);

export const updateEnquiry = createAsyncThunk(
  "entity/save",
  async ({ id, ...entity }: any, { dispatch }) => {
    try {
      const config = {
        method: "POST",
        body: JSON.stringify(entity),
      };
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/edit/${id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);


export const getTranslatorToken = createAsyncThunk(
  "booking/translatorToken",
 /* async ({ entity}: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      let Body = {
        userName: "user1",
        password: "Ilove2Mango$"
      } 
      const config = {
        method: "POST",
        body: JSON.stringify(Body)
      };
      const  data  = await fetch(
        `${API_BASE_URL}/api/tourism/booking/TranslatorInterpretors/token`,
        config
      ).then((data) => data.json());
      if (data) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  } */
  async (arg, { fulfillWithValue, rejectWithValue }) => {
    try {
      let Body = {
        userName: "user1",
        password: "Ilove2Mango$"
      } 
        const config = {
      method: "POST",
      body: JSON.stringify(Body),
    };
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/booking/TranslatorInterpretors/token`,
        config
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

  
  


export const updateBooking = createAsyncThunk(
  "booking/update",
  async ({ id,...entity}: any, { dispatch }) => {
    try {
     
      let body = {
        "appointment_id": entity.status.data.appointment_id,
        "appointment_status": entity.status.status,
        "token": entity.token
    }
      const config = {
        method: "PUT",
        body: JSON.stringify(body),
      };
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/booking/update/${id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);

export const deleteEntity = createAsyncThunk(
  "entity/delete",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "DELETE",
      };
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/delete/${entity.id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);

export const deleteSubEntity = createAsyncThunk(
  "entity/delete",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "DELETE",
      };
      const { data } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/${entity.subEntity}/delete/${entity.subId}`,
        config
      ).then((data) => data.json());
      return {
        data,
        entity: entity.name,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAllSubEntity(entity));
    }
  }
);

export const approve = createAsyncThunk(
  "entity/approve",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "PUT",
      };
      const { data, message } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/approve/${entity.id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        message,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);


export const active = createAsyncThunk(
  "entity/approve",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "PUT",
      };
      const { data, message } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/active/${entity.id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        message,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);

export const inactive = createAsyncThunk(
  "entity/approve",
  async (entity: any, { dispatch }) => {
    try {
      const config = {
        method: "PUT",
      };
      const { data, message } = await fetch(
        `${API_BASE_URL}/api/tourism/${entity.name}/inactive/${entity.id}`,
        config
      ).then((data) => data.json());
      return {
        data,
        message,
      };
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getAll({ entity: entity.name }));
    }
  }
);

export const searchBlogs = createAsyncThunk(
  "blog/search",
  async (
    { page, size, blogId }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { data, success, message } = await fetch(
        // `${API_BASE_URL}/api/tourism/blog/display?page=${page}&size=${size}`
        `${API_BASE_URL}/api/tourism/blog/search?page=1&size=3&requestFetchType=next&current=${blogId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEnqById = createAsyncThunk(
  "enquiry/getbyId",
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { success, data } = await fetch(
        `${API_BASE_URL}/api/tourism/enquiry/view/${id}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue({
          data,
        });
      } else {
        return rejectWithValue("Error");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
