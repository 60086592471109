import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin } from "./authActions";
const token =
  localStorage.getItem("token") !== "undefined"
    ? localStorage.getItem("token")
    : null;

const userInfo =
  localStorage.getItem("userInfo") !== "undefined"
    ? localStorage.getItem("userInfo")
    : null;

const entities =
  localStorage.getItem("entities") !== "undefined"
    ? localStorage.getItem("entities")
    : [];

const initialState = {
  loading: false,
  userInfo: userInfo && userInfo.length ? JSON.parse(userInfo) : null,
  token,
  error: null,
  success: false,
  isAuthenticated: !!token,
  isAdmin: false,
  entities: entities && entities.length ? entities.toString().split(",") : [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      localStorage.clear();
      state.userInfo = null;
      state.token = null;
      state.entities = [];
      state.isAuthenticated = false;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = false;
      state.isAuthenticated = false;
      state.isAdmin = false;
    });

    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.user;
      state.token = payload.token;
      state.success = true;
      state.isAuthenticated = true;
      state.entities = payload.entities.flat(1);
      state.isAdmin =
        payload.user.roles.some(({ code }: any) => code === "ADMIN") || false;
      localStorage.setItem("entities", payload.entities.flat(1).toString());
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as any;
      state.success = false;
      state.isAuthenticated = false;
      state.isAdmin = false;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(registerUser.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true; // registration successful
    });

    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
  },
});
export const { logout, setToken } = authSlice.actions;
export default authSlice.reducer;
