import fac from "@/assets/icon/facebook.png";
import ins from "@/assets/icon/instagram.png";
import twi from "@/assets/icon/twitterBlue.png";
import lin from "@/assets/icon/linkedin.png";
import yt from "@/assets/icon/youtube.png";

import { FlexChildContainer, IClassName } from "./ContactBar";
import Image from "../utils/ResponsiveImage";

const SocialLinks = ({ className }: IClassName) => {
  return (
    <FlexChildContainer className={`rs_socialLinks ${className}`}>
      <a href="https://www.facebook.com/people/Planmedicaltourism/61560562493778/" target="_blank">
        <Image xs={fac} md={fac} alt="" style={{ width: '25px', height: '25px' }} />
      </a>
      <a href="https://www.instagram.com/plan_medical_tourism/ " target="_blank">
        <Image xs={ins} md={ins} alt="" style={{ width: '25px', height: '25px' }} />
      </a>
      <a href="https://twitter.com/PlanMedicalTour" target="_blank">
      <Image xs={twi} md={twi} alt="" style={{ width: '25px', height: '25px' }} />
      </a>
      <a href="https://www.linkedin.com/in/plan-medical-tourism-148524275/" target="_blank">
        <Image xs={lin} md={lin} alt="" style={{ width: '25px', height: '25px' }} />
      </a>
      <a href="https://www.youtube.com/channel/UCt3hp3OHfgl7mFt9IHKt4WA/" target="_blank">
        <Image xs={yt} md={yt} alt="" style={{ width: '25px', height: '25px' }} />
      </a>
    </FlexChildContainer>
  );
};

export default SocialLinks;
