import locationIcon from "@/assets/doctors/location.png";
import styled from "styled-components";

const LocationIcon = styled.div`
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    background: url(${locationIcon}) no-repeat;
    top: 6px;
    left: -5px;
  }
`;

export default LocationIcon;
