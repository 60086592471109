import React, { useEffect } from "react";
import styled from "styled-components";
import img1 from "@/assets/amulance-cervices-img/air-ambulance/airamb1.jpg";
import img2 from "@/assets/amulance-cervices-img/air-ambulance/airamb2.jpg";
import img3 from "@/assets/amulance-cervices-img/air-ambulance/air3.jpg";
import img4 from "@/assets/amulance-cervices-img/air-ambulance/air4.jpg";
import img5 from "@/assets/amulance-cervices-img/air-ambulance/air5.jpg";
import img6 from "@/assets/amulance-cervices-img/air-ambulance/air5.jpg";
import img7 from "@/assets/amulance-cervices-img/air-ambulance/air6.jpg";
import img8 from "@/assets/amulance-cervices-img/air-ambulance/air7.jpg";
import img9 from "@/assets/amulance-cervices-img/air-ambulance/air8.jpg";
import AmbulanceHelpForm from "../AmbulanceHelpForm";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const StyledSection = styled.div`
  .section1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 400px;
  }
  p{
    line-height:1.8;
  }
  .image-container {
    overflow: hidden;
    position: relative;
}
.image-container img {
    transition: transform 0.3s ease-in-out;
}
.image-container:hover img {
  transform: scale(1.2, 1.2); 
}
  @media only screen and (max-width: 768px) {
    .air_amb{
      padding: 1.5rem;
    }
  }
`;

function Index() {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isFormVisible = queryParams.get("form") === "true";
    if (isFormVisible) {
      const targetSection = document.getElementById("Ambulance");
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop,
          behavior: "smooth"
        });
      }
    }
  }, [location.search]);
  return (
    <StyledSection>
       <Helmet>
        <title>Top Air Ambulance Services | Plan Medical Tourism</title>
        <meta name="keywords" content="" />
        <meta name="description" content="Plan Medical Tourism provides air ambulance services for top medical transportation services." />
        <link rel="canonical" href="https://planmedicaltourism.com/air-ambulance" />
      </Helmet>
      <div className='air_amb'>
        <h1 className='blue heading bold'>Air Ambulance</h1>
        <div className='who_we_are'>
          <h6 className='sub-heading blue bold pt-2 pb-2'>Who we are</h6>
          <div className='row section1 '>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img3} width={"600px"} className='img-fluid' alt='' />
            </div>
            </div>
            <div className='col-md-6 justify'>
              <p>
              PMT partner air ambulance provides the critical need for top-tier medical transportation services. Our commitment lies in providing unparalleled care, specialized support, and cutting-edge technology to ensure safe and efficient patient transfers. Whether it's across the country or within the city, our comprehensive range of services caters to diverse medical requirements, ensuring optimal care throughout the journey. For patients demanding specialized care, monitoring, and equipment during long-distance transportation, PMT partnered Charter Air Ambulance services stand as a beacon of reliability. We recognize the urgency of expert 
              </p>
            </div>
            <p className="justify">medical attention, especially in cases requiring immediate intervention. PMT partnered network of high-quality Charter Air Ambulance companies ensures seamless transfers equipped with the latest medical equipment and technology. Trust us to prioritize your well-being and safety, ensuring your journey is as comfortable as it is efficient. </p>
          </div>
        </div>
        <div className='what_we_offer'>
          <h6 className='sub-heading blue bold pt-5 pb-4'>What we offer</h6>
          <div className='row section1 '>
            <div className='col-md-6 justify'>
              <p>
              We offer the Air Ambulance facility that include emergency transfers to medical hospital.  The patient requires medical care & to be transported through an unconventional route like the air. In cases of emergency, transporting patients from remote areas to cities providing best medical treatments across global borders. Highly equipped & specially crafted on- air medical assistance ensuring prompt, safe & comfortable emergency transfers. 
              </p>
            </div>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img4} style={{height:'300px'}} className='img-fluid' alt='' />
            </div>
            </div>
          </div>
        </div>
        <div className='what_we_offer'>
          <div className='row section1 pt-5'>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img7} className='img-fluid' alt='' />
            </div>
            </div>
            <div className='col-md-6 justify'>
              <p>
              As an air ambulance service provider, we offer a range of comprehensive and specialized services to ensure the safe and efficient transportation of patients in need of critical care. Here are some of the services and features we offer:
                <br />
                <b>Medical Expertise</b> : Highly trained medical professionals, including doctors, nurses, and paramedics, who are experienced in providing medical care in-flight and are skilled in handling various medical conditions and emergencies will always be there with the patients.
              </p>
            </div>
          </div>
          <p className='justify pt-4'>
            <b>Advanced Medical Equipment</b> :PMT partnered chartered air ambulance are well-equipped aircrafts with advanced medical equipment and technology, such as cardiac monitors, ventilators, defibrillators, infusion pumps, and other necessary medical devices to support the patient's condition during the flight. <br />
            <b>24/7 Availability</b> : Round-the-clock chartered air ambulance services are available by making a call to our help line number which will adhere to emergency calls and provide air ambulance services at any time of day or night. <br />
            <b>Rapid Response</b> : PMT partners act fast to answer transfer requests, ensuring critical care access for those in need.
          </p>
        </div>
        <div className='what_we_offer'>
          <div className='row section1 pt-5'>
            <div className='col-md-6 justify'>
              <p>
                <b>Multiple Aircraft Options</b> : PMT partners operate a range of well-equipped aircraft designed for air medical transportation, including both airplanes and helicopters. These aircraft are tailored to meet various patient needs and travel distances. <br />
                <b> Expansive Service Area </b> : PMT enables patient transfers to a wide range of regional, national, or international locations, determined by the reach and capabilities of our service.  
              </p>
            </div>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img6} className='img-fluid' alt='' />
            </div>
            </div>
          </div>
        </div>
        <div className='what_we_offer'>
          <div className='row section1 pt-5'>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img9} className='img-fluid' alt='' />
            </div>
            </div>
            <div className='col-md-6 justify'>
              <p>
                <b> Bed-to-Bed Service</b>:PMT's Partners ensures smooth collaboration with ground transportation for a comprehensive door-to-door service. This includes ground ambulance transfers to and from the aircraft, guaranteeing uninterrupted patient care. <br />
                <b> Specialized Care</b> :PMT's Partners customize care plans to match the patient's medical condition, encompassing critical care, neonatal care, pediatric care, and specialized medical interventions.
                <br />
                <b>Interfacility Transfers</b> : PMT's Partners ensures secure and streamlined transfers between healthcare facilities, such as hospitals or clinics, for advanced care or specialized treatments that may not be accessible locally.
              </p>
            </div>
          </div>
          <p className='justify pt-4'>
            <b>Safety and Regulatory Compliance</b> :PMT's Partners strict compliance with stringent safety standards, aviation regulations, and medical protocols, all aimed at guaranteeing the safety of patients throughout their journey.<br />
            <b> Communication and Coordination</b> : PMT partners efficient communicate and coordinate with the medical facilities referring and receiving patients, ensuring a seamless transition of care and the accurate exchange of medical information. <br />
            <b> Family Accommodation</b>: PMT's partners ensures the possibility for family members or loved ones to accompany the patient during the flight, taking into account aircraft capacity and safety considerations.
          </p>
        </div>
        <div className='what_we_offer'>
          <div className='row section1 pt-5'>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img8} className='img-fluid' alt='' />
            </div>
            </div>
            <div className='col-md-6 justify'>
              <p>
                <b>Privacy and Comfort</b> : PMT's partners prioritize patient privacy and ensure a comfortable environment within the aircraft cabin for both the patient and any accompanying family members. <br />
                <b>Experienced Operations Team</b> : PMT's partners have a specialized operations team tasked with mission planning, logistics, and ensuring that all essential arrangements are in place for a successful transfer.
                <br />
                <b>Transparent Pricing</b> : PMT's partners offer a straightforward and transparent pricing system. They can provide cost estimates and assist with billing and payment procedures for your convenience. <br />
                <b>Positive Patient Experience</b> : PMT's partners emphasize delivering caring and expert assistance, with a strong commitment to the patient's comfort and well-being during every step of the transport journey.
              </p>
            </div>
          </div>
        </div>
        <div className='what_we_offer'>
          <h6 className='sub-heading blue pt-5 bold'>WHY CHOOSE US</h6>
          <div className='row section1 pt-5'>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img2} className='img-fluid' alt='' />
            </div>
            </div>
            <div className='col-md-6 justify'>
              <p>
              Choosing an air ambulance service is an important decision that involves considering several factors to ensure the safety and well-being of the patient being transported. When deciding on an air ambulance provider, here are some reasons why you might choose our partnered air-ambulance services<br />
                <b>Medical Expertise</b>: When choosing an air ambulance service, you must seek the one that utilizes extensively trained medical experts, comprising doctors, nurses, and paramedics, who possess substantial experience in delivering medical care during transportation. PMT’s partnered air ambulance excellence medical team is pivotal in guaranteeing the highest level of care for the patient.
              </p>
            </div>
          </div>
        </div>
        <div className='what_we_offer'>
          <div className='row section1 pt-5'>
            <div className='col-md-6 justify'>
              <p>
                <b>Aircraft and Equipment</b> : When selecting an air ambulance service, opt for one that utilizes well-maintained and properly tailored aircraft built to cater to critical care patients. PMT partnered aircrafts are equipped with state-of-the-art medical apparatus and technology for monitoring and assisting the patient's condition throughout the flight.
                <br />
                <b> Experience and Safety</b>: When evaluating air ambulance services in partnership with PMT, we take into account the service's track record and safety performance. A reliable provider like us should boast a track record of secure and effective patient transfers, consistently upholding stringent safety protocols and compliance with regulations. <br />
                <b> Coverage Area</b> : PMT Partner ensure that the air ambulance service can function within your preferred coverage area. Certain services may have restrictions on the geographical regions they can cover, so PMT Partner confirm their ability to transport the patient to their intended destination.    
              </p>
              <br />
            </div>
            <div className='col-md-6'>
            <div className="image-container">
              <img src={img1} className='img-fluid' alt='' />
            </div>
            </div>
          </div>
          <p className='justify'>
            <b>Response Time</b> : In medical emergencies, time is frequently of the essence. PMT Partners opt for a service that can offer a rapid response and swift activation for air ambulance missions.  <br />
            <b>Communication and Coordination</b> : Efficient communication and coordination among the air ambulance team, referring medical facility, and receiving facility are crucial. PMT partner services are dependable and have established processes to enable the smooth exchange of information.  <br />
            <br />
            <b>Licensing and Accreditation</b> : VPMT Partner ensure that the air ambulance service holds valid licenses, accreditations, and maintains compliance with pertinent medical and aviation regulations. Accreditation from respected organizations can serve as an indicator of elevated quality and safety standards. <br />
            <b>Patient Comfort</b> : PMT Partner take into account the degree of comfort and assistance offered to the patient during the flight. This encompasses elements such as cabin layout, seating provisions, and onboard amenities.
            <br />
            <br />
            <b>Customer Reviews and References</b> : PMT continues reviews the feedback of patients for PMR Partner Air-Ambulance services. These reviews and feedback assist PMT to get valuable insights into the experience others have had with the air ambulance service. Positive testimonials can contribute to bolstering patient’s confidence in decision-making process.
          </p>
        </div>
      </div>
      <section id='Ambulance'>
        <AmbulanceHelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
      </section>
    </StyledSection>
  );
}
export default Index;