import { getPackagesHome } from "@/features/web/web-actions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  margin-top: 24px;
  gap: 24px;
  @media (max-width: 768px) {
    justify-content: center;
    padding-bottom: 2em;
  }
`;

const StyledPackageCard = styled.div`
  width: 152px;
  text-align: left;
  padding: 16px;
  display: flex;
  gap: 10px;
  flex: 0 0 42%;
  .pricing {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const PackagesCard = ({ data }: any) => {
  const { id, slug, title, subtitle, price, actual_price, moreLink } = data;
  return (
    <StyledPackageCard className="card shadow">
      <div className="green semibold">{title}</div>
      <div className="pricing">
        <div className="link-text">{}</div>
        {/* <div className="body-text blue semibold"> &#8377; {actual_price}</div> */}
      </div>
      <Link to={`../packages/${slug}`} className="link-text regular">
        View More &gt;
      </Link>
    </StyledPackageCard>
  );
};

const Packages = () => {
  // http://localhost:5123/api/tourism/package/display?page=1&size=50
  const [data, setData] = useState([]);

  useEffect(() => {
    getPackagesHome().then((res) => {
      setData(res.rows);
    });
  }, []);
  return (
    <div className="packages">
      <div className="heading pt-4 blue bold">Packages</div>
      <CardsWrapper>
        {data.map((item, idx) => {
          return <PackagesCard key={idx} data={item} />;
        })}
      </CardsWrapper>
    </div>
  );
};

export default Packages;
