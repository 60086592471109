import { Helmet } from "react-helmet";
import HelpForm from "../doctors/HelpForm";

const Enquiry = () => (
  <>
    <Helmet>
      <title>Get a Free Enquire & Consultation Today for Plan Your Medical Tourism in India | Plan Medical Tourism</title>
      <meta name="description" content="Consult with health experts, compare hospitals, and discover the best treatments. Start your journey to health and wellness today with Plan Medical Tourism." />
      <link rel="canonical" href="https://planmedicaltourism.com/enquiry" />
      <meta name="keywords" content="" />
    </Helmet>
    <HelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
  </>
);

export default Enquiry;
