import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk action creator
export const fetchHospitalById = createAsyncThunk(
  "hospital/fetchById",
  async (slug: string, { fulfillWithValue, rejectWithValue }) => {
    const response = await fetch(
      `${API_BASE_URL}/api/tourism/hospital/slug/${slug}`
    );
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("Resource not found");
      } else {
        throw new Error("An error occurred");
      }
    }

    return response.json();
  }
);

export const fetchHospitalGallery = createAsyncThunk(
  "hospital/gallery/fetch",
  async (hosId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/hospital/gallery/get/all/${hosId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAssociateDoctors = createAsyncThunk(
  "hospital/associatedDr/fetch",
  async (hosId: number, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/hospital/associate/doctors/${hosId}`
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue("");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associateDoctorToHospital = createAsyncThunk(
  "hospital/associate/doctor",
  async (
    {
      associate,
      ...payload
    }: { doctor_id: number; hospital_id: number; associate: boolean },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const associateKey = associate ? "associate" : "dissociate";
      const { data, success, message } = await fetch(
        `${API_BASE_URL}/api/tourism/doctor/${associateKey}/hospital`,
        {
          method: "POST",
          body: JSON.stringify(payload),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
