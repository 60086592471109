import { createSlice } from "@reduxjs/toolkit";

const languagesSlice = createSlice({
  name: "languages",
  initialState: [], 
  reducers: {
    setLanguages: (state, action) => {
      return action.payload; 
    },
  },
});

export const { setLanguages } = languagesSlice.actions;
export default languagesSlice.reducer;
