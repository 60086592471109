import today from "@/utils";
import { useField } from "formik";

const DateInput = (props: any) => {
  const { name, placeholder, max = today } = props;
  const [{ onChange, onBlur }, { value }] = useField(name);
  const handleKeyPress = (event : any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
    }
  };
  return (
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      onKeyDown ={handleKeyPress}
      onBlur={(e) => {
        e.target.type = "text";
        onBlur(e);
      }}
      value={value}
      onFocus={(e) => (e.target.type = "date")}
      max={max}
    />
  );
};

export default DateInput;
