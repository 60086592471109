import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { getTranslators } from "./interpreter-actions";

interface Translator {
    translatorCode: number;
    yearsofExperience: string | null;
    experinceSummary: string | null;
  }

interface TranslatorsState {
  data: Translator[];
  loading: boolean;
  error: string | null;
}

const translatorsSlice = createSlice({
    name: "translators",
    initialState: { data: [], loading: false, error: null } as TranslatorsState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getTranslators.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getTranslators.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.map((translator: Translator) => {
          return {
            translatorCode: translator.translatorCode,
            yearsofExperience: translator.yearsofExperience || "N/A",
            experinceSummary: translator.experinceSummary || "No summary available",
          };
        });
      });
      builder.addCase(getTranslators.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
    },
  });
  
export const selectTranslators = (state: RootState) => state.translators.data;

export default translatorsSlice.reducer;
