import styled from "styled-components";
// import TravelHeading from "./TravelHeading";
// import TravelSearch from "./TravelSearch";
// import TravelInfo from "./TravelInfo";
// import TravelAirport from "./TravelAirport";
// import Accommodation from "./Accommodation";
// import SightSeeing from "./SightSeeing";
// import CurrencyCalc from "./CurrencyCalc";
// import Translator from "./Translator";
// import TravelInfoSection from "./TravelInfoSection";
// import Journey from "../home/Journey";
import HelpForm from "../doctors/HelpForm";
import EVisa from "./EVisa";
import { Helmet } from "react-helmet";
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const TravelServices = () => {
  return (
    <BodyWrapper>
       <Helmet>
        <title>Plan Medical Tourism - Your Medical Tourism Partner</title>
        <meta name="description" content="Plan Medical Tourism, your one-stop destination for Medical Tourism and personalized healthcare experiences in India. Start making plans for your medical trip now!" />
        <link rel="canonical" href="https://planmedicaltourism.com/travel-services" />
        <meta name="keywords" content="" />
      </Helmet>
      {/* <TravelHeading />
      <TravelSearch />
      <TravelInfo />
      <TravelAirport />
      <Accommodation />
      <SightSeeing />
      <CurrencyCalc />
      <Translator /> */}
      {/* <TravelInfoSection /> */}
      <EVisa />
      {/* <Journey /> */}
      <div className="d-none d-sm-block">
        <HelpForm />
      </div>
      <div className="d-block d-sm-none">
        <HelpForm isCompact={true} />
      </div>
    </BodyWrapper>
  );
};

export default TravelServices;
