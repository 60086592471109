import { useSelector } from "react-redux";
import { RootState } from "@/store";
import styled from "styled-components";

const StyledDoctorDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: baseline;
    gap: 20px;
    .hospital-name {
      font-size: 36px;
      word-break: break-all;
    }

    .details {
      flex-grow: 1;
      text-align: left;
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    .details p{
      text-align: justify;
      padding: 0px 0.8em;
    }
  }
`;
const DoctorAbout = () => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  if (doctor) {
    const { about, first_name, last_name, salutation } = doctor;
    return (
      <StyledDoctorDetail>
        <div className="details">
          <div className="title blue bold heading">About</div>
          <div className="">
            <p dangerouslySetInnerHTML={{ __html: about }}></p>
          </div>
        </div>
      </StyledDoctorDetail>
    );
  }
  return null;
};

export default DoctorAbout;
