import { useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import FormPopup from "./EntityPopup";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import {
  deleteEntity,
  getAll,
  getOptions,
  viewEntity,
} from "@/features/admin/admin-actions";
import { ColDef, GridApi } from "ag-grid-community";
import styled from "styled-components";
import EnquiryPopup from "../popups/EnquiryPopup";
import BookingPopup from "../popups/BookingPopup";
import AmbulanceEnquiryPopup from "../popups/AmbulanceEnquiryPopup";
import { confirm } from "react-bootstrap-confirmation";
import ImageViewer from "../popups/ImageViewer";
import EnquiryHistoryPopup from "../popups/EnquiryHistoryPopup";
import DrAssociation from "../popups/DrAssociation";
import SubEntity from "../popups/SubEntityPopup";
import GalleryPopup from "../popups/GalleryPopup";
import ReactPaginate from "react-paginate";
import "./pagination.scss";
import "./admin.grid.scss";
import Filters from "../Filters";
import useRoles from "@/components/hooks/useRoles";
export const ButtonContainer = styled.div`
  gap: 20px;
  margin: 0px;
  padding: 0px 0px 16px 0px;
  background: #f5f5f5;
  .filters {
    flex-grow: 1;
  }
  .buttons {
    display: flex;
    gap: 24px;
  }
`;
const itemsPerPage = 50;

export const StyledActionButton = styled(Button)`
  --mo-btn-active-bg: var(--blue);
  --mo-btn-active-border-color: white;
  border: 2px solid var(--blue);
  background: #f5f5f5;
  color: var(--blue);
  width: 130px;
  height: 50px;
  font-size: 16px;
  &:hover {
    color: white;
    background: var(--blue);
    border: 1px solid white;
  }
  &.active {
    color: white;
    background: var(--blue);
    border: 1px solid white;
  }
  &:disabled {
    border: 2px solid var(--blue);
    background: #f5f5f5;
    color: var(--blue);
  }
`;

const AdminContent = styled.div``;
const Entities = () => {
  const {
    userInfo: { first_name , id },
  } = useSelector((state: RootState) => state.auth);
  let { entity: entityParam } = useParams();
  const [entity, setEntity] = useState("");

  const {
    data: storeData,
    entities,
    loading,
  } = useSelector((state: RootState) => state.admin) as Record<string, any>;

  const isAdmin = useRoles("ADMIN");
  const isAgent = useRoles("FAREAGENT");
  const { [entity]: data } = storeData;
  
  const { title = "", hideActionButtons = false } = entity
    ? entities[entity]
    : {};

  const { edit = "", hideActionButtonsEdit = true } = entity
  ? entities[entity]
  : {};

  const dispatch = useDispatch<AppDispatch>();

  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setData] = useState<any>();
  const [showSubEntityPopup, setShowSubEntityPopup] = useState(false);
  const [showGalleryPopup, setShowGalleryPopup] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showEnquiryHistory, setShowEnquiryHistory] = useState(false);
  const [showAssociationPopup, setShowAssociationPopup] = useState(false);
  const [rowData, setRowData] = useState<{
    rows: any[];
    totalPages: number;
    currentPage: number;
    count: number;
  }>({ rows: [], totalPages: 0, currentPage: 0, count: 0 });
  const [gridOptions, setGridOptions] = useState<any>();
  const [selected, setSelected] = useState<any>(null);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [enquiryStatus, setEnquiryStatus] = useState({
    status: "",
    id: -1,
  });
  const [bookingStatus, setBookingStatus] = useState({
    data: "",
    status: "",
    id: -1,
  });

  const [bookingDataStatus, setBookingDataStatus] = useState({
    data: "",
    id: -1,
  });
  const [ambulanceEnquiryStatus, setAmbulanceEnquiryStatus] = useState({
    status: "",
    id: -1,
  });
  const [appointment_id, setAppointment_id] = useState("");
  const [bookingStatusPopup, setBookingStatusPopup] = useState(false);
  const [enquiryStatusPopup, setEnquiryStatusPopup] = useState(false);
  const [ambulanceEnquiryStatusPopup, setAmbulanceEnquiryStatusPopup] =
    useState(false);
  const [filterQS, setFilterQS] = useState("");

  useEffect(() => {
    if (loading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  });

  useEffect(() => {
    if (entityParam) {
      setEntity(entityParam);
    }
  }, [entityParam]);

  useEffect(() => {
    if (entity) {
      setRowData((vals) => ({
        ...vals,
        currentPage: 1,
        rows: [],
      }));
      dispatch(getAll({ entity, page: 1, size: itemsPerPage }));
      setSelected(null);
      setFilterQS("");
    }

    return () => {
      setGridOptions(null);
    };
  }, [entity, dispatch, gridApi]);

  useEffect(() => {
    if (entity && data && !data.optionsApiStatus) {
      dispatch(getOptions(entity));
    }
  }, [entity, data, dispatch]);
  useEffect(() => {
    (async () => {
      if (data && data.rows) {
        try {
          const { columnDefs, ...rest } = await import(
            `../grid-options/${entity}`
          );
          if (!isAdmin && entity === "enquiry") {
            const newColDefs = columnDefs.filter((column: ColDef<any>) => {
              return column.field !== "contact_no";
            });
            setGridOptions({
              ...rest,
              columnDefs: newColDefs,
            });
          } else {
            setGridOptions({
              ...rest,
              columnDefs,
            });
          }

          // }
        } catch (e) {
          console.error(
            `Entity "${entity}" does not have a grid options configured`
          );
        }
        if (isAgent && entity === "agent") {
           // State to hold the filtered data
            const filterData = data.rows.filter((item : any) => item.user_id === id);
           let newData = { rows: filterData, totalPages: 1, currentPage: 1, count: 1 }
           return setRowData(newData);
        }
        setRowData(data);
      }
    })();
  }, [data, entity, isAdmin]);

  const handleShow = useCallback(() => {
    setShowPopup(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowPopup(false);
    setEnquiryStatusPopup(false);
    setAmbulanceEnquiryStatusPopup(false);
    setBookingStatusPopup(false);
    setShowSubEntityPopup(false);
    setShowImagePopup(false);
    setShowEnquiryHistory(false);
    setShowAssociationPopup(false);
    setShowGalleryPopup(false);
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridApi?.getSelectedRows();
    setSelected(selectedRows?.at(0) as any);
  };
  const onGridReady = (e: any) => {
    setGridApi(e.api);
    e.api.sizeColumnsToFit();
    e.columnApi.resetColumnState();
  };

  const handleEdit = async () => {
    await dispatch(viewEntity({ name: entity, id: selected?.id! }));
    setShowPopup(true);
  };

  const handleDelete = async () => {
    const doDelete = await confirm("Are you sure you want to delete?", {
      okText: "delete",
    });
    if (doDelete) {
      await dispatch(deleteEntity({ name: entity, id: selected?.id! })).then(
        () => {
          setSelected(null);
        }
      );
    }
  };

  const enquiryUpdate = (id: number, status: string, statusId: string) => {
    setEnquiryStatus({
      status: statusId,
      id,
    });
    setEnquiryStatusPopup(true);
  };

  const bookingUpdate = (id: number, data: string, statusId: string) => {
    setBookingStatus({
      data,
      status: statusId,
      id,
    });
    setBookingStatusPopup(true);
  };

  const AmbulanceEnquiryUpdate = (
    id: number,
    status: string,
    statusId: string
  ) => {
    setAmbulanceEnquiryStatus({
      status: statusId,
      id,
    });
    setAmbulanceEnquiryStatusPopup(true);
  };
  const subEntityPopup = (data: any, conf: any) => {
    setData({
      ...data,
      conf,
    });
    setShowSubEntityPopup(true);
  };

  const showGallery = (data: any, conf: any) => {
    setData({
      ...data,
      conf,
    });
    setShowGalleryPopup(true);
  };

  const showProfilePic = (data: any, urlField: string) => {
    setData({
      ...data,
    });
    setShowImagePopup(true);
  };

  const showEnqHistory = (data: any) => {
    setData({
      ...data,
    });
    setShowEnquiryHistory(true);
  };
  const showAssociation = (data: any) => {
    setData({
      ...data,
    });
    setShowAssociationPopup(true);
  };

  const getRowId = (params: any) => params.data.id;

  const handlePageClick = async (event: any) => {
    dispatch(getAll({ entity, page: event.selected + 1, size: itemsPerPage }));
  };

  const onFilterChange = (data: any) => {
    if (data) {
      const qs = "type=" + data;
      setFilterQS(qs);
    }
  };

  useEffect(() => {
    dispatch(getAll({ entity, page: 1, size: itemsPerPage, qs: filterQS }));
  }, [entity, filterQS, dispatch]);

  return (
    <AdminContent className="d-flex flex-column" style={{ height: "98%" }}>
      {/* <h3 style={{ textAlign: "left" }}>{title}</h3> */}

      <ButtonContainer className="d-flex justify-content-between">
        <div className="filters">
          <Filters entity={entity} setFilterQS={setFilterQS} data={data} />
        </div>

        <div className="buttons">
          {!hideActionButtons && (
            <>
              {isAdmin && (
                <StyledActionButton variant="primary" onClick={handleShow}>
                  + Add
                </StyledActionButton>
              )}
              {isAdmin && hideActionButtonsEdit && (
              <StyledActionButton
                onClick={handleEdit}
                disabled={selected == null}
              >
                Edit
              </StyledActionButton>
              )}
              {isAdmin && (
                <StyledActionButton
                  onClick={handleDelete}
                  disabled={selected == null}
                >
                  - Delete
                </StyledActionButton>
              )}
            </>
          )}
        </div>
      </ButtonContainer>

      <div
        className="ag-theme-alpine ag-theme-pmt"
        style={{ width: "100%", height: "calc(100% - 50px)" }}
      >
        <AgGridReact
          {...gridOptions}
          rowSelection="single"
          getRowId={getRowId}
          rowData={rowData.rows} // Row Data for Rows
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          suppressRowTransform={true}
          suppressRowClickSelection={true}
          getRowHeight={() => 60}
          loadinf
          context={{
            bookingUpdate,
            AmbulanceEnquiryUpdate,
            enquiryUpdate,
            subEntityPopup,
            showProfilePic,
            showEnqHistory,
            showAssociation,
            showGallery,
          }}
        />
        <div className="paginate">
          <ReactPaginate
            forcePage={rowData.currentPage - 1}
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={rowData.totalPages}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <FormPopup
        show={showPopup}
        handleClose={handleClose}
        entity={entityParam}
      />
      {enquiryStatusPopup && (
        <EnquiryPopup
          show={enquiryStatusPopup}
          handleClose={handleClose}
          entity={entityParam}
          status={enquiryStatus}
        />
      )}
      {bookingStatusPopup && (
        <BookingPopup
          show={bookingStatusPopup}
          handleClose={handleClose}
          entity={entityParam}
          status={bookingStatus}
        />
      )}
      {ambulanceEnquiryStatusPopup && (
        <AmbulanceEnquiryPopup
          show={ambulanceEnquiryStatusPopup}
          handleClose={handleClose}
          entity={entityParam}
          status={ambulanceEnquiryStatus}
        />
      )}
      {showSubEntityPopup && (
        <SubEntity
          show={showSubEntityPopup}
          handleClose={handleClose}
          entity={entityParam}
          data={selectedData}
        />
      )}

      {showGalleryPopup && (
        <GalleryPopup
          show={showGalleryPopup}
          handleClose={handleClose}
          entity={entityParam}
          data={selectedData}
        />
      )}
      {showImagePopup && (
        <ImageViewer
          show={showImagePopup}
          handleClose={handleClose}
          entity={entityParam}
          data={selectedData}
        />
      )}
      {showEnquiryHistory && (
        <EnquiryHistoryPopup
          show={showEnquiryHistory}
          handleClose={handleClose}
          data={selectedData}
        />
      )}
      {showAssociationPopup && (
        <DrAssociation
          show={showAssociationPopup}
          handleClose={handleClose}
          data={selectedData}
        />
      )}
    </AdminContent>
  );
};

export default Entities;
