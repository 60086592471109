import styled from "styled-components";

export const NavWrapper = styled.div`
  ul {
    margin: 0;
    padding: 0;
    li {
      text-align: left;
      list-style-type: none;
      height: 68px;
      border-top: 1px solid rgb(202, 202, 202);
      font-size: 20px;
      &:first-child {
        border-top: none;
      }
      a {
        padding: 19px;
        display: block;
        &:hover,
        &:active {
          background: #ecf6fd;
        }
      }
    }
  }
`;

const HospitalNav = () => {
  return (
    <NavWrapper className="card shadow">
      <ul>
        <li>
          <a href="#About">About the hospital</a>
        </li>
        <li>
          <a href="#Teams">Teams & Specialties</a>
        </li>
        <li>
          <a href="#Doctors">Doctors</a>
        </li>
        <li>
          <a href="#Treatment">Treatments</a>
        </li>
        <li>
          <a href="#Facilities">Facilities</a>
        </li>
        <li>
          <a href="#Photos">Gallery</a>
        </li>
        {/* <li>
          <a href="#NearByHotels">Near by hotels & Guest House</a>
        </li> */}
      </ul>
    </NavWrapper>
  );
};

export default HospitalNav;
