import { ProgressBar } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import DoctorForm1 from "./DoctorForm1";
import DoctorForm2 from "./DoctorForm2";
import { registerDoctor, saveEntityForDr } from "@/features/web/web-actions";
import { FormikValues } from "formik";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { setToken } from "@/features/auth/authSlice";
const saveList = [
  { formikValue: "awards", key: "award" },
  { formikValue: "publications", key: "publication" },
  { formikValue: "memberships", key: "membership" },
  { formikValue: "achievements", key: "achievement" },
  { formikValue: "qualifications", key: "qualification" },
  { formikValue: "experiences", key: "experience" },
];

const processForSave = async (list: any, id: any, entity: string) => {
  const promises = list.map((body: any) =>
    Object.values(body).filter(Boolean).length === 0
      ? Promise.resolve({ success: true })
      : saveEntityForDr({ body, id, entity })
  );
  const res = await Promise.all(promises);
  return new Promise((resolve, reject) => {
    if (!res.some(({ success }) => success === false)) {
      resolve(true);
    } else {
      reject("failed");
    }
  });
};

const intialValues = {
  salutation: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  city: "",
  country: "",
  isd: "",
  contact_no: "",
  profile_url: "",
  address: "",
  number_of_procedures_performed: "",
  success_rate: "",
  about: "",
  license_number: "",
  practice_start_date: "",
  state: "",
  specialization: [],
  treatment: [],
};

const DoctorFormWizard = ({ style }: any) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [id, setId] = useState<null | string>();
  const [doctorFormData, setDoctorFormData] =
    useState<FormikValues>(intialValues);
  const [genericError, setGenericError] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [currentStep]);

  const finalSubmit = useCallback(
    (additionalValues: any) => {
      registerDoctor(doctorFormData).then((resp: any) => {
        if (!resp.success) {
          if (resp.responseCode === 422) {
            setErrors({
              ...resp.data,
            });
          } else {
            setGenericError(resp.message);
          }
        } else {
          const { id, token } = resp.data;
          // resetForm();
          dispatch(setToken(token));
          setId(id);

          Promise.all(
            saveList.map(({ formikValue, key }) =>
              processForSave(
                (additionalValues as FormikValues)[
                  formikValue as keyof FormikValues
                ],
                id,
                key
              )
            )
          ).then(() => {
            dispatch(setToken(null));
            setCurrentStep(3);
          });
        }
      });
    },
    [doctorFormData, dispatch]
  );

  return (
    <>
      {currentStep === 1 && (
        <div style={{ display: currentStep === 1 ? "block" : "none" }}>
          <div className="text-center">
            <p className="mb-0">Welcome! </p>
            <p className="pb-3">
              Please help us know you better for a better experience!
            </p>
            <ProgressBar className="progressBar" now={50} />
          </div>
          <DoctorForm1
            doctorFormData={doctorFormData}
            setDoctorFormData={setDoctorFormData}
            genericError={genericError}
            errors={errors}
            nextStep={() => {
              // setId(id);
              setCurrentStep(2);
            }}
          />
        </div>
      )}
      {currentStep === 2 && (
        <>
          <div className="text-center">
            <p className="mb-0">Yay last one! </p>
            <p className="pb-3">
              Please tell us more about your academic and professional
              achievements!
            </p>
            <ProgressBar className="progressBar" now={100} />
          </div>
          <DoctorForm2
            id={id}
            finalSubmit={finalSubmit}
            prevStep={() => setCurrentStep(1)}
            genericError={genericError}
            errors={errors}
            nextStep={() => {
              setCurrentStep(3);
            }}
          />
        </>
      )}
      {currentStep === 3 && <Navigate to="../partner/thank-you" />}
    </>
  );
};

export default DoctorFormWizard;
