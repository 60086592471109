import React from "react";
import styled from "styled-components";
import AmbulanceHelpForm from "./AmbulanceHelpForm";
import img1 from "@/assets/amulance-cervices-img/Hearse-Image.png"

const StyledSection = styled.div`
  .hearse_amb p {
    text-align: left;
    padding: 10px 0px;
    line-height: 1.7;
  }
  .hearse_amb ol li {
    text-align: left;
    padding: 10px 0px;
    line-height: 1.7;
  }
  @media only screen and (max-width: 768px) {
    .hearse_amb{
      padding: 1.5rem;
    }
    .hearse_amb p{
     text-align: justify;
    }
  }
`;

function HearseAmbulance() {
  return (
    <StyledSection>
      <div className='hearse_amb'>
        <h1 className='blue heading bold'>Hearse Ambulance Service </h1>

        <div className="row section1 ">
                <div className="col-md-6">
                    <img src={img1} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 justify">
        <p>
          When a loved one passes away, it's important to ensure their body is
          transported with respect and care. We provide affordable
          transportation of dead bodies by road, with refrigerated ambulances to
          prevent decomposition until the funeral. We handle all necessary
          paperwork and follow relevant laws to provide a convenient and
          inexpensive service during a difficult time. We provide these services
          throughout India, including major cities. Our team of professionals
          handles the body of your loved ones with utmost care and respect as we
          take it back to their hometown.
        </p>

                </div>
            </div>

        <p>
          Our ambulances are equipped with necessary equipment, including
          refrigeration to prevent decomposition until the funeral. We
          understand that losing a loved one is a challenging and emotional
          time, and we strive to help those who are grieving by providing a
          dignified and compassionate service.
        </p>

        <p>
          There are various laws and paperwork requirements for the
          transportation of dead bodies, but our team handles everything to
          provide a hassle-free service. Our aim is to make this difficult time
          a little easier for you by providing a cost-effective and efficient
          transportation service.
        </p>

        <p>
          If you need to transport the body of a loved one, please contact us.
          We are available 24/7 to provide assistance and support during this
          challenging time.
        </p>

        <h2 className='blue sub-heading bold'>
          Steps to Transport the Deceased Bodies
        </h2>
        <ol>
        <li>In the event of someone's passing, please do not hesitate to reach out to our ambulance emergency number for
            prompt assistance.</li>
        <li>We assure you that our ambulance services will reach any location in Mumbai within an hour of receiving your
            call.</li>
        <li>To prevent the body from decomposing during transportation to another state or city, we ensure that it is kept
            refrigerated.</li>
        <li>We offer transportation services for the deceased to their hometowns, not just in Mumbai but also throughout
            India.</li>
        <li>Transporting deceased bodies requires adherence to specific guidelines and legal procedures.</li>
        <li>We ensure that our vehicles are thoroughly cleaned and sanitized both before and after the transportation.</li>
    </ol>

      </div>
      <section id="Ambulance">
      <AmbulanceHelpForm style={{ maxWidth: 842, margin: "0 auto" }}/>
      </section>
    </StyledSection>
  );
}

export default HearseAmbulance;