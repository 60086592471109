import { useEffect, useState } from "react";
import React from "react";
import { Card } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import ReactEcharts from "echarts-for-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "@/store";
import stethoscope from "@/assets/images/stethoscope.png";
import hospital from "@/assets/images/hospital.png";
import treatment from "@/assets/images/treatment.png";
import patient from "@/assets/images/patient.png";
import styled from "styled-components";
import { getOptions } from "@/features/admin/admin-actions";

const StyleWrappers = styled.div`
  height: 78vh;
  overflow-x: hidden;
  .dashCard {
    box-shadow: 0px 4px 14px 0px #2BACE229;
    border:none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0em 1em;
  }
  .dashCardTitle{
    font-size:24px;
    font-weight:700;
    text-align:left;
    color: #2bace7;
  }
  .dashCardTitle p{
    margin: 0px;
    left: 40px;
    position: relative;
  }
  .dashCardTitle span{
    margin: 0px;
    left: 40px;
    position: relative;
    font-size:24px;
    font-weight:800;
  }
  .chartCard{
    border:none;
    box-shadow: 0px 4px 16px 0px #2BACE224;
  }
  .lineChart{
    height: 300px;
    width: 100%'
  }
`;

interface AnimatedCountProps {
  count: number;
}

const AnimatedCount: React.FC<AnimatedCountProps> = ({ count }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const props = useSpring({
    from: { value: 0 },
    to: { value: inView ? count : 0 },
    config: { duration: 2500 },
  });

  return (
    <animated.div ref={ref}>
      {props.value.to((val) => Math.floor(val))}
    </animated.div>
  );
};
function Dashboard() {
  const entityName = "enquiry";
  const [counts, setCounts] = useState({
    doctor_count: 0,
    hospital_count: 0,
    treatment_count: 0,
    patient_count: 0,
  });

  const { data } = useSelector((state: RootState) => state.admin);
  const options = data[entityName]?.options || {};
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getOptions("enquiry"));
  }, [dispatch]);

  useEffect(() => {
    if (options.count_details) {
      setCounts(options.count_details);
    }
  }, [options]);

  // Dummy data for multiple line charts
  const lineChartsData = {
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: Array.from({ length: 100 }, (_, i) => i),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        smooth: true,
        symbol: "none",
        data: Array.from({ length: 100 }, (_, i) => i * 2),
        name: "Line Chart 1",
      },
      {
        type: "line",
        smooth: true,
        symbol: "none",
        data: Array.from({ length: 100 }, (_, i) => Math.sin(i / 5)),
        name: "Line Chart 2",
      },
      {
        type: "line",
        smooth: true,
        symbol: "none",
        data: Array.from({ length: 100 }, (_, i) => Math.cos(i / 10)),
        name: "Line Chart 3",
      },
    ],
  };

  // Dummy data for the wave chart
  const waveChartData = {
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: Array.from({ length: 100 }, (_, i) => i),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        smooth: true,
        symbol: "none",
        data: Array.from({ length: 100 }, (_, i) => Math.sin(i / 5)),
      },
    ],
  };

  const barChartData = {
    xAxis: {
      type: "category",
      data: [
        "Category 1",
        "Category 2",
        "Category 3",
        "Category 4",
        "Category 5",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [120, 200, 150, 80, 70],
        type: "bar",
      },
    ],
  };

  const pieChartData = {
    series: [
      {
        type: "pie",
        radius: "50%",
        data: [
          { value: 335, name: "Category 1" },
          { value: 310, name: "Category 2" },
          { value: 234, name: "Category 3" },
          { value: 135, name: "Category 4" },
          { value: 1548, name: "Category 5" },
        ],
      },
    ],
  };

  return (
    <StyleWrappers>
      <div>
        <div className="container">
          <p className="sub-heading justify pt-4 ps-4">Dashboard Statistics</p>
          <div className="row">
            <div className="col-md-3">
              <Card className="dashCard" title="Doctors">
                <Card.Img variant="center" src={stethoscope} />
                <div className="dashCardTitle">
                  <p>Doctors</p>
                  <span>
                    <AnimatedCount
                      count={
                        (Array.isArray(counts)
                          ? counts[0]?.doctor_count
                          : 0) as number
                      }
                    />
                  </span>
                </div>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="dashCard" title="Hospitals">
                <Card.Img variant="center" src={hospital} />
                <div className="dashCardTitle">
                  <p>Hospitals</p>
                  <span>
                    <AnimatedCount
                      count={
                        (Array.isArray(counts)
                          ? counts[0]?.hospital_count
                          : 0) as number
                      }
                    />
                  </span>
                </div>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="dashCard" title="Treatments">
                <Card.Img variant="center" src={treatment} />
                <div className="dashCardTitle">
                  <p>Treatments</p>
                  <span>
                    <AnimatedCount
                      count={
                        (Array.isArray(counts)
                          ? counts[0]?.treatment_count
                          : 0) as number
                      }
                    />
                  </span>
                </div>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="dashCard" title="Patients">
                <Card.Img variant="center" src={patient} />
                <div className="dashCardTitle">
                  <p>Patients</p>
                  <span>
                    <AnimatedCount
                      count={
                        (Array.isArray(counts)
                          ? counts[0]?.patient_count
                          : 0) as number
                      }
                    />
                  </span>
                </div>
              </Card>
            </div>
            {/* <div className="col-md-12">
              <p className="sub-heading justify pt-4 ps-4">
                Statistics for business analytics
              </p>
              <Card className="chartCard" title="Multiple Line Charts">
                <ReactEcharts option={lineChartsData} className="lineChart" />
              </Card>
            </div> */}
            {/* <div className="col-md-6">
              <Card className="chartCard mt-5" title="Bar Chart">
                <ReactEcharts option={barChartData} className="lineChart" />
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="chartCard mt-5" title="Pie Chart">
                <ReactEcharts option={pieChartData} className="lineChart" />
              </Card>
            </div> */}
          </div>
        </div>
      </div>
    </StyleWrappers>
  );
}

export default Dashboard;
