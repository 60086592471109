import "./ImageGallery.css";
import { getGroupAwards } from "@/features/web/web-actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState, AppDispatch } from '../../store';

const ImageGallery = () => {
  const [hoveredImage, setHoveredImage] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { images, loading, error } = useSelector((state: RootState) => state.groupAward);
  const [data, setData] = useState<{ id: string; image_url: string; alt_tag: string }[]>([]);

  useEffect(() => {
    dispatch(getGroupAwards({ categoryId: 4 })).then((res: any) => {
      setData(res.payload.rows);
    });
  }, [dispatch]);

  const handleImageHover = (id: string | null) => {
    setHoveredImage(id);
  };

  return (
    <div id='gallery' className='image-gallery about_us_div'>
      {/* <h1 className="blue heading bold" style={{padding: '2em 0em'}}>Award certificates</h1> */}
      <div className='row gx-4'>
        {data.map(({ id, image_url , alt_tag }) => (
          <div key={id} className='col-md-4'>
            <div
              className={`image-wrapper ${hoveredImage === id ? "hovered" : ""}`}
              onMouseEnter={() => handleImageHover(id)}
              onMouseLeave={() => handleImageHover(null)}
            >
              <img height={270} src={image_url} className='img-responsive' alt={alt_tag} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
