import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const registerUser = createAsyncThunk(
  "auth/register",
  async (postdata: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/tourism/auth/register`,
        {
          method: "POST",
          body: JSON.stringify(postdata),
        }
      ).then((data) => data.json());
      if (success) {
        return fulfillWithValue(data);
      }
      return rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/login",
  async (
    { email, password, rememberMe }: any,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    };

    const { data, message, success } = await fetch(
      `${API_BASE_URL}/api/tourism/auth/login`,
      requestOptions
    ).then((data) => data.json());

    if (success) {
      if (rememberMe) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(data.user));
      }

      return fulfillWithValue(data);
    }

    return rejectWithValue(message);
  }
);
