import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { AgGridReact } from "ag-grid-react";
import { getEnqById } from "@/features/admin/admin-actions";
import { ValueFormatterParams } from "ag-grid-community";
import { useSelector } from "react-redux";

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;
function nameFormatter({ value }: ValueFormatterParams) {
  return `${value.first_name || ""} ${value.last_name || ""}`;
}

const dateFormatter = ({ value }: ValueFormatterParams) => {
  if (!value) return "";
  return new Date(value).toLocaleDateString("en-IN");
};

const EnquiryHistoryPopup = ({ show, handleClose, data }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [rowData, setRowData] = useState([]);
  const { data: storeData } = useSelector(
    (state: RootState) => state.admin
  ) as Record<string, any>;

  const options = useMemo(
    () =>
      storeData.enquiry.options
        ? storeData.enquiry.options.enquiryStatus
        : null,
    [storeData.enquiry]
  );

  const statusFormatter = ({ value }: ValueFormatterParams) => {
    return options && options[value];
  };

  const colDefs = [
    { field: "User", valueFormatter: nameFormatter },
    { field: "enquiry_status", valueFormatter: statusFormatter },
    { field: "remarks" },
    { field: "createdAt", valueFormatter: dateFormatter },
  ];
  useEffect(() => {
    if (data && data.id) {
      dispatch(getEnqById(data.id)).then((response) => {
        const { data } = response.payload as any;
        if (data && data.EnquiryRemarks) {
          setRowData(data.EnquiryRemarks);
        }
      });
    }
  }, [data]);

  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>History</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="ag-theme-alpine" style={{ width: "100%", height: 200 }}>
          <AgGridReact
            columnDefs={colDefs}
            rowSelection="single"
            rowData={rowData} // Row Data for Rows
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          />
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default EnquiryHistoryPopup;
