import { configureStore } from "@reduxjs/toolkit";
import authReducer from "@/features/auth/authSlice";
import bannerReducer from "@/features/home/banner-slice";
import webReducer from "@/features/web/web-slice";
import doctorReducer from "@/features/web/doctor-slice";
import hospitalReducer from "@/features/web/hospital-slice";
import treatmentReducer from "@/features/web/treatment-slice";
import adminReducer from "@/features/admin/admin-slice";
import agentReducer from "@/features/agent/admin-slice";
import languagesReducer from "@/features/web/languagesSlice";
import translatorsReducer from "@/features/web/translators-slice";
import interpretersReducer from "@/features/web/interpreters-slice";
import galleryReducer from "@/features/web/gallerySlice"
import destinationReducer from "@/features/web/destinationSlice"
import popularCitySlice from "./features/web/popularCity-slice";
import popularCityByIdSlice from "./features/web/popularCityById-slice";
import groupAwardSlice from "./features/web/groupAward-slice";
import popularTreatmentsSlice from "./features/web/popularTreatments-slice";
import staticPagesSlice from "./features/web/staticPagesSlice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    agent : agentReducer,
    auth: authReducer,
    banner: bannerReducer,
    web: webReducer,
    doctor: doctorReducer,
    hospital: hospitalReducer,
    treatment: treatmentReducer,
    languages: languagesReducer,
    translators: translatorsReducer,
    interpreters: interpretersReducer,
    gallery: galleryReducer,
    destination: destinationReducer,
    staticPages: staticPagesSlice,
    groupAward: groupAwardSlice,
    popularTreatments: popularTreatmentsSlice,
    popularCities: popularCitySlice,
    popularCitiesById: popularCityByIdSlice
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
