import { createSlice } from "@reduxjs/toolkit";
import { fetchCitiesTopTreatment, fetchDoctorTopTreatment, fetchHospitalTopTreatment, fetchTreatmentById, fetchTreatmentBySlug } from "./treatment-actions";

const treatmentSlice = createSlice({
  name: "treatment",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
    topCities: [],
    topHospitals: [],
    topDoctors: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTreatmentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchTreatmentBySlug.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCitiesTopTreatment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topCities = action.payload;
        state.error = null;
      })
      .addCase(fetchHospitalTopTreatment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topHospitals = action.payload;
        state.error = null;
      })
      .addCase(fetchDoctorTopTreatment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topDoctors = action.payload;
        state.error = null;
      })
      .addCase(fetchTreatmentById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as any;
      });
  },
});

export default treatmentSlice.reducer;
