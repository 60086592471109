import StaticBanner from "@/components/StaticBanner";
import styled from "styled-components";
import TreatmentSection from "../doctors/Treatments";
import BestMedicalTreatments from "./BestMedicalTreatments";
import WhyChooseUs from "../home/WhyChooseUs";
import Blogs from "../home/Blogs";
import HelpForm from "../doctors/HelpForm";
import SearchBar from "../hospitals/SearchBar";
import SliderTestimonial from "../home/SliderTestimonial";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const TreatmentsPage = () => {
  return (
    <BodyWrapper>
       <Helmet>
        <title>Affordable Medical Treatments in India: From Surgery to Cardiology | Plan Medical Tourism</title>
        <meta name="description" content="Discover specialised medical treatments at an affordable cost with Plan Medical Tourism. Top doctors, advanced facilities and personalised care. Plan your medical trip to India today." />
        <link rel="canonical" href="https://planmedicaltourism.com/treatments" />
        <meta name="keywords" content="" />
      </Helmet>
      <SearchBar entity="treatment" />
      <StaticBanner id={4} />
      <TreatmentSection title="Speciality" />
      <BestMedicalTreatments />
      <SliderTestimonial />
      <WhyChooseUs />
      <Blogs />
      <HelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default TreatmentsPage;
