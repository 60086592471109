import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from "@/store";
import { getPatientTranslatorEnquiryData } from "@/features/web/web-actions";
import { useSelector } from "react-redux";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import useRoles from "@/components/hooks/useRoles";
import { getOptions, viewEntity } from "@/features/admin/admin-actions";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

export const StyleSection = styled.div`
 table th{
  color: #2bace2;
  font-weight: 500;
 }
 .entable{
  box-shadow: 0px 4px 20px 0 rgba(148, 148, 148, 0.24) !important;
  padding: 2em 0em;
  border-radius:10px;
 }
 .pagination {
  display: flex;
  justify-content: center;
}
`;

interface EnquiryItem {
    id: number;
    userName: string;
    email: string;
    appointment_type: string;
    medical_problem: string;
    contact_no: string;
    appointment_link: string;
    targetLangName: string;
    sourceLangName: string;
    appointment_date: string;
    appointment_status: string;
  }
  type SelectedPage = {
    selected: number;
  };
  interface IPaginate {
    result: {
      currentPage: number;
      totalPages: number;
    };
    handlePageClick: (event: any) => void;
  }
  interface IPagination {
    rows: any[];
    totalPages: number;
    currentPage: number;
    count: number;
  }

function PatientTranslatorEnquiry() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const entityName = "patient";
  const { userInfo, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isAdmin = useRoles("ADMIN");
  useEffect(() => {
    if (isAuthenticated && userInfo.entityId) {
      dispatch(getOptions(entityName));
      dispatch(viewEntity({ name: "patient", id: userInfo.entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
          } else {
          }
        }
      );
    } else {
      if (isAdmin) {
        navigate("/admin/");
      } else {
        navigate("/");
      }
    }
  }, [dispatch, isAuthenticated, navigate, userInfo, isAdmin]);
  const patientId = userInfo.entityId;
  const [enquiryData, setEnquiryData] = useState<EnquiryItem[]>([]);
  const [result, setResult] = useState<IPagination>({
    rows: [],
    totalPages: 0,
    currentPage: 0,
    count: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getPatientTranslatorEnquiryData({ patientId, page: currentPage, size: 5 })
        );

        if (response.payload) {
          setEnquiryData(response.payload.rows as EnquiryItem[]);
          setTotalPages(response.payload.totalPages);
          setResult({
            rows: response.payload.rows,
            totalPages: response.payload.totalPages,
            currentPage: currentPage,
            count: response.payload.count,
          });
        } else if (response.payload && response.payload.message) {
          console.error("Error fetching data:", response.payload.message);
        } else {
          console.error("Unknown error occurred while fetching data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, patientId, currentPage]);


  const handlePageClick = (selectedPage: SelectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const Paginate = ({ result, handlePageClick }: IPaginate) => {
    const { currentPage, totalPages } = result;
    return (
      <ReactPaginate
        forcePage={currentPage - 1}
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPages}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    );
  };

  return (
    <StyleSection>
      <p className="blue heading bold">Interpreter Enquiries</p>
      <div className="row">
        <div className="col-md-12">
            <div  className="table-responsive entable">
            <table style={{whiteSpace:"nowrap", maxHeight:"400px"}} className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact No</th>
                <th scope="col">Appointment Date</th>
                <th scope="col">Source Language</th>
                <th scope="col">Target Language</th>
                <th scope="col">Appointment Type</th>
                <th scope="col">Medical Problem</th>
                <th scope="col">Appointment Status</th>
              </tr>
            </thead>
            <tbody>
              {enquiryData.map((item, index) => (
                <tr key={item.id}>
                  <th>{index + 1}</th>
                  <td>{item.userName}</td>
                  <td>{item.email}</td>
                  <td>{item.contact_no}</td>
                  <td>{item.appointment_date}</td>
                  <td>{item.sourceLangName}</td>
                  <td>{item.targetLangName}</td>
                  <td>{item.appointment_type}</td>
                  <td>{item.medical_problem}</td>
                  <td>{item.appointment_status}</td>
                </tr>
              ))}
            </tbody>
            </table>
          </div>
        </div>
      </div>
      <Paginate result={result} handlePageClick={handlePageClick} />
    </StyleSection>
  );
}

export default PatientTranslatorEnquiry;
