import { API_BASE_URL } from "@/utils/Env";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTreatmentById = createAsyncThunk(
    "treatment/fetchTreatmentById",
    async (slug: string, { fulfillWithValue, rejectWithValue }) => {
      try {
        const { data, success } = await fetch(
          `${API_BASE_URL}/api/tourism/treatment/slug/${slug}`
        ).then((data) => data.json());
        if (success) {
          return fulfillWithValue(data);
        }
        return rejectWithValue("");
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
export const fetchTreatmentBySlug = createAsyncThunk(
    "treatment/fetchTreatmentBySlug",
    async (id: string, { fulfillWithValue, rejectWithValue }) => {
      try {
        const { data, success } = await fetch(
          `${API_BASE_URL}/api/tourism/treatment/view/${id}`
        ).then((data) => data.json());
        if (success) {
          return fulfillWithValue(data);
        }
        return rejectWithValue("");
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  
export const fetchCitiesTopTreatment = createAsyncThunk(
    "treatment/fetchCitiesTopTreatment",
    async (args, { fulfillWithValue, rejectWithValue }) => {
      try {
        const { data, success } = await fetch(
          `${API_BASE_URL}/api/tourism/hospital/get/top/city/treatment`
        ).then((data) => data.json());
        if (success) {
          return fulfillWithValue(data);
        }
        return rejectWithValue("");
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  
export const fetchHospitalTopTreatment = createAsyncThunk(
    "treatment/fetchHospitalTopTreatment",
    async (treatId: number, { fulfillWithValue, rejectWithValue }) => {
      try {
        const { data, success } = await fetch(
          `${API_BASE_URL}/api/tourism/hospital/get/top/treatment/${treatId}`
        ).then((data) => data.json());
        if (success) {
          return fulfillWithValue(data);
        }
        return rejectWithValue("");
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  
export const fetchDoctorTopTreatment = createAsyncThunk(
    "treatment/fetchDoctorTopTreatment",
    async (treatId: number, { fulfillWithValue, rejectWithValue }) => {
      try {
        const { data, success } = await fetch(
          `${API_BASE_URL}/api/tourism/doctor/get/top/treatment/${treatId}`
        ).then((data) => data.json());
        if (success) {
          return fulfillWithValue(data);
        }
        return rejectWithValue("");
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );