/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import HelpForm from "../doctors/HelpForm";
import DoctorDetail from "./DoctorDetail";
import DoctorAbout from "./DoctorAbout";
import DoctorNav from "./DoctorNav";
import Services from "./Services";
import Specializations from "./Specializations";
import AcademicQualifications from "./AcademicQualifications";
import Experiences from "./Experiences";
import Awards from "./Awards";
import { useEffect , useState} from "react";
import { fetchDoctorById } from "@/features/web/doctor-actions";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import DetailPageLayout from "@/conatiners/layouts/DetailPageLayout";
import { useParams } from "react-router-dom";
import AssociatedHospitals from "./AssociatedHospitals";
import Memberships from "./Memberships";
import Achievements from "./Achievements";
import Publications from "./Publications";
import DoctorIntroVideo from "./DoctorIntroVideo";
import { useSelector } from "react-redux";
import {Helmet} from "react-helmet";

interface DoctorData {
  meta_title: string;
  meta_keyword: string;
  meta_description: string;
  canonical: string;
}

const DoctorDetailPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, error } = useSelector((state: RootState) => state.hospital);

  const { slug } = useParams();
  const [doctorId, setDoctorId] = useState('');
  const [doctorData, setDoctorData] = useState<DoctorData | null>(null);
  const [hospitalId, setHospitalId] = useState('');
  
  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const response = await   dispatch(fetchDoctorById((slug as string)));
        const id = response.payload.id;
        const hospitalId = response.payload.hospital_id;
        if (id) {
          setDoctorId(id);
          setDoctorData(response.payload);
          setHospitalId(hospitalId);
        }
      } catch (error) {
        console.error('Error fetching doctor data:', error);
      }
    };
  
    fetchDoctorData();
  }, [dispatch, slug, doctorId]);

  if (!data && error) {
    console.error(error);
    return <div className="error">Doctor Not found!</div>;
  }
  return (
    <DetailPageLayout
      sideBar={
        <>
          <DoctorNav />
          <HelpForm additionalVals={{ doctor_id: doctorId }} additionalHosVal={{hospital_id: hospitalId}} />
        </>
      }
      content={
        <>
         <Helmet>
        <title>{doctorData?.meta_title}</title>
        <meta name="keywords" content={doctorData?.meta_keyword} />
        <meta name="description" content={doctorData?.meta_description} />
        <link rel="canonical" href={doctorData?.canonical} />
      </Helmet>
          <div>
            <a id="details"></a>
            <DoctorDetail />
          </div>

          <div>
            <a id="About"></a>
            <DoctorAbout />
          </div>
          <div>
            <a id="Services"></a>
            <Services />
          </div>
          <div>
            <a id="Specializations"></a>
            <Specializations />
          </div>
          <div>
            <a id="Associated"></a>
            <AssociatedHospitals title="Associated" />
          </div>
          <div>
            <a id="AcademicQualifications"></a>
            <AcademicQualifications />
          </div>
          <div>
            <a id="Experiences"></a>
            <Experiences />
          </div>
          <div>
            <a id="Awards"></a>
            <Awards />
          </div>
          <div>
            <a id="Achievements"></a>
            <Achievements />
          </div>
          <div>
            <a id="Memberships"></a>
            <Memberships />
          </div>
          <div>
            <a id="Publications"></a>
            <Publications />
          </div>
          <div>
            <a id="Intro Video"></a>
            <DoctorIntroVideo />
          </div>

          <HelpForm additionalVals={{ doctor_id: doctorId }} />
        </>
      }
    />
  );
};

export default DoctorDetailPage;
