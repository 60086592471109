import { Button, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useState } from "react";
import { StyledForm, Tpl } from "../entities/EntityForm";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import {
  deleteSubEntity,
  getAllSubEntity,
  subEntityCreate,
  subEntityEdit,
} from "@/features/admin/admin-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useOptions from "../entities/useOptions";
import { confirm } from "react-bootstrap-confirmation";
import widgets from "../rjsf-widgets/widgets";
import useRoles from "@/components/hooks/useRoles";

const SubEntity = ({ handleClose, show, data }: any) => {
  const isAdmin = useRoles("ADMIN");
  const { data: storeData } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppDispatch>();
  const [gridOptions, setGridOptions] = useState<any>([]);
  const { entity } = useParams();
  const [schema, setSchema] = useState<RJSFSchema>();
  const [uischema, uiSetSchema] = useState<RJSFSchema>();
  const finalSchema = useOptions(schema, entity);
  const { conf } = data;
  const [showList, setShowList] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [message, setMessage] = useState<null | string>(null);
  const [formData, setFormData] = useState<null | Record<string, unknown>>();

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    (async () => {
      if (conf && conf.key) {
        try {
          const schema = await import(`../schemas/${conf.key}.json`);
          setSchema(schema as RJSFSchema);
        } catch (e) {
          console.error("schema not found");
        }

        try {
          const uischema = await import(`../schemas/${conf.key}-ui.json`);
          uiSetSchema(uischema as RJSFSchema);
        } catch (e) {
          console.warn("uischema not found");
        }

        try {
          const go = await import(`../grid-options/${conf.key}`);
          setGridOptions(go);
        } catch (e) {
          console.error("GridOptions not found");
        }

        dispatch(
          getAllSubEntity({
            name: entity,
            subEntity: conf.key,
            id: data.id,
          })
        );
      }
    })();
  }, [dispatch, entity, data.id, conf, conf.key]);

  useEffect(() => {
    if (storeData && entity) {
      const rowData = storeData[entity][conf.key];
      if (rowData) {
        setRowData(rowData);
      }
    }
  }, [storeData, entity, conf.key]);

  const onAdd = useCallback(() => {
    if(isAdmin){
    setIsEditing(false);
    setFormData(null);
    setShowList(false);
    setMessage(null);}
  }, []);

  const back = () => {
    setShowList(true);
    setIsEditing(false);
  };
  const onSubmit = ({ formData }: any) => {
    setMessage(null);
   // Create a copy of formData
  
    if (!isEditing) {
      if (formData.disease_name) {
        const arr1 = { mainModelName: 'patientdiseasehistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.drug_name) {
        const arr1 = { mainModelName: 'patientdrughistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.surgery_name) {
        const arr1 = { mainModelName: 'patientsurgeryhistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.hospital_name) {
        const arr1 = { mainModelName: 'patienthospitalizationhistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.healthy_habit_name) {
        const arr1 = { mainModelName: 'patienthabit' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.first_name && formData.ecy_p_relationship_id) {
        const arr1 = { mainModelName: 'patientemergencycontacts' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.register_as && formData.company_type) {
        const arr1 = { mainModelName: 'agentcompany' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.bank_name && formData.branch_name_and_code) {
        const arr1 = { mainModelName: 'agentbank' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      dispatch(
        subEntityCreate({
          body: formData,
          name: entity,
          id: data.id,
          subEntity: conf.key,
        })
      ).then((res) => {
        if (res.hasOwnProperty("error")) {
          setMessage(res.payload as string);
        } else {
          setShowList(true);
          setFormData(null);
          setMessage((res.payload as any).message!);
        }
      });
    } else {
      console.log('submitting ow .......', formData);
      if (formData.disease_name) {
        const arr1 = { mainModelName: 'patientdiseasehistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.drug_name) {
        const arr1 = { mainModelName: 'patientdrughistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.surgery_name) {
        const arr1 = { mainModelName: 'patientsurgeryhistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.hospital_name) {
        const arr1 = { mainModelName: 'patienthospitalizationhistory' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.healthy_habit_name) {
        const arr1 = { mainModelName: 'patienthabit' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.first_name && formData.relationship_id) {
        const arr1 = { mainModelName: 'patientemergencycontacts' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.register_as && formData.company_type) {
        const arr1 = { mainModelName: 'agentcompany' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      if (formData.bank_name && formData.branch_name_and_code) {
        const arr1 = { mainModelName: 'agentbank' };
        formData = { ...formData, ...arr1 }; // Merge formData with arr1
      }
      dispatch(
        subEntityEdit({
          body: formData,
          name: entity,
          subEntity: conf.key,
          id: formData.id,
        })
      ).then((res) => {
        if (res.hasOwnProperty("error")) {
          setMessage(res.payload as string);
        } else {
          setShowList(true);
          setFormData(null);
          setMessage((res.payload as any).message!);
        }
      });
    }
  };

  const deleteHandle = async (o: any) => {
    const doDelete = await confirm("Are you sure you want to delete?", {
      okText: "delete",
    });
    if (doDelete) {
      dispatch(
        deleteSubEntity({
          name: entity,
          subEntity: conf.key,
          id: data.id,
          subId: o.data.id,
        })
      );
    }
  };

  const checkProps = (data: any, prop: string = "") => {
    if (data.hasOwnProperty(prop) && typeof data[prop] == "string") {
      const array = [];
      if (data[prop].length) {
        array.push(data[prop]);
      }
      return {
        [prop]: array,
      };
    }
    return {};
  };

  const editHandle = async (o: any) => {
    setIsEditing(true);
    const data = {
      ...o.data,
      ...checkProps(o.data, "document_url"),
      ...checkProps(o.data, "profile_url"),
    };
    setFormData(data);
    setShowList(false);
    setMessage(null);
  };

  return (
    <Modal onHide={handleClose} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{conf.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showList && (
          <div className="list">
            <div style={{ textAlign: "center", color: "green" }}>{message}</div>
            <div
              className="d-flex justify-content-end"
              style={{ marginBottom: 10 }}
            >
              <Button onClick={onAdd}>Add</Button>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: 200 }}
            >
              <AgGridReact
                {...gridOptions}
                rowSelection="single"
                context={{ deleteHandle, editHandle }}
                rowData={rowData} // Row Data for Rows
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              />
            </div>
          </div>
        )}
        {!showList && (
          <>
            <Button onClick={back}>&lt; Back</Button>
            {message && (
              <div style={{ textAlign: "center", color: "orange" }}>
                {message}
              </div>
            )}
            <StyledForm
              schema={finalSchema as RJSFSchema}
              uiSchema={uischema as UiSchema}
              formData={formData}
              widgets={widgets}
              validator={validator}
              onSubmit={onSubmit}
              onChange={({ formData }) => setFormData(formData as any)}
              templates={{ FieldTemplate: Tpl }}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SubEntity;
