import styled from "styled-components";
import img1 from "@/assets/images/t1.jpg"
import img2 from "@/assets/images/t2.jpg"
import img3 from "@/assets/images/t3.jpg"
import img4 from "@/assets/images/t4.jpg"
import img5 from "@/assets/images/t5.jpg"
import img6 from "@/assets/images/t6.jpg"
const StyledForm = styled.div`
.our_services_container{
  background: #2bace2;
  padding: 2em 2em;
}
.our_services_container .card-title{
  text-align: left;
  font-weight: 700;
}
.our_services_container .card {
  padding: 30px 20px;
  height: 100%;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.2s ease;
  }
}
.our_services_container .card img {
  transition: transform 0.2s ease; 
}

.our_services_container .card:hover img {
  transform: scale(1.1); 
}
.our_services_container .card-text {
  text-align: left;
}
.our_services_container .cusBtn {
  text-align: left;
}
.our_services_container .cusBtn a{
  color: #fff;
  font-weight: 700;
  background: #2bace2;
  padding: 10px 15px;
  border-radius: 28px;
  text-decoration: none;
}
.our_services_container .cusBtn a:hover {
      background: #eee;
      color: #2bace2;
}
.travel_services p{
  text-align: justify;
}
b{
  color: #2bace2;
}
a{
  color: #2bace2;
  text-decoration:underline;
}
@media only screen and (max-width: 768px) {
  .travel_services{
    padding: 1.5rem;
  }
}
`;
const EVisa = () => {
  return (
    <StyledForm>
    <div className="travel_services">
      <h6 className='blue center sub-heading bold pt-5'>
        Plan Medical Tourism - Your Gateway to Health and Travel in India
      </h6>
      <p className='link-text justify pt-3'>
        Welcome to Plan Medical Tourism, your one-stop destination for
        exceptional travel services and personalized healthcare experiences in
        India. We take immense pride in offering the best rates for flight
        tickets, hotel bookings, serviced apartments, custom-designed tour
        packages, interpreter services, local SIM card facilities, car rentals,
        and comprehensive information on medical visas for your journey to
        India.
      </p>
      <h6></h6>
      <p>
        
        <b>About Us :  </b>
        At Plan Medical Tourism, we understand the significance of your health
        and well-being, and that's why we strive to provide you with top-notch
        travel services while ensuring seamless access to India's renowned
        medical facilities. Our team of dedicated professionals is committed to
        making your medical journey comfortable, convenient, and rewarding.
      </p>
      <h6 className="blue sub-heading pb-3">Our Services</h6>
      <div className="container our_services_container">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
            <div className="card">
              <img src={img1} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Air Booking</h5>
                <p className="card-text">We have partnered with leading airlines
                    to bring you the most affordable and
                    flexible flight options to India. Whether
                    you require direct flights or connecting
                    flights, our booking platform will assist
                    you in finding the best deals tailored to
                    your preferences. For booking flights</p>
              </div>
              <div className="cusBtn">
                <a href="https://www.planyourjourney.com" target="_blank">Book Now</a>
              </div>
            </div>
          </div>
        <div className="col">
            <div className="card">
              <img src={img2} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Hotel Booking</h5>
                <p className="card-text">Choose from a wide range of
                      accommodations, including luxury
                      hotels, cozy guesthouses, and budget-
                      friendly options. Rest assured, we will
                      secure the most competitive rates to suit
                      your needs.For booking hotels</p>
              </div>
              <div className="cusBtn">
                <a href="https://www.planyourjourney.com" target="_blank">Book Now</a>
              </div>
            </div>
          </div>
        <div className="col">
            <div className="card">
              <img src={img3} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Car Booking</h5>
                <p className="card-text">Discover India at your own pace with our
                          car rental services. Choose from a variety
                          of well-maintained vehicles and explore
                          the country's beauty with comfort and
                          freedom. For booking a car</p>
              </div>
              <div className="cusBtn">
                <a href="https://www.planyourjourney.com" target="_blank">Book Now</a>
              </div>
            </div>
          </div>
        <div className="col">
            <div className="card">
              <img src={img4} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Interpreter Services</h5>
                <p className="card-text">Communication is key, especially in a
                      foreign land. Our interpreter services
                      ensure that language barriers do not
                      stand in the way of effective
                      communication between you and your
                      healthcare providers. For requesting
                      interpreter services</p>
              </div>
              <div className="cusBtn">
                <a href="./interpreter-services">Book Now</a>
              </div>
            </div>
          </div>
        <div className="col">
            <div className="card">
              <img src={img5} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Custom designed tour packages</h5>
                <p className="card-text">While you focus on your health, allow us
                    to curate memorable travel experiences
                    for you and your loved ones. Explore the
                    vibrant culture, breathtaking landscapes,
                    and historical landmarks with our tailor-
                    made tour packages. For booking
                    tourism packages</p>
              </div>
              <div className="cusBtn">
                <a href="https://www.planyourjourney.com" target="_blank">Book Now</a>
              </div>
            </div>
          </div>
        <div className="col">
            <div className="card">
              <img src={img6} className="card-img-top" alt="..."/>
              <div className="card-body">
                <h5 className="card-title">Serviced Apartments</h5>
                <p className="card-text">Experience a home away from home
                        with our selection of fully furnished and
                        serviced apartments. Ideal for extended
                        stays or post-treatment recovery, these
                        apartments offer comfort and
                        convenience. For booking services
                        apartments</p>
              </div>
              <div className="cusBtn">
                <a href="https://www.planyourjourney.com" target="_blank">Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p>
        <b>Flight Tickets :  </b>
        We have partnered with leading airlines to bring you the most affordable
        and flexible flight options to India. Whether you require direct flights
        or connecting flights, our booking platform will assist you in finding
        the best deals tailored to your preferences. For booking flights <a href="https://www.planyourjourney.com" target="_blank"> click
        here </a>
        
      </p> */}
      {/* <p>
        <b>Hotel Bookings : </b>
        Choose from a wide range of accommodations, including luxury hotels,
        cozy guesthouses, and budget-friendly options. Rest assured, we will
        secure the most competitive rates to suit your needs.For booking hotels &nbsp;
         <a href="https://www.planyourjourney.com" target="_blank"> click
        here </a>
      </p> */}
      {/* <p>
        <b>Serviced Apartments : </b>
        Experience a home away from home with our selection of fully furnished
        and serviced apartments. Ideal for extended stays or post-treatment
        recovery, these apartments offer comfort and convenience. For booking
        services apartments <a href="https://www.planyourjourney.com" target="_blank"> click
        here </a>
      </p> */}
      {/* <p>
        <b>Custom Designed Tour Packages :  </b>
        While you focus on your health, allow us to curate memorable travel
        experiences for you and your loved ones. Explore the vibrant culture,
        breathtaking landscapes, and historical landmarks with our tailor-made
        tour packages. For booking tourism packages <a href="https://www.planyourjourney.com" target="_blank"> click
        here </a>
      </p> */}
      {/* <p>
        <b>Interpreter Services :  </b>
        Communication is key, especially in a foreign land. Our interpreter
        services ensure that language barriers do not stand in the way of
        effective communication between you and your healthcare providers. For
        requesting interpreter services <a href="./interpreter-services"> click
        here </a>
      </p> */}
      {/* <p>
        <b>Car Rentals :  </b>
        Discover India at your own pace with our car rental services. Choose
        from a variety of well-maintained vehicles and explore the country's
        beauty with comfort and freedom. For booking a car <a href="https://www.planyourjourney.com" target="_blank"> click
        here </a>
      </p> */}

      <div>
        <p className='blue sub-heading bold pt-3'>
          India Medical Visa (eVisa for India for Medical Purposes)
        </p>
        <p className='link-text center pt-3'>
            All details, conditions and requirements that you need to know about
            the Indian Medical Visa are available here. Please apply for this
            Indian Medical Visa if you are arriving for a medical treatment.
        </p>
        <p
          style={{ lineHeight: "1.8" }}
          className='link-text justify pt-2 pb-5'
        >
          
          As a patient seeking medical treatment in another country, the last
          thought on your mind should be the loops you would have to go through
          in order to get your Visa for the visit. Especially in the situation
          of some emergency where urgent medical care is required it would be
          quite a hurdle to have to visit the Embassy of that country so as to
          procure the Visa on which you can visit that country for medical
          treatment. That is why it’s extremely helpful that the Government of
          India has made available an electronic or e-Visa meant specifically
          for international visitors to the country who have arrived due to
          medical purposes. You can &nbsp;
           <a
            style={{ color: "#39ace0" }}
            href='https://www.india-visa-online.org/visa'
          >
            
             apply for the Medical Visa for India online
          </a>
          &nbsp;
          instead of having to go to the local Indian Embassy in your country in
          order to get it for your visit to India. &nbsp;
          <a
            style={{ color: "#39ace0" }}
            href='https://www.india-visa-online.org/application-for-indian-e-visa-online'
          >
            
             Application for Indian Medical Visa
          </a>
          &nbsp;
          must be made online.
        </p>
      </div>
      <p>
        <b>Medical Insurance : </b>
        For added peace of mind during your medical journey, we also offer
        comprehensive medical insurance options tailored to your specific needs.
        Rest assured that you'll be covered for any unforeseen medical expenses,
        providing an extra layer of protection and support throughout your stay
        in India.
      </p>
      <h6 className="blue sub-heading pb-4">Why Choose Plan Medical Tourism</h6>
      <p>
        <b>Extensive Network : </b> We have established strong partnerships with
        leading medical institutions, travel providers, and local service
        providers across India, allowing us to offer you the best options.
      </p>
      <p>
        <b>Personalized Assistance : </b> Our dedicated team is available 24/7 to
        assist you throughout your medical journey. From pre-booking inquiries
        to on-ground support, we are here for you every step of the way.
      </p>
      <p>
        <b>Quality Assurance : </b> Your safety and comfort are our top
        priorities. We ensure that you receive top-notch medical care from
        reputable healthcare facilities.
      </p>
      <p>
        <b>Transparent Pricing : </b> At Plan Medical Tourism, we believe in
        transparency. There are no hidden costs, and we provide detailed
        breakdowns of all services to help you make informed decisions.
      </p>
      <p>
        <b>Cultural Sensitivity : </b> We understand the importance of cultural
        sensitivity in healthcare. Our team is trained to respect your cultural
        values and ensure you feel at home during your stay in India.
      </p>
      <p>
        <b > </b>
      </p>
      <h6 className="blue sub-heading">Get Started on Your Medical Journey Today</h6>

      <p>
        Embark on a transformative healthcare experience combined with the
        enchanting charm of India. Let Plan Medical Tourism take care of all
        your travel needs, leaving you with the freedom to focus on your health
        and wellness. <br />
        Contact us today to plan your journey to a healthier tomorrow! <br />
        
      </p>
    
    </div>
    </StyledForm>
  );
};

export default EVisa;
