import { createSlice } from "@reduxjs/toolkit";
import { getBannerData } from "./banner-actions";
import { ICarouselListItem } from "@/components/FlyingBanner";

interface BannerState {
  loading: boolean;
  data: ICarouselListItem[];
}

const initialState: BannerState = {
  loading: false,
  data: [],
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBannerData.pending, (state, action) => {
      state.loading = true;
      state.data = [];
    });

    builder.addCase(getBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data.data as any;
    });
  },
});

export default bannerSlice.reducer;
