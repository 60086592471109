import styled from "styled-components";
import { useParams } from "react-router-dom";
import SearchBar from "../hospitals/SearchBar";
import Listing from "./Listing";
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const SearchListing = () => {
  const { entity } = useParams();

  return (
    <BodyWrapper>
      <SearchBar entity={entity} />
      <Listing />
    </BodyWrapper>
  );
};

export default SearchListing;
