import { NavWrapper } from "../hospitals/HospitalNav";

const DoctorNav = () => (
  <NavWrapper className="card shadow">
    <ul>
      <li>
        <a href="#About">About the doctor</a>
      </li>
      <li>
        <a href="#Services">Services</a>
      </li>
      <li>
        <a href="#Specializations">Specializations</a>
      </li>
      <li>
        <a href="#Associated">Associated</a>
      </li>
      <li>
        <a href="#AcademicQualifications">Academic Qualifications</a>
      </li>
      <li>
        <a href="#Experiences">Experiences</a>
      </li>
      <li>
        <a href="#Awards">Awards</a>
      </li>

      <li>
        <a href="#Achievements">Achievements</a>
      </li>

      <li>
        <a href="#Memberships">Memberships</a>
      </li>

      <li>
        <a href="#Publications">Publications</a>
      </li>

      <li>
        <a href="#Intro Video">Intro Video </a>
      </li>
    </ul>
  </NavWrapper>
);

export default DoctorNav;
