import React, { useEffect, useState } from 'react'
import styled from "styled-components";

const StyledSection = styled.div`
.button-container {
    position: relative;
  }
  
  .to-top-button {
    position: fixed;
    bottom: 24px;
    right: 0px;
    padding: 4px 8px;
    background-color: rgb(43, 172, 226);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease 0s;
  }
  
  .to-top-button:hover {
    opacity: 0.8;
  }
  `;
  
function TopButton() {
    const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <StyledSection>
    <div className="button-container">
    {showButton && (
      <button className="to-top-button" onClick={scrollToTop}>
        <i className="bi bi-arrow-up-square"></i>
      </button>
    )}
  </div>
  </StyledSection>
  )
}

export default TopButton;