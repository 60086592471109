import { useDoctorDetail } from "./use-doctor-detail";
import DoctorStyledSection from "./DoctorStyledSection";

const AcademicQualifications = () => {
  const data = useDoctorDetail("qualification");
  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Academic Qualifications</div>
      <div className="wrapper justify">
        {data.length === 0 && <div>No Academic Qualifications added</div>}
        {data
          .filter((item) => Object.values(item).filter(Boolean).length > 1)
          .map(({ college_name, college_city, passout_year, qualification_name }, idx) => {
            return (
              <div className="card shadow">
                <div className="blue body-text bold">{qualification_name} </div>
                <div className="bold">{college_name}</div>
                <div className="">{college_city}</div>
                <div className="year">{passout_year || ""}</div>
              </div>
            );
          })}
      </div>
    </DoctorStyledSection>
  );
};

export default AcademicQualifications;
