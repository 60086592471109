import { getDoctorDetairFor } from "@/features/web/doctor-actions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useDoctorDetail = (section: string) => {
  const { slug = "" } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (slug) {
      (async () => {
        try {
          const data = await getDoctorDetairFor(section, slug);
          setData(data);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [slug, section]);

  return data || [];
};
