import StaticBanner from "@/components/StaticBanner";
import styled from "styled-components";
import BestDoctors from "./BestDoctors";
import WhyChooseUs from "../home/WhyChooseUs";
import Blogs from "../home/Blogs";
import HelpForm from "./HelpForm";
import TreatmentSection from "./Treatments";
import SearchBar from "../hospitals/SearchBar";
import SliderTestimonial from "../home/SliderTestimonial";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const Doctors = () => {
  return (
    <BodyWrapper>
       <Helmet>
          <title>Find India's Top Doctors | Plan Medical Tourism</title>
          <meta name="description" content="Explore the expertise of top Doctors in India with Plan Medical Tourism. Expert surgeons are available, with years of experience to ensure that your journey to better health is in good hands." />
          <link rel="canonical" href="https://planmedicaltourism.com/doctors" />
          <meta name="keywords" content="" />
      </Helmet>
      <SearchBar entity="doctor" />
      <StaticBanner id={2} />
      <TreatmentSection title="Speciality" />
      <BestDoctors title="Best Doctors In India" category="country" />
      <BestDoctors title="Best Doctors In Metro City" category="metro" />
      <BestDoctors
        title="Best Doctors In Top Travel Destinations"
        category="destination"
      />
      <SliderTestimonial />
      <WhyChooseUs />
      <Blogs />
      <HelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default Doctors;
