import { NavLink, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { globalSearch } from "@/features/web/web-actions";
import styled from "styled-components";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ListGroup, Spinner } from "react-bootstrap";
import { Paginate } from "./Listing";
const StyledListing = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
  }
`;

interface IPagination {
  rows: any[];
  totalPages: number;
  currentPage: number;
  count: number;
}

const itemsPerPage = 10;

const useSearch = (entity: string, query: string | null, page = 1) => {
  const dispatch = useDispatch<AppDispatch>();
  const [result, setResult] = useState<null | IPagination>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (entity && query) {
      setLoading(true);
      dispatch(
        globalSearch({
          page,
          size: itemsPerPage,
          query,
          entity,
        })
      ).then((res) => {
        setResult(res.payload);
        setLoading(false);
      });
    }
  }, [dispatch, entity, query, page]);

  return {
    result,
    loading,
  };
};

const HospitalRow = (data: any) => {
  return (
    <ListGroup.Item>
      <NavLink to={"/hospital/" + data.slug}>
        {data.name}
        {data.city}
      </NavLink>
    </ListGroup.Item>
  );
};

const DoctorRow = (data: any) => {
  return (
    <ListGroup.Item>
      <NavLink to={"/doctor/" + data.slug}>
        {data.salutation} {data.first_name + " " + data.last_name}
      </NavLink>
    </ListGroup.Item>
  );
};
const TreatmentRow = (data: any) => {
  return (
    <ListGroup.Item>
      <NavLink to={"/treatment/" + data.slug}>{data.name}</NavLink>
    </ListGroup.Item>
  );
};

const BlogsRow = (data: any) => {
  return (
    <ListGroup.Item>
      <NavLink to={"/blog/" + data.slug}>{data.title}</NavLink>
    </ListGroup.Item>
  );
};
const Loader = ({ loading, result }: any) => {
  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else if (result.length === 0) {
    return <div>No results found </div>;
  }
  return null;
};

const RowGroup = ({ children, loading, result, keyword }: any) => {
  return (
    <>
      {!loading && !!result.length && (
        <>
          <div style={{ margin: 24 }}>
            Showing result for: <i>{keyword}</i>
          </div>

          <ListGroup style={{ textAlign: "left" }}>
            {result.map((data: any) => children(data))}
          </ListGroup>
        </>
      )}

      <Loader loading={loading} result={result} />
    </>
  );
};

const GlobalSearchListing = () => {
  const [entity, setEntity] = useState("hospitals");
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();

  const handlePageClick = async (event: any) => {
    setPage(event.selected + 1);
  };
  const keyword = searchParams.get("q") || null;

  const { result, loading } = useSearch(entity, keyword, page);

  const renderRowGroup = (
    category: string,
    component: (data: any) => JSX.Element
  ) => {
    return (
      result &&
      result.rows.map((row: any) => {
        const data = row[category];
        if (data && data.length > 0) {
          return (
            <RowGroup
              key={category}
              loading={loading}
              result={data}
              keyword={keyword}
            >
              {component}
            </RowGroup>
          );
        }
        return null;
      })
    );
  };

  const onTabSelect = (k: string | null) => {
    setEntity(k as string);
    setPage(1);
  };
  
  const hasHospitals = result?.rows[0].hospital.length !== 0;
  const hasTreatments = result?.rows[3].treatment.length !== 0;
  const hasDoctors = result?.rows[2].doctor.length !== 0;
  const hasBlogs = result?.rows[1].blog.length !== 0;

  const getActiveTab = () => {
    if (result && result.rows) {
      if (result.rows[0].hospital.length !== 0) return "hospitals";
      if (result.rows[3].treatment.length !== 0) return "treatments";
      if (result.rows[2].doctor.length !== 0) return "doctors";
      if (result.rows[1].blog.length !== 0) return "blogs";
    }
    // Default to the first tab if none of the categories have data
    return "hospitals";
  };

  return (
    <StyledListing>
      {result && (
        <div>
          <Tabs
            defaultActiveKey={getActiveTab()}
            className="mb-3"
            onSelect={onTabSelect}
          >
            {hasHospitals && (
              <Tab eventKey="hospitals" title="Hospitals">
                {renderRowGroup("hospital", (props: any) => HospitalRow(props))}
              </Tab>
            )}
            {hasTreatments && (
              <Tab eventKey="treatments" title="Treatments">
                {renderRowGroup("treatment", (props: any) => TreatmentRow(props))}
              </Tab>
            )}
            {hasDoctors && (
              <Tab eventKey="doctors" title="Doctors">
                {renderRowGroup("doctor", (props: any) => DoctorRow(props))}
              </Tab>
            )}
            {hasBlogs && (
              <Tab eventKey="blogs" title="Blogs">
                {renderRowGroup("blog", (props: any) => BlogsRow(props))}
              </Tab>
            )}
          </Tabs>
        </div>
      )}

      {result && (
        <div style={{ marginTop: 24 }}>
          <Paginate result={result} handlePageClick={handlePageClick} />
        </div>
      )}
    </StyledListing>
  );
};

export default GlobalSearchListing;