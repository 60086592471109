import { createSlice } from "@reduxjs/toolkit";
import { getBestof, getKnowledgeCat, sendOrder } from "./web-actions";

interface IValuesLoader {
  loading: boolean;
  success: boolean;
  list: any[];
}

export interface WebDoctorState {
  bestin_country: IValuesLoader;
}
export interface WebHospitalState {
  bestin_country: IValuesLoader;
}

interface ICountries {
  text: string;
  id: number;
}

interface WebState {
  loading: boolean;
  data: Record<string, WebDoctorState>;
  countries: ICountries[];
  knowledge: Record<string, any>;
  orderResponse: any;
}

const initialState: WebState = {
  loading: false,
  data: {
    doctor: {
      bestin_country: {
        loading: false,
        list: [],
        success: false,
      },
    },
    hospital: {
      bestin_country: {
        loading: false,
        list: [],
        success: false,
      },
    },
  },
  knowledge: {
    menu: {},
  },
  countries: [],
  orderResponse: null,
};

const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBestof.pending, (state, { meta }) => {
      const { entity, category } = meta.arg;
      state.data[entity][`bestin_${category}` as keyof WebDoctorState] = {
        loading: true,
        list: [],
        success: false,
      };
    });

    builder.addCase(getKnowledgeCat.fulfilled, (state, action) => {
      state.knowledge.menu = action.payload.reduce((acc: any, curr: any) => {
        const { id } = curr;
        acc[id] = curr;
        return acc;
      }, {});

      console.log(state.knowledge.menu);
    });

    builder.addCase(getBestof.fulfilled, (state, { payload }) => {
      const { data, entity, category } = payload as any;

      state.data[entity][`bestin_${category}` as keyof WebDoctorState] = {
        loading: false,
        list: data,
        success: true,
      };
    });
    builder.addCase(sendOrder.fulfilled, (state, action) => {
      state.orderResponse = action.payload;
    });

    builder.addCase(sendOrder.rejected, (state, action) => {
    });
  },
});

export default webSlice.reducer;
