import styled from "styled-components";
import { Link } from "react-router-dom";
const StyledCard = styled.div`
  width: 365px;
  padding: 10px;
  height: 378px;
  justify-content: space-between;
  img {
    height: 260px;
  }

  .title {
    padding: 8px 0;
  }
`;

const PackageCard = ({ profile_url, title, id , slug}: any) => {
  return (
    <StyledCard className="card shadow">
      <div>
        <img src={profile_url} alt="" />
      </div>
      <div>
        <div className="title sub-heading semibold blue">{title}</div>
        <Link to={`../packages/${slug}`} className="link-text regular">
          View More &gt;
        </Link>
      </div>
    </StyledCard>
  );
};

export default PackageCard;
