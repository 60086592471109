import { useDoctorDetail } from "./use-doctor-detail";
import DoctorStyledSection from "./DoctorStyledSection";
import { BlueButton } from "@/components/styled";

const Publications = () => {
  const data = useDoctorDetail("publication");

  if (data.length === 0) {
    return null;
  }

  return (
    <DoctorStyledSection>
      <div className="title blue bold sub-heading">Publications </div>
      <div className="wrapper justify">
        {data.map(
          (
            {
              publication_name,
              about,
              publication_type,
              publication_date,
              document_url,
            },
            idx
          ) => (
            <div key={idx} className="card shadow">
              <div className="bold">{publication_name}</div>
              <div
                className="year"
                dangerouslySetInnerHTML={{ __html: about }}
              ></div>
              <div className="year">{publication_type}</div>
              <div className="year">{publication_date}</div>
            </div>
          )
        )}
      </div>
    </DoctorStyledSection>
  );
};

export default Publications;