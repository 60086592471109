import styled from "styled-components";
import Certificate from "../gallery/Certificate"
const AwardsCardStyled = styled.div`
  overflow: hidden;
  height: 150px;
  width: 60px;
  flex: 0 1 46%;
  align-items: center;
  @media (min-width: 425px) {
    height: 200px;
    width: 200px;
  }
`;

const AwardsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 90%;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (min-width: 425px) {
    flex-wrap: nowrap;
  }
`;

const Awards = () => {
  return (
    <div className="mt-5">
      <p className="blue heading bold">Group Awards</p>
      <Certificate/>
    </div>
  );
};

export default Awards;
