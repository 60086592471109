/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getDestination, getPopularCities } from "@/features/web/web-actions";

const DesticationCard = styled.div`
  width: 328px;
  height: auto;
  margin: 1em 0em;
  .crCityImg{
    width: 296px;
    height:203px;
  }
`;

const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,  
  autoplaySpeed: 3000,
  nextArrow: (
    <div>
      <div className='next-slick-arrow'>
        <i className='bi bi-arrow-right-circle bi_arrow'></i>
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className='prev-slick-arrow'>
        <i className='bi bi-arrow-left-circle bi_arrow'></i>
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CardDestination = ({ image, title, alt, subtitle = "" }) => {
  return (
    <DesticationCard className='card shadow'>
      <div className='card-body'>
        <img src={image} className='card-img-top destination-img crCityImg' alt={alt} />
        <p className='card-text m-0 sub-heading blue semibold'>{title}</p>
      </div>
    </DesticationCard>
  );
};

const PopularDestinationsIndia = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state: RootState) => state.popularCities);

  useEffect(() => {
    dispatch(getPopularCities());
  }, [dispatch]);
  return (
    <div className=''>
      <p className='blue heading bold'>
        {t("homePage.popularDestinationsInIndia")}
      </p>
      {data && data.length > 0 && (
        <Slider {...settings}>
          {data.map(({ thumbnail, title, thumbnail_alt_tag, id , slug }, idx) => (
            <a href={`popular-destination/${slug}`}>
            <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-4'>
              <CardDestination
                image={thumbnail}
                title={title}
                alt={thumbnail_alt_tag}
              />
            </div>
            </a>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default PopularDestinationsIndia;
