import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Skeleton } from "antd";
import { associateDoctorToHospital } from "@/features/web/hospital-actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import SearchDoctor from "./SearchDoctor";

interface DataType {
  first_name: string;
  last_name: string;
  profile_url: string;
  id: string;
  loading: boolean;
  Specializations: [];
}

interface AssociatedDrListProps {
  list: DataType[];
  hospitalName: string;
  onRemoveSuccess: () => void;
  hospital_id: number;
}

const AssociatedDrList = ({
  list,
  hospital_id,
  hospitalName,
  onRemoveSuccess,
}: AssociatedDrListProps) => {
  const [message, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [innerList, setInnerList] = useState<DataType[]>([]);
  const [unfiltered, setUnfiltered] = useState<DataType[]>([]);

  useEffect(() => {
    setInnerList(list);
    setUnfiltered(list);
  }, [list]);

  const disAssociate = (item: DataType) => {
    setMessage(null);
    dispatch(
      associateDoctorToHospital({
        associate: false,
        hospital_id,
        doctor_id: item.id as unknown as number,
      })
    ).then((res) => {
      if (res.hasOwnProperty("error")) {
        if (typeof res.payload == "string") {
          setMessage(res.payload);
        }
      } else {
        onRemoveSuccess();
      }
    });
  };

  const searchChange = (text: string) => {
    if (text.length) {
      setInnerList((l) =>
        l.filter(({ first_name, last_name }) => {
          const name = `${first_name} ${last_name}`;
          return name.toUpperCase().includes(text.toUpperCase());
        })
      );
    } else {
      setInnerList(unfiltered);
    }
  };

  const onCancel = () => {
    setInnerList(unfiltered);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ minHeight: 50 }}>
        Associated Doctors with {hospitalName}
      </div>

      {message && (
        <div
          style={{
            color: "red",
            fontSize: 12,
          }}
        >
          {message}
        </div>
      )}
      <SearchDoctor onChange={searchChange} onCancel={onCancel} />
      <List
        style={{
          width: "100%",
          height: 500,
          overflow: "auto",
          border: "1px solid",
          padding: "10px",
        }}
        itemLayout="horizontal"
        dataSource={innerList}
        renderItem={(item) => (
         <List.Item
          //  actions={[
           //   <Button type="primary" onClick={() => disAssociate(item)} danger>
           //     Remove
           //   </Button>,
           // ]}
          > 
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar src={item.profile_url} />}
                title={`${item.first_name} ${item.last_name}`}
                description={item.Specializations.map(({ name }) => name).join(
                  ","
                )}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default AssociatedDrList;
