import { fetchPopularCitiesById } from '@/features/web/web-actions';
import { AppDispatch, RootState } from "@/store";
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import HospitalSlider from './HospitalSlider';
const StyledContainer = styled.div`
  .city_title {
    color: #fff; 
    font-size: 24px;
    font-weight: bold;
    position: relative;
    top: 8em;
    background: #0000006b;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
  }
    .city_row{
        margin-top: -4em;}
`;
function PopularDestinationDetail() {
  const dispatch = useDispatch<AppDispatch>();
    const { slug } = useParams();
    const { data, error } = useSelector((state: RootState) => state.popularCitiesById);
    const [getCityDetail, setCityDetail] = useState();
  
    useEffect(() => {
      const fetchCitiesData = async () => {
        try {
          const response = await dispatch(fetchPopularCitiesById(slug));
          setCityDetail(response.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchCitiesData();
    }, [dispatch, slug]);
  return (
    <StyledContainer>
    <div className="container">
    {getCityDetail && (
        <div className="row city_row">
            <div className="col-md-12">
                {/* Place the title above the banner */}
                <p className='heading blue city_title'>{getCityDetail['sub_title']}</p>
                <div className="banner mb-5">
                <img src={getCityDetail['banner']} alt={getCityDetail['banner_alt_tag']} />
                </div>
                <div className="justify about_us_div">
                   <p dangerouslySetInnerHTML={{ __html: getCityDetail['description'] }}></p>
                </div>
                <div>
                <p className='heading bold blue'>Popular Hospitals in {getCityDetail['title']}</p>
                  <HospitalSlider/>
                </div>
            </div>
        </div>
         )}
    </div>
</StyledContainer>

  )
}

export default PopularDestinationDetail