import React from 'react'
import verified from "@/assets/check-verified-02.png";

function ThankYou() {
    return (
        <div className="card shadow" style={{ padding: 36 }}>
          <div className="success">
            <div className="icon">
              <img src={verified} alt="..." />
            </div>
            <p className="blue heading bold">
              Thank You! Your response has been recorded!
            </p>
            <p className="body-text">Our representative will contact you shortly</p>
          </div>
        </div>
      );
}

export default ThankYou