import styled from "styled-components";
import { ReactNode } from "react";

const TwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 45px;
  .aside {
    display: flex;
    flex-direction: column;
    width: 350px;
    gap: 50px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
  flex-grow: 1;

  & > a {
    width: 1px;
    height: 1px;
    display: inline;
  }

  @media (min-width: 425px) {
    padding: 0;
    .title {
      font-size: 36px;
      text-align: left;
    }
  }
`;

interface DetailPageLayoutProps {
  sideBar: ReactNode;
  content: ReactNode;
  className?: string;
}

const DetailPageLayout = ({
  sideBar,
  content,
  className = "",
}: DetailPageLayoutProps) => {
  return (
    <TwoColumns className={className}>
      <div className="d-none d-sm-block">
        <div className="aside">{sideBar}</div>
      </div>
      <BodyWrapper className="main">{content}</BodyWrapper>
    </TwoColumns>
  );
};

export default DetailPageLayout;
