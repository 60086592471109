import Carousel from "react-bootstrap/Carousel";
import Image from "../utils/ResponsiveImage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getGallery } from "@/features/web/web-actions";
import { RootState, AppDispatch } from "@/store";

function HospitalCarousel() {
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState([]);
  const { images, loading, error } = useSelector((state: RootState) => state.gallery);

  useEffect(() => {
    dispatch(getGallery({ categoryId: 3 })).then((res: any) => {
      setData(res.payload.rows);
    });
  }, [dispatch]);
  return (
    <div>
      <p className="fw-bold">Hospital</p>
      <div
        id='carouselExampleInterval4'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-inner cr_shadow'>
          {data.map(({ id, alt, image_url, alt_tag }, idx) => (
            <div className={idx ===0?"carousel-item active":"carousel-item "} key={idx} data-bs-interval='1000'>
              <div className="card partnerCarouselCard">
               <img src={image_url} className='w-100 ' alt={alt_tag} />
             </div>
            </div>
          ))}
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselExampleInterval4'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon custom-carousel-control'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselExampleInterval4'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon custom-carousel-control'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
    </div>
  );
}

export default HospitalCarousel;
