import styled from "styled-components";
import FlyingBanner from "../../components/FlyingBanner";
import ContactBar from "../../components/header/ContactBar";
import NavigationBar from "../../components/header/NavigationBar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const StyledFooter = styled.footer`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(43, 172, 226, 0.3) 47.4%,
    rgba(137, 197, 67, 0.3) 100%
  );
`;

const HomeLayout = () => {
  return (
    <>
      <div className="container">
        <header>
          <ContactBar />
          <NavigationBar />
        </header>
        <Outlet />
      </div>
      <StyledFooter>
        <div className="container">
          <Footer />
        </div>
      </StyledFooter>
    </>
  );
};

export default HomeLayout;
