import styled from "styled-components";
import { NavLink } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { searchBlogs } from "@/features/admin/admin-actions";
import { BlogListWrapper, BlogThumbnail } from "../news/Knowledge";
const StyledSection = styled.div`
  padding: 20px;
`;

const MoreLikeThis = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [news, setNews] = useState([]);

  useEffect(() => {
    dispatch(
      searchBlogs({
        page: 1,
        size: 3,
        blogId: 1,
      })
    ).then((res: any) => {
      setNews(res.payload.rows);
    });
  }, []);
  return (
    <StyledSection>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="title blue bold sub-heading"
          style={{ textAlign: "left" }}
        >
          More Like this
        </div>
        <div className="">
          <NavLink href="../knowledge/blog"> View All &gt;&gt;</NavLink>
        </div>
      </div>
      <BlogListWrapper>
        {news.map((item, idx) => (
          <BlogThumbnail data={item} key={idx} />
        ))}
      </BlogListWrapper>
    </StyledSection>
  );
};

export default MoreLikeThis;
