/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getOptions } from "@/features/admin/admin-actions";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useDispatch } from "react-redux";
import TextInput from "../common/fields/TextInput";
import SingleSelect from "../common/fields/SingleSelect";

import { StyledRegisterForm } from "../common/StyledRegisterForm";
import FileInput from "../common/fields/FileInput";
import { registerFacilitator } from "@/features/web/web-actions";

export const patientInitialValues = {
  salutation: "",
  first_name: "",
  last_name: "",
  email: "",
  contact_no: "",
  user_name: "",
  password: "",
  company_details: [
    {
      company_name: "",
      register_as: "",
      company_type: "",
      pan_number: "",
      pan_name: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      phone: "",
      fax_no: "",
      tax_no: "",
      gst_no: "",
      gst_certificate: [
      ],
      address_proof: [],
      experience_certificate:[],
      resume_upload:[],
      income_tax_return_option: "",
      year_of_experience: "",
    },
  ],
  bank_details: [
    {
      name_as_mentioned_in_the_bank_account: "",
      bank_name: "",
      branch_name_and_code: "",
      account_number: "",
      ifsc_code: "",
      mmid: "",
      swift_code: "",
      professtional_fee: "",
      upload_cancel_cheque_option:[]
    },
  ],
};

const entityName = "agent";

const FacilitatorForm = ({ style }: any) => {
  const [questions, setQuestions] = useState<any>(null);
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };

  const dispatch = useDispatch<AppDispatch>();
  const [currentDivIndex, setCurrentDivIndex] = useState(0);
  const [genericError, setGenericError] = useState(null);

  const handleNextClick = () => {
    setCurrentDivIndex((prevIndex) => (prevIndex < 2 ? prevIndex + 1 : 0));
  };

  const handlePrevClick = () => {
    setCurrentDivIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  useEffect(() => {
    if (options && options.questions && questions == null) {
      setQuestions(
        options.questions.reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        )
      );
    }
  }, [options, questions]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);
  const totalSteps = 3; // Set the total number of steps here
  return (
    <>
      <div className="text-center">
        <p className="mb-0">Welcome!</p>
        <p className="pb-3">
          Please help us know you better for a better experience!
        </p>

        <ProgressBar
          animated={true}
          className="progressBar"
          now={(currentDivIndex / totalSteps) * 100}
        />
      </div>
      <div className="card shadow" style={{ padding: 36, ...style }}>
        <Formik
          initialValues={patientInitialValues}
          onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            setErrors({});
            setGenericError(null);

            registerFacilitator({
              ...values,
            }).then((resp: any) => {
              if (!resp.success) {
                if (resp.responseCode === 422) {
                  setErrors({
                    ...resp.data,
                  });
                } else {
                  setGenericError(resp.message);
                }
                window.scrollTo(0, 0);
              } else {
                resetForm();
                navigate("../partner/thank-you");
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <StyledRegisterForm>
                {genericError && (
                  <div className="genericError">{genericError}!</div>
                )}
                <div
                  style={{
                    display: currentDivIndex === 0 ? "block" : "none",
                  }}
                >
                  <div>
                    <p className="sub-heading blue">Personal Details</p>
                  </div>
                  <div className="row gy-4 gx-5">
                    <div className="form-row col-md-6">
                    <SingleSelect
                        placeholder="salutation"
                        name="salutation"
                        options={options?.salutation}
                        backendsavefield="name"
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <TextInput name="first_name" placeholder="First name" />
                    </div>
                    <div className="form-row col-md-6">
                      <TextInput name="last_name" placeholder="Last name" />
                    </div>
                    <div className="form-row col-md-6">
                      <TextInput name="email" placeholder="Email" />
                    </div>
                    <div className="col-md-12 ">
                      <TextInput
                        placeholder="Mobile Number"
                        name="contact_no"
                        maxLength={12}
                      />
                    </div>
                    <label htmlFor="">
                      Password
                      <div className="form-row col-md-12">
                        <TextInput
                          type="password"
                          name="password"
                          placeholder="Password"
                        />
                      </div>
                    </label>
                    <div className="submit-btn-container">
                      <button
                        onClick={handleNextClick}
                        type="button"
                        className="btn btn-primary"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                {/* this is second section start */}

                <div
                  style={{
                    display: currentDivIndex === 1 ? "block" : "none",
                  }}
                >
                  <div>
                    <p className="blue sub-heading">
                      Company / Individual Details
                    </p>
                  </div>

                  <div className="row gy-4 gx-5 pb-5">
                    <div className="col-md-6">
                      <label htmlFor="">Company Name</label>
                      <TextInput
                        placeholder="Company Name"
                        name="company_details[0].company_name"
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <label htmlFor="">Register As</label>
                      <SingleSelect
                        placeholder="Register As"
                        name="company_details[0].register_as"
                        options={options?.register_as}
                        backendsavefield="name"
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <label htmlFor="">Company Type</label>
                      <SingleSelect
                        placeholder="Company Type"
                        name="company_details[0].company_type"
                        options={options?.company_type}
                        backendsavefield="name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">PAN Number</label>
                      <TextInput
                        placeholder="PAN Number"
                        name="company_details[0].pan_number"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Name on PAN</label>
                      <TextInput
                        placeholder="Name on PAN"
                        name="company_details[0].pan_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Address Line 1</label>
                      <TextInput
                        placeholder="Address Line 1"
                        name="company_details[0].address_line_1"
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="">Address Line 2</label>
                      <TextInput
                        placeholder="Address Line 2"
                        name="company_details[0].address_line_2"
                      />
                    </div>
                    <div className="form-row col-md-3 ">
                      <SingleSelect
                        placeholder="City"
                        name="company_details[0].city"
                        options={options?.city}
                        backendsavefield="name"
                      />
                    </div>
                    <div className="form-row col-md-3 ">
                      <SingleSelect
                        placeholder="State"
                        name="company_details[0].state"
                        backendsavefield="id"
                        options={options?.state}
                      />
                    </div>
                    <div className="form-row col-md-3 ">
                      <TextInput
                        type="number"
                        placeholder="Zip Code"
                        name="company_details[0].pincode"
                      />
                    </div>
                    <div className="form-row col-md-3 ">
                      <SingleSelect
                        placeholder="Country"
                        name="company_details[0].country"
                        backendsavefield="id"
                        options={options?.country}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Landline</label>
                      <TextInput
                        placeholder="Landline"
                        name="company_details[0].phone"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Fax No</label>
                      <TextInput
                        placeholder="Fax No"
                        name="company_details[0].fax_no"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Tax number for international</label>
                      <TextInput
                        placeholder="Tax number for international"
                        name="company_details[0].tax_no"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">GST No</label>
                      <TextInput
                        placeholder="GST No"
                        name="company_details[0].gst_no"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Year of experience</label>
                      <TextInput
                        placeholder="Year of experience"
                        name="company_details[0].year_of_experience"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Income tax return</label>
                      <TextInput
                        placeholder="Year of experience"
                        name="company_details[0].income_tax_return_option"
                      />
                    </div>
                    <div className="form-row col-md-12">
                      <label htmlFor="">GST Certificate</label>
                      <FileInput
                        name={`company_details[0].gst_certificate`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <label htmlFor="">Address Proof</label>
                      <FileInput
                        name={`company_details[0].address_proof`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div>
                    {/* <div className="form-row col-md-6">
                      <label htmlFor="">Income Tax Return</label>
                      <FileInput
                        name={`company_details[0].document_urls.income_tax_return`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div> */}
                    <div className="form-row col-md-6">
                      <label htmlFor="">Experience Certificate</label>
                      <FileInput
                        name={`company_details[0].experience_certificate`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div>
                    <div className="form-row col-md-6">
                      <label htmlFor="">Resume</label>
                      <FileInput
                        name={`company_details[0].resume_upload`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div>
                  </div>

                  <div className="submit-btn-container">
                    <button
                      onClick={handlePrevClick}
                      type="button"
                      className="btn btn-primary"
                    >
                      Previous
                    </button>

                    <button
                      onClick={handleNextClick}
                      type="button"
                      className="btn btn-primary"
                    >
                      Next
                    </button>
                  </div>
                </div>

                {/* this is third section start */}

                <div
                  style={{
                    display: currentDivIndex === 2 ? "block" : "none",
                  }}
                >
                  <div>
                    <p className="blue sub-heading">Bank Details</p>
                  </div>

                  <div className="row gy-4 gx-5 pb-5">
                    <div className="col-md-6">
                      <label htmlFor="">Name as mentioned in the Bank account</label>
                      <TextInput
                        placeholder="Name as mentioned in the Bank account"
                        name="bank_details[0].name_as_mentioned_in_the_bank_account"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Bank Name</label>
                      <TextInput
                        placeholder="Bank Name"
                        name="bank_details[0].bank_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Branch Name & Code</label>
                      <TextInput
                        placeholder="Branch Name & Code"
                        name="bank_details[0].branch_name_and_code"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Account Number</label>
                      <TextInput
                        placeholder="Account Number"
                        name="bank_details[0].account_number"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">IFSC Code</label>
                      <TextInput
                        placeholder="IFSC Code"
                        name="bank_details[0].ifsc_code"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">MMID</label>
                      <TextInput placeholder="MMID" name="bank_details[0].mmid" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">SWIFT Code</label>
                      <TextInput
                        placeholder="SWIFT Code"
                        name="bank_details[0].swift_code"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Professional fees/commission</label>
                      <SingleSelect
                        placeholder="Professional fees/commission"
                        name="bank_details[0].professtional_fee"
                        backendsavefield="id"
                        options={options?.professional_fees}
                      />
                    </div>
                    <div className="form-row col-md-12">
                      <label htmlFor="">Upload Cancel Cheque</label>
                      <FileInput
                        name={`bank_details[0].upload_cancel_cheque_option`}
                        data={{
                          maincategory: "agent",
                          subcategory: "documents",
                        }}
                      />
                    </div>
                  </div>

                  <div className="submit-btn-container">
                    <button
                      onClick={handlePrevClick}
                      type="button"
                      className="btn btn-primary"
                    >
                      Previous
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>

                {currentDivIndex === 3 && (
                  <Navigate to="../partner/thank-you" />
                )}
              </StyledRegisterForm>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FacilitatorForm;
