import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { message } from "antd";
import StyledLoginForm from "./StyledLoginForm";
import { resetPassword } from "@/features/web/web-actions";

const ResetPassword = ({ style }: any) => {
  const { usertype = "" } = useParams<{ usertype: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userEmail = searchParams.get("email");
  const userToken = searchParams.get("id");

  
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    setFieldValue,
    errors,
  } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      email: userEmail ? userEmail : "",
      new_pass: "",
      new_password: "",
      token: userToken ? userToken : "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
        try {
          await dispatch(resetPassword(values));
          message.success("Password reset successfully");
          navigate(`/login/${usertype}`);
        } catch (error) {
          message.error("Failed to reset password.");
        }
      },
    });
  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <form onSubmit={handleSubmit}>
        <StyledLoginForm>
          <h3> Reset Password</h3>
            <>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="password"
                    name="new_pass"
                    className="form-control"
                    placeholder="New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_pass}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="password"
                    name="new_password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password}
                  />
                  {touched.new_password && (
                    <div className="error">{errors.new_password}</div>
                  )}
                </div>
              </div>

              <div className="submit-btn-container">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </>
        </StyledLoginForm>
      </form>
    </div>
  );
};

export default ResetPassword;
