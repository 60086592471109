import styled from "styled-components";
import { BlueButton } from "@/components/styled/forms";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import LocationIcon from "@/components/common/LocationIcon";
import { useNavigate } from "react-router-dom";

const StyledDoctorDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  img {
    width: 178px;
    background: rgb(236 246 253);
    height: 100%;
    border: none;
  }

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: baseline;
    gap: 20px;
    .hospital-name {
      font-size: 36px;
      word-break: break-word;
    }

    .details {
      flex-grow: 1;
      text-align: left;
      font-size: 20px;
    }
  }
  button.enquire {
    width: 234px;
    height: 48px;
  }
  .location_section {
    text-transform: capitalize;
    word-break: break-word;
  }
`;

const DoctorDetail = () => {
  const { doctor } = useSelector((state: RootState) => state.doctor);
  const navigate = useNavigate();

  if (doctor) {
    const {
      profile_url,
      profile_alt_tag,
      salutation,
      first_name,
      last_name,
      practice_start_date,
      Specializations,
      city,
      state,
      id,
      address,
    } = doctor;
    const exp =
      new Date().getFullYear() - new Date(practice_start_date).getFullYear();

    const SpecializationsArr = Array.from(Specializations).map(
      ({ name }: any) => name
    );

    return (
      <StyledDoctorDetail>
        <img alt={profile_alt_tag} src={profile_url} />
        <div className="details">
          <div className="hospital-name blue bold">
            {salutation} {first_name} {last_name}
          </div>
          <LocationIcon className="location_section">
            {[address, city, state].join(" | ")}
          </LocationIcon>
          <div className="link-text">{SpecializationsArr.join(" | ")}</div>
          <div className="link-text">{exp} Years Experience</div>
        </div>
        <BlueButton className="enquire"
        onClick={() => navigate(`/enquiry?doctor_id=${id}`)}
        >Enquire Now</BlueButton>
      </StyledDoctorDetail>
    );
  }

  return null;
};

export default DoctorDetail;
