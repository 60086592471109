import styled from "styled-components";
import { BlueButton } from "@/components/styled/forms";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import LocationIcon from "@/components/common/LocationIcon";
import { Pagination } from "react-bootstrap";
import React from "react";

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    font-size: 20px;
  }
  .image {
    width: 91px;
  }
  .location {
    font-size: 20px;
    font-weight: 500;
  }

  .no-of {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .city-block {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    button {
      width: 152px;
      height: 48px;
      margin: 0 auto;
    }
  }

  @media (min-width: 425px) {
    .image {
      width: 192px;
    }
    .wrapper {
      .city-block {
        display: flex;
        flex-direction: row;
        gap: 36px;

        .info-block {
          display: flex;
          flex-direction: column;

          text-align: left;
          gap: 10px;

          .no-of {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: auto;
          }
          }

          .view-more{

          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .no-of{
      flex-direction: column;
      gap: 10px;
    }
    .image{
      width: 260px;
      height: 150px
    }
  }
`;

interface ITopHospital {
  id: number;
}
export const CityRow = ({ data }: any) => {
  const { id, name, hospital_type, established_in, city, state, profile_url, slug } =
    data;
  const navigate = useNavigate();
  return (
    <div className="city-block">
      <img alt="" className="image" src={profile_url} />
      <div className="info-block">
        <div className="location blue bold">{name}</div>
        <div className="no-of">
          <LocationIcon className="">
            {city}, {state}
          </LocationIcon>
          <div className="hospitals">Established : {established_in}</div>
          <div className="doctors">{hospital_type}</div>
        </div>
        <div className="d-block d-sm-none">
          <BlueButton onClick={() => navigate(`../hospital/${slug}`)}>
            View More
          </BlueButton>
        </div>
        <div className="view-more d-none d-sm-block">
          <NavLink to={`../hospital/${slug}`}>View More &gt;</NavLink>
        </div>
      </div>
    </div>
  );
};
const TopHospitalsTreatment = () => {
  const { data = { name: 'treatment' }, topHospitals } = useSelector(
    (state: RootState) => state.treatment
  );

  const [activePage, setActivePage] = React.useState(1);
  const itemsPerPage = 3; 

  const totalPages = Math.ceil(topHospitals.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  return (
    <StyledSection>
      <div className="title blue bold">
        Top hospitals for {data?.name} in India
      </div>
      <div className="wrapper">
        {topHospitals
          .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
          .map((item: ITopHospital) => (
            <CityRow key={item.id} data={item} />
          ))}
      </div>

      <Pagination className="mt-4 d-flex align-items-center justify-content-center">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === activePage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </StyledSection>
  );
};
export default TopHospitalsTreatment;
