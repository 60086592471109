import { createSlice } from '@reduxjs/toolkit';
import { fetchPopularCitiesById } from './web-actions';

const initialState = {
  data: null,          
  loading: false,      
  error: null,         
};

const popularCitiesByIdSlice = createSlice({
  name: 'popularCities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPopularCitiesById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPopularCitiesById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data; 
        state.error = null;
      })
      .addCase(fetchPopularCitiesById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as any;
      })
  }
});

export default popularCitiesByIdSlice.reducer;
