import styled from "styled-components";
import HelpForm from "../doctors/HelpForm";
import Packages from "./Packages";

const PackageFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 425px) {
    flex-direction: row;
    > .packages {
      flex: 0 0 50%;
    }
  }
`;

const PackageHelpForm = () => {
  return (
    <PackageFormWrapper>
      <Packages />
      <HelpForm isCompact={false} />
    </PackageFormWrapper>
  );
};

export default PackageHelpForm;
