import { useField } from "formik";
import { Form } from "react-bootstrap";

interface Option {
  id: string;
  name: string;
}
interface SingleSelectProps {
  name: string;
  placeholder: string;
  options: Option[];
  backendsavefield?: string;
}

const SingleSelect = (props: SingleSelectProps) => {
  const [{ onChange, onBlur, name }, { value, error, touched }] = useField(
    props.name
  );

  return (
    <>
      <Form.Select
        className="form-control"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        <option value="">{props.placeholder}</option>
        {props.options && props.options.map(({ id, name }: any) => (
          <option key={id} value={props.backendsavefield === 'name' ? name : id}>
            {name}
          </option>
        ))}
      </Form.Select>
      {touched && <div className="error">{error}</div>}
    </>
  );
};

export default SingleSelect;
