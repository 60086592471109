import { Button, Input, Select } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { removeEmpty } from "./entities/EntityPopup";
import { StyledActionButton } from "./entities";
import { CloseOutlined } from "@ant-design/icons";

const FilterStyled = styled.div`
  display: flex;
  gap: 24px;
  .select {
    border: 2px solid var(--blue);
    border-radius: 5px;

    .ant-select-selector {
      border: none;
      border-radius: 10px;
      height: 48px;
      background-color: transparent;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        align-items: center;
        display: flex;
      }
    }
  }

  .search {
    position: relative;
    min-width: 250px;

    &-input {
      height: 48px;
      border: 2px solid var(--blue);
      font-size: 16px;
      overflow: auto;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      left: 0;
      background: #f5f5f5;
    }
    &-clear-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      line-height: initial;
    }
  }
`;

const Filters = ({ entity, setFilterQS, data }: any) => {
  const [values, setValues] = useState<any>({});
  const [showClearBtn, setShowClearBtn] = useState(false);
  const onSearch = () => {
    const queryObj = removeEmpty(values);
    const query = new URLSearchParams(queryObj).toString();
    setFilterQS(query);
  };

  const { search = "" } = values;

  const userFilterByRoleChange = (data: string) => {
    setValues((vals: any) => ({
      ...vals,
      type: data,
    }));
  };

  const onSearchInputChange = (e: any) => {
    const { value } = e.target;
    setValues((vals: any) => ({
      ...vals,
      search: value,
    }));
  };
  useEffect(() => {
    setValues({});
  }, [entity]);

  useEffect(() => {
    setShowClearBtn(search.length > 0);
  }, [search]);

  const renderSwitchCase = () => {
    switch (entity) {
      case "user":
        return (
          <Select
            className="select"
            style={{ width: 140, height: 48}}
            placeholder="Filter by role"
            options={
              data?.options?.type?.map(({ id, name }: any) => ({
                label: name,
                value: id,
              })) || []
            }
            onChange={userFilterByRoleChange}
          />
        );
    }
  };

  const clearSearch = () => {
    const obj = {
      ...values,
      search: "",
    };
    setValues(obj);
    const queryObj = removeEmpty(obj);
    const query = new URLSearchParams(queryObj).toString();
    setFilterQS(query);
  };

  return (
    <FilterStyled>
      <div className="search">
        <Input
          placeholder="Search"
          className="search-input"
          type="text"
          value={search}
          onChange={onSearchInputChange}
        />
        {showClearBtn && (
          <Button
            onClick={clearSearch}
            className="search-clear-icon"
            shape="circle"
            icon={<CloseOutlined />}
          />
        )}
      </div>
      {renderSwitchCase()}
      <StyledActionButton style={{ width: 60 }} onClick={onSearch}>
        Go
      </StyledActionButton>
    </FilterStyled>
  );
};

export default Filters;
