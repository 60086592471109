import styled from "styled-components";

const StyledTitlePara = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .title {
    font-size: 20px;
  }
  .text {
    text-align: left;
  }

  @media (min-width: 425px) {
    .title {
      text-align: left;
      font-size: 24px;
    }
  }
`;

const TitlePara = ({ title, text }: any) => {
  return (
    <StyledTitlePara>
      <div className="title blue bold heading">{title}</div>
      <div className="text link-text">{text}</div>
    </StyledTitlePara>
  );
};

export const TitleParaBlack = ({ title, text }: any) => {
  return (
    <StyledTitlePara>
      <div className="title bold sub-heading">{title}</div>
      <div className="text link-text">{text}</div>
    </StyledTitlePara>
  );
};

export default TitlePara;
