import Modal from "react-bootstrap/Modal";
import SchemaForm from "./EntityForm";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { createEntity, saveEntity } from "../../features/admin/admin-actions";
import { clearViewEntity } from "@/features/admin/admin-slice";
import { useParams } from "react-router-dom";

export function removeEmpty(obj: any) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v !== "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

const DataMapper = (entity: string, formData: any) => {
  if (formData == null) {
    return null;
  }
  const data = removeEmpty(formData) as any;
  switch (entity) {
    case "doctor":
      return {
        ...data,
        country: data.country_id,
        profile_url: [data.profile_url],
        specialization:
          data.Specializations && data.Specializations.map(({ id }: any) => id),
        treatments: data.Treatments && data.Treatments.map(({ id }: any) => id)
       // hospital : data.hospital && data.hospital.map(({ id }: any) => id),
        //type : data.type && data.type.map(({ id }: any) => id)
      };
      case "popularcity":
        return {
          ...data,
          hospital : data.hospital && data.hospital.map(({ id }: any) => id),
        };
    case "hospital":
      return {
        ...data,
        country: data.country_id,
        profile_url: [data.profile_url],
        treatments: data.treatments && data.treatments.map(({ id }: any) => id),
        hospital_infrastructure:
          data.infrastructure && data.infrastructure.map(({ id }: any) => id),
        hospital_rules: data.rules && data.rules.map(({ id }: any) => id),
        hospital_service_categories:
          data.SpecialityCategories &&
          data.SpecialityCategories.map(({ id }: any) => id),
        translator:
          data.translators && data.translators.map(({ id }: any) => id),
        local_commute: data.commutes && data.commutes.map(({ id }: any) => id),
        cuisine: data.cuisines && data.cuisines.map(({ id }: any) => id),
        form_c: [data.form_c].filter(Boolean),
        accreditation: data.accreditation
          ? data.accreditation.map(({ id }: any) => id)
          : [],
      };
    case "treatment":
      return {
        ...data,
        domestic_cost: {
          min: data.min_domestic_cost,
          max: data.max_domestic_cost,
        },
        international_cost: {
          min: data.min_international_cost,
          max: data.max_international_cost,
        },
      };
    case "blog":
      return {
        ...data,
        profile_url: [data.profile_url],
        document_url: [data.document_url],
      };
    case "banner":
      return {
        ...data,
        banner_url: [data.banner_url],
      };
    case "speciality":
      return {
        ...data,
        profile_url: [data.profile_url],
      };
    case "patient":
      return {
        ...data,
        country: data.country_id,
        profile_url: [data.profile_url],
        document_url: [data.document_url],
        specialization:
        data.Specializations && data.Specializations.map(({ id }: any) => id),
       // treatments: data.Treatments && data.Treatments.map(({ id }: any) => id),
        ecy_p_relationship_id : data.ecy_p_relationship_id && data.ecy_p_relationship_id.map(({ id }: any) => id),
      };
    case "package":
        return {
          ...data,
          profile_url: [data.profile_url]
        };
    default:
      return data;
  }
};

const useFormData = (formData: any) => {
  const { entity = "" } = useParams();
  const val = DataMapper(entity, formData);
  return val;
};

const FormPopup = ({ show, handleClose, entity }: any) => {
  const { viewedEntity, entities } = useSelector((state: any) => state.admin);
  const [schema, setSchema] = useState<RJSFSchema | null>();
  const [uiSchema, setUiSchema] = useState<UiSchema | null>();
  const [errors, setErrors] = useState<any>({});
  const [message, setMessage] = useState<null | string>(null);
  const dispatch = useDispatch<AppDispatch>();

  const modelHeading = useMemo(() => {
    let title = "Add";
    if (viewedEntity && viewedEntity.data) {
      title = "Edit";
    }
    title += ` ${entities[entity].title}`;
    return title;
  }, [viewedEntity, entity, entities]);

  useEffect(() => {
    import(`../schemas/${entity}.json`).then((component) => {
      let { properties, required } = component;
      if (viewedEntity && viewedEntity.data) {
        required = required.filter((r: string) => r !== "password");
        delete properties.password;
      }
      setSchema({
        ...component,
        required: [...required],
        properties: { ...properties },
      } as RJSFSchema);
    });

    import(`../schemas/${entity}-ui.json`)
      .then((component) => {
        setUiSchema(component as UiSchema);
      })
      .catch(({ message }) => {
        console.warn(message);
      });
    setErrors("");
    setMessage("");
  }, [entity, viewedEntity]);

  const onSubmit = useCallback(
    ({ formData }: any) => {
      setErrors("");
      setMessage("");
      if (viewedEntity) {
        dispatch(
          saveEntity({
            body: formData,
            id: viewedEntity.data.id,
            name: entity,
          })
        ).then((res: any) => {
          if (res.hasOwnProperty("error")) {
            if (res.payload.hasOwnProperty("errors")) {
              const { errors } = res.payload;
              if (Object.keys(errors).length) {
                console.log(res.payload.errors);
                setErrors(res.payload.errors);
              } else {
                setMessage(res.payload.message);
              }
            }
          } else {
            handleClose();
          }
        });
      } else {
        dispatch(
          createEntity({
            body: formData,
            name: entity,
          })
        ).then((res: any) => {
          if (res.hasOwnProperty("error")) {
            if (res.payload.hasOwnProperty("errors")) {
              const { errors } = res.payload;
              if (Object.keys(errors).length) {
                console.log(res.payload.errors);
                setErrors(res.payload.errors);
              } else {
                setMessage(res.payload.message);
              }
            }
          } else {
            handleClose();
          }
        });
      }
      return false;
    },
    [viewedEntity, entity]
  );

  const preClose = () => {
    dispatch(clearViewEntity());
    setErrors("");
    setMessage("");
    handleClose();
  };
  const formData = useFormData(viewedEntity && viewedEntity.data);

  return (
    <Modal show={show} onHide={preClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{modelHeading}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message && (
          <div style={{ textAlign: "center", color: "red" }}>{message}</div>
        )}
        {schema && (
          <SchemaForm
            formData={formData}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={onSubmit}
            entity={entity}
            errors={errors}
          />
        )}
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default FormPopup;
