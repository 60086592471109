import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAssociateDoctors,
  fetchHospitalById,
  fetchHospitalGallery,
} from "./hospital-actions";
import { fetchDoctorsByHospitalId } from "./doctor-actions";

const hospitalSlice = createSlice({
  name: "hospital",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
    gallery: [],
    association: {},
    associatedDrs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchHospitalById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.error = null;
      })
      .addCase(fetchHospitalById.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.error = action.error.message as any;
      })
      .addCase(fetchHospitalGallery.fulfilled, (state, action) => {
        state.gallery = action.payload;
      })
      .addCase(fetchAssociateDoctors.fulfilled, (state, action) => {
        state.associatedDrs = action.payload;
      })
      .addCase(fetchDoctorsByHospitalId.fulfilled, (state, action) => {
        state.association = {
          ...state.association,
          // TODO:
          // ["234"]: action.payload,
        };
      });
  },
});

export default hospitalSlice.reducer;
