const CostRange = (props: any) => {
  return (
    <div>
      <input type="number" />
      768
      <input type="number" />
    </div>
  );
};

export default CostRange;
