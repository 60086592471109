import styled from "styled-components";
import SearchBar from "../hospitals/SearchBar";
import PackageCard from "./PackageCard";
import HelpForm from "../doctors/HelpForm";
import { useEffect, useState } from "react";
import { getPackage } from "@/features/admin/admin-actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;

  .package-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media (max-width: 768px) {
    .package-list {
   justify-content:center;
   padding: 0em 2em;
    }
  }
`;

const PackageListing = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      getPackage({
        page: 1,
        size: 50,
      })
    ).then((res: any) => {
      setData(res.payload.rows);
    });
  }, [dispatch]);

  return (
    <BodyWrapper>
      <SearchBar entity="package" />
      <h2 className="blue sub-heading">Packages</h2>
      <div className="package-list">
        {data.map((data) => (
          <PackageCard {...(data as object)} />
        ))}
      </div>
      <HelpForm style={{ maxWidth: 842, margin: "0 auto" }} />
    </BodyWrapper>
  );
};

export default PackageListing;
