import { createSlice } from '@reduxjs/toolkit';
import { getStaticPages } from './web-actions';

interface StaticPageState {
  [key: string]: {
    images: any[];
    loading: boolean;
    error: string | null;
  };
}

const initialState: StaticPageState = {};

const staticPageSlice = createSlice({
  name: 'StaticPages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStaticPages.pending, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        if (!state[categoryId]) {
          state[categoryId] = { images: [], loading: false, error: null };
        }
        state[categoryId].loading = true;
        state[categoryId].error = null;
      })
      .addCase(getStaticPages.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state[categoryId].loading = false;
        state[categoryId].images = action.payload;
      })
      .addCase(getStaticPages.rejected, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        state[categoryId].loading = false;
        state[categoryId].error = action.error.message || 'Failed to fetch images';
      });
  },
});

export default staticPageSlice.reducer;
