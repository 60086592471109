import styled from "styled-components";
import { BlueButton } from "@/components/styled/forms";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import LocationIcon from "@/components/common/LocationIcon";
import NABH from "@/assets/accreditation/nabh.png";
import NABL from "@/assets/accreditation/nabl.png";
import JCI from "@/assets/accreditation/jci.png";

const StyledHospitalDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: baseline;
    gap: 20px;

    > img {
      width: 240px;
    }
    .hospital-name {
      font-size: 28px;
      word-break: break-word;
    }

    .details {
      flex-grow: 1;
      text-align: left;
      font-size: 20px;
    }
  }
  button.enquire {
    width: 234px;
    height: 48px;
  }
  .hospitalCity {
    text-transform: capitalize;
  }
`;
const AccreditationSection = styled.div`
  .accreditationImg {
    display: flex;
    align-items: center;
  }
  .accreditationImg img {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 768px) {
    .accreditationImg {
      justify-content: space-around;
    }
  }
`;
interface Accreditation {
  id: number;
  name: string;
}

interface Hospital {
  accreditation: Accreditation[];
}

const AccreditationImages: React.FC<{ accreditation: Accreditation[] }> = ({
  accreditation,
}) => {
  const accreditationImages: Record<string, string> = {
    NABH: NABH,
    NABL: NABL,
    JCI: JCI,
  };
  return (
    <AccreditationSection>
      <div className="accreditationImg">
        {accreditation.map((accreditation) => {
          const { id, name } = accreditation;
          const imageUrl = accreditationImages[name];
          return (
            <div key={id}>
              {imageUrl ? <img src={imageUrl} alt={name} /> : <p>{name}</p>}
            </div>
          );
        })}
      </div>
    </AccreditationSection>
  );
};
const HospitalDetail = () => {
  const { data: hospital } = useSelector((state: RootState) => state.hospital);

  const navigate = useNavigate();

  if (!hospital) {
    return null;
  }

  const {
    name,
    HospitalType,
    city,
    profile_url,
    profile_alt_tag,
    id,
    Ownership,
    accreditation,
  } = hospital || {};

  return (
    <StyledHospitalDetail>
      <img alt={profile_alt_tag} src={profile_url} />
      <div className="details">
        <div className="hospital-name blue bold"> {name}</div>
        <div className="link-text d-block d-sm-none">
          {HospitalType && (HospitalType as any).name}
        </div>
        <LocationIcon className="hospitalCity">{city}</LocationIcon>
        <p>Ownership ({(Ownership as any).name})</p>
        <AccreditationImages accreditation={accreditation} />
        <div></div>
      </div>
      <BlueButton
        className="enquire"
        onClick={() => navigate(`/enquiry?hospital_id=${id}`)}
      >
        Enquire
      </BlueButton>
    </StyledHospitalDetail>
  );
};

export default HospitalDetail;
