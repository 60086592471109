import { API_BASE_URL } from "@/utils/Env";

export const registerPatient = async (postdata: any) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/tourism/patient/web/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};

export const updatePatient = async (id: number, postdata: any) => {
  try {
    const config = {
      method: "PUT",
      body: JSON.stringify(postdata),
    };
    const resp = await fetch(
      `${API_BASE_URL}/api/tourism/patient/edit/${id}`,
      config
    ).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};
