import { API_BASE_URL } from "@/utils/Env";
import { PlusCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useField } from "formik";

interface FileInputProps {
  name: string;
  placeholder?: string;
  data?: any;
}

const FileInput = (props: FileInputProps) => {
  const { name, placeholder, data } = props;
  const [, { value }, { setValue }] = useField(name);

  const uploadProps = {
    name: "file",
    data,
    multiple: true,
    action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);

        setValue([...value, info.file.response.imageUrl]);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Dragger {...uploadProps}>
      <PlusCircleOutlined />
      <p className="ant-upload-text">{placeholder || "Attachments"}</p>
    </Dragger>
  );
};

export default FileInput;
