import { API_BASE_URL } from "@/utils/Env";
import { Avatar, Upload, message } from "antd";
import { useField } from "formik";

const ProfilePictureInput = (props: any) => {
  const { name } = props;
  const [, { value }, { setValue }] = useField(name);

  const uploadProps = {
    name: 'file',
    data: {
      maincategory: "patient",
      subcategory: "profile",
    },
    multiple: false,
    action: `${API_BASE_URL}/api/tourism/fileupload/web-public-upload`,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);

        setValue(info.file.response.imageUrl);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="profile-picture-upload">
      <Avatar className="avatar" src={value} />
      <br />
      <br />
      <Upload {...uploadProps}>
        <div className="blue text-center">Edit profile photo</div>
      </Upload>
    </div>
  );
};

export default ProfilePictureInput;
