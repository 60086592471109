import { createSlice } from '@reduxjs/toolkit';
import { getGroupAwards } from './web-actions';



interface DestinationState {
  images: any[];
  loading: boolean;
  error: string | null;
}

const initialState: DestinationState = {
  images: [],
  loading: false,
  error: null,
};

const groupAwardSlice = createSlice({
  name: 'groupAwards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupAwards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupAwards.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(getGroupAwards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default groupAwardSlice.reducer;
