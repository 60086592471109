import React from 'react'
import styled from "styled-components";
import img1 from "@/assets/amulance-cervices-img/road-ambulance/infection-control.jpg"
import img2 from "@/assets/amulance-cervices-img/road-ambulance/pain-management.jpg"
import img3 from "@/assets/amulance-cervices-img/road-ambulance/safety.jpg"
import img4 from "@/assets/amulance-cervices-img/road-ambulance/skilled-team.jpg"
import img5 from "@/assets/amulance-cervices-img/road-ambulance/who-we-are.jpg"
import img6 from "@/assets/amulance-cervices-img/road-ambulance/why-choose-us.jpg"


const StyledSection = styled.div`
.section1{
    display:flex;
    align-items:center;
    justify-content:center;
}
img{
    width: 400px;
}
`;

function index() {
  return (
      <StyledSection>
        <div className='air_amb'>
            <h1 className='blue heading bold'>Road Ambulance</h1>
            <div className="who_we_are">
                        <h6 className='sub-heading blue bold pt-5 pb-4'>Who we are</h6>
                <div className="row section1 ">
                    <div className="col-md-6">
                        <img src={img5} className='img-fluid' alt="" />
                    </div>
                    <div className="col-md-6 justify">
                        <p>At Plan Medical Tourism , we are dedicated to providing exceptional road ambulance services that prioritize patient care, safety, and professionalism. With a team of highly trained medical professionals and state-of-the-art ambulances, we are committed to ensuring the safe and efficient transfer of patients in various medical situations. <br /><br />

                       <b>Our Mission</b> : Our mission is to deliver compassionate, high-quality medical transportation that meets the diverse needs of our community. We strive to make a positive impact on the lives of patients and their families by providing reliable and responsive road ambulance services.
                        </p>
                    </div>
                </div>
                <div>
                  <ul className="justify">
                  <h6 className='sub-heading blue  pt-5 pb-4'>Our Services</h6>
                    <li><b>Emergency Medical Response</b>: Our rapid emergency response team is available 24/7 to provide critical care on-site and ensure timely transportation to medical facilities.</li>
                    <li><b>Non-Emergency Medical Transport</b>: For patients requiring medical supervision during transfers between facilities or home, we offer non-emergency medical transport services with skilled medical escorts.</li>
                    <li><b>Interfacility Transfers</b>: We facilitate seamless transfers between hospitals, clinics, and other medical facilities, ensuring patients receive the care they need.</li>
                    <li><b>Specialized Care</b>: Our trained paramedics and EMTs are equipped to handle a wide range of medical conditions, offering specialized care for various patient populations.</li>
                    <li><b>Trauma Care</b>: In critical situations, our trauma care services provide immediate medical attention and stabilization before transport to appropriate medical centers.</li>
                    <li><b>Basic and Advanced Life Support</b>: We offer both Basic Life Support (BLS) and Advanced Life Support (ALS) services to cater to different levels of medical need.</li>
                  </ul>
                </div>
            </div>
            <div className="what_we_offer">
                <div className="row section1 ">
                    <div className="col-md-6">
                        <img src={img4} className='img-fluid' alt="" />
                    </div>
                    <div className="col-md-6 justify">
                        <p><b>Our Commitment</b> : Patient-Centered Approach: Every decision we make is guided by the best interests of our patients. We prioritize their comfort, dignity, and well-being throughout the transportation process. <br/>

                       <b>Skilled Medical Team</b> : Our dedicated team of paramedics and EMTs undergoes rigorous training and stays current with the latest medical practices to ensure optimal patient care. <br/>

                       <b>State-of-the-Art Ambulances</b> : Our fleet of well-equipped ambulances is outfitted with advanced medical equipment and technology, allowing us to provide the highest level of care during transport. <br/>

                       <b>Community Engagement</b> : We are actively involved in community health initiatives, providing first aid training and education to enhance public safety and well-being. <br/>

                       <b>Safety and Compliance</b> : Adhering to strict safety protocols and regulatory standards, we ensure that every transport is conducted with the utmost attention to safety and professionalism.<br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="what_we_offer">
                        <h6 className='sub-heading blue bold pt-5 pb-4'>What we offer (road ambulance)</h6>
                <div className="row section1 ">
                    <div className="col-md-12 justify">
                        <p>
                        As a road ambulance service provider, you offer essential and potentially life-saving transportation for patients in various medical situations. Your services encompass a range of specialized care and resources to ensure the safe and efficient transfer of patients from one location to another. Here's what you might offer as part of your road ambulance services:<br/><br/>

                        <b> Emergency Medical Services (EMS):</b> <br/><br/>
                        Prompt emergency response to 911 calls and other urgent medical situations.
                        Trained paramedics and EMTs equipped to provide on-site medical assessment and intervention.
                        Critical care capabilities, including advanced airway management, cardiac monitoring, and medication administration.<br/><br/>

                        <b> Non-Emergency Medical Transportation:</b> <br/><br/>
                        Scheduled transport for patients who require medical supervision during transfers between medical facilities, clinics, or home.
                        Medical escorts for patients who need assistance but do not require emergency medical care.
                        Medical Equipment and Supplies:
                        Well-equipped ambulances with essential medical equipment, such as cardiac monitors, oxygen, defibrillators, IV supplies, and medications.
                        Stretchers designed for patient comfort and safety during transport.
                        Specialized Care:
                        Skilled medical personnel trained to handle various medical conditions, injuries, and emergencies.
                        Specialized care for specific patient populations, such as pediatric, geriatric, or patients with mobility challenges.<br/><br/>

                        <b> Trauma Care:</b><br/><br/>
                        Rapid response to trauma scenes to provide immediate medical attention and stabilize patients before transport.
                        Transport to trauma centers or appropriate medical facilities for further care.<br/><br/>

                        <b>Interfacility Transfers:</b>   <br/><br/>
                        Safe and seamless transfers between hospitals, clinics, and other medical facilities.
                        Medical supervision during transfers to ensure continuity of care.<br/><br/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="what_we_offer">
                <div className="row section1 pt-5">
                    <div className="col-md-6">
                        <img src={img2} className='img-fluid' alt="" />
                    </div>
                    <div className="col-md-6 justify">
                        <p>
                        <b>Pain Management:</b> <br/>
                        Administration of pain relief medications and interventions as appropriate for patients in pain.<br/><br/>
                        <b>Basic Life Support (BLS) and Advanced Life Support (ALS):</b>    BLS services for patients requiring basic medical care and transportation. ALS services for patients with critical medical needs, including advanced airway management, cardiac monitoring, and medication administration.<br/>
                        <b>Continuous Monitoring:</b> 
                        Ongoing monitoring of patient vital signs and medical condition throughout the transport.<br/>
                        </p>
                    </div>
                    <div className="justify">
                      <p>
                        <b>Infection Control and Hygiene:</b> 
                        Adherence to strict infection control protocols to prevent the spread of infections within the ambulance environment.<br/>
                        <b>Patient Comfort and Dignity:</b> 
                        Compassionate care that respects the patient's comfort, privacy, and dignity during the transportation process.<br/>
                        <b>Communication and Coordination: </b>  Effective communication with medical facilities, receiving hospitals, and emergency services to ensure seamless transitions and exchange of medical information. <br/>
                      <b>Emergency Obstetric Services:</b> 
                      Transport for pregnant women in labor or requiring specialized obstetric care.<br/>
                      <b>Community Engagement:</b>  
                      Active participation in community health and safety initiatives, such as first aid training and public health education.<br/>
                      <b>Insurance and Cost Assistance:</b> 
                      Transparent pricing structure and assistance with insurance claims for patients and their families.<br/>
                      <b>Patient and Family Communication:</b>   
                      Regular updates and communication with patients and their families to keep them informed about the transport process. <br/>
                      </p>
                    </div>
                </div>
            </div>
            <div className="what_we_offer">
                        <h6 className='sub-heading blue pt-5 bold'>WHY CHOOSE US</h6>
                <div className="row section1 pt-5">
                    <div className="col-md-6">
                        <img src={img6} className='img-fluid' alt="" />
                    </div>
                    <div className="col-md-6 justify">
                        <p> 
                        When choosing a road ambulance service, there are several factors to consider to ensure the safe and effective transportation of patients. Here are some reasons why someone might choose your road ambulance service: <br/><br/>

                        <b>Medical Expertise</b> : Your service employs skilled and certified medical professionals, including paramedics and EMTs, who are experienced in providing medical care during transportation. The quality of medical personnel is crucial for ensuring patient safety and care.
                        </p>
                    </div>
                    <div className="justify pt-4">
                      <p>
                      <b>  Equipped Ambulances</b>: Your fleet of ambulances is well-equipped with essential medical equipment and technology, such as cardiac monitors, oxygen supply, stretchers, medical supplies, and other necessary tools for providing care en route.<br/>

                      <b>Coverage Area</b> : Your service operates within a broad coverage area, ensuring that you can provide timely response and transportation to various locations, including urban and rural areas.<br/>

                      <b>24/7 Availability</b>: Your service offers round-the-clock availability to respond to emergency calls and provide medical transportation at any time of day or night.<br/>

                      <b>Rapid Response</b> : You prioritize quick mobilization and response times to reach the patient's location promptly, especially in emergency situations.<br/>

                      <b>Specialized Care</b> : Your team is trained to provide specialized care for different medical conditions, including trauma, cardiac emergencies, respiratory distress, and more.<br/>

                      <b>Interfacility Transfers</b> : You offer safe and efficient transfers between medical facilities, ensuring that patients receive the necessary care during transportation.<br/>

                      <b> Communication and Coordination</b>: Effective communication and coordination with referring and receiving medical facilities, as well as with emergency services, to ensure seamless transitions and accurate exchange of medical information.<br/>
                      </p>
                    </div>
                </div>
            </div>
            <div className="what_we_offer">
                <div className="row section1 pt-5">
                    <div className="col-md-6 justify">
                        <p> 
                        <b>Patient Comfort and Dignity</b> : Your staff is trained to provide compassionate care that respects the patient's dignity and comfort throughout the transportation process.<br/>

                        <b>Safety and Regulatory Compliance</b>  : Adherence to strict safety protocols and regulatory standards to ensure patient safety and comply with relevant medical and transportation regulations.<br/>

                        <b>Experience and Reputation</b>  : A track record of safe and successful patient transfers, with positive feedback and testimonials from previous clients.<br/>

                        <b> Accessibility</b> : Your service is accessible to people with various medical needs, including those who require assistance with mobility or have specific medical requirements.<br/>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={img3} className='img-fluid' alt="" />
                    </div>
                </div>
                <p className='justify'>
                <b>Adaptability</b>  : Your service can adapt to different situations and patient needs, whether it's a routine transfer, emergency response, or specialized medical transport.<br/>

                <b> Reliability</b>: Consistent and reliable service that can be counted on in critical situations.<br/>

                <b>Community Engagement</b>  : Involvement and engagement with the local community, demonstrating a commitment to providing essential medical services.<br/>

                <b>Continuous Training</b>  : Your medical personnel undergo regular training and updates to stay current with the latest medical practices and technologies.<br/>

                <b> Communication with Family</b> : Keeping the patient's family informed about the transportation process and providing updates as needed.<br/>
                </p>
            </div>
        </div>
    </StyledSection>

  )
}

export default index