import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import HospitalForm from "@/components/HospitalRegistrationForm/HospitalFormWizard";
import PartnerCarousel from "@/components/PartnerCarousels/PartnerCarousel";
import PatientForm from "@/components/PatientRegistrationForm/PatientForm";
import DoctorStepsWizard from "@/components/DoctorRegistrationForm/DoctorFormWizard";

import verified from "@/assets/check-verified-02.png";
import FacilitatorForm from "@/components/FacilitatorRegistrationForm/FacilitatorForm";
import { Helmet } from "react-helmet";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const ThankYouPage = () => {
  return (
    <div className="card shadow" style={{ padding: 36 }}>
      <div className="success">
        <div className="icon">
          <img src={verified} alt="..." />
        </div>
        <p className="blue heading bold">
          Thank You! Your response has been recorded!
        </p>
        <p className="body-text">Our representative will contact you shortly</p>
      </div>
    </div>
  );
};

const Partner = () => {
  let { usertype } = useParams();

  let content;
  if (usertype === "doctor") {
    content = <DoctorStepsWizard />;
  } else if (usertype === "hospital") {
    content = <HospitalForm />;
  } else if (usertype === "patient") {
    content = <PatientForm />;
  } else if (usertype === "facilitator") {
    content = <FacilitatorForm />;
  } else if (usertype === "thank-you") {
    content = <ThankYouPage />;
  } else {
    content = null;
  }

  return (
    <BodyWrapper>
       <Helmet>
        <title>Our Partner | plan medical tourism</title>
        <meta name="description" content="plan medical tourism partner for Travel Tourism, Hospital, Wellness and more." />
        <link rel="canonical" href="https://planmedicaltourism.com/partner" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="form_wizards">{content}</div>
      <PartnerCarousel />
    </BodyWrapper>
  );
};

export default Partner;
