import { getOptions, viewEntity } from "@/features/admin/admin-actions";
import {
  getInterpretors,
  getTranslators,
  getLanguages,
  CreateUpdateAppointment,
  guestCreateAppointment,
} from "@/features/web/interpreter-actions";
import useRoles from "@/components/hooks/useRoles";
import { setLanguages } from "@/features/web/languagesSlice";
import { AppDispatch, RootState } from "@/store";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;

  .interpreter_row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .form-row {
    display: flex;
    flex-direction: row;
  }

  .form-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  input,
  textarea,
  select,
  button {
    border: 1px solid var(--blue);
  }

  input,
  select {
    height: 50px;
  }

  .form-row-timings {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    overflow: auto;
    scroll-snap-type: x mandatory;
    width: 100%;
    margin: 0 auto;

    button {
      background: white;
      border-radius: 5px;
      height: 50px;
      flex: 1 1 auto;
      display: flex;
      max-width: 114px;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 1.7rem;
      margin: 0rem 0rem 1rem;
      width: 100%;
      &.active {
        color: white;
        background: var(--blue);
      }
    }
  }
  .inter_radio {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .btn_title {
    display: inline-block;
    word-break: revert;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin: 1rem auto;
    font-size: 13px;
    font-weight: 500;
  }
  .submit-btn-container {
    text-align: center;

    button {
      background-color: var(--blue);
      height: 48px;
      width: 185px;

      @media (min-width: 425px) {
        width: 292px;
        height: 55px;
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }

  .appointment {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .body-text {
      margin-bottom: 24px;
    }
  }
  .table-container {
    max-height: 400px;
    overflow-y: scroll;
  }

  .table {
    width: 100%;
  }
`;

const entityName = "enquiry";

interface Language {
  languageCode: string;
  language: string;
}
interface TranslatorData {
  translatorCode: number;
  yearsofExperience: number;
  experinceSummary: string;
}
interface Translator {
  translatorCode: number;
  yearsofExperience: number;
  experinceSummary: string;
}
interface Interpreter {
  translatorCode: number;
  yearsofExperience: number;
  experinceSummary: string;
}
interface Props {
  data: TranslatorData[];
  onSelectionChange: (selectedItems: TranslatorData[]) => void;
}

const InterpreterServices = ({ style, isCompact = false }: any) => {
  const guestBookingToken = localStorage.getItem("bookingToken");
  const { userInfo = null, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const { entityId = null } = userInfo || {};
  const [userData, setUserData] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<TranslatorData | null>(null);

  const handleCheckboxChange = (translator: TranslatorData) => {
    if (
      selectedItem &&
      selectedItem.translatorCode === translator.translatorCode
    ) {
      setSelectedItem(null);
    } else {
      setSelectedItem(translator);
    }
  };

  let [searchParams] = useSearchParams();

  const intialValues = {
    first_name: userData ? userData.first_name : "",
    last_name: userData ? userData.last_name : "",
    email: userData ? userData.email : "",
    password: "",
    medical_problem: "",
    gender: userData ? userData.gender : "",
    contact_no: userData ? userData.contact_no : "",
    source_lang_code: "",
    target_lang_code: "",
    appointment_date: "",
    appointment_location: "",
    appointment_link: "",
    appointment_type: "",
  };
  const [showForm, setShowForm] = useState(true);
  const [fetchedLanguages, setFetchedLanguages] = useState<Language[]>([]);
  const [fetchedTranslators, setFetchedTranslators] = useState<Translator[]>(
    []
  );
  const [fetchedInterpreters, setFetchedInterpreters] = useState<Interpreter[]>(
    []
  );
  const [fetchedValue, setFetchedValue] = useState<Translator[]>([]);
  useEffect(() => {
    const tableData =
      fetchedTranslators.length > 0 ? fetchedTranslators : fetchedInterpreters;
    setFetchedValue(tableData);
  }, [fetchedTranslators, fetchedInterpreters]);
  const languagesToDisplay = fetchedLanguages ?? [];
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };

  const dispatch = useDispatch<AppDispatch>();

  const languages = useSelector((state: RootState) => state.languages);
  const translators = useSelector((state: RootState) => state.translators);
  const interpreters = useSelector((state: RootState) => state.interpreters);
  const isPatient = useRoles("PATIENT");
  useEffect(() => {
    if (isAuthenticated && entityId && isPatient) {
      dispatch(viewEntity({ name: "patient", id: entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setUserData((payload as any).data);
          }
        }
      );
    }
  }, [dispatch, isAuthenticated, entityId, isPatient]);
  useEffect(() => {
    dispatch(getLanguages())
      .then((action) => {
        setFetchedLanguages(action.payload);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);

  const [selectedOption, setSelectedOption] = useState("");
  const [showSelectInput, setShowSelectInput] = useState(false);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>, resetForm: () => void) => {
    setSelectedOption(e.currentTarget.value);
    setShowSelectInput(e.currentTarget.value === "yes");
    resetForm();
    setSelectedItem(null);
    setFetchedTranslators([]);
    setFetchedInterpreters([]);
  };
  function isValidDate(dateString: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    const isValid = regex.test(dateString);
    return isValid;
  }
  const postdata = {
    sourceLangCode: "123",
    targetLangCode: "124",
  };

  return (
    <div
      className="card shadow"
      style={{ padding: 24, maxWidth: 842, margin: "0 auto", ...style }}
    >
      {showForm && (
        <>
          <p className="sub-heading bold blue">Translator</p>
          <Formik
            enableReinitialize={true}
            initialValues={intialValues}
            validate={(values) => {
              const errors: any = {};

              if (!values.source_lang_code) {
                errors.name = "Required";
              }
              if (!values.target_lang_code) {
                errors.name = "Required";
              }
              return errors;
            }}
            onSubmit={(
              { target_lang_code, source_lang_code, ...restValues },
              { setSubmitting, resetForm, setErrors }
            ) => {
              const values = {
                ...restValues,
                // selectedValue: selectedOption,
                translator_code: selectedItem?.translatorCode,
                token: guestBookingToken,
                source_lang_code: parseInt(source_lang_code),
                target_lang_code: parseInt(target_lang_code),
                appointment_status: "Pending",
                patient_id: userData ? userData.id : null,
              };
              
              dispatch(guestCreateAppointment(values)).then(() => {
                setErrors({});
                resetForm();
                setShowForm(false);
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <StyledForm>
                  <div className="">
                    <div className="interpreter_row">
                      <div className="">
                        <label className="bold blue inter_radio">
                          Get Translator
                          <input
                            type="radio"
                            value="yes"
                            checked={selectedOption === "yes"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleOptionChange(e, resetForm)
                            }
                          />
                        </label>
                      </div>
                      <div className="">
                        <label className="bold blue inter_radio">
                          Get Interpreter
                          <input
                            type="radio"
                            value="no"
                            checked={selectedOption === "no"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleOptionChange(e, resetForm)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    {(selectedOption === "yes" || selectedOption === "no") && (
                      <div className="d-none d-sm-block">
                        <div className="row gx-5 gy-4">
                          <div className="row gx-5 gy-4">
                            <div className="form-group col-md-6 ">
                              <Form.Select
                                placeholder=""
                                className="form-control"
                                name="source_lang_code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.source_lang_code}
                              >
                                <option value="" selected disabled>
                                  Select Source Language
                                </option>
                                {languagesToDisplay.map((lang, index) => (
                                  <option key={index} value={lang.languageCode}>
                                    {lang.language}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="form-group col-md-6 ">
                              <Form.Select
                                placeholder=""
                                className="form-control"
                                name="target_lang_code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.target_lang_code}
                              >
                                <option value="" selected disabled>
                                  Select Target Language
                                </option>
                                {languagesToDisplay.map((lang, index) => (
                                  <option key={index} value={lang.languageCode}>
                                    {lang.language}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="form-group col-md-6">
                              <div className="form-row">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Date of appointment: DD/MM/YYYY"
                                  name="appointment_date"
                                  onChange={handleChange}
                                  onBlur={(e) => {
                                    e.target.type = "text";
                                    handleBlur(e);
                                    if (isValidDate(e.target.value)) {
                                      const postdata = {
                                        sourceLangCode: values.source_lang_code,
                                        targetLangCode: values.target_lang_code,
                                        appointmentdate: e.target.value,
                                      };
                                      if (selectedOption === "yes") {
                                        dispatch(getTranslators(postdata))
                                          .then((action) => {
                                            setFetchedTranslators(
                                              action.payload
                                            );
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error fetching translators:",
                                              error
                                            );
                                          });
                                      } else if (selectedOption === "no") {
                                        dispatch(getInterpretors(postdata))
                                          .then((action) => {
                                            setFetchedInterpreters(
                                              action.payload
                                            );
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error fetching translators:",
                                              error
                                            );
                                          });
                                      }
                                    }
                                  }}
                                  value={values.appointment_date}
                                  onFocus={(e) => (e.target.type = "date")}
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-6 ">
                              <Form.Select
                                placeholder="appointment_type"
                                className="form-control"
                                name="appointment_type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.appointment_type}
                              >
                                <option value="" selected disabled>
                                  Select Appointment Type
                                </option>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="table-container">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Select</th>
                                    <th>#</th>
                                    <th>Experience</th>
                                    <th>Summary</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fetchedValue.map((translator) => (
                                    <tr key={translator.translatorCode}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedItem?.translatorCode ===
                                            translator.translatorCode
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(translator)
                                          }
                                        />
                                      </td>
                                      <td>{translator.translatorCode}</td>
                                      <td>{translator.yearsofExperience}</td>
                                      <td>{translator.experinceSummary}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {userInfo !== null ? (
                            <div></div>
                          ) : (
                            <div className="row gx-5 gy-4">
                              <div className="form-row col-md-6">
                                <input
                                  type="input"
                                  className="form-control"
                                  placeholder="First Name"
                                  name="first_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.first_name}
                                />
                                {touched.first_name && (
                                  <div className="error">
                                    {errors.first_name as string}
                                  </div>
                                )}
                              </div>
                              <div className="form-row col-md-6">
                                <input
                                  type="input"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="last_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.last_name}
                                />
                                {touched.last_name && (
                                  <div className="error">
                                    {errors.last_name as string}
                                  </div>
                                )}
                              </div>
                              <div className="form-row col-md-6">
                                <input
                                  type="input"
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                {touched.email && (
                                  <div className="error">
                                    {errors.email as string}
                                  </div>
                                )}
                              </div>
                              <div className="form-row col-md-6">
                                <Form.Select
                                  className="form-control"
                                  placeholder="gender"
                                  name="gender"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.gender}
                                >
                                  <option value="" selected disabled>
                                    Select Gender
                                  </option>
                                  <option value="M">Male</option>
                                  <option value="F">Female</option>
                                </Form.Select>
                                {touched.gender && (
                                  <div className="error">
                                    {errors.gender as string}
                                  </div>
                                )}
                              </div>
                              <div className="form-row col-md-12">
                                <input
                                  type="input"
                                  className="form-control"
                                  placeholder="Contact No"
                                  name="contact_no"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.contact_no}
                                />
                                {touched.contact_no && (
                                  <div className="error">
                                    {errors.contact_no as string}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="row gx-5 gy-4 mt-0">
                            <div className="form-row col-md-12">
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Medical Problem"
                                name="medical_problem"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.medical_problem}
                              />
                              {touched.medical_problem && (
                                <div className="error">
                                  {errors.medical_problem as string}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group col-md-12">
                            <div className="submit-btn-container">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </StyledForm>
              </form>
            )}
          </Formik>
        </>
      )}

      {!showForm && (
        <div>
          <p className="sub-heading bold blue">Enquiry sent Successfully!</p>
          <div className="submit-btn-container">
            <button
              type="button"
              onClick={() => setShowForm(true)}
              className="btn btn-primary"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterpreterServices;
