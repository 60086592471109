import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ckeditor.css";

const RTEditor = (props: any) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props.value || props.formData || ""}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log("Editor is ready to use!", editor);
        editor.editing.view.change((writer: any) => {
          writer.setStyle(
            "height",
            "200px",
            editor.editing.view.document.getRoot()
          );
        });
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        props.onChange(data);
      }}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
};

export default RTEditor;
