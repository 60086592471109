import support from "@/assets/wcu1.png";
import deal1 from "@/assets/wcu4.png";
import solution from "@/assets/wcu2.png";
import donation from "@/assets/wcu3.png";
import worldwide from "@/assets/wcu5.png";
import protection from "@/assets/wcu6.png";

//mobile
import supportm from "@/assets/mobile/support.png";
import line from "./Line 1.png";
import deal1m from "@/assets/mobile/deal1.png";
import solutionm from "@/assets/mobile/solution.png";
import donationm from "@/assets/mobile/donation.png";
import worldwidem from "@/assets/mobile/worldwide.png";
import protectionm from "@/assets/mobile/protection.png";
import styled from "styled-components";
import Image from "@/components/utils/ResponsiveImage";
import { useTranslation } from "react-i18next";

const DottedBG = styled.div`
  flex: 0 1 50%;
  display: flex;
  background: url(${line});
  background-repeat: repeat-x;
  background-position: 0px 50px;
  justify-content: flex-end;
  margin-bottom: 20px;

  .link-text {
    width: 100%;
  }

  .inner-wrapper {
    display: flex;
    flex-direction: column;
  }

  &:nth-child(2n + 2) .inner-wrapper {
    align-items: flex-end;
  }

  &:nth-child(2n + 1) .link-text {
    margin-left: -20px;
  }
  &:nth-child(2n + 2) .link-text {
    margin-right: -20px;
  }

  &:nth-child(2n + 1) {
    justify-content: flex-start;
  }

  @media (min-width: 425px) {
    flex: 0 1 33%;
    background-position: 0px 90px;

    &:nth-child(3n + 1) {
      justify-content: flex-start;
    }
    &:nth-child(3n + 3) {
      justify-content: flex-end;
    }
    &:nth-child(3n + 2) {
      justify-content: center;
    }

    .inner-wrapper {
      max-width: 270px;
    }

    &:nth-child(3n + 3) .inner-wrapper {
      align-items: flex-end;
    }

    &:nth-child(3n + 3) .link-text {
      margin-right: -40px;
    }

    &:nth-child(3n + 1) .inner-wrapper {
      align-items: flex-start;
    }

    &:nth-child(3n + 1) .link-text {
      margin-left: -40px;
    }
    &:nth-child(3n + 2) .inner-wrapper {
      align-items: center;
    }
    &:nth-child(3n + 2) .link-text {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const Circle = styled.div`
  border: 5px solid #f9f9f9;
  outline: 2px solid ${"var(--green)"};
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 425px) {
    border: 10px solid #f9f9f9;
    width: 180px;
    height: 180px;
    border-radius: 90px;
  }
`;

const CardTreatment = ({ image, imagexs, text }: any) => {
  return (
    <DottedBG className="outer">
      <div className="inner-wrapper">
        <Circle>
          <Image xs={imagexs} md={image} alt="" />
        </Circle>
        <p className="link-text text-justify ">{text}</p>
      </div>
    </DottedBG>
  );
};

const WhyChooseUs = () => {
  const { t } = useTranslation();

  return (
    <div className="ps-5 pe-5 pt-3">
      <p className="blue heading bold px-5">{t("homePage.whyChooseUs")}</p>
      {/* <p className="body-text text-justify ">{t("homePage.whyChooseUs")}</p> */}
      <div className="d-flex flex-wrap justify-content-strech">
        <CardTreatment
          imagexs={supportm}
          image={support}
          text={t("homePage.whyChoose1")}
        />
        <CardTreatment
          imagexs={solutionm}
          image={solution}
          text={t("homePage.whyChoose2")}
        />
        <CardTreatment
          imagexs={donationm}
          image={donation}
          text={t("homePage.whyChoose3")}
        />
        <CardTreatment
          imagexs={deal1m}
          image={deal1}
          text={t("homePage.whyChoose4")}
        />
        <CardTreatment
          imagexs={worldwidem}
          image={worldwide}
          text={t("homePage.whyChoose5")}
        />
        <CardTreatment
          imagexs={protectionm}
          image={protection}
          text={t("homePage.whyChoose6")}
        />
      </div>
      <p className="justify ">
        {t("homePage.whyChooseUsLongDescription")}{" "}
      </p>
    </div>
  );
};

export default WhyChooseUs;
