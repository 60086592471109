import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface EnumArrays {
  enum: string[];
  enumNames: string[];
}

const getEnums = (array: any[], multiSelect: boolean) => {
  const initial: EnumArrays = {
    enum: [],
    enumNames: [],
  };
  if (!multiSelect) {
    initial.enum.push("-1");
    initial.enumNames.push("Select");
  }
  return array.reduce((acc, curr) => {
    acc.enum.push(curr.id);
    acc.enumNames.push(curr.name);
    return acc;
  }, initial);
};

const useOptions = (schema: any, entity: any, formData?: any) => {
  const [finalSchema, setFinalSchema] = useState(schema);
  const { data } = useSelector((state: RootState) => state.admin);
  const { [entity]: entityData } = data;
  const options = (entityData && entityData.options) || null;
  const status = (entityData && entityData.optionsApiStatus) || "";

  useEffect(() => {
    if (schema && options && status === "loaded") {
      const newSchema = structuredClone(schema);
      for (const [key, value] of Object.entries<any>(schema.properties)) {
        if (options.hasOwnProperty(key)) {
          if (value.type === "array" && options.hasOwnProperty(key)) {
            const currOptions = getEnums(options[key], true);
            newSchema.properties[key] = {
              ...value,
              items: {
                ...value.items,
                ...currOptions,
              },
            };
          } else {
            const currOptions = getEnums(options[key], false);
            newSchema.properties[key] = {
              ...value,
              ...currOptions,
            };
          }
        }
      }
      setFinalSchema(newSchema);
    }
  }, [schema, options, status]);

  //special case for hospital type & subtype
  useEffect(() => {
    if (
      formData &&
      formData.hasOwnProperty("hospital_service_categories") &&
      options &&
      options.hasOwnProperty("hospital_type")
    ) {
      const opts = formData.hospital_service_categories.length
        ? options.hospital_type.filter(
            (item: any) =>
              formData.hospital_service_categories.indexOf(
                item.speciality_category_id
              ) !== -1
          )
        : [];
      const currOptions = getEnums(opts, false);

      setFinalSchema((existingSchema: any) => {
        const clone = structuredClone(existingSchema);
        clone.properties.hospital_type = {
          ...existingSchema.properties.hospital_type,
          ...currOptions,
        };

        return clone;
      });
    }
  }, [formData, options]);

  //special case for doctor specialization & expert treatment
  useEffect(() => {
    if (
      formData &&
      formData.hasOwnProperty("specialization") &&
      options &&
      options.hasOwnProperty("treatment")
    ) {
      const opts = formData.specialization.length
        ? options.treatment.filter(
            (item: any) =>
              formData.specialization.indexOf(item.specialization_id) !== -1
          )
        : [];
      const currOptions = getEnums(opts, false);

      setFinalSchema((existingSchema: any) => {
        const clone = structuredClone(existingSchema);
        clone.properties.treatment = {
          ...existingSchema.properties.treatment,
          items: {
            ...currOptions,
          },
        };
        return clone;
      });
    }
  }, [formData, options]);

  //special case for hospital -
  useEffect(() => {
    if (
      formData &&
      formData.hasOwnProperty("hospital_service_categories") &&
      options &&
      options.hasOwnProperty("treatments")
    ) {
      const opts = formData.hospital_service_categories.length
        ? options.treatments.filter(
            (item: any) =>
              formData.hospital_service_categories.indexOf(
                item.speciality_category_id
              ) !== -1
          )
        : [];
      const currOptions = getEnums(opts, false);

      setFinalSchema((existingSchema: any) => {
        const clone = structuredClone(existingSchema);
        clone.properties.treatments = {
          ...existingSchema.properties.treatments,
          items: {
            ...currOptions,
          },
        };
        return clone;
      });
    }
  }, [formData, options]);

  return finalSchema;
};

export default useOptions;
