import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { getBlogs } from "@/features/admin/admin-actions";
import styled from "styled-components";
import Image from "@/components/utils/ResponsiveImage";
import { Link } from "react-router-dom";
import blog1 from "@/assets/blog-1.png";
import blog1xs from "@/assets/mobile/blog-1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Card = styled.div`
  width: 100%;
  margin-bottom: 20px;
    @media (max-width: 768px) {
    .link-para{
          padding-top: 4em;
    }
}
  a.link-text {
    color: var(--blue);
  }

  @media (min-width: 425px) {
    width: 324px;
  }
`;

export const ImageWrapper = styled.div`
  height: 132px;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 425px) {
    height: 250px;
  }
  @media (max-width: 768px) {
    height: 240px;
  }
`;

const CardWrapper = styled.div`
  flex: 0 1 50%;
  max-height: 290px;
  overflow: hidden;
  @media (min-width: 425px) {
    max-height: 450px;
  }
`;

const BlogCard = ({ image, imagexs, text, alt, slug }: any) => {
  return (
    <Card>
      <ImageWrapper>
        <Image xs={imagexs} md={image} alt={alt} />
      </ImageWrapper>
      <p className="link-text link-para">{text}</p>
      <Link className="link-text"  to={`/blog/${slug}`}>
        Read More &gt;
      </Link>
    </Card>
  );
};

const Blogs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    dispatch(
      getBlogs({
        catId: 1,
        page: 1,
        size: 10,
      })
    ).then((res: any) => {
      setBlog(res.payload.rows);
    });
  }, [dispatch]);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: (
      <div>
        <div className='next-slick-arrow'>
          <i className='bi bi-arrow-right-circle bi_arrow'></i>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className='prev-slick-arrow'>
          <i className='bi bi-arrow-left-circle bi_arrow'></i>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="p-2">
      <p className="blue heading bold px-5">Blogs</p>
      {blog && !!blog.length && (
        <Slider {...settings}>
          {blog.map(({ title, id, profile_url, document_url, thumbnail_alt_tag , slug }) => (
            <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-4' key={id}>
              <BlogCard
                slug={slug}
                imagexs={profile_url || blog1xs}
                image={profile_url || blog1}
                text={title}
                alt={thumbnail_alt_tag}
              />
            </div>
          ))}
        </Slider>
      )}
      <a className="btn faqBtn" href="./knowledge/blog">
        View more &gt;
      </a>
    </div>
  );
};

export default Blogs;
