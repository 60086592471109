import Awards from "../home/Awards";

const AwardsDetails = () => {
  return (
    <div>
      <Awards />
    </div>
  );
};

export default AwardsDetails;
