import useRoles from "@/components/hooks/useRoles";
import { getOptions, viewEntity } from "@/features/admin/admin-actions";
import { sendEnquiry } from "@/features/web/web-actions";
import { AppDispatch, RootState } from "@/store";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;

  .form-row {
    display: flex;
    flex-direction: row;
  }

  .form-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  input,
  textarea,
  select,
  button {
    border: 1px solid var(--blue);
  }

  input,
  select {
    height: 50px;
  }

  .form-row-timings {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    overflow: auto;
    scroll-snap-type: x mandatory;
    width: 100%;
    margin: 0 auto;

    button {
      background: white;
      border-radius: 5px;
      height: 50px;
      flex: 1 1 auto;
      display: flex;
      max-width: 114px;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 1.7rem;
      margin: 0rem 0rem 1rem;
      width: 100%;
      &.active {
        color: white;
        background: var(--blue);
      }
    }
  }
  .inter_radio {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .btn_title {
    display: inline-block;
    word-break: revert;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin: 1rem auto;
    font-size: 13px;
    font-weight: 500;
  }
  .submit-btn-container {
    text-align: center;

    button {
      background-color: var(--blue);
      height: 48px;
      width: 185px;

      @media (min-width: 425px) {
        width: 292px;
        height: 55px;
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }

  .appointment {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .body-text {
      margin-bottom: 24px;
    }
  }
`;

const language = [
  "English-Afrikaans-English",
  "English – Arabic",
  "English – Bengali (BANGLADESH)",
  "English – Chinese",
  "English – Czech",
  "English – Dutch",
  "English – French",
  "English – German",
  "English - Italian",
  "English - Japanese",
  "English - Korean",
  "English - Norwegian",
  "English - Portuguese",
  "English - Spanish",
  "English - Thai",
  "English - Vietnamese",
  "English - Sinhalese",
  "English – Amharic",
  "English - Swahili",
];

interface Slot {
  id: number;
  text: string;
}
const slots: Slot[] = [
  { id: 1, text: "9:00 - 9:30 AM" },
  { id: 2, text: "9:30 - 10:00 AM" },
  { id: 3, text: "10:00 - 10:30 AM" },
  { id: 4, text: "10:30 - 11:00 AM" },
  { id: 5, text: "11:00 - 11:30 AM" },
  { id: 6, text: "11:30 - 12:00 PM" },
  { id: 7, text: "12:00 - 12:30 PM" },
  { id: 8, text: "12:30 - 1:00 PM" },
  { id: 9, text: "1:00 - 1:30 PM" },
  { id: 10, text: "1:30 - 2:00 PM" },
  { id: 11, text: "2:00 - 2:30 PM" },
  { id: 12, text: "2:30 - 3:00 PM" },
  { id: 13, text: "3:00 - 3:30 PM" },
  { id: 14, text: "3:30 - 4:00 PM" },
  { id: 15, text: "4:00 - 4:30 PM" },
  { id: 16, text: "4:30 - 5:00 PM" },
  { id: 17, text: "5:00 - 5:30 PM" },
  { id: 18, text: "5:30 - 6:00 PM" },
  { id: 19, text: "6:00 - 6:30 PM" },
  { id: 20, text: "6:30 - 7:00 PM" },
  { id: 21, text: "7:00 - 7:30 PM" },
  { id: 22, text: "7:30 - 8:00 PM" },
  { id: 23, text: "8:00 - 8:30 PM" },
  { id: 24, text: "8:30 - 9:00 PM" },
];

// const TimeSlots = () => {
//   const [activeId, setActiveId] = useState<Slot>({ id: -1, text: "" });
//   const formik = useFormikContext();

//   useEffect(() => {
//     formik.setValues({
//       ...(formik.values as any),
//       available_time: activeId.text,
//     });
//   }, [activeId]);

//   return (
//     <div className="form-row-timings">
//       {slots.map(({ id, text }) => {
//         return (
//           <button
//             key={id}
//             type="button"
//             className={activeId.id === id ? "active" : ""}
//             onClick={() => setActiveId({ id, text })}
//           >
//             <p className="btn_title">{text}</p>
//           </button>
//         );
//       })}
//     </div>
//   );
// };

const countriesLocal = [
  {
    id: 5,
    name: "India",
  },
];

const entityName = "enquiry";

const AmbulanceHelpForm = ({
  style,
  isCompact = false,
  additionalVals,
}: any) => {
  let [searchParams] = useSearchParams();

  const [showForm, setShowForm] = useState(true);
  const [countries, setCountries] = useState(countriesLocal);
  const { data } = useSelector((state: RootState) => state.admin);
  const { options } = data[entityName] || { options: {} };
  const { userInfo = null, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();

  const { entityId = null } = userInfo || {};

  const [userData, setUserData] = useState<any>(null);

  const name = userData ? userData.first_name + " " + userData.last_name : "";

  const intialValues = {
    email: userData ? userData.email : "",
    name: name,
    city: userData ? userData.city : "",
    country: userData ? userData.country_id : "",
    isd: "",
    phone: userData ? userData.contact_no : "",
    medical_problem: "",
    dob: userData ? userData.dob : "",
    available_date: "",
    available_time: "",
    pick_up_location: "",
    drop_location: "",
    ambulance_enquiry_type: "",
    consult_interpreter: "",
    ref_url: window.location.href,
    hospital_id:
      searchParams.get("hospital_id") ||
      (additionalVals && additionalVals.hospital_id) ||
      null,
    doctor_id:
      searchParams.get("doctor_id") ||
      (additionalVals && additionalVals.doctor_id) ||
      null,
      patient_id: userData ? userData.id : null,
  };

  const isPatient = useRoles("PATIENT");

  useEffect(() => {
    if (isAuthenticated && entityId && isPatient) {
      dispatch(viewEntity({ name: "patient", id: entityId })).then(
        ({ payload }) => {
          if (payload && (payload as any).data) {
            setUserData((payload as any).data);
          }
        }
      );
    }
  }, [dispatch, isAuthenticated, entityId, isPatient]);

  useEffect(() => {
    if (options && options.country) {
      setCountries(options.country);
    }
  }, [options]);

  useEffect(() => {
    dispatch(getOptions(entityName));
  }, [dispatch]);

  const [selectedOption, setSelectedOption] = useState("");
  const [showSelectInput, setShowSelectInput] = useState(false);

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.currentTarget.value);
    setShowSelectInput(e.currentTarget.value === "yes");
  };

  return (
    <div
      className='card shadow'
      style={{ padding: 24, width: "100%", ...style }}
    >
      {showForm && (
        <>
          <p className='sub-heading bold blue'>
            Need Help? We will assist you!
          </p>
          <p className='link-text'>
            Get assistance for your treatment from our experienced care team!
          </p>
          <Formik
            enableReinitialize={true}
            initialValues={intialValues}
            validate={(values) => {
              const errors: any = {};

              if (!values.name) {
                errors.name = "Required";
              }

              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (values.phone === "") {
                errors.phone = "Required";
              }
              return errors;
            }}
            onSubmit={(
              { isd, phone, country, ...restValues },
              { setSubmitting, resetForm, setErrors }
            ) => {
              const values = {
                ...restValues,
                contact_no: isd + phone,
                country: parseInt(country),
              };
              dispatch(sendEnquiry(values)).then(() => {
                setErrors({});
                resetForm();
                setShowForm(false);
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <StyledForm>
                  <div className='form-row'>
                    <div className='flex-grow-1'>
                      <input
                        type='input'
                        className='form-control'
                        placeholder='Patient Name'
                        name='name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {touched.name && (
                        <div className='error'>{(errors as any).name}</div>
                      )}
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='flex-grow-1'>
                      <input
                        type='email'
                        name='email'
                        className='form-control'
                        placeholder='Email ID'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {touched.email && (
                        <div className='error'>{(errors as any).email}</div>
                      )}
                    </div>
                  </div>
                  {!isCompact && (
                    <div className='d-none d-sm-block'>
                      <div className='form-row'>
                        <div className='form-group col-md-5'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='City'
                            name='city'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                          />
                        </div>
                        <div className='form-group col-md-6 offset-md-1'>
                          <Form.Select
                            placeholder='Country'
                            className='form-control'
                            name='country'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                          >
                            <option value=''>Select Country</option>
                            {countries.map(({ id, name }: any) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  )}

                  {isCompact && (
                    <div className='form-row'>
                      <Form.Select
                        placeholder='Country'
                        className='form-control'
                        name='country'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                      >
                        <option value=''>Select Country</option>
                        {countries.map(({ id, name }: any) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  )}

                  <div className='row gx-4 gy-4 '>
                  <div className='col-md-3'>
                      <input
                        type='string'
                        className='form-control'
                        placeholder='Pick Up Location'
                        name='pick_up_location'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pick_up_location}
                      />
                    </div>
                  <div className='col-md-3'>
                      <input
                        type='string'
                        className='form-control'
                        placeholder='Drop Location'
                        name='drop_location'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.drop_location}
                      />
                    </div>
                    <div className='form-group col-md-6'>
                          <Form.Select
                            placeholder='Country'
                            className='form-control'
                            name='ambulance_enquiry_type'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ambulance_enquiry_type}
                          >
                            <option selected disabled value=''>Select Ambulance Type</option>
                            <option value='airAmbulance'>Air Ambulance</option>
                            <option value='trainAmbulance'>Train Ambulance</option>
                            <option value='basicLifeSupport'>Basic Life Support</option>
                            <option value='hearseAmbulance'>Hearse Ambulance</option>
                            <option value='advanceLifeSupport'>Advance Life Support</option>
                            
                          </Form.Select>
                        </div>
                  </div>
                  <div className='row'>
                    <div className='col-2'>
                      <input
                        type='string'
                        maxLength={4}
                        className='form-control'
                        placeholder='+91'
                        name='isd'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.isd}
                      />
                    </div>
                    <div className='col-9 offset-1 '>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Mobile Number'
                        name='phone'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        maxLength={10}
                      />
                      {touched.phone && (
                        <div className='error'>{(errors as any).phone}</div>
                      )}
                    </div>
                  </div>

                  <div className='form-row'>
                    <textarea
                      className='form-control'
                      placeholder='Describe your medical problem in short'
                      name='medical_problem'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.medical_problem}
                    ></textarea>
                  </div>

                  <div className='form-row'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Date of birth : DD/MM/YYYY'
                      name='dob'
                      onChange={handleChange}
                      onBlur={(e) => {
                        e.target.type = "text";
                        handleBlur(e);
                      }}
                      value={values.dob}
                      onFocus={(e) => (e.target.type = "date")}
                    />
                  </div>
                  <div className='body-text '>
                    Do you need an interpreter during consultation.
                  </div>
                  <div className='inter_radio'>
                    <label className='inter_radio'>
                      <input
                        type='radio'
                        value='yes'
                        checked={selectedOption === "yes"}
                        onChange={handleOptionChange}
                      />
                      Yes
                    </label>
                    <label className='inter_radio'>
                      <input
                        type='radio'
                        value='no'
                        checked={selectedOption === "no"}
                        onChange={handleOptionChange}
                      />
                      No
                    </label>
                    {showSelectInput && (
                      <Form.Select
                        placeholder='Time Slots'
                        className='form-control'
                        name='consult_interpreter'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.consult_interpreter}
                      >
                        <option value='' selected disabled>
                          Select language
                        </option>
                        {language.map((lang, index) => (
                          <option key={index} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </div>
                  {!isCompact && (
                    <div className=''>
                      <div className='appointment'>
                        <div className='body-text'>
                          Tell us a suitable date and time to contact you!
                        </div>

                        <div className='form-row row gy-4'>
                          <div className='form-group col-md-5 col-sm-12'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Date'
                              name='available_date'
                              value={values.available_date}
                              onChange={handleChange}
                              onBlur={(e) => {
                                e.target.type = "text";
                                handleBlur(e);
                              }}
                              onFocus={(e) => (e.target.type = "date")}
                            />
                          </div>
                          <div className='form-group col-md-6 col-sm-12 offset-md-1'>
                            <Form.Select
                              placeholder='Time Slots'
                              className='form-control'
                              name='available_time'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.available_time}
                            >
                              <option value='' selected disabled>
                                Time Slots
                              </option>
                              {slots.map(({ text }, index) => (
                                <option key={index} value={text}>
                                  {text}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='submit-btn-container'>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                </StyledForm>
              </form>
            )}
          </Formik>
        </>
      )}

      {!showForm && (
        <div>
          <p className='sub-heading bold blue'>Enquiry sent Successfully!</p>
          <div className='submit-btn-container'>
            <button
              type='button'
              onClick={() => setShowForm(true)}
              className='btn btn-primary'
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmbulanceHelpForm;
