import { BlueButton, WhiteButton } from "@/components/styled/forms";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { updateRank } from "@/features/web/doctor-actions";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px;
  button {
    width: 234px;
    height: 48px;
    flex-glow: 1;
  }
`;

const StyledColumn = styled.div`
  padding: 10px;
  max-height: 600px;
  min-height: 200px;
  overflow: auto;
  border: 1px solid var(--green);
  border-radius: 10px;
  // display: flex;
  // flex-direction: column;
  // gap: 10px;
`;

const StyledRow = styled.div`
  border: 1px solid var(--blue);
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 42px;
  .rank {
    font-size: 20px;
  }
  &.toppers {
    background: #e9f8ff;
  }
`;
const RowWrapper = styled.div`
  text-align: left;
  margin-top: 10px;
  &:first-child {
    margin-top: 0px;
  }
`;

function Task(props: any) {
  const { id, index, title } = props;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <RowWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledRow className={index < 10 ? "card toppers" : "card"}>
            <div>{title}</div>
            {index < 10 && <div className="rank">{index + 1} </div>}
          </StyledRow>
        </RowWrapper>
      )}
    </Draggable>
  );
}

const ToastSuccess = styled.div`
  position: absolute;
  z-index: 1;
  border: 1px solid var(--green);
  right: 0;
  left: 0;
  padding: 24px;
  background: rgb(233 240 226);
  border-radius: 10px;
  color: var(--green);
`;

const ToastError = styled.div`
  position: absolute;
  z-index: 1;
  border: 1px solid red;
  right: 0;
  left: 0;
  padding: 24px;
  background: #ffe7e9;
  border-radius: 10px;
  color: red;
`;
function Column(props: any) {
  const { droppableId, list, type } = props;

  return (
    <Droppable droppableId={droppableId} type={type}>
      {(provided) => (
        <StyledColumn {...provided.droppableProps} ref={provided.innerRef}>
          {list.map((val: any, index: number) => {
            return (
              <Task id={val.id} key={val.id} index={index} title={val.title} />
            );
          })}

          {provided.placeholder}
        </StyledColumn>
      )}
    </Droppable>
  );
}

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const ReOrderList = ({ list, name, rankColumn, entity }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [items, setItems] = useState<any>([]);
  const [resetList, setResetList] = useState<any>([]);
  const [saveDisabled, setSaveDisabled] = useState<any>(true);
  const [apiResponse, setApiResponse] = useState<any>(null);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newitems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newitems);
    setSaveDisabled(_.isEqual(resetList, newitems));
  };

  useEffect(() => {
    setItems([...list]);
    setResetList([...list]);
  }, [list]);

  const onSave = useCallback(() => {
    const topTen = Math.min(items.length, 10);
    const ids = [];
    for (let i = 0; i < topTen; i++) {
      ids.push(parseInt(items[i].id));
    }
    dispatch(
      updateRank({
        entity,
        putData: {
          idsInRank: ids,
          rankColumn,
        },
      })
    ).then((res) => {
      if (res.hasOwnProperty("error")) {
        setApiResponse({
          error: true,
        });
      } else {
        setApiResponse({
          success: true,
        });
        setSaveDisabled(true);
      }
      setTimeout(() => {
        setApiResponse(null);
      }, 5000);
    });
  }, [items, rankColumn, entity]);

  const onReset = useCallback(() => {
    setItems([...resetList]);
  }, [resetList]);

  useEffect(() => {
    setSaveDisabled(_.isEqual(resetList, items));
  }, [items, resetList]);
  return (
    <div className="card shadow" style={{ padding: 10 }}>
      <h4 className="blue">{name}</h4>
      <div style={{ position: "relative" }}>
        {apiResponse && (
          <>
            {apiResponse.success && <ToastSuccess>Ranking Saved</ToastSuccess>}
            {apiResponse.error && (
              <ToastError>Unable to Save Ranking </ToastError>
            )}
          </>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Column className="column" droppableId={name} list={items} />
        </DragDropContext>
      </div>

      <ButtonWrapper>
        <WhiteButton disabled={saveDisabled} onClick={onReset}>
          Reset
        </WhiteButton>
        <BlueButton disabled={saveDisabled} onClick={onSave}>
          Save
        </BlueButton>
      </ButtonWrapper>
    </div>
  );
};

export default ReOrderList;
