import { Formik } from "formik";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { userLogin } from "@/features/auth/authActions";
import { message } from "antd";
import StyledLoginForm from "./StyledLoginForm";

const titleMap: Record<string, string> = {
  patient: "Patient",
  doctor: "Doctor",
  hospital: "Hospital",
  facilitator: "Facilitator",
  "": "",
};

const LoginForm = ({ style, isCompact = false }: any) => {
  const { usertype = "" } = useParams<{ usertype: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="card shadow" style={{ padding: 36, ...style }}>
      <Formik
        initialValues={{
          password: "",
          email: "",
          rememberMe: true,
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          dispatch(userLogin(values)).then(({ payload }) => {
            if (payload && payload.token) {
              const { roles } = payload.user;
              const isPatient = roles.some(
                ({ code }: any) => code === "PATIENT"
              );
              const isAgent = roles.some(
                ({ code }: any) => code === "FAREAGENT"
              );
              const isDoctor = roles.some(
                ({ code }: any) => code === "DOCTOR"
              );
              const isAdmin = roles.some(
                ({ code }: any) => code === "ADMIN"
              );
              const isHopital = roles.some(
                ({ code }: any) => code === "HOSPITAL"
              );
              if (isAgent) {
                navigate("/agent/agent");
              }
              if (isPatient) {
                navigate("/");
              }
              if (isDoctor) {
                navigate("/admin/doctor");
              }
               if (isAdmin) {
                navigate("/admin/dashbroad");
              }
              if (isHopital) {
                navigate("/admin/hospital");
              }
            } else {
              message.error("Login failed");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledLoginForm>
              <h3>{titleMap[usertype] + " Login"}</h3>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email Id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && <div className="error">{errors.email}</div>}
                </div>
              </div>
              <div className="form-row">
                <div className="flex-grow-1">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {touched.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="text-center" style={{ width: "100%" }}>
                  <NavLink to="forgot-password">Forgot Password?</NavLink>
                </div>
              </div>
              <div className="submit-btn-container">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={errors && errors.hasOwnProperty("otp")}
                >
                  Login
                </button>
              </div>
            </StyledLoginForm>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
