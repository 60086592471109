import languagesm from "@/assets/mobile/language.png";
import language from "@/assets/icon/language.svg";

// import { lngs } from "../../i18n";
import { IClassName } from "./ContactBar";
import Image from "../utils/ResponsiveImage";
import styled from "styled-components";
// import { useTranslation } from "react-i18next";

const FlexContainer = styled.div`
  display: flex;
  span{
    color: var(--blue);
  }
`;

const Language = ({ className }: IClassName) => {
  // const { i18n } = useTranslation();

  // const handleTrans = (code: any) => {
  //   i18n.changeLanguage(code);
  // };

  return (
    <FlexContainer className={className}>
      <div className="icon-link">
        <Image xs={languagesm} md={language} alt="" />
        <span>
        English
        </span>
        {/* <>
          {lngs.map((lng, i) => {
            const { code, native } = lng;
            return (
              <input
                type="button"
                onClick={() => handleTrans(code)}
                value={native}
              />
            );
          })}
        </> */}
      </div>
    </FlexContainer>
  );
};

export default Language;
