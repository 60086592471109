import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState, AppDispatch } from '../../store';
import { getStaticPages } from "@/features/web/web-actions";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const StyledSection = styled.div`
  text-align: left;
`;

const AboutUS = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { images, loading, error } = useSelector((state: RootState) => state.staticPages);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getStaticPages({ categoryId: 1 })).then((res: any) => {
      setData(res.payload.rows);
    }); 
  }, [dispatch]);

  const pageData = data[0] || {};
  const { title, banner_url, banner_alt_tag, content ,meta_title,meta_keyword,meta_description ,canonical} = pageData;

  return (
    <StyledSection>
      <div className="about_us_div">
        <p className="blue text-center heading bold">{title}</p>

        <Helmet>
          <title>{meta_title}</title>
          <meta name="keywords" content={meta_keyword} />
          <meta name="description" content={meta_description} />
          <link rel="canonical" href={canonical} />
        </Helmet>

        <div className="article-title green sub-heading"></div>
        <div style={{ textAlign: "center" }}>
        {banner_url ? <img src={banner_url} alt={banner_alt_tag} /> : null}
        </div>
        <br />
        <br />
        {content ? <p dangerouslySetInnerHTML={{ __html: content }}></p> : null}
      </div>
    </StyledSection>
  );
};

export default AboutUS;
